import { DateWrapper } from '@/core';
import { UpdateCampaignFailure } from '@/features/campaigns/domain';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { updateCampaignSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useUpdateCampaign = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: {
      id: string;
      name: string;
      trackingParameter: string;
      startDate: DateWrapper;
      endDate: DateWrapper;
    }) => {
      const result = await facade.updateCampaign(params.id, {
        name: params.name,
        trackingParameter: params.trackingParameter,
        startDate: params.startDate,
        endDate: params.endDate,
      });

      if (result.isRight()) {
        const campaign = result.extract();
        store.dispatch(updateCampaignSucceeded, { campaign });
        toast.success(t('campaigns.useUpdateCampaign.success'));
      } else {
        const error= result.extract() as UpdateCampaignFailure;
      
        switch (error.status) {
          case 503:
            toast.error(t('common.errors.serviceUnavailable')); break;
          case 500:
            toast.error(t('common.errors.internalServer')); break;
          case 400:
            toast.error(t('common.errors.badRequest')); break;
          default:
            toast.error(t('campaigns.useUpdateCampaign.error'));
        }
      }
    },
  );
};
