
import { ModalEvents } from '@/components/modal/modalEvents';
import { computed, defineComponent, ref } from 'vue';
import { useDeleteFolder } from '../application/useDeleteFolder';
import { useDeleteAsset } from '../application/useDeleteAsset';
import { Folder } from '../domain/folder';
import { Asset } from '../domain/asset';

export default defineComponent({
  props: {
    isModalOpen: { type: Boolean, required: true },
    media: [Asset, Folder],
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const hasSubmitted = ref(false);

    const {
      isLoading: isFolderDeleteLoading,
      call: folderDeleteCall,
    } = useDeleteFolder();
    const {
      isLoading: isFileDeleteLoading,
      call: fileDeleteCall,
    } = useDeleteAsset();

    const onCancel = () => {
      hasSubmitted.value = false;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onConfirm = () => {
      hasSubmitted.value = true;
      if (props.media) {
        if (props.media.isFile()) {
          fileDeleteCall(props.media as Asset);
        } else {
          folderDeleteCall(props.media as Folder);
        }
        onCancel();
      }
    };

    const mediaName = computed(() => props.media?.getName() ?? '');

    return {
      isLoading: isFileDeleteLoading || isFolderDeleteLoading,
      onCancel,
      onConfirm,
      hasSubmitted,
      mediaName,
    };
  },
});
