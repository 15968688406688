import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Contract } from '../../domain/contract';

export const useGetContracts = (): { contracts: ComputedRef<Contract[]> } => {
  const store = useStore();

  const contracts = computed(() => store.getters.contracts);

  return { contracts };
};
