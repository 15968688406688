<template>
  <div class="my-2">
    <div class="py-5 flex flex-col" v-if="!versions?.length">
      <app-txt
        variant="medium"
        class="text-center font-semibold text-black-basic"
        >{{ t('emptyState.title') }}</app-txt
      >
      <app-txt variant="small" class="text-center mt-2">{{
        t('emptyState.description')
      }}</app-txt>
    </div>
    <VersionItem
      v-for="(version, idx) in versions"
      :key="version.id"
      @click="$emit(updateVersionIdSelected, version.id)"
      :id="version.id"
      :isFirstItem="idx === 0"
      :type="version.type"
      :date="version.createdAt.toTableFormatWithHours()"
      :dateFromRestored="version.dateFromRestored?.toTableFormatWithHours()"
      :changedFormat="version.changedFormat"
      :author="getFullName(version.author)"
      :isSelected="versionIdSelected === version.id"
    />
  </div>
</template>

<script lang="ts">
import { Version } from '@/features/campaigns/domain/valueObjects/version';
import { defineComponent, PropType } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { User } from '@/features/users/domain/user';
import VersionItem from './versionItem.vue';
const updateVersionIdSelected = 'update:updateVersionIdSelected';
export default defineComponent({
  components: { VersionItem },
  emits: [updateVersionIdSelected],
  props: {
    versionIdSelected: { type: String, required: true },
    motiveVersions: { type: Array as PropType<Version[]>, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.versionHistory');
    return {
      versions: props.motiveVersions,
      updateVersionIdSelected,
      t,
      getFullName: User.validateUserEmailAndGetFullname,
    };
  },
});
</script>
