import { FormatDefault } from '../domain/format/formatDefault';

export class FormatDefaultDTO {
  static toDomain(json: { [key: string]: any }): FormatDefault {
    return FormatDefault.create({
      id: json._id || json.id,
      name: json.name,
      motiveId: json.motiveId,
      motiveName: json.motiveName,
      isActive: json.isActive,
      size: json.size,
      slides: json.slides,
      slideOrder: json.slideOrder,
    });
  }

  static toJson(format: FormatDefault): { [key: string]: any } {
    return {
      id: format.id,
      name: format.name,
      motiveId: format.motiveId,
      motiveName: format.motiveName,
      isActive: format.isActive,
      size: format.size,
      slides: format.slides,
      slideOrder: format.slideOrder,
    };
  }
}
