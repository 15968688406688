import { Either } from 'purify-ts';
import { validateDate, ValueObjectFailure, ValueObjectValue } from '..';
import { UnexpectedValueError } from './error/errors';

export abstract class ValueObject<T> {
  constructor(protected val: Either<ValueObjectFailure<T>, T>) {}

  get value(): ValueObjectValue<T> {
    return this.val;
  }

  isValid(): boolean {
    return this.value.isRight();
  }

  getOrCrash(): T {
    return this.value.either(
      _ => {
        throw new UnexpectedValueError(_);
      },
      _ => _,
    );
  }

  getValue(): T {
    return this.value.either(
      _ => _.failedValue,
      (_): T => _,
    );
  }

  getOrEmpty(): T | null {
    return this.value.either(
      () => null,
      (_): T => _,
    );
  }

  trueOrFalse(): boolean {
    return this.value.either(
      () => false,
      () => true,
    );
  }

  equals(anotherValue: ValueObject<T>): boolean {
    return this.getValue() === anotherValue.getValue();
  }
}

export class DateWrapper extends ValueObject<Date> {
  constructor(val: ValueObjectValue<Date>) {
    super(val);
  }

  static create(input: string) {
    return new DateWrapper(validateDate(new Date(input)));
  }

  static createFromDate(input: Date) {
    return new DateWrapper(validateDate(input));
  }

  static empty() {
    return DateWrapper.create('');
  }

  toTableFormat(monthFormat: 'long' | 'short' = 'long') {
    const day = this.getValue()
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = this.getValue().getMonth() + 1;
    const year = this.getValue()
      .getFullYear()
      .toString();
    const shortYear = year.substring(year.length - 2, year.length);
    return `${day}.${month.toString().padStart(2, '0')}.${shortYear}`;
  }

  toTableFormatWithHours() {
    const minutes = this.getValue()
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const hours = this.getValue()
      .getHours()
      .toString()
      .padStart(2, '0');
    const day = this.getValue()
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = this.getValue().getMonth() + 1;
    const time = `${hours}:${minutes}`;
    const year = this.getValue()
      .getFullYear()
      .toString();

    const shortYear = year.substring(year.length - 2, year.length);
    return `${day}.${month.toString().padStart(2, '0')}.${shortYear}, ${time}`;
  }

  toVersionFormat() {
    const day = this.getValue().getDate();
    const month = this.getValue().toLocaleString('default', {
      month: 'short',
    });
    const time = this.getTimeInCurrentDay();
    return `${month} ${day}, ${time}`;
  }

  toSyncTableFormat(locale: string) {
    const dayName = this.getValue().toLocaleDateString(locale, {
      weekday: 'long',
    });
    const formattedDayName =
      dayName.charAt(0).toUpperCase() + dayName.substr(1);
    const day = ('0' + this.getValue().getDate()).slice(-2);
    const month = ('0' + (this.getValue().getMonth() + 1)).slice(-2);
    const year = ('000' + this.getValue().getFullYear()).slice(-4);

    return `${formattedDayName}, ${day}.${month}.${year}`;
  }

  equals(other: DateWrapper) {
    return this.getValue()?.toISOString() === other.getValue()?.toISOString();
  }

  getTimeInCurrentDay() {
    const minutes = this.getValue()
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const hours = this.getValue()
      .getHours()
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
