import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { emptyCampaignBinSucceeded } from '../../store/store';
import { EmptyCampaignBinFailure } from '../../domain';

export const useEmptyCampaignBin = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async () => {
    const result = await facade.emptyCampaignBin();

    if (result.isRight()) {
      store.dispatch(emptyCampaignBinSucceeded, {});
      toast.success(t('campaigns.useEmptyCampaignBin.success'));
    } else {
      const error= result.extract() as EmptyCampaignBinFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useEmptyCampaignBin.error'));
      }
    }
  });
};
