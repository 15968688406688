
import { CampaignEvents } from '../events';
import { defineComponent } from 'vue';
import { useGetOptionsForMotive } from '../../application/motive/list/useGetOptionsForMotive';

export default defineComponent({
  props: {
    motiveId: { type: String, required: true },
  },
  emits: [CampaignEvents.DELETE_MOTIVE, CampaignEvents.DUPLICATE_MOTIVE],
  setup(props, context) {
    const options = useGetOptionsForMotive(props.motiveId, context.emit);

    return { options };
  },
});
