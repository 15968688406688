import { PreviewAttributes } from './iPreviewAttributes';
import { PreviewTransition } from './iPreviewTransition';
import { PreviewInterferer } from './previewInterferer';
import { PreviewPrice } from './previewPrice';

export type PreviewSlideContentPriceCreationParams = {
  attributes: PreviewAttributes;
  content: PreviewPrice;
  transition?: PreviewTransition;
};

export type PreviewSlideContentCreationParams = {
  attributes: PreviewAttributes;
  content: string;
  transition?: PreviewTransition;
};

export type PreviewSlideContentInterfererCreationParams = {
  attributes?: PreviewAttributes;
  content: PreviewInterferer[];
  transition?: PreviewTransition;
};

export enum PreviewSlideContentType {
  Image = 'image',
  Text = 'text',
  Price = 'price',
  Interferer = 'slider',
}

export class PreviewSlideContent {
  private constructor(
    public type: PreviewSlideContentType,
    public content: string | PreviewPrice | PreviewInterferer[],
    public transition?: PreviewTransition | undefined,
    public attributes?: PreviewAttributes | undefined,
  ) {}

  static createAsPrice(params: PreviewSlideContentPriceCreationParams) {
    return new PreviewSlideContent(
      PreviewSlideContentType.Price,
      params.content,
      params.transition,
      params.attributes,
    );
  }

  static createAsText(params: PreviewSlideContentCreationParams) {
    return new PreviewSlideContent(
      PreviewSlideContentType.Text,
      params.content,
      params.transition,
      params.attributes,
    );
  }

  static createAsImage(params: PreviewSlideContentCreationParams) {
    return new PreviewSlideContent(
      PreviewSlideContentType.Image,
      params.content,
      params.transition,
      params.attributes,
    );
  }

  static createAsInterferer(
    params: PreviewSlideContentInterfererCreationParams,
  ) {
    return new PreviewSlideContent(
      PreviewSlideContentType.Interferer,
      params.content,
      params.transition,
      params.attributes,
    );
  }
}
