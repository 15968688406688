import {
  CreateSlideForValuesParams,
  CreateSlideParams,
  Slide,
} from '../valueObjects/slide';
import { mapValues } from '../utils';
import { FormatRaw } from './formatRaw';

export class Format {
  constructor(
    public id: string,
    public name: string,
    public motiveId: string,
    public motiveName: string,
    public isActive: boolean,
    public slides: Record<string, Slide>,
    public slideOrder: string[],
  ) {}

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    motiveName: string;
    isActive: boolean;
    slides: Record<string, CreateSlideParams>;
    slideOrder: string[];
  }) {
    return new Format(
      params.id,
      params.name,
      params.motiveId,
      params.motiveName,
      params.isActive,
      mapValues(params.slides, Slide.create),
      params.slideOrder,
    );
  }

  static createForValues(params: {
    id: string;
    name: string;
    motiveId: string;
    motiveName: string;
    isActive: boolean;
    slides: Record<string, CreateSlideForValuesParams>;
    slideOrder: string[];
  }) {
    return new Format(
      params.id,
      params.name,
      params.motiveId,
      params.motiveName,
      params.isActive,
      mapValues(params.slides, Slide.createForValues),
      params.slideOrder,
    );
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      motiveId: this.motiveId,
      motiveName: this.motiveName,
      isActive: this.isActive,
      slides: mapValues(this.slides, slide => slide.toJson()),
      slideOrder: this.slideOrder,
    };
  }

  toRaw() {
    return FormatRaw.create({
      id: this.id,
      name: this.name,
      motiveId: this.motiveId,
      motiveName: this.motiveName,
      isActive: this.isActive,
      slides: mapValues(this.slides, slide => slide.toJson()),
      slideOrder: this.slideOrder,
    });
  }
}

export type UpdateFormatDTO = {
  id: string;
  name: string;
  motiveId: string;
  slides: Record<string, Partial<Slide> | null>;
  slideOrder?: string[];
};
