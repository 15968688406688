import { HttpService, AxiosService } from '@/core';
import { User } from '@/features/users/domain/user';
import { UserDTO } from '@/features/users/infrastructure/user_dto';
import { devLog } from '@/utils/devLog';
import { Right, Left, Either } from 'purify-ts';
import {
  SessionLoginParams,
  SessionLoginFailure,
  Meta,
  SendResetMailFailure,
  ResetPasswordFailure,
} from '../domain';
import { SessionMap } from './session_map';

const endpoints = {
  login: 'auth/login',
  sendResetMail: 'password/forgot',
  resetPassword: 'password/reset',
};

const logError = (e: Error) => {
  devLog(`[sessionHttpFacade]: Failed with: ${e}`);
};

export class SessionHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async login(
    params: SessionLoginParams,
  ): Promise<Either<SessionLoginFailure, { user: User; meta: Meta }>> {
    try {
      const response = await this.httpService.post(
        endpoints.login,
        SessionMap.loginToAPI(params),
      );
      return Right({
        user: UserDTO.toDomain(response.data.user),
        meta: {
          accessToken: response.data.meta.accessToken,
          refreshToken: response.data.meta.refreshToken,
        },
      });
    } catch (e) {
      logError(e);
      return Left(new SessionLoginFailure(e, e.code));
    }
  }

  async sendResetMail(
    email: string,
  ): Promise<Either<SendResetMailFailure, null>> {
    try {
      await this.httpService.post(endpoints.sendResetMail, {
        email,
      });

      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new SendResetMailFailure(e, e.code));
    }
  }

  async resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Promise<Either<ResetPasswordFailure, null>> {
    try {
      await this.httpService.post(endpoints.resetPassword, {
        password,
        confirmPassword,
        token,
      });

      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new ResetPasswordFailure(e, e.code));
    }
  }
}
