import { Asset, AssetType } from '@/features/media/domain/asset';
import { ImageContentType } from './blocks/slideImage';

const maxDimension = 2000;

export class VisualInfo {
  private constructor(
    public visualWanted: ImageContentType,
    public visualUrl?: string | undefined,
    public type?: AssetType,
    public width?: number | undefined,
  ) {}

  public static createEmptyVisual(visualWanted: ImageContentType): VisualInfo {
    return new VisualInfo(visualWanted);
  }

  public static getEditParams(asset: Asset) {
    return {
      width: Math.min(asset.dimensions?.width ?? maxDimension, maxDimension),
      height: Math.min(asset.dimensions?.height ?? maxDimension, maxDimension),
    };
  }

  public static createFromAsset(asset: Asset, visualWanted: ImageContentType) {
    if (asset.type === AssetType.animation) {
      return new VisualInfo(visualWanted, asset.url, AssetType.animation);
    }

    return new VisualInfo(
      visualWanted,
      asset.type === 'svg' || asset.type === 'gif'
        ? asset.s3TrimmedUrl
        : Asset.createImageHandlerUrl(
            asset.s3TrimmedUrl,
            VisualInfo.getEditParams(asset),
          ),
      AssetType[asset.type],
      asset.dimensions?.width,
    );
  }
}
