import { HttpService, AxiosService, DateWrapper } from '@/core';

import { devLog } from '@/utils/devLog';
import { Either, Left, Right } from 'purify-ts';
import {
  ActivateCampaignFailure,
  ActivateFormatFailure,
  ActivateMotiveFailure,
  BulkCreateMotivesFailure,
  CreateCampaignFailure,
  CreateMotiveFailure,
  CreateMotiveVersionFailure,
  DeactivateCampaignFailure,
  DeactivateFormatFailure,
  DeactivateMotiveFailure,
  DeleteCampaignFailure,
  DeleteMotiveFailure,
  DeleteMotiveGroupFailure,
  DuplicateCampaignFailure,
  DuplicateMotiveFailure,
  EmptyCampaignBinFailure,
  GetAllMotivesFailure,
  GetCampaignsFailure,
  GetContractsFailure,
  GetDevicesFailure,
  GetFormatByIdFailure,
  GetFormatsForMotiveIdFailure,
  GetMotiveByIdFailure,
  GetMotiveFormatsPreviewFailure,
  GetMotiveGroupsFailure,
  GetMotivesForCampaignIdFailure,
  GetMotiveVersionsFailure,
  GetRawFormatsForMotiveIdFailure,
  HardDeleteCampaignFailure,
  RestoreMotiveToVersionFailure,
  UpdateCampaignFailure,
  UpdateFormatFailure,
  UpdateMotiveFailure,
} from '../domain';
import { Campaign } from '../domain/campaign';
import { Contract } from '../domain/contract';
import { Device } from '../domain/device';
import { CreateMotive } from '../domain/motive/createMotive';
import { Format, UpdateFormatDTO } from '../domain/format/format';
import { FormatOverview } from '../domain/format/formatOverview';
import { FormatPreview } from '../domain/format/formatPreview';
import { Motive } from '../domain/motive/motive';
import { CampaignDTO } from './campaign_dto';
import { ContractDTO } from './contract_dto';
import { DeviceDTO } from './device-dto';
import { FormatDTO } from './format_dto';
import { FormatOverviewDTO } from './format_overview_dto';
import { MotiveDTO } from './motive_dto';
import { MotiveGroup } from '../domain/motive/motiveGroup';
import { MotiveGroupDTO } from './motiveGroup_dto';
import { FormatRawDTO } from './format_raw_dto';
import { FormatRaw } from '../domain/format/formatRaw';
import { VersionDTO } from './version_dto';
import { CreateVersion, Version } from '../domain/valueObjects/version';
import { UpdateMotiveGeneralSettings } from '@/features/campaigns/application/motive/actions/useUpdateMotiveGeneralSettings';
import { FormatStaticCreationParams } from '@/features/campaigns/domain/format/formatStaticDefault';

export const endpoints = {
  getCampaigns: 'campaign',
  createCampaign: 'campaign',
  deactivateCampaign: (id: string) => `campaign/deactivate/${id}`,
  activateCampaign: (id: string) => `campaign/activate/${id}`,
  deleteCampaign: (id: string) => `campaign/${id}`,
  updateCampaign: (id: string) => `campaign/${id}`,
  hardDeleteCampaign: (id: string) => `campaign/hardDelete/${id}`,
  getContracts: 'campaign/products',
  getDevices: 'campaign/devices',
  createMotive: 'campaign/motive',
  createMotiveVersion: 'campaign/motive/version',
  restoreMotiveToVersion: (params: { motiveId: string; versionId: string }) =>
    `campaign/motive/${params.motiveId}/version/${params.versionId}`,
  getMotiveVersions: (id: string) => `campaign/motive/version?motiveId=${id}`,
  getMotivesForCampaignId: (id: string) => `campaign/motive?campaignId=${id}`,
  getMotiveById: (id: string) => `campaign/motive/${id}`,
  getRawFormatsDefaultsInstancesForMotiveId: (id: string) =>
    `campaign/format-default-instance/raw?motiveId=${id}`,
  getFormatsForMotiveId: (id: string) => `campaign/format?motiveId=${id}`,
  getRawFormatsForMotiveId: (id: string) =>
    `campaign/format/raw?motiveId=${id}`,
  getMotiveFormatsPreview: (id: string) => `campaign/motive/${id}/previews`,
  updateMotive: (id: string) => `campaign/motive/${id}`,
  duplicateCampaign: (id: string) => `campaign/duplicate/${id}`,
  exportCampaign: (id: string) => `campaign/export/${id}`,
  exportMotivesOverview: '/campaign/export/overview/',
  getFormatById: (id: string) => `campaign/format/${id}`,
  updateFormat: (id: string) => `campaign/format/${id}`,
  deactivateFormat: (id: string) => `campaign/format/deactivate/${id}`,
  activateFormat: (id: string) => `campaign/format/activate/${id}`,
  deactivateMotive: (id: string) => `campaign/motive/deactivate/${id}`,
  activateMotive: (id: string) => `campaign/motive/activate/${id}`,
  deleteMotive: (id: string) => `campaign/motive/${id}`,
  getAllMotives: 'campaign/motives',
  emptyCampaignBin: 'campaign/emptyBin',
  duplicateMotive: (id: string) => `campaign/duplicateMotive/${id}`,
  bulkCreateMotives: 'campaign/bulkCreateMotives',
  getMotiveGroups: 'campaign/motiveGroup',
  deleteMotiveGroup: (id: string) => `campaign/motiveGroup/${id}`,
  updateMotiveGeneralSettings: (id: string) =>
    `campaign/motive/${id}/general-settings`,
  fetchFormatStaticDefaults: () => `campaign/motive/defaults`,
};

const logError = (e: Error) => {
  devLog(`[campaignHttpFacade]: Failed with: ${e}`);
};
export class CampaignHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async getCampaigns(): Promise<Either<GetCampaignsFailure, Campaign[]>> {
    try {
      const response = await this.httpService.get(endpoints.getCampaigns);
      const campaigns = response.data.map((campaign: Record<string, any>) =>
        CampaignDTO.toDomain(campaign),
      );
      return Right(campaigns);
    } catch (e) {
      logError(e as any);
      return Left(new GetCampaignsFailure(e, (e as any)?.code));
    }
  }

  async createCampaign(params: {
    name: string;
    trackingParameter: string;
    startDate: DateWrapper;
    endDate: DateWrapper;
  }): Promise<Either<CreateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.post(endpoints.createCampaign, {
        name: params.name,
        trackingParameter: params.trackingParameter,
        startDate: params.startDate.getOrEmpty(),
        endDate: params.endDate.getOrEmpty(),
      });
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new CreateCampaignFailure(e, (e as any)?.code));
    }
  }

  async deactivateCampaign(params: {
    id: string;
  }): Promise<Either<DeactivateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.put(
        endpoints.deactivateCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new DeactivateCampaignFailure(e, (e as any)?.code));
    }
  }

  async activateCampaign(params: {
    id: string;
  }): Promise<Either<ActivateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.put(
        endpoints.activateCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new ActivateCampaignFailure(e, (e as any)?.code));
    }
  }

  async deleteCampaign(params: {
    id: string;
  }): Promise<Either<DeleteCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.delete(
        endpoints.deleteCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new DeleteCampaignFailure(e, (e as any)?.code));
    }
  }

  async updateCampaign(
    id: string,
    params: {
      name: string;
      trackingParameter: string;
      startDate: DateWrapper;
      endDate: DateWrapper;
    },
  ): Promise<Either<UpdateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.put(
        endpoints.updateCampaign(id),
        {
          name: params.name,
          trackingParameter: params.trackingParameter || '',
          startDate: params.startDate.getOrEmpty(),
          endDate: params.endDate.getOrEmpty(),
        },
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new UpdateCampaignFailure(e, (e as any)?.code));
    }
  }

  async hardDeleteCampaign(params: {
    id: string;
  }): Promise<Either<HardDeleteCampaignFailure, null>> {
    try {
      await this.httpService.delete(endpoints.hardDeleteCampaign(params.id));
      return Right(null);
    } catch (e) {
      logError(e as any);
      return Left(new HardDeleteCampaignFailure(e, (e as any)?.code));
    }
  }

  async getContracts(): Promise<Either<GetContractsFailure, Contract[]>> {
    try {
      const response = await this.httpService.get(endpoints.getContracts);
      const contracts = response.data.map((contract: Record<string, any>) =>
        ContractDTO.toDomain(contract),
      );
      return Right(contracts);
    } catch (e) {
      logError(e as any);
      return Left(new GetContractsFailure(e, (e as any)?.code));
    }
  }

  async getDevices(): Promise<Either<GetDevicesFailure, Device[]>> {
    try {
      const response = await this.httpService.get(endpoints.getDevices);
      const devices = response.data.map((device: Record<string, any>) =>
        DeviceDTO.fromBackendDTO(device),
      );
      return Right(devices);
    } catch (e) {
      logError(e as any);
      return Left(new GetDevicesFailure(e, (e as any)?.code));
    }
  }

  async getAllMotives(): Promise<Either<GetCampaignsFailure, Motive[]>> {
    try {
      const response = await this.httpService.get(endpoints.getAllMotives);
      const motives = response.data.map((motive: Record<string, any>) =>
        MotiveDTO.toDomain(motive),
      );
      return Right(motives);
    } catch (e) {
      logError(e as any);
      return Left(new GetAllMotivesFailure(e, (e as any)?.code));
    }
  }

  async exportMotivesOverview(
    campaignIds: string,
    status?: string,
  ): Promise<void> {
    try {
      const params = new URLSearchParams();
      if (campaignIds) params.append('campaignIds', campaignIds);
      if (status) params.append('status', status);

      const url = `${endpoints.exportMotivesOverview}?${params.toString()}`;

      const response = await this.httpService.get(url, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'motives.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      logError(e as any);
      throw new Error('Failed to export motives overview');
    }
  }

  async createMotive(
    createMotive: CreateMotive,
  ): Promise<Either<CreateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.post(
        endpoints.createMotive,
        createMotive.toJson(),
      );

      const motive = MotiveDTO.toDomain(response.data);
      return Right(motive);
    } catch (e) {
      logError(e as any);
      return Left(new CreateMotiveFailure(e, (e as any)?.code));
    }
  }

  async createMotiveVersion(
    createMotiveVersion: CreateVersion,
  ): Promise<Either<CreateMotiveVersionFailure, Version>> {
    try {
      const response = await this.httpService.post(
        endpoints.createMotiveVersion,
        createMotiveVersion,
      );
      const version = VersionDTO.toDomain(response.data);
      return Right(version);
    } catch (e) {
      logError(e as any);
      return Left(new CreateMotiveVersionFailure(e, (e as any)?.code));
    }
  }
  async getMotiveVersions(
    motiveId: string,
  ): Promise<Either<GetMotiveVersionsFailure, Version[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotiveVersions(motiveId),
      );
      const versions = response.data.map((version: Record<string, any>) =>
        VersionDTO.toDomain(version),
      );
      return Right(versions);
    } catch (e) {
      logError(e as any);
      return Left(new GetMotiveVersionsFailure(e, (e as any)?.code));
    }
  }

  async restoreMotiveToVersion(params: {
    author: string;
    motiveId: string;
    versionId: string;
  }): Promise<
    Either<GetMotiveVersionsFailure, { motive: Motive; formats: Format[] }>
  > {
    try {
      const response = await this.httpService.put(
        endpoints.restoreMotiveToVersion({
          motiveId: params.motiveId,
          versionId: params.versionId,
        }),
        {
          author: params.author,
        },
      );
      const motive = MotiveDTO.toDomain(response.data.motive);
      const formats = response.data.formats.map((format: Record<string, any>) =>
        FormatDTO.toDomain(format),
      );
      return Right({ motive, formats });
    } catch (e) {
      logError(e as any);
      return Left(new RestoreMotiveToVersionFailure(e, (e as any)?.code));
    }
  }

  async getMotivesForCampaignId(
    campaignId: string,
  ): Promise<Either<GetMotivesForCampaignIdFailure, Motive[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotivesForCampaignId(campaignId),
      );

      const motives = response.data.map((motive: Record<string, any>) =>
        MotiveDTO.toDomain(motive),
      );
      return Right(motives);
    } catch (e) {
      logError(e as any);
      return Left(new GetMotivesForCampaignIdFailure(e, (e as any)?.code));
    }
  }

  async getMotiveById(
    motiveId: string,
  ): Promise<Either<GetMotiveByIdFailure, Motive>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotiveById(motiveId),
      );

      const motive = MotiveDTO.toDomain(response.data);
      return Right(motive);
    } catch (e) {
      logError(e as any);
      return Left(new GetMotiveByIdFailure(e, (e as any)?.code));
    }
  }

  async getRawFormatsDefaultsInstancesForMotiveId(
    motiveId: string,
  ): Promise<Either<GetFormatsForMotiveIdFailure, FormatOverview[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getRawFormatsDefaultsInstancesForMotiveId(motiveId),
      );
      const formats = response.data.map((format: Record<string, any>) =>
        FormatRawDTO.toDomain(format),
      );
      return Right(formats);
    } catch (e) {
      logError(e as any);
      return Left(new GetFormatsForMotiveIdFailure(e, (e as any)?.code));
    }
  }

  async getFormatsForMotiveId(
    motiveId: string,
  ): Promise<Either<GetFormatsForMotiveIdFailure, FormatOverview[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getFormatsForMotiveId(motiveId),
      );

      const formatOverview = response.data.map(
        (formatOverview: Record<string, any>) =>
          FormatOverviewDTO.toDomain(formatOverview),
      );
      return Right(formatOverview);
    } catch (e) {
      logError(e as any);
      return Left(new GetFormatsForMotiveIdFailure(e, (e as any)?.code));
    }
  }

  async getRawFormatsForMotiveId(
    motiveId: string,
  ): Promise<Either<GetRawFormatsForMotiveIdFailure, FormatRaw[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getRawFormatsForMotiveId(motiveId),
      );

      const formats = response.data.map((format: Record<string, any>) =>
        FormatRawDTO.toDomain(format),
      );

      return Right(formats);
    } catch (e) {
      logError(e as any);
      return Left(new GetRawFormatsForMotiveIdFailure(e, (e as any)?.code));
    }
  }

  async getMotiveFormatsPreview(
    motiveId: string,
  ): Promise<Either<GetMotiveFormatsPreviewFailure, FormatPreview[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotiveFormatsPreview(motiveId),
      );

      return Right(response.data);
    } catch (e) {
      logError(e as any);
      return Left(new GetMotiveFormatsPreviewFailure(e, (e as any)?.code));
    }
  }

  async updateMotive(
    motive: Motive,
  ): Promise<Either<UpdateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.put(
        endpoints.updateMotive(motive.id),
        motive.toJson(),
      );

      const updatedMotive = MotiveDTO.toDomain(response.data);

      return Right(updatedMotive);
    } catch (e) {
      logError(e as any);
      return Left(new UpdateMotiveFailure(e, (e as any)?.code));
    }
  }

  async duplicateCampaign(params: {
    id: string;
  }): Promise<Either<DuplicateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.post(
        endpoints.duplicateCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new DuplicateCampaignFailure(e, (e as any)?.code));
    }
  }

  async getFormatById(
    id: string,
  ): Promise<Either<GetFormatByIdFailure, Format>> {
    try {
      const response = await this.httpService.get(endpoints.getFormatById(id));
      const format = FormatDTO.toDomain(response.data);
      return Right(format);
    } catch (e) {
      logError(e as any);
      return Left(new GetFormatByIdFailure(e, (e as any)?.code));
    }
  }

  async updateFormat(
    updateFormat: UpdateFormatDTO,
  ): Promise<Either<UpdateFormatFailure, void>> {
    try {
      await this.httpService.put(
        endpoints.updateFormat(updateFormat.id),
        updateFormat,
      );

      return Right(undefined);
    } catch (e) {
      logError(e as any);
      return Left(new UpdateFormatFailure(e, (e as any)?.code));
    }
  }

  async updateFormatDefaults(
    updateFormat: UpdateFormatDTO,
  ): Promise<Either<UpdateFormatFailure, void>> {
    try {
      await this.httpService.put(
        endpoints.updateMotive(updateFormat.motiveId),
        updateFormat,
      );

      return Right(undefined);
    } catch (e) {
      logError(e as any);
      return Left(new UpdateFormatFailure(e, (e as any)?.code));
    }
  }

  async deactivateFormat(
    formatId: string,
  ): Promise<Either<DeactivateFormatFailure, FormatOverview>> {
    try {
      const response = await this.httpService.put(
        endpoints.deactivateFormat(formatId),
      );
      return Right(FormatOverviewDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new DeactivateFormatFailure(e, (e as any)?.code));
    }
  }

  async activateFormat(
    formatId: string,
  ): Promise<Either<ActivateFormatFailure, FormatOverview>> {
    try {
      const response = await this.httpService.put(
        endpoints.activateFormat(formatId),
      );
      return Right(FormatOverviewDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new ActivateFormatFailure(e, (e as any)?.code));
    }
  }

  async deactivateMotive(
    motiveId: string,
  ): Promise<Either<DeactivateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.put(
        endpoints.deactivateMotive(motiveId),
      );

      const deactivatedMotive = MotiveDTO.toDomain(response.data);

      return Right(deactivatedMotive);
    } catch (e) {
      logError(e as any);
      return Left(new DeactivateMotiveFailure(e, (e as any)?.code));
    }
  }

  async activateMotive(
    motiveId: string,
  ): Promise<Either<ActivateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.put(
        endpoints.activateMotive(motiveId),
      );

      const activatedMotive = MotiveDTO.toDomain(response.data);

      return Right(activatedMotive);
    } catch (e) {
      logError(e as any);
      return Left(new ActivateMotiveFailure(e, (e as any)?.code));
    }
  }

  async deleteMotive(
    motiveId: string,
  ): Promise<Either<DeleteMotiveFailure, null>> {
    try {
      await this.httpService.delete(endpoints.deleteMotive(motiveId));

      return Right(null);
    } catch (e) {
      logError(e as any);
      return Left(new DeleteMotiveFailure(e, (e as any)?.code));
    }
  }

  async emptyCampaignBin(): Promise<Either<EmptyCampaignBinFailure, null>> {
    try {
      await this.httpService.delete(endpoints.emptyCampaignBin);

      return Right(null);
    } catch (e) {
      logError(e as any);
      return Left(new EmptyCampaignBinFailure(e, (e as any)?.code));
    }
  }

  async duplicateMotive(params: {
    motiveId: string;
    campaignId: string;
  }): Promise<Either<DuplicateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.post(
        endpoints.duplicateMotive(params.motiveId),
        { campaignId: params.campaignId },
      );
      return Right(MotiveDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new DuplicateMotiveFailure(e, (e as any)?.code));
    }
  }

  async bulkCreateMotives(params: {
    name: string;
    baseMotiveId: string;
    campaignId: string;
    isAutoUpdateOn: boolean;
    deviceId?: string;
    contractId?: string;
  }): Promise<Either<BulkCreateMotivesFailure, MotiveGroup>> {
    try {
      const response = await this.httpService.post(
        endpoints.bulkCreateMotives,
        params,
      );
      return Right(MotiveGroupDTO.toDomain(response.data));
    } catch (e) {
      logError(e as any);
      return Left(new BulkCreateMotivesFailure(e, (e as any)?.code));
    }
  }

  async getMotiveGroups(): Promise<
    Either<GetMotiveGroupsFailure, MotiveGroup[]>
  > {
    try {
      const response = await this.httpService.get(endpoints.getMotiveGroups);
      const motiveGroups = response.data.map(
        (motiveGroup: Record<string, any>) =>
          MotiveGroupDTO.toDomain(motiveGroup),
      );

      return Right(motiveGroups);
    } catch (e) {
      logError(e as any);
      return Left(new GetMotiveGroupsFailure(e, (e as any)?.code));
    }
  }

  async deleteMotiveGroup(
    motiveGroupId: string,
  ): Promise<Either<DeleteMotiveGroupFailure, null>> {
    try {
      await this.httpService.delete(endpoints.deleteMotiveGroup(motiveGroupId));

      return Right(null);
    } catch (e) {
      logError(e as any);
      return Left(new DeleteMotiveGroupFailure(e, (e as any)?.code));
    }
  }

  async updateMotiveGeneralSettings(
    params: UpdateMotiveGeneralSettings,
  ): Promise<Either<UpdateMotiveFailure, null>> {
    try {
      await this.httpService.put(
        endpoints.updateMotiveGeneralSettings(params.id),
        params,
      );

      return Right(null);
    } catch (e) {
      return Left(new UpdateMotiveFailure(e, (e as any)?.code));
    }
  }

  async fetchFormatStaticDefault() {
    try {
      const result = await this.httpService.get(
        endpoints.fetchFormatStaticDefaults(),
      );
      const casted = result.data as FormatStaticCreationParams[];
      return Right(casted);
    } catch (e) {
      return Left(new Error());
    }
  }
}
