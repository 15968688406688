import { Format } from '../domain/format/format';
import { mapValues } from '../domain/utils';

export class FormatDTO {
  static toDomain(json: { [key: string]: any }): Format {
    return Format.create({
      id: json.id,
      name: json.name,
      motiveId: json.motiveId,
      motiveName: json.motiveName,
      isActive: json.isActive,
      slides: json.slides,
      slideOrder: json.slideOrder,
    });
  }

  static toJson(format: Format): { [key: string]: any } {
    return {
      id: format.id,
      name: format.name,
      motiveId: format.motiveId,
      motiveName: format.motiveName,
      isActive: format.isActive,
      slides: mapValues(format.slides, slide => slide.toJson()),
      slideOrder: format.slideOrder,
    };
  }
}
