import { DateWrapper, Failure } from '@/core';
import { CreateSlideForValuesParams, Slide } from './valueObjects/slide';

export enum CampaignStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum MotiveStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DEACTIVATED_BY_FEED = 'deactivated_by_feed',
}

export type CampaignRow = {
  id: string;
  name: string;
  startDate: DateWrapper;
  endDate: DateWrapper;
  status: CampaignStatus;
};

export type MotiveOverviewRow = {
  id: string;
  name: string;
  clickUrl: string;
  description: string;
  campaignName: string;
  startDate: DateWrapper;
  endDate: DateWrapper;
  status: CampaignStatus;
};

export type CampaignDetailsRow = {
  id: string;
  name: string;
  campaignName?: string;
  campaignId?: string;
  clickUrl: string;
  motiveGroupName: string;
  startDate: DateWrapper;
  endDate: DateWrapper;
  isDefaultMotive: boolean;
  templateVersion: string;
  status: MotiveStatus;
  description: string;
  uniqueClickUrls?: string[];
};

export type MotiveDetailRow = {
  id: string;
  name: string;
  isActive: boolean;
  size: string;
  devices: string;
  contracts: string;
  description?: string;
  clickUrls: string[];
};

export enum Tabs {
  allCampaigns = 'allCampaigns',
  bin = 'bin',
  archive = 'archive',
}

export class GetCampaignsFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get campaigns failure', error, status);
  }
}

export class CreateCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Create campaign failure', error, status);
  }
}

export class DeactivateCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Deactivate campaign failure', error, status);
  }
}

export class ActivateCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Activate campaign failure', error, status);
  }
}

export class DeleteCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Delete campaign failure', error, status);
  }
}

export class UpdateCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Update campaign failure', error, status);
  }
}

export class HardDeleteCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Hard Delete campaign failure', error, status);
  }
}

export class GetContractsFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get contracts failure', error, status);
  }
}

export class GetDevicesFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get devices failure', error, status);
  }
}

export class CreateMotiveVersionFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Create motive version failure', error, status);
  }
}
export class GetMotiveVersionsFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get motive versions failure', error, status);
  }
}

export class GetAllMotivesFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get all motives failure', error, status);
  }
}
export class RestoreMotiveToVersionFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Restore motive to version failure', error, status);
  }
}
export class CreateMotiveFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Create motive failure', error, status);
  }
}

export class GetMotivesForCampaignIdFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get motives for campaign id failure', error, status);
  }
}

export class GetMotiveByIdFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get motive by id failure', error, status);
  }
}

export class GetFormatsForMotiveIdFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get formats for motive id failure', error, status);
  }
}

export class GetRawFormatsForMotiveIdFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get raw formats for motive id failure', error, status);
  }
}

export class GetMotiveFormatsPreviewFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get motive formats preview failure', error, status);
  }
}

export class UpdateMotiveFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Update motive failure', error, status);
  }
}
export class DuplicateCampaignFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Duplicate campaign failure', error, status);
  }
}

export class GetFormatByIdFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get format by id failure', error, status);
  }
}

export class UpdateFormatFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Update format failure', error, status);
  }
}

export class DeactivateFormatFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Deactivate format failure', error, status);
  }
}

export class ActivateFormatFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Activate format failure', error, status);
  }
}

export class DeactivateMotiveFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Deactivate motive failure', error, status);
  }
}

export class ActivateMotiveFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Activate motive failure', error, status);
  }
}

export class DeleteMotiveFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Delete motive failure', error, status);
  }
}

export class EmptyCampaignBinFailure extends Failure {
  constructor(error: any, status?: number) {
    super('empty campaign bin failure', error, status);
  }
}

export class DuplicateMotiveFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Duplicate Motive failure', error, status);
  }
}

export class BulkCreateMotivesFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Bulk create motives failure', error, status);
  }
}

export class GetMotiveGroupsFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get motive groups failure', error, status);
  }
}

export class DeleteMotiveGroupFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Delete motive group failure', error, status);
  }
}
