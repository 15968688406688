
import { computed, defineComponent } from 'vue';
import { UserEvents } from './events';
import { useGetCurrentUser } from '../application/useGetCurrentUser';
import { useGetOptionsForUser } from '../application/useGetOptionsForUser';
import { useConfirmationModal } from '@/features/shared/application/useConfirmationModal';
import { useDeleteUser } from '@/features/users/application/useDeleteUser';

export default defineComponent({
  props: {
    userId: { type: String, required: true },
  },
  emits: [UserEvents.CHANGE_ROLE],
  setup(props, context) {
    const { currentUser } = useGetCurrentUser();
    // Current user becomes undefined when login out
    const isCurrentUser = computed(
      () => props.userId === currentUser.value?.id,
    );

    const {
      onConfirm,
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForUser(props.userId, context.emit, {
      onDelete: withConfirm,
    });

    const { call: callDelete } = useDeleteUser();
    const deleteUser = async () => {
      await callDelete({
        id: props.userId,
      });
      onCancel();
    };

    return {
      deleteUser,
      isCurrentUser,
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onConfirmDeletion: onConfirm,
      onCancel,
    };
  },
});
