
import {
  HorizontalAlign,
  InterfererAnimation,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { PropType, defineComponent, ref, computed, watch } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { Field, fieldTypes, FormGenerator } from '@/components/formGenerator';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import ConfirmResetModal from '../../confirmResetModal.vue';
import { SlideElementSimpleType } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import get from 'lodash.get';
import { emToPixels } from '@/utils/emToPixels';
import { SlideInterferer } from '@/features/campaigns/domain/valueObjects/blocks/slideInterferer';
import { TransitionType } from '@/features/campaigns/domain/valueObjects/preview/iPreviewTransition';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPicker,
    ConfirmResetModal,
  },
  props: {
    slideIndex: { type: Number, required: true },
    interfererIndex: { type: Number, required: true },
    fields: { type: Array as PropType<Field[]>, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    interfererLabel: { type: String, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');
    const {
      slides,
      slideOrder,
      getSlideResetValues,
      removeSlideElement,
      addSecondGraphic,
      removeSecondGraphic,
    } = injectStrict(EditFormatsFormKey);
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));
    const interfererByLabel = ref<SlideInterferer>(
      slides.value[slideId.value].interferers.find(
        interferer => interferer.label === props.interfererLabel,
      ) || ({} as SlideInterferer),
    );

    const updateTransitionOrAnimation = (
      value: InterfererAnimation | TransitionType,
      id: string,
    ) => {
      if (id.includes('animation')) {
        interfererByLabel.value.animation = value as InterfererAnimation;
      } else if (interfererByLabel.value.transition?.type) {
        interfererByLabel.value.transition.type = value;
      }
    };

    const transitionOriginXModal = ref<number>(
      interfererByLabel.value?.transition?.x || 0,
    );
    const transitionOriginYModal = ref<number>(
      interfererByLabel.value?.transition?.y || 0,
    );

    watch(transitionOriginXModal, () => {
      if (interfererByLabel.value && interfererByLabel.value.transition) {
        interfererByLabel.value.transition.x = transitionOriginXModal.value;
      }
    });
    watch(transitionOriginYModal, () => {
      if (interfererByLabel.value && interfererByLabel.value.transition) {
        interfererByLabel.value.transition.y = transitionOriginYModal.value;
      }
    });

    return {
      slideId,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      addSecondGraphic,
      removeSecondGraphic,
      t,
      SlideElementSimpleType,
      removeSlideElement,
      resetValues,
      resetModalOpen,
      onResetCallback,
      get,
      updateTransitionOrAnimation,
      emToPixels,
      interfererByLabel,
      transitionOriginXModal,
      transitionOriginYModal,
    };
  },
});
