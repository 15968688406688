<template>
  <app-unauthenticated-layout :title="$t('login.title')">
    <form @submit.stop.prevent="handleLogin">
      <div class="flex flex-col">
        <div class="pb-3">
          <app-input
            :errorMessage="
              !isEmailValid(email) && hasSubmitted
                ? $t('login.form.email.errorMessage')
                : null
            "
            v-model="email"
            type="text"
            :label="$t('login.form.email.label')"
            :placeholder="$t('login.form.email.placeholder')"
          />
        </div>
        <div class="pb-2">
          <app-input
            :errorMessage="
              !isPasswordValid(password) && hasSubmitted
                ? $t('login.form.password.errorMessage')
                : null
            "
            v-model="password"
            type="password"
            :label="$t('login.form.password.label')"
            :placeholder="$t('login.form.password.placeholder')"
          />
        </div>
        <a class="app-link text-primary" @click="onForgotPassword">{{
          $t('login.resetPassword')
        }}</a>
        <div>
          <app-button type="submit" class="mt-4 w-full" :isLoading="isLoading">
            {{ $t('login.form.button') }}
          </app-button>
        </div>
      </div>
    </form>
  </app-unauthenticated-layout>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useLogin } from '../application/useLogin';
import { useRouter } from 'vue-router';
import { sessionRouteNames } from './routes/sessionRouteNames';
import { validateEmailAddress, validateMinLength } from '@/core';
import { isEmailValid, isPasswordValid } from '@/features/users/application/utils';

export default {
  setup() {
    const { call, isLoading } = useLogin();
    const router = useRouter();

    const email = ref('');
    const password = ref('');
    const hasSubmitted = ref(false);

    const handleLogin = () => {
      hasSubmitted.value = true;
      const passwordIsValid = validateMinLength(password.value, 8).isRight();
      const emailIsValid = validateEmailAddress(email.value).isRight();

      if (passwordIsValid && emailIsValid) {
        call({
          email: email.value,
          password: password.value,
        });
      }
    };

    const onForgotPassword = () => {
      router.push({ name: sessionRouteNames.password.askResetPassword });
    };

    return {
      email,
      password,
      handleLogin,
      hasSubmitted,
      isLoading,
      onForgotPassword,
      isEmailValid,
      isPasswordValid
    };
  },
};
</script>
