import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { updateIsActiveFormatForMotiveIdSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { DeactivateFormatFailure } from '../../domain';

export const useDeactivateFormat = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  const store = useStore();

  return useMutation(async (args: { motiveId: string; formatId: string }) => {
    store.dispatch(updateIsActiveFormatForMotiveIdSucceeded, {
      formatId: args.formatId,
      motiveId: args.motiveId,
      isActive: false,
    });
    const result = await facade.deactivateFormat(args.formatId);

    if (result.isRight()) {
      toast.success(t('campaigns.useDeactivateFormat.success'));
    } else {
      store.dispatch(updateIsActiveFormatForMotiveIdSucceeded, {
        formatId: args.formatId,
        motiveId: args.motiveId,
        isActive: true,
      });

      const error= result.extract() as DeactivateFormatFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useDeactivateFormat.error'));
      }
    }
  });
};
