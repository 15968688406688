<template>
  <div class="relative flex items-center justify-center">
    <dropdown-wrapper class="top-3.5 right-0">
      <template v-slot:trigger>
        <app-icon
          name="threeDots"
          alt="open dropdown icon"
          class="h-campaignListDropdownCell ml-auto"
        />
      </template>
      <template v-slot:dropdown>
        <dropdown-item
          v-for="option in options"
          :key="option.label"
          class="flex flex-row"
          @click.stop="option.onClick"
          v-slot="{ active }"
        >
          <app-icon
            :name="option.icon"
            :alt="option.iconAlt"
            :class="{ 'active-icon': active, 'w-iconSm': true }"
          />

          <app-txt variant="small" class="ml-1.5">
            {{ option.label }}
          </app-txt>
        </dropdown-item>
      </template>
    </dropdown-wrapper>
  </div>
  <confirm-deletion-modal
    :title="$t('common.deletionModal.title')"
    :confirm-label="$t('common.deletionModal.confirmDeleteLabel')"
    :cancel-label="$t('common.deletionModal.cancel')"
    :description="$t('common.deletionModal.description')"
    :delete-function="onConfirmDeletion"
    :is-modal-open="isConfirmDeleteOpen"
    @close-modal="onCancel"
  />
</template>

<script lang="ts">
import { useGetOptionsForCampaign } from '../../application/campaign/list/useGetOptionsForCampaign';
import { useConfirmationModal } from '../../../shared/application/useConfirmationModal';
import { CampaignEvents } from '../events';
import { defineComponent } from 'vue';
import ConfirmDeletionModal from '@/components/confirmDeletionModal/confirmDeletionModal.vue';
import { useHardDeleteCampaign } from '@/features/campaigns/application/campaign/actions/useHardDeleteCampaign';

export default defineComponent({
  components: { ConfirmDeletionModal },
  props: {
    campaignId: { type: String, required: true },
  },
  emits: [CampaignEvents.EDIT_CAMPAIGN, CampaignEvents.ADD_MOTIVE],
  setup(props, context) {
    const {
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForCampaign(
      props.campaignId,
      {
        onHardDelete: withConfirm,
      },
      context.emit,
    );

    const { call: hardDelete } = useHardDeleteCampaign();

    const onConfirmDeletion = async () => {
      await hardDelete({ id: props.campaignId });
      onCancel();
    };

    return {
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onCancel,
      onConfirmDeletion,
    };
  },
});
</script>
