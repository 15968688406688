
import { TemplateNames } from '@/components/table';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  DateCellWithEndingDays,
  SortableHeaderCell,
  StatusCell,
} from '../../../../components/table/cellTemplates';
import { useFetchCampaigns } from '../../application/campaign/actions/useFetchCampaigns';
import { useGetActiveTab } from '../../application/campaign/list/useGetActiveTab';
import { useDeleteMotive } from '../../application/motive/actions/useDeleteMotive';
import { useFetchMotives } from '../../application/motive/actions/useFetchMotives';
import { overviewListTableHeaders } from '../../application/motive/list/overviewListTableHeaders';
import { Slide } from '../../domain/valueObjects/slide';
import DuplicateMotiveModal from '../campaignDetails/duplicateMotiveModal.vue';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import ExportModal from './exportModal.vue';
import OverviewListDropdownCell from './overviewListDropdownCell.vue';
import { generateTooltipContent } from '@/utils/generateTooltipContent';

export default {
  components: {
    StatusCell,
    OverviewListDropdownCell,
    DuplicateMotiveModal,
    SortableHeaderCell,
    DateCellWithEndingDays,
    ExportModal,
  },
  methods: {
    generateTooltipContent,
  },
  setup() {
    const router = useRouter();
    useFetchMotives({});
    useFetchCampaigns({});
    const statusOptions = ref([
      { label: t('overview.filter.all'), value: 'all' },
      { label: t('overview.filter.active'), value: 'active' },
      { label: t('overview.filter.inactive'), value: 'inactive' },
      {
        label: t('overview.filter.deactivatedByFeed'),
        value: 'deactivated_by_feed',
      },
    ]);

    const searchedValue = ref('');
    const isModalOpen = ref(false);
    const setIsModalOpen = (value: boolean) => {
      isModalOpen.value = value;
    };

    const selectedTabId = useGetActiveTab();
    const store = useStore();

    const motives = computed(() => store.getters.motives);

    const selectedStatus = computed({
      get: () => store.getters.userPreferences.selectedStatus,
      set: value => store.dispatch('updateSelectedStatus', value),
    });

    const motiveOverviewRows = computed(() => {
      return store.getters.motives
        .map(motive => motive.toOverviewRow())
        .filter(motive => {
          if (selectedStatus.value === 'all') {
            return true;
          }
          return motive.status === selectedStatus.value;
        });
    });

    const currentlyDeletedMotiveId = ref('');

    const { call } = useDeleteMotive();
    const onDeleteMotive = (motiveId: string) => {
      currentlyDeletedMotiveId.value = motiveId;
    };
    const deleteMotive = async () => {
      await call(currentlyDeletedMotiveId.value);
      currentlyDeletedMotiveId.value = '';
    };

    const resetCurrentlyDeletedMotiveId = () => {
      currentlyDeletedMotiveId.value = '';
    };

    const currentlyDuplicatedMotiveId = ref('');
    const onDuplicateMotive = (motiveId: string) => {
      currentlyDuplicatedMotiveId.value = motiveId;
    };
    const resetCurrentlyDuplicatedMotiveId = () => {
      currentlyDuplicatedMotiveId.value = '';
    };

    const openCompanyLink = (event: Event, companyName: string) => {
      event.stopPropagation();
      const companyId = motiveOverviewRows.value.find(
        motive => motive.campaignName === companyName,
      )?.campaignId;
      router.push(`/campaigns/details?id=${companyId}`);
    };

    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.motiveDetails, query: { id } });
    };

    const updateSelectedStatus = (status: any) => {
      store.dispatch('updateSelectedStatus', status);
    };

    return {
      TemplateNames,
      headers: overviewListTableHeaders,
      motives,
      motiveOverviewRows,
      selectedTabId,
      isModalOpen,
      setIsModalOpen,
      searchedValue,
      openCompanyLink,
      onDeleteMotive,
      onDuplicateMotive,
      currentlyDuplicatedMotiveId,
      resetCurrentlyDuplicatedMotiveId,
      deleteMotive,
      currentlyDeletedMotiveId,
      resetCurrentlyDeletedMotiveId,
      statusOptions,
      selectedStatus,
      updateSelectedStatus,
      onRowClick,
    };
  },
};
