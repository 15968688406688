
import { CampaignStatus, MotiveStatus } from '@/features/campaigns/domain';
import { SyncStatus } from '@/features/sync/domain/value_objects';
import { UserStatus } from '@/features/users/domain/value_objects';
import { computed, defineComponent } from 'vue';
import { t } from '@/services/i18n';

export default defineComponent({
  props: {
    value: String,
  },
  setup(props) {
    const isSuccess = computed(() =>
      [
        CampaignStatus.ACTIVE,
        MotiveStatus.ACTIVE,
        UserStatus.Active,
        SyncStatus.Success,
      ].includes(props.value ?? ''),
    );
    const isError = computed(() =>
      [
        CampaignStatus.INACTIVE,
        MotiveStatus.INACTIVE,
        UserStatus.Inactive,
        SyncStatus.Error,
        MotiveStatus.DEACTIVATED_BY_FEED,
      ].includes(props.value ?? ''),
    );

    const isInvited = computed(() =>
      [UserStatus.Invited].includes(props.value ?? ''),
    );

    const text = computed(() => {
      if (props.value === MotiveStatus.DEACTIVATED_BY_FEED) {
        return t('table.deactivatedByFeed');
      }
      
      return t(`table.${props.value}`);
    });

    return {
      isSuccess,
      isError,
      isInvited,
      text
    };
  },
});
