<template>
  <div>
    <div class="flex flex-row w-full justify-between items-center">
      <div
        v-for="(option, index) in options"
        :key="option.modelValue"
        :class="{
          'flex-grow': true,
          'hidden ': defaultVisual && index !== selectedOption,
        }"
      >
        <div
          :class="{
            'flex flex-row h-mediaPickerGroupHeader justify-center items-center cursor-pointer': true,
            'border-b-2 border-primary ': index === selectedOption,
            'border-b border-gray-200 ': index !== selectedOption,
            'hidden ': defaultVisual && index !== selectedOption,
          }"
          @click="selectOption(index)"
        >
          <component :is="option.icon" :isSelected="index === selectedOption" />
          <app-txt
            variant="small"
            :class="{
              'pl-1': true,
              'text-gray-700': index !== selectedOption,
              'text-primary-700': index === selectedOption,
            }"
            >{{ option.optionLabel }}</app-txt
          >
        </div>
      </div>
    </div>
    <div>
      <MediaPicker
        :modelValue="options[selectedOption].modelValue"
        :initialModelValue="options[selectedOption].initialModelValue"
        @update:modelValue="
          updateModelValue(options[selectedOption].event, $event)
        "
        :allowAnimations="allowAnimations"
        :hasError="hasError"
        :hasReset="hasReset"
        :label="options[selectedOption].visualLabel"
        :labelStyleClasses="labelStyleClasses"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { computed, defineComponent, ref } from 'vue';
import MediaPicker from './mediaPicker.vue';
import { MediaPickerEvents } from './events';
import HorizontalVisualIcon from './visualIcons/horizontalVisualIcon.vue';
import SquareVisualIcon from './visualIcons/squareVisualIcon.vue';
import VerticalVisualIcon from './visualIcons/verticalVisualIcon.vue';

export default defineComponent({
  name: 'media-picker-group',
  components: {
    HorizontalVisualIcon,
    SquareVisualIcon,
    VerticalVisualIcon,
    MediaPicker,
  },
  emits: [
    MediaPickerEvents.UPDATE_SQUARE_VISUAL,
    MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL,
    MediaPickerEvents.UPDATE_VERTICAL_VISUAL,
  ],
  props: {
    squareVisual: { type: String, required: false },
    horizontalVisual: { type: String, required: false },
    verticalVisual: { type: String, required: false },
    initialSquareVisual: { type: String, required: false },
    initialHorizontalVisual: { type: String, required: false },
    initialVerticalVisual: { type: String, required: false },
    hasError: { type: Boolean, required: true },
    hasReset: { type: Boolean, required: true },
    allowAnimations: { type: Boolean, default: true, required: false },
    labelStyleClasses: String,
    visualLabel: { type: String, default: 'Visual' },
    defaultVisual: String,
  },
  setup(props, context) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');

    const getSelectedOption = () => {
      const options = ['square', 'horizontal', 'vertical'];
      const indexOfDefault = options.indexOf(props.defaultVisual as string);
      return Math.max(0, indexOfDefault);
    };

    const selectedOption = ref(getSelectedOption());

    const options = computed(() => [
      {
        optionLabel: t('squareOptionLabel'),
        visualLabel: t('visualLabel'),
        event: MediaPickerEvents.UPDATE_SQUARE_VISUAL,
        modelValue: props.squareVisual,
        initialModelValue: props.initialSquareVisual,
        icon: 'square-visual-icon',
      },
      {
        optionLabel: t('horizontalOptionLabel'),
        visualLabel: t('visualLabel'),
        event: MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL,
        modelValue: props.horizontalVisual,
        initialModelValue: props.initialHorizontalVisual,
        icon: 'horizontal-visual-icon',
      },
      {
        optionLabel: t('verticalOptionLabel'),
        visualLabel: t('visualLabel'),
        event: MediaPickerEvents.UPDATE_VERTICAL_VISUAL,
        modelValue: props.verticalVisual,
        initialModelValue: props.initialVerticalVisual,
        icon: 'vertical-visual-icon',
      },
    ]);

    const selectOption = (index: number) => {
      selectedOption.value = index;
    };

    const updateModelValue = (event: string, assetId: string | null) => {
      if (event === MediaPickerEvents.UPDATE_SQUARE_VISUAL) {
        const verticalOption = options.value.find(
          option => option.event === MediaPickerEvents.UPDATE_VERTICAL_VISUAL,
        );
        const horizontalOption = options.value.find(
          option => option.event === MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL,
        );
        const squareOption = options.value.find(
          option => option.event === MediaPickerEvents.UPDATE_SQUARE_VISUAL,
        );

        const hasVerticalVisual = verticalOption?.modelValue;
        const hasHorizontalVisual = horizontalOption?.modelValue;
        const isSameImage =
          verticalOption?.modelValue === squareOption?.modelValue &&
          horizontalOption?.modelValue === squareOption?.modelValue;
        const isDeletion = assetId === null;

        if (!isDeletion) {
          if ((!hasVerticalVisual && !hasHorizontalVisual) || isSameImage) {
            context.emit(MediaPickerEvents.UPDATE_VERTICAL_VISUAL, assetId);
            context.emit(MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL, assetId);
          }
        }

        if (isDeletion && isSameImage) {
          context.emit(MediaPickerEvents.UPDATE_VERTICAL_VISUAL, null);
          context.emit(MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL, null);
        }
      }

      context.emit(event, assetId);
    };

    return { selectedOption, selectOption, options, updateModelValue };
  },
});
</script>
