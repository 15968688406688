import { useSendResetMail } from '@/features/session/application/useSendResetMail';
import { t } from '@/services/i18n';
import { computed, ComputedRef } from 'vue';
import { useToast } from 'vue-toastification';
import { UserEvents } from '../presentation/events';
import { UserStatus } from '../domain/value_objects';
import { useActivateUser } from './useActivateUser';
import { useDeactivateUser } from './useDeactivateUser';
import { useDeleteUser } from './useDeleteUser';
import { useGetUserById } from './useGetUserById';

export const useGetOptionsForUser = (
  userId: string,
  emit: (event: string, payload: string) => void,
  { onDelete }: { onDelete: any },
): ComputedRef<{
  icon: string;
  iconAlt: string;
  label: string;
  onClick: () => void;
  isVisible: boolean;
}[]> => {
  const { user } = useGetUserById(userId);
  const showDeactivateOption = computed(
    () => user.value.status.getValue() !== UserStatus.Inactive,
  );

  const { call: callDeactivate } = useDeactivateUser();
  const onDeactivateUser = () => {
    callDeactivate({ id: userId });
  };

  const { call: callActivate } = useActivateUser();
  const onActivateUser = () => {
    callActivate({ id: userId });
  };

  const { call: callDelete } = useDeleteUser();
  const onDeleteUser = () => {
    callDelete({ id: userId });
  };

  const showChangeRoleOption = computed(
    () => user.value.status.getValue() !== UserStatus.Invited,
  );
  const toast = useToast();
  const { call: callResendInvite } = useSendResetMail(() =>
    toast.success(t('password.useSendResetMail.success')),
  );
  const onResendInvite = () => {
    callResendInvite(user.value.email);
  };

  return computed(() =>
    [
      {
        icon: 'deactivate',
        iconAlt: 'Deactivate user',
        label: t('users.dropdown.deactivate'),
        onClick: onDeactivateUser,
        isVisible: showDeactivateOption.value,
      },
      {
        icon: 'activate',
        iconAlt: 'Activate user',
        label: t('users.dropdown.activate'),
        onClick: onActivateUser,
        isVisible: !showDeactivateOption.value,
      },
      {
        icon: 'changeRole',
        iconAlt: 'Change user role',
        label: t('users.dropdown.changeRole'),
        onClick: () => emit(UserEvents.CHANGE_ROLE, userId),
        isVisible: showChangeRoleOption.value,
      },
      {
        icon: 'resendInvite',
        iconAlt: 'resend invite',
        label: t('users.dropdown.resendInvite'),
        onClick: onResendInvite,
        isVisible: !showChangeRoleOption.value,
      },
      {
        icon: 'delete',
        iconAlt: 'Delete user',
        label: t('users.dropdown.delete'),
        onClick: () => onDelete(onDeleteUser),
        isVisible: true,
      },
    ].filter(option => option.isVisible),
  );
};
