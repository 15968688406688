import { CreateMotiveFailure } from '@/features/campaigns/domain';
import { CreateMotive } from '@/features/campaigns/domain/motive/createMotive';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { campaignsRouteNames } from '@/features/campaigns/presentation/routes/campaignsRouteNames';
import { createMotiveSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export const useCreateMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();
  const router = useRouter();

  return useMutation(async (createMotive: CreateMotive) => {
    const result = await facade.createMotive(createMotive);

    if (result.isRight()) {
      const motive = result.extract();
      await router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motive.id },
      });
      store.dispatch(createMotiveSucceeded, { motive });
      toast.success(t('campaigns.useCreateMotive.success'));
    } else {
      const error= result.extract() as CreateMotiveFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useCreateMotive.error'));
      }
    }
  });
};
