import { Position, PositionCreationParams } from '../position';
import {
  CSSInlineStyle,
  CssUnit,
} from '@/features/campaigns/domain/valueObjects/preview/iPreviewAttributes';
import { CreateSlideElementParams, SlideElement } from './slideElement';
import { formatContractData, formatDeviceData, evaluate } from '../../utils';
import { PreviewTransition } from '../preview/iPreviewTransition';
import { PreviewSlideContent } from '../preview/previewSlideContent';

export type CreateSlideTextParams = CreateSlideElementParams & {
  content?: string;
  size?: number;
  lineSpacing?: number;
  transition?: PreviewTransition;
  position?: PositionCreationParams;
  blockWidth?: number;
};

export type CreateSlideTextForValuesParams = CreateSlideElementParams & {
  content?: string;
  size?: number;
  lineSpacing?: number;
  transition?: PreviewTransition;
  position?: Position;
  blockWidth?: number;
  removed?: boolean;
  isDefault?: boolean;
};

const defaultLabel = 'Text';

const HTML_TAG_REGEX = RegExp(/<[^>]+>/gi);

export class SlideText extends SlideElement {
  public content?: string;
  public size?: number;
  public lineSpacing?: number;
  public position?: Position;
  public transition?: PreviewTransition;
  public blockWidth?: number;

  private constructor(params: CreateSlideTextForValuesParams) {
    super(params.label, params.zIndex, params.removed);
    this.content = params.content;
    this.size = params.size;
    this.lineSpacing = params.lineSpacing;
    this.position = params.position;
    this.blockWidth = params.blockWidth;
    this.transition = params.transition;
    this.isDefault = params.isDefault;
  }

  public toPreviewContent(params: {
    contractData: ReturnType<typeof formatContractData>;
    deviceData: ReturnType<typeof formatDeviceData>;
  }): PreviewSlideContent {
    const style = new CSSInlineStyle()
      .addFontSize(this.size)
      .addPosition(this.position?.toJson())
      .addWidth(this.blockWidth)
      .addZIndex(this.zIndex)
      .build();

    const content = evaluate(this.getContentWithLineHeightInHTMLTag(), {
      contract: params.contractData,
      device: params.deviceData,
    });

    return PreviewSlideContent.createAsText({
      content,
      attributes: {
        style,
      },
      transition: this.transition,
    });
  }

  static create(params: CreateSlideTextParams) {
    return new SlideText({
      label: params.label ?? defaultLabel,
      content: params.content,
      size: params.size,
      lineSpacing: params.lineSpacing,
      transition: params.transition,
      position: params.position ? Position.create(params.position) : undefined,
      blockWidth: params.blockWidth,
      zIndex: params.zIndex,
      removed: params.removed,
      isDefault: params.isDefault,
    });
  }

  static createForValues(params: CreateSlideTextForValuesParams) {
    return new SlideText({
      label: params.label,
      content: params.content,
      size: params.size,
      lineSpacing: params.lineSpacing,
      transition: params.transition,
      position: params.position,
      blockWidth: params.blockWidth,
      zIndex: params.zIndex,
      removed: params.removed,
      isDefault: params.isDefault,
    });
  }

  public getContentWithLineHeightInHTMLTag() {
    let content = this.content;
    const lineSpacing = this.lineSpacing;

    const tags = content?.match(HTML_TAG_REGEX);

    if (!tags) {
      return content;
    }

    const lineHeight = `line-height: ${lineSpacing}${CssUnit.EM};`;

    for (const tag of tags) {
      const isOpeningTag = !tag.includes('</');
      const isParagraphTag = tag.includes('<p');
      const hasStyleAttribute = tag.includes('style=');

      if (isOpeningTag && isParagraphTag) {
        if (hasStyleAttribute) {
          const styleAttribute = tag.match(/style="[^"]+"/gi);

          if (styleAttribute) {
            const styleAttributeValue = styleAttribute[0];

            const hasLineHeight = styleAttributeValue.includes('line-height');

            if (hasLineHeight) {
              const newStyleAttributeValue = styleAttributeValue.replace(
                /line-height: [^;]+;/gi,
                lineHeight,
              );

              const newTag = tag.replace(
                styleAttributeValue,
                newStyleAttributeValue,
              );

              content = content?.replace(tag, newTag);
            }

            const styleAttributeValueWithoutClosingQuote = styleAttributeValue.replace(
              /"$/gi,
              '',
            );

            const newStyleAttributeValue = `${styleAttributeValueWithoutClosingQuote} ${lineHeight}"`;

            const newTag = tag.replace(
              styleAttributeValue,
              newStyleAttributeValue,
            );

            content = content?.replace(tag, newTag);
          }
        } else {
          const newTag = tag.replace(
            '<p',
            `<p style="line-height: ${lineSpacing}${CssUnit.EM};"`,
          );

          content = content?.replace(tag, newTag);
        }
      }
    }

    return content;
  }

  public toJson() {
    return {
      label: this.label,
      content: this.content,
      size: this.size,
      lineSpacing: this.lineSpacing,
      transition: this.transition,
      position: this.position?.toJson(),
      blockWidth: this.blockWidth,
      zIndex: this.zIndex,
      removed: this.removed,
      isDefault: this.isDefault,
    };
  }
}
