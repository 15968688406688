import { getters } from './getters';
import { Campaign } from '../domain/campaign';
import { Contract } from '../domain/contract';
import { Format } from '../domain/format/format';
import { FormatOverview } from '../domain/format/formatOverview';
import { Motive } from '../domain/motive/motive';
import { updateEntity, unionBy } from '@/core/helpers';
import { Device } from '../domain/device';
import { CampaignDTO } from '../infrastructure/campaign_dto';
import { ContractDTO } from '../infrastructure/contract_dto';
import { DeviceDTO } from '../infrastructure/device-dto';
import { FormatDTO } from '../infrastructure/format_dto';
import { FormatOverviewDTO } from '../infrastructure/format_overview_dto';
import { MotiveDTO } from '../infrastructure/motive_dto';
import { MotiveGroup } from '../domain/motive/motiveGroup';
import { MotiveGroupDTO } from '../infrastructure/motiveGroup_dto';
import { FormatRawDTO } from '../infrastructure/format_raw_dto';
import { FormatRaw } from '../domain/format/formatRaw';
import { Version } from '../domain/valueObjects/version';
import { VersionDTO } from '../infrastructure/version_dto';
import { FormatStaticDefault } from '@/features/campaigns/domain/format/formatStaticDefault';
export interface CampaignState {
  campaigns: Record<string, any>[];
  motives: Record<string, any>[];
  contracts: Record<string, any>[];
  devices: Record<string, any>[];
  motivesByCampaignId: Record<string, Record<string, any>[]>;
  formatOverviewByMotiveId: Record<string, Record<string, any>[]>;
  formats: Record<string, any>[];
  rawFormats: Record<string, any>[];
  rawFormatsDefaultsInstances: Record<string, any>[];
  motiveGroups: Record<string, any>[];
  motive: Record<string, any>;
  motiveVersions: Record<string, any>[];
  formatStaticDefaults: Record<string, any>[];
  userPreferences: {
    selectedStatus: string;
    sortedColumn: { field: string; order: string };
  };
}

export const fetchCampaignsSucceededMutation =
  'fetchCampaignsSucceededMutation';
export const fetchCampaignsSucceeded = 'fetchCampaignsSucceeded';
export const createCampaignSucceededMutation =
  'createCampaignSucceededMutation';
export const createCampaignSucceeded = 'createCampaignSucceeded';

export const duplicateCampaignSucceeded = 'duplicateCampaignSucceeded';
export const duplicateCampaignSucceededMutation =
  'duplicateCampaignSucceededMutation';

export const updateCampaignSucceededMutation =
  'updateCampaignSucceededMutation';
export const updateCampaignSucceeded = 'updateCampaignSucceeded';
export const hardDeleteCampaignSucceededMutation =
  'hardDeleteCampaignSucceededMutation';
export const hardDeleteCampaignSucceeded = 'hardDeleteCampaignSucceeded';
export const fetchContractsSucceededMutation =
  'fetchContractsSucceededMutation';
export const fetchContractsSucceeded = 'fetchContractsSucceeded';
export const fetchDevicesSucceededMutation = 'fetchDevicesSucceededMutation';
export const fetchDevicesSucceeded = 'fetchDevicesSucceeded';
export const createMotiveSucceededMutation = 'createMotiveSucceededMutation';
export const fetchMotiveVersionsSucceededMutation =
  'fetchMotiveVersionsSucceededMutation';
export const fetchMotiveVersionsSucceeded = 'fetchMotiveVersionsSucceeded';
export const createMotiveSucceeded = 'createMotiveSucceeded';
export const fetchMotivesForCampaignIdSucceededMutation =
  'fetchMotivesForCampaignIdSucceededMutation';
export const fetchMotiveByIdSucceededMutation =
  'fetchMotiveByIdSucceededMutation';
export const fetchMotivesForCampaignIdSucceeded =
  'fetchMotivesForCampaignIdSucceeded';
export const fetchMotiveByIdSucceeded = 'fetchMotiveByIdSucceeded';
export const fetchMotivesSucceededMutation = 'fetchMotivesSucceededMutation';
export const fetchMotivesSucceeded = 'fetchMotivesSucceeded';
export const fetchFormatsForMotiveIdSucceededMutation =
  'fetchFormatsForMotiveIdSucceededMutation';
export const updateIsActiveFormatForMotiveIdSucceeded =
  'updateIsActiveFormatForMotiveIdSucceeded';
export const updateIsActiveFormatForMotiveIdSucceededMutation =
  'updateIsActiveFormatForMotiveIdSucceededMutation';
export const fetchRawFormatsDefaultsInstancesForMotiveIdSucceededMutation =
  'fetchRawFormatsDefaultsInstancesForMotiveIdSucceededMutation';
export const fetchRawFormatsDefaultsInstancesForMotiveIdSucceeded =
  'fetchRawFormatsDefaultsInstancesForMotiveIdSucceeded';
export const fetchRawFormatsForMotiveIdSucceededMutation =
  'fetchRawFormatsForMotiveIdSucceededMutation';
export const fetchFormatsForMotiveIdSucceeded =
  'fetchFormatsForMotiveIdSucceeded';
export const fetchRawFormatsForMotiveIdSucceeded =
  'fetchRawFormatsForMotiveIdSucceeded';
export const fetchFormatsPreviewForMotiveIdSucceeded =
  'fetchFormatsPreviewForMotiveIdSucceeded';
export const updateMotiveSucceededMutation = 'updateMotiveSucceededMutation';
export const updateMotiveSucceeded = 'updateMotiveSucceeded';
export const fetchFormatByIdSucceededMutation =
  'fetchFormatByIdSucceededMutation';
export const fetchFormatByIdSucceeded = 'fetchFormatByIdSucceeded';
export const updateFormatSucceededMutation = 'updateFormatSucceededMutation';
export const updateFormatSucceeded = 'updateFormatSucceeded';
export const deleteMotiveSucceededMutation = 'deleteMotiveSucceededMutation';
export const deleteMotiveSucceeded = 'deleteMotiveSucceeded';
export const emptyCampaignBinSucceededMutation =
  'emptyCampaignBinSucceededMutation';
export const emptyCampaignBinSucceeded = 'emptyCampaignBinSucceeded';
export const duplicateMotiveSucceeded = 'duplicateMotiveSucceeded';
const duplicateMotiveSucceededMutation = 'duplicateMotiveSucceededMutation';
export const createMotiveGroupSucceeded = 'createMotiveGroupSucceeded';
const createMotiveGroupSucceededMutation = 'createMotiveGroupSucceededMutation';
export const fetchMotiveGroupsSucceeded = 'fetchMotiveGroupsSucceeded';
const fetchMotiveGroupsSucceededMutation = 'fetchMotiveGroupsSucceededMutation';
export const deleteMotiveGroupSucceeded = 'deleteMotiveGroupSucceeded';
const deleteMotiveGroupSucceededMutation = 'deleteMotiveGroupSucceededMutation';
export const fetchFormatStaticDefaultsSucceeded =
  'fetchFormatStaticDefaultsSucceeded';
const fetchFormatStaticDefaultsSucceededMutation =
  'fetchFormatStaticDefaultsSucceededMutation';

export const initialState: CampaignState = {
  campaigns: [],
  motives: [],
  contracts: [],
  devices: [],
  motivesByCampaignId: {},
  formatOverviewByMotiveId: {},
  formats: [],
  rawFormats: [],
  rawFormatsDefaultsInstances: [],
  motiveGroups: [],
  motive: {},
  motiveVersions: [],
  formatStaticDefaults: [],
  userPreferences: {
    selectedStatus: 'all',
    sortedColumn: { field: 'status', order: 'asc' },
  },
};

export const mutations = {
  setSelectedStatus(
    state: { userPreferences: { selectedStatus: any } },
    status: any,
  ) {
    state.userPreferences.selectedStatus = status;
  },
  setSortedColumn(
    state: { userPreferences: { sortedColumn: any } },
    column: any,
  ) {
    state.userPreferences.sortedColumn = column;
  },
  [fetchCampaignsSucceededMutation](
    state: CampaignState,
    args: { campaigns: Campaign[] },
  ) {
    state.campaigns = args.campaigns.map((campaign: Campaign) =>
      CampaignDTO.toJson(campaign),
    );
  },
  [createCampaignSucceededMutation](
    state: CampaignState,
    args: { campaign: Campaign },
  ) {
    state.campaigns = [CampaignDTO.toJson(args.campaign), ...state.campaigns];
  },
  [duplicateCampaignSucceededMutation](
    state: CampaignState,
    args: { campaign: Campaign },
  ) {
    state.campaigns = [CampaignDTO.toJson(args.campaign), ...state.campaigns];
  },
  [updateCampaignSucceededMutation](
    state: CampaignState,
    args: { campaign: Campaign },
  ) {
    state.campaigns = updateEntity(
      CampaignDTO.toJson(args.campaign),
      state.campaigns,
    );
  },
  [hardDeleteCampaignSucceededMutation](
    state: CampaignState,
    args: { campaignId: string },
  ) {
    state.campaigns = state.campaigns.filter(
      (campaign: Record<string, any>) => campaign.id !== args.campaignId,
    );

    const deletedMotiveIds =
      state.motivesByCampaignId[args.campaignId]?.map(motive => motive.id) ??
      [];

    deletedMotiveIds.forEach(motiveId => {
      state.formatOverviewByMotiveId[motiveId] = [];
      state.formats = state.formats.filter(
        format => !deletedMotiveIds.includes(format.motiveId),
      );
    });

    state.motivesByCampaignId[args.campaignId] = [];
  },
  [fetchContractsSucceededMutation](
    state: CampaignState,
    args: { contracts: Contract[] },
  ) {
    state.contracts = args.contracts.map((contract: Contract) =>
      ContractDTO.toJson(contract),
    );
  },
  [fetchDevicesSucceededMutation](
    state: CampaignState,
    args: { devices: Device[] },
  ) {
    state.devices = args.devices.map((device: Device) =>
      DeviceDTO.toJson(device),
    );
  },
  [fetchMotiveVersionsSucceededMutation](
    state: CampaignState,
    args: { versions: Version[] },
  ) {
    state.motiveVersions = args.versions.map(v => VersionDTO.toJson(v));
  },
  [createMotiveSucceededMutation](
    state: CampaignState,
    args: { motive: Motive },
  ) {
    const existingCampaignMotives =
      state.motivesByCampaignId[args.motive.campaignId];

    state.motivesByCampaignId[args.motive.campaignId] = existingCampaignMotives
      ? [MotiveDTO.toJson(args.motive), ...existingCampaignMotives]
      : [MotiveDTO.toJson(args.motive)];
  },
  [fetchMotivesForCampaignIdSucceededMutation](
    state: CampaignState,
    args: { campaignId: string; motives: Motive[] },
  ) {
    state.motivesByCampaignId[args.campaignId] = args.motives.map(motive =>
      MotiveDTO.toJson(motive),
    );
  },
  [fetchMotiveByIdSucceededMutation](
    state: CampaignState,
    args: { motive: Motive },
  ) {
    state.motive = MotiveDTO.toJson(args.motive);
  },
  [fetchMotivesSucceededMutation](
    state: CampaignState,
    args: { motives: Motive[] },
  ) {
    state.motives = args.motives.map(motive => MotiveDTO.toJson(motive));
  },

  [fetchFormatsForMotiveIdSucceededMutation](
    state: CampaignState,
    args: { motiveId: string; formatOverviews: FormatOverview[] },
  ) {
    state.formatOverviewByMotiveId[
      args.motiveId
    ] = args.formatOverviews.map(formatOverview =>
      FormatOverviewDTO.toJson(formatOverview),
    );
  },
  [updateIsActiveFormatForMotiveIdSucceededMutation](
    state: CampaignState,
    args: { motiveId: string; formatId: string; isActive: boolean },
  ) {
    const replacerIndex = state.formatOverviewByMotiveId[
      args.motiveId
    ].findIndex(format => format.id === args.formatId);

    const formatOverviewSelected =
      state.formatOverviewByMotiveId[args.motiveId][replacerIndex];

    state.formatOverviewByMotiveId[args.motiveId][replacerIndex] = {
      ...formatOverviewSelected,
      isActive: args.isActive,
    };
    const valueIndexToReplace = state.rawFormats.findIndex(
      format => format.id === args.formatId,
    );
    const rawFormatSelected = state.rawFormats[valueIndexToReplace];
    state.rawFormats[valueIndexToReplace] = {
      ...rawFormatSelected,
      isActive: args.isActive,
    };
  },
  [fetchRawFormatsDefaultsInstancesForMotiveIdSucceededMutation](
    state: CampaignState,
    args: { motiveId: string; rawFormatsDefaultsInstances: FormatRaw[] },
  ) {
    state.rawFormatsDefaultsInstances =
      args.rawFormatsDefaultsInstances?.map?.(format =>
        FormatRawDTO.toJson(format),
      ) || [];
  },
  [fetchRawFormatsForMotiveIdSucceededMutation](
    state: CampaignState,
    args: { motiveId: string; rawFormats: FormatRaw[] },
  ) {
    state.rawFormats =
      args.rawFormats?.map?.(format => FormatRawDTO.toJson(format)) || [];
  },
  [updateMotiveSucceededMutation](
    state: CampaignState,
    args: { motive: Motive },
  ) {
    const motivesToUpdate = state.motivesByCampaignId[args.motive.campaignId];

    state.motivesByCampaignId[args.motive.campaignId] = updateEntity(
      MotiveDTO.toJson(args.motive),
      motivesToUpdate ?? [],
    );
  },
  [fetchFormatByIdSucceededMutation](
    state: CampaignState,
    args: { format: Format },
  ) {
    state.formats = unionBy(FormatDTO.toJson(args.format), state.formats);
  },
  [updateFormatSucceededMutation](
    state: CampaignState,
    args: { format: Format },
  ) {
    state.formats = updateEntity(FormatDTO.toJson(args.format), state.formats);
  },
  [deleteMotiveSucceededMutation](
    state: CampaignState,
    args: { motiveId: string },
  ) {
    Object.keys(state.motivesByCampaignId).forEach(key => {
      state.motivesByCampaignId[key] = state.motivesByCampaignId[key].filter(
        motive => motive.id !== args.motiveId,
      );
    });

    state.formats = state.formats.filter(
      format => format.motiveId !== args.motiveId,
    );

    state.formatOverviewByMotiveId[args.motiveId] = [];
  },
  [emptyCampaignBinSucceededMutation](state: CampaignState, args: {}) {
    const deletedCampaignIds = state.campaigns
      .filter(campaign => !!campaign.deletedAt)
      .map(campaign => campaign.id);
    state.campaigns = state.campaigns.filter(
      campaign => !deletedCampaignIds.includes(campaign.id),
    );

    let deletedMotiveIds: string[] = [];
    deletedCampaignIds.forEach(campaignId => {
      deletedMotiveIds = deletedMotiveIds.concat(
        state.motivesByCampaignId[campaignId]?.map(motive => motive.id) ?? [],
      );
      state.motivesByCampaignId[campaignId] = [];
    });

    deletedMotiveIds.forEach(motiveId => {
      state.formatOverviewByMotiveId[motiveId] = [];
      state.formats = state.formats.filter(
        format => !deletedMotiveIds.includes(format.motiveId),
      );
    });
  },
  [duplicateMotiveSucceededMutation](
    state: CampaignState,
    args: { motive: Motive },
  ) {
    const motivesOfCampaign = state.motivesByCampaignId[args.motive.campaignId];

    state.motivesByCampaignId[args.motive.campaignId] = [
      MotiveDTO.toJson(args.motive),
      ...(motivesOfCampaign ?? []),
    ];
  },
  [createMotiveGroupSucceededMutation](
    state: CampaignState,
    args: { motiveGroup: MotiveGroup },
  ) {
    state.motiveGroups = [
      MotiveGroupDTO.toJson(args.motiveGroup),
      ...state.motiveGroups,
    ];
  },
  [fetchMotiveGroupsSucceededMutation](
    state: CampaignState,
    args: { motiveGroups: MotiveGroup[] },
  ) {
    state.motiveGroups = args.motiveGroups.map(motiveGroup =>
      MotiveGroupDTO.toJson(motiveGroup),
    );
  },
  [deleteMotiveGroupSucceededMutation](
    state: CampaignState,
    args: { motiveGroupId: string },
  ) {
    state.motiveGroups = state.motiveGroups.filter(
      motiveGroup => motiveGroup.id !== args.motiveGroupId,
    );

    state.motivesByCampaignId = Object.fromEntries(
      Object.entries(state.motivesByCampaignId).map(([campaignId, motives]) => [
        campaignId,
        motives.filter(motive => motive.motiveGroupId !== args.motiveGroupId),
      ]),
    );
  },
  [fetchFormatStaticDefaultsSucceededMutation](
    state: CampaignState,
    args: { formatStaticDefaults: FormatStaticDefault[] },
  ) {
    state.formatStaticDefaults = args.formatStaticDefaults.map(formatStatic =>
      formatStatic.toJSON(),
    );
  },
};

export const actions = {
  [fetchCampaignsSucceeded](
    { commit }: { commit: any },
    args: { campaigns: Campaign[] },
  ) {
    commit(fetchCampaignsSucceededMutation, {
      campaigns: args.campaigns,
    });
  },
  [createCampaignSucceeded](
    { commit }: { commit: any },
    args: { campaign: Campaign },
  ) {
    commit(createCampaignSucceededMutation, {
      campaign: args.campaign,
    });
  },
  [duplicateCampaignSucceeded](
    { commit }: { commit: any },
    args: { campaign: Campaign },
  ) {
    commit(duplicateCampaignSucceededMutation, {
      campaign: args.campaign,
    });
  },
  [updateCampaignSucceeded](
    { commit }: { commit: any },
    args: { campaign: Campaign },
  ) {
    commit(updateCampaignSucceededMutation, {
      campaign: args.campaign,
    });
  },
  [hardDeleteCampaignSucceeded](
    { commit }: { commit: any },
    args: { campaignId: string },
  ) {
    commit(hardDeleteCampaignSucceededMutation, {
      campaignId: args.campaignId,
    });
  },
  [fetchContractsSucceeded](
    { commit }: { commit: any },
    args: { contracts: Contract[] },
  ) {
    commit(fetchContractsSucceededMutation, {
      contracts: args.contracts,
    });
  },
  [fetchDevicesSucceeded](
    { commit }: { commit: any },
    args: { devices: Contract[] },
  ) {
    commit(fetchDevicesSucceededMutation, {
      devices: args.devices,
    });
  },
  [fetchMotiveVersionsSucceeded](
    { commit }: { commit: any },
    args: { versions: Version[] },
  ) {
    commit(fetchMotiveVersionsSucceededMutation, {
      versions: args.versions,
    });
  },
  [fetchMotivesSucceeded](
    { commit }: { commit: any },
    args: { motives: Motive[] },
  ) {
    commit(fetchMotivesSucceededMutation, {
      motives: args.motives,
    });
  },
  [createMotiveSucceeded](
    { commit }: { commit: any },
    args: { motive: Motive },
  ) {
    commit(createMotiveSucceededMutation, {
      motive: args.motive,
    });
  },
  [fetchMotivesForCampaignIdSucceeded](
    { commit }: { commit: any },
    args: { campaignId: string; motives: Motive[] },
  ) {
    commit(fetchMotivesForCampaignIdSucceededMutation, {
      campaignId: args.campaignId,
      motives: args.motives,
    });
  },
  [fetchMotiveByIdSucceeded](
    { commit }: { commit: any },
    args: { motive: Motive },
  ) {
    commit(fetchMotiveByIdSucceededMutation, {
      motive: args.motive,
    });
  },
  [fetchFormatsForMotiveIdSucceeded](
    { commit }: { commit: any },
    args: { motiveId: string; formatOverviews: FormatOverview[] },
  ) {
    commit(fetchFormatsForMotiveIdSucceededMutation, {
      motiveId: args.motiveId,
      formatOverviews: args.formatOverviews,
    });
  },
  [updateIsActiveFormatForMotiveIdSucceeded](
    { commit }: { commit: any },
    args: { motiveId: string; formatId: string; isActive: boolean },
  ) {
    commit(updateIsActiveFormatForMotiveIdSucceededMutation, {
      motiveId: args.motiveId,
      formatId: args.formatId,
      isActive: args.isActive,
    });
  },
  [fetchRawFormatsDefaultsInstancesForMotiveIdSucceeded](
    { commit }: { commit: any },
    args: { motiveId: string; rawFormatsDefaultsInstances: FormatRaw[] },
  ) {
    commit(fetchRawFormatsDefaultsInstancesForMotiveIdSucceededMutation, {
      motiveId: args.motiveId,
      rawFormatsDefaultsInstances: args.rawFormatsDefaultsInstances,
    });
  },
  [fetchRawFormatsForMotiveIdSucceeded](
    { commit }: { commit: any },
    args: { motiveId: string; rawFormats: FormatRaw[] },
  ) {
    commit(fetchRawFormatsForMotiveIdSucceededMutation, {
      motiveId: args.motiveId,
      rawFormats: args.rawFormats,
    });
  },
  [updateMotiveSucceeded](
    { commit }: { commit: any },
    args: { motive: Motive },
  ) {
    commit(updateMotiveSucceededMutation, {
      motive: args.motive,
    });
  },
  [fetchFormatByIdSucceeded](
    { commit }: { commit: any },
    args: { format: Format },
  ) {
    commit(fetchFormatByIdSucceededMutation, {
      format: args.format,
    });
  },
  [updateFormatSucceeded](
    { commit }: { commit: any },
    args: { format: Format },
  ) {
    commit(updateFormatSucceededMutation, {
      format: args.format,
    });
  },
  [deleteMotiveSucceeded](
    { commit }: { commit: any },
    args: { motiveId: string },
  ) {
    commit(deleteMotiveSucceededMutation, {
      motiveId: args.motiveId,
    });
  },
  [emptyCampaignBinSucceeded]({ commit }: { commit: any }, args: {}) {
    commit(emptyCampaignBinSucceededMutation, {});
  },
  [duplicateMotiveSucceeded](
    { commit }: { commit: any },
    args: { motive: Motive },
  ) {
    commit(duplicateMotiveSucceededMutation, {
      motive: args.motive,
    });
  },
  [createMotiveGroupSucceeded](
    { commit }: { commit: any },
    args: { motiveGroup: MotiveGroup },
  ) {
    commit(createMotiveGroupSucceededMutation, {
      motiveGroup: args.motiveGroup,
    });
  },
  [fetchMotiveGroupsSucceeded](
    { commit }: { commit: any },
    args: { motiveGroups: MotiveGroup[] },
  ) {
    commit(fetchMotiveGroupsSucceededMutation, {
      motiveGroups: args.motiveGroups,
    });
  },
  [deleteMotiveGroupSucceeded](
    { commit }: { commit: any },
    args: { motiveGroupId: string },
  ) {
    commit(deleteMotiveGroupSucceededMutation, {
      motiveGroupId: args.motiveGroupId,
    });
  },
  [fetchFormatStaticDefaultsSucceeded](
    { commit }: { commit: any },
    args: { formatStaticDefaults: FormatStaticDefault[] },
  ) {
    commit(fetchFormatStaticDefaultsSucceededMutation, {
      formatStaticDefaults: args.formatStaticDefaults,
    });
  },
  updateSelectedStatus({ commit }: { commit: any }, status: any) {
    commit('setSelectedStatus', status);
  },
  updateSortedColumn({ commit }: { commit: any }, column: any) {
    commit('setSortedColumn', column);
  },
};

export default {
  state: initialState,
  getters: getters,
  mutations,
  actions: actions,
};
