<template>
  <div
    :class="{
      'cursor-pointer': header.sort,
      'flex flex-row items-center py-1': true,
    }"
    @click="
      () => {
        if (header.sort) {
          changeSortedColumn(header.id);
        }
      }
    "
  >
    {{ header.label }}

    <app-icon
      name="arrowDownBlue"
      alt="sort icon"
      v-if="header.sort"
      :class="{
        'ml-1': true,
        'transition-transform transform rotate-180':
          header.id === sortBy && sortOrder === -1,
        'transition-transform transform rotate-0':
          header.id !== sortBy || sortOrder !== -1,
      }"
    />
  </div>
</template>

<script lang="ts">
export default {
  props: {
    sortBy: { type: String, required: true },
    sortOrder: { type: Number, required: true },
    changeSortedColumn: { type: Function, required: true },
    header: { type: Object, required: true },
  },
  setup() {
    return {};
  },
};
</script>
