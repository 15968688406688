<template>
  <modal
    class="md:max-w-mediumModalMaxWidth justify-center"
    :isOpen="isModalOpen"
    @close-modal="onClose"
  >
    <DropzoneOverlay :isVisible="!!($refs.upload && $refs.upload.dropActive)" />
    <div class="relative flex flex-col items-center mt-1">
      <FileUpload
        :multiple="true"
        :drop="true"
        :drop-directory="true"
        v-model="files"
        @input-file="onUpload"
        ref="upload"
        :input-id="FILE_UPLOAD_INPUT_ID"
      >
        <div class="flex flex-col items-center mt-7 md:mt-0">
          <div class="flex flex-col items-center" v-if="!isLoading">
            <img alt="file logo" :src="uploadFileImg" />
            <app-txt variant="h3" class="text-primary-700 font-bold my-4">{{
              $t('media.uploadMedia.dropFilesHere')
            }}</app-txt>
            <app-txt variant="tiny" class="text-gray-500 font-semibold">{{
              $t('media.uploadMedia.maxSize')
            }}</app-txt>
            <app-txt variant="tiny" class="text-gray-500">{{
              $t('media.uploadMedia.allowedTypes')
            }}</app-txt>
          </div>

          <!-- <Loading display> -->
          <div v-else>
            <clip-loader
              :loading="true"
              :size="'72px'"
              :color="SPINNER_COLOR"
            ></clip-loader>
            <app-txt variant="tiny" class="text-gray-500 font-semibold mt-4">{{
              $t('media.uploadMedia.uploading', {
                filename: filenameBeingUploaded,
              })
            }}</app-txt>
          </div>
          <!-- </Loading display> -->
        </div>
      </FileUpload>
      <app-txt
        v-if="showFileNameError"
        variant="tiny"
        class="text-red-600 mt-2 text-center"
        >{{ $t('media.uploadMedia.invalidFileNameError') }}</app-txt
      >

      <app-txt
        v-if="droppedFileIsBiggerThanMaxSize.sizeIsBigger"
        variant="tiny"
        class="text-red-600 mt-2 text-center"
        >{{ fileSizeExceededErrorMessage }}</app-txt
      >

      <app-button
        variant="secondary"
        class="mt-3.5"
        @click="onBrowse"
        v-if="!isLoading"
        >{{ $t('media.uploadMedia.browse') }}</app-button
      >
    </div>
  </modal>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import FileUpload from 'vue-upload-component';
import uploadFileImg from '../assets/uploadFile.png';
import { useUploadFile } from '../../application/useUploadFile';
import { ref, defineComponent, computed } from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { useGetMedia } from '../../application/useGetMedia';
import {
  bytesToMegabytes,
  getCreatedFileKey,
  isFileNameValid,
} from '../../application/utils';
import DropzoneOverlay from './dropzoneOverlay.vue';
import { useToast } from 'vue-toastification';
import { t } from '@/services/i18n';

const ACCEPTED_TYPES = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/svg+xml',
  'application/zip',
  'application/x-zip-compressed',
];
const MAX_FILE_SIZE_IN_BYTES = 3_350_000; // 3.35 MB

const FILE_UPLOAD_INPUT_ID = 'fileUploadInput';

const SPINNER_COLOR = '#3FC1FD'; // primary-700

export default defineComponent({
  components: {
    FileUpload,
    ClipLoader,
    DropzoneOverlay,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    currentFolderPath: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const { media: allMedia } = useGetMedia();
    const filenameBeingUploaded = ref('');
    const hasSubmitted = ref(false);
    const { isLoading, call } = useUploadFile();
    const droppedFileIsBiggerThanMaxSize = ref({
      sizeIsBigger: false,
      size: 0,
    });
    const showFileNameError = ref(false);

    const fileSizeExceededErrorMessage = computed(() => {
      return t('media.useUploadFile.fileTooLargeError', {
        maxFileSize: bytesToMegabytes(MAX_FILE_SIZE_IN_BYTES),
        currentFileSize: bytesToMegabytes(
          droppedFileIsBiggerThanMaxSize.value.size,
        ),
      });
    });

    const onClose = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
      droppedFileIsBiggerThanMaxSize.value = {
        sizeIsBigger: false,
        size: 0,
      };
      showFileNameError.value = false;
    };

    const toast = useToast();

    const onUpload = async (params: {
      type: string;
      file: File;
      size: number;
    }) => {
      hasSubmitted.value = true;
      if (params.size > MAX_FILE_SIZE_IN_BYTES) {
        droppedFileIsBiggerThanMaxSize.value = {
          sizeIsBigger: true,
          size: params.size,
        };
        toast.error(fileSizeExceededErrorMessage.value);
        return;
      }

      if (!ACCEPTED_TYPES.includes(params.type)) {
        toast.error(t('media.useUploadFile.wrongFileTypeError'));
        return;
      }

      if (!isFileNameValid(params.file.name)) {
        showFileNameError.value = true;
        toast.error(t('media.useUploadFile.invalidFileNameError'));
        return;
      }

      filenameBeingUploaded.value = params.file.name;
      const file = new File(
        [params.file],
        getCreatedFileKey(
          props.currentFolderPath,
          params.file.name,
          allMedia.value,
        ),
        { type: params.type },
      );
      await call(file);
      onClose();
    };

    const onBrowse = () => {
      document.getElementById(FILE_UPLOAD_INPUT_ID)?.click();
      droppedFileIsBiggerThanMaxSize.value = {
        sizeIsBigger: false,
        size: 0,
      };
      showFileNameError.value = false;
    };

    return {
      fileSizeExceededErrorMessage,
      hasSubmitted,
      isLoading,
      onClose,
      files: [],
      onUpload,
      uploadFileImg,
      filenameBeingUploaded,
      onBrowse,
      FILE_UPLOAD_INPUT_ID,
      SPINNER_COLOR,
      droppedFileIsBiggerThanMaxSize,
      showFileNameError,
    };
  },
});
</script>
