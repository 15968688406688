import { SessionLoginParams } from '../domain';

export class SessionMap {
  static loginToAPI(params: SessionLoginParams) {
    return {
      email: params.email,
      password: params.password,
    };
  }
}
