import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { Folder } from '../../domain/folder';
import { MediaHttpFacade } from '../../infrastructure/media_http_facade';
import { updateFolderSucceeded } from '../../store/store';
import { MoveFolderFailure } from '../../domain';

export const useMoveFolder = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { folder: Folder; newFolder: Folder }) => {
    const result = await facade.moveFolder(params.folder, params.newFolder);

    if (result.isRight()) {
      const folder = result.extract();
      store.dispatch(updateFolderSucceeded, {
        folder,
      });

      toast.success(t('media.useMoveFolder.success'));
    } else {
      const error= result.extract() as MoveFolderFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('media.useMoveFolder.error'));
      }
    }
  });
};
