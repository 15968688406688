import { PreviewAttributes } from './iPreviewAttributes';

export enum PreviewContentType {
  Image = 'image',
  Text = 'text',
}
export interface PreviewContent {
  type: 'image' | 'text';
  attributes?: PreviewAttributes;
  content?: string;
}
