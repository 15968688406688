
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { TemplateNames } from '.';
import { filterRows } from './utils/filterRows';
import { sortTableRows } from './utils/sortRows';
import { useStore } from '@/store';

const DEFAULT_PAGE_SIZE = 20;

type Row = {
  id: string;
  focusOut?: () => void;
  isSelected: boolean;
};

type Header = {
  id: string;
  label: string;
  templateName: string;
  // Optional header params:
  laptopOnly?: boolean;
  mobileOnly?: boolean;
  sort?: (row: Row) => void;
  rest?: (row: Row) => void;
  description?: (row: Row) => void;
  extraClasses?: string;
  searchable?: boolean;
  headerTemplateName?: string;
};

export default defineComponent({
  name: 'app-table',
  inheritAttrs: false,
  props: {
    headers: { type: Array as PropType<Array<Header>>, required: true },
    rows: { type: Array as PropType<Array<Row>>, required: true },
    searchedValue: String,
    initialSort: String,
    pageSize: { type: Number, default: DEFAULT_PAGE_SIZE },
    onRowClick: { type: Function, required: false },
  },
  setup(props) {
    const page = ref(1);
    const store = useStore();

    const sortedColumn = computed(
      () => store.getters.userPreferences.sortedColumn,
    );
    const sortBy = computed(
      () => sortedColumn.value.field ?? props.initialSort ?? '',
    );
    const sortOrder = computed(() =>
      sortedColumn.value.order === 'asc' ? 1 : -1,
    );

    const changeSortedColumn = (columnId: string) => {
      const currentOrder =
        sortedColumn.value.field === columnId &&
        sortedColumn.value.order === 'asc'
          ? 'desc'
          : 'asc';
      store.dispatch('updateSortedColumn', {
        field: columnId,
        order: currentOrder,
      });
    };

    const filteredRows = computed(() => {
      const searchedColumn = props.headers.find(
        (header: Header) => header.searchable,
      );
      return filterRows({
        rows: props.rows,
        searchedValue: props.searchedValue,
        searchedColumnId: searchedColumn?.id,
      });
    });

    const formattedRows = computed(() => {
      return sortTableRows({
        rows: filteredRows.value,
        headers: props.headers,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
      });
    });
    const maxPage = computed(() =>
      Math.ceil(filteredRows.value.length / props.pageSize),
    );
    const changePage = (newPage: number) => {
      window.scroll({ top: 0 });
      page.value = newPage;
    };

    watch([maxPage], () => {
      if (page.value > maxPage.value) page.value = 1;
    });

    return {
      formattedRows,
      page,
      maxPage,
      changePage,
      changeSortedColumn,
      sortBy,
      sortOrder,
      TemplateNames,
    };
  },
});
