import { Campaign } from '../domain/campaign';

export class CampaignDTO {
  static toDomain(json: { [key: string]: any }): Campaign {
    return Campaign.create({
      id: json.id,
      name: json.name,
      trackingParameter: json.trackingParameter,
      startDate: json.startDate,
      endDate: json.endDate,
      active: json.active,
      deletedAt: json.deletedAt,
    });
  }

  static toJson(campaign: Campaign): { [key: string]: any } {
    return {
      id: campaign.id,
      name: campaign.name,
      trackingParameter: campaign.trackingParameter,
      startDate: campaign.startDate.getOrEmpty()?.toString(),
      endDate: campaign.endDate.getOrEmpty()?.toString(),
      active: campaign.active,
      deletedAt: campaign.deletedAt?.getOrEmpty()?.toString(),
    };
  }
}
