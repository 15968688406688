
import { SyncEvents } from './events';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  emits: [SyncEvents.REMOVE_EMAIL, 'input', 'keydown'],
  props: {
    emails: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    input: { type: String, required: true },
  },
  setup(props, context) {
    const removeEmail = (index: number) => {
      context.emit(SyncEvents.REMOVE_EMAIL, index);
    };

    return {
      removeEmail,
    };
  },
});
