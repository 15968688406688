import { useToast } from 'vue-toastification';
import { TemplateHTTPFacade } from '../infraestructure/template_http_facade';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { DownloadTemplateFailure } from '../domain';

const handleDataAndDownloadZip = (data: Blob | undefined, version: string) => {
  if (!data) {
    return;
  }
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/zip',
    }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `template-v${version}.zip`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
};

export const useFetchDownloadTemplateZip = ({
  facade = new TemplateHTTPFacade(),
}: {
  facade?: TemplateHTTPFacade;
}) => {
  const toast = useToast();
  return useMutation(async (version: string) => {
    const result = await facade.downloadTemplate(version);
    if (result.isRight()) {
      const data = result.extract();
      return handleDataAndDownloadZip(data, version);
    } else {
      const error= result.extract() as DownloadTemplateFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('templates.errorDownload'));
      }
    }
  });
};
