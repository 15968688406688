import { DuplicateCampaignFailure } from '@/features/campaigns/domain';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { duplicateCampaignSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useDuplicateCampaign = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { id: string }) => {
    const result = await facade.duplicateCampaign({
      id: params.id,
    });

    if (result.isRight()) {
      const campaign = result.extract();
      store.dispatch(duplicateCampaignSucceeded, { campaign });
      toast.success(t('campaigns.useDuplicateCampaign.success'));
    } else {
      const error= result.extract() as DuplicateCampaignFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useDuplicateCampaign.error'));
      }
    }
  });
};
