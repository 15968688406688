
import { ModalEvents } from '@/components/modal/modalEvents';
import { MediaEvents } from './events';
import { Folder } from '../domain/folder';
import { Asset } from '../domain/asset';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isModalOpen: { type: Boolean, required: true },
    folder: { type: [Folder, Asset] },
  },
  emits: [ModalEvents.CLOSE_MODAL, MediaEvents.CONFIRM_DELETE_NESTED_FILES],
  setup(props, context) {
    const onConfirm = () => {
      context.emit(MediaEvents.CONFIRM_DELETE_NESTED_FILES, props.folder);
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    return {
      onCancel,
      onConfirm,
    };
  },
});
