
import { PropType, computed, defineComponent, ref, watch } from 'vue';
import { CoreEvents } from '@/core/domain/events';
import { t } from '@/services/i18n';
import { overviewTranslateByScope } from '@/features/campaigns/presentation/overviewTranslationScope';

interface Option {
  value: string;
  label: string;
}

export default defineComponent({
  components: {},
  name: 'app-searchable-select-multi',
  emits: [CoreEvents.UPDATE_MODEL_VALUE, 'update:isValid'],
  props: {
    placeholder: { type: String, default: '' },
    label: String,
    errorMessage: String,
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    disabled: { type: Boolean, default: false },
  },
  setup(props, context) {
    const t = overviewTranslateByScope('exportModal');
    const isDropdownOpen = ref(false);
    const selectedValues = ref(props.modelValue || []);

    const selectedLabels = computed(() =>
      props.options
        .filter(option => selectedValues.value.includes(option.value))
        .map(option => option.label),
    );

    const searchedValue = ref('');

    watch(
      selectedValues,
      newVal => {
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, newVal);
        context.emit('update:isValid', newVal.length > 0);
      },
      { deep: true },
    );

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const filteredOptions = computed(() => {
      if (!searchedValue.value) return props.options;
      return props.options.filter(option =>
        option.label.toLowerCase().includes(searchedValue.value.toLowerCase()),
      );
    });

    const toggleSelectAll = (event: Event) => {
      if (event.target && (event.target as HTMLInputElement).checked) {
        selectedValues.value = filteredOptions.value.map(
          option => option.value,
        );
      } else {
        selectedValues.value = [];
      }
    };

    const isAllSelected = computed(() => {
      return filteredOptions.value.every(option =>
        selectedValues.value.includes(option.value),
      );
    });

    return {
      toggleDropdown,
      selectedLabels,
      searchedValue,
      filteredOptions,
      isDropdownOpen,
      selectedValues,
      t,
      toggleSelectAll,
      isAllSelected,
    };
  },
});
