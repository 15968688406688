import { Contract } from '@/features/campaigns/domain/contract';
import { Device } from '@/features/campaigns/domain/device';
import { Format } from '@/features/campaigns/domain/format/format';
import { FormatDefault } from '@/features/campaigns/domain/format/formatDefault';
import { FormatPreview } from '@/features/campaigns/domain/format/formatPreview';
import { FormatRaw } from '@/features/campaigns/domain/format/formatRaw';
import { PreviewSettings } from '@/features/campaigns/domain/format/previewSettings';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { Asset } from '@/features/media/domain/asset';
import { useStore } from '@/store';
import { computed, ComputedRef, Ref } from 'vue';

type FormatsByFormatName = Record<string, FormatRaw>;
type DefaultsInstancesByFormatName = Record<string, FormatDefault>;

const getFormatsByName = (rawFormats: FormatRaw[]): FormatsByFormatName => {
  const nameToRawFormatMap = {} as FormatsByFormatName;

  return rawFormats.reduce(
    (map, rawFormat) => ({
      ...map,
      [rawFormat.name]: rawFormat,
    }),
    nameToRawFormatMap,
  );
};

const getDefaultsInstancesByName = (
  defaultsInstances: FormatDefault[],
): DefaultsInstancesByFormatName => {
  const nameToRawFormatDefaultMap = {} as DefaultsInstancesByFormatName;

  return defaultsInstances.reduce(
    (map, rawFormatDefaultInstance) => ({
      ...map,
      [rawFormatDefaultInstance.name]: rawFormatDefaultInstance,
    }),
    nameToRawFormatDefaultMap,
  );
};

export const useGetPreviewsByFormatName = (
  motive: Ref<Motive>,
  sidebarFormat: Ref<Format | undefined>,
  imagesById: Ref<Record<string, Asset>>,
  devicesById: Ref<Record<string, Device>>,
  contractsById: Ref<Record<string, Contract>>,
  campaignTrackingParam?: string,
  previewSettings?: Ref<PreviewSettings>,
): ComputedRef<Record<string, FormatPreview>> => {
  const store = useStore();

  const previewsByFormatName = computed(() => {
    const rawFormats = store.getters.rawMotiveFormats(motive.value.id);
    const rawDefaultsInstances = store.getters.rawMotiveFormatsDefaultsInstances(
      motive.value.id,
    );

    const formatsByName = getFormatsByName(rawFormats);

    const defaultInstancesByName = getDefaultsInstancesByName(
      rawDefaultsInstances,
    );

    const formatNames = Object.keys(formatsByName);

    return formatNames.reduce(
      (nameToFormatPreviewMap, formatName) => ({
        ...nameToFormatPreviewMap,
        [formatName]: FormatPreview.create({
          motive: motive.value,
          sidebarFormat: sidebarFormat.value,
          specificFormat: formatsByName[formatName],
          defaultFormat: defaultInstancesByName[formatName],
          imagesById: imagesById.value,
          devicesById: devicesById.value,
          contractsById: contractsById.value,
          campaignTrackingParam,
          previewSettings: previewSettings?.value,
        }),
      }),
      {},
    );
  });

  return previewsByFormatName;
};
