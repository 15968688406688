import { DateWrapper } from '@/core';
import { Layout } from '../valueObjects';
import { Slide, CreateSlideForValuesParams } from '../valueObjects/slide';

export class CreateMotive {
  constructor(
    public name: string,
    public description: string,
    public isDefaultMotive: boolean,
    public startDate: DateWrapper,
    public endDate: DateWrapper,
    public layout: Layout,
    public clickUrl: string,
    public slides: Slide[],
    public campaignId: string,
    public isActive: boolean,
    public motiveGroupId?: string,
  ) {}

  static createForValues(params: {
    name: string;
    description: string;
    isDefaultMotive: boolean;
    startDate: DateWrapper;
    endDate: DateWrapper;
    layout: Layout;
    clickUrl: string;
    slides: CreateSlideForValuesParams[];
    campaignId: string;
    isActive: boolean;
    motiveGroupId?: string;
  }) {
    return new CreateMotive(
      params.name,
      params.description,
      params.isDefaultMotive,
      params.startDate,
      params.endDate,
      params.layout,
      params.clickUrl,
      params.slides.map(slide => Slide.createForValues(slide)),
      params.campaignId,
      params.isActive,
      params.motiveGroupId,
    );
  }

  toJson() {
    return {
      name: this.name,
      description: this.description,
      isDefaultMotive: this.isDefaultMotive,
      startDate: this.startDate.getValue().toString(),
      endDate: this.endDate.getValue().toString(),
      layout: this.layout,
      clickUrl: this.clickUrl,
      slides: this.slides.map(slide => slide.toJson()),
      campaignId: this.campaignId,
      isActive: this.isActive,
      motiveGroupId: this.motiveGroupId,
    };
  }
}
