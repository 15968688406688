import { Failure } from '@/core';

export interface Meta {
  accessToken: string;
  refreshToken: string;
}

export class SessionLoginParams {
  constructor(public email: string, public password: string) {}
}

export class SessionLoginFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Login failure', error, status);
  }
}

export class SendResetMailFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Forgot password failure', error, status);
  }
}

export class ResetPasswordFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Reset password failure', error, status);
  }
}
