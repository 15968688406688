
import { defineComponent } from 'vue';
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { injectStrict } from '@/utils/injectStrict';
import { FormEvents } from '@/components/formGenerator/formEvents';
import { CreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';

export default defineComponent({
  name: 'modal',
  inheritAttrs: false,
  components: { Dialog, DialogOverlay, DialogTitle },
  props: {
    step: Number,
    isOpen: Boolean,
    title: String,
    cancelLabel: String,
    continueLabel: String,
    confirmLabel: String,
    onConfirm: { type: Function, required: true },
    onContinue: { type: Function, required: true },
    isLoading: Boolean,
    confirmBtnStyle: { type: String, required: false },
    isSubmitDisabled: Boolean,
    isContinueDisabled: Boolean,
  },
  emits: [ModalEvents.CLOSE_MODAL, FormEvents.SET_SUBMITTED],
  setup(props, context) {
    const {
      isGeneralSectionValid,
      isVariationsSectionValid
    } = injectStrict(CreateMotiveFormKey);

    const onContinuePressed = () => {
      context.emit(FormEvents.SET_SUBMITTED, true);

      if (isGeneralSectionValid.value) {
        context.emit(FormEvents.SET_SUBMITTED, false);
        props.onContinue();
      }
    }

    const onConfirmPressed = () => {
      context.emit(FormEvents.SET_SUBMITTED, true);

      if (isVariationsSectionValid.value) {
        context.emit(FormEvents.SET_SUBMITTED, false);
        props.onConfirm();
      }
    }
    
    return { ModalEvents, isGeneralSectionValid, isVariationsSectionValid, onContinuePressed, onConfirmPressed };
  },
});
