<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between pb-1">
      <app-txt v-if="label" variant="small" class="text-gray-700 font-bold">{{
        label
      }}</app-txt>
      <button v-if="isResetVisible" @click="onReset" class="focus:outline-none">
        <reset-icon color="#6B7280" />
      </button>
    </div>
    <div class="flex flex-row w-full">
      <app-unit-input
        class="w-full mr-1"
        type="number"
        :modelValue="vertical"
        @update:modelValue="onUpdateVertical"
        :unit="Unit.PX"
        leftIcon="vertical"
      />
      <app-unit-input
        class="w-full"
        type="number"
        :modelValue="horizontal"
        @update:modelValue="onUpdateHorizontal"
        :unit="Unit.PX"
        leftIcon="horizontal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { AxisEvents } from '@/core/domain/events';
import { Unit } from '@/features/campaigns/application/motive/forms/unit';


export default defineComponent({
  name: 'app-offset-picker',
  emits: [
    AxisEvents.UPDATE_VERTICAL,
    AxisEvents.UPDATE_HORIZONTAL,
  ],
  props: {
    horizontal: { type: Number, required: true },
    vertical: { type: Number, required: true },
    label: String,
    hasReset: { type: Boolean, default: false },
    initialHorizontal: { type: Number, required: false },
    initialVertical: { type: Number, required: false },
  },
  setup(props, context) {
    const onUpdateVertical = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_VERTICAL,
        value,
      );
    };

    const onUpdateHorizontal = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_HORIZONTAL,
        value,
      );
    };

    const onReset = () => {
      context.emit(
        AxisEvents.UPDATE_HORIZONTAL,
        props.initialHorizontal,
      );
      context.emit(
        AxisEvents.UPDATE_VERTICAL,
        props.initialVertical,
      );
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialHorizontal != undefined &&
        props.initialVertical !== undefined &&
        (props.initialHorizontal !== props.horizontal ||
          props.initialVertical !== props.vertical)
      );
    });

    return {
      isResetVisible,
      onReset,
      onUpdateVertical,
      onUpdateHorizontal,
      Unit,
    };
  },
});
</script>
