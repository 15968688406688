import { Quill } from '@vueup/vue-quill';

const Inline = Quill.import('blots/inline');
const Block = Quill.import('blots/block');

// Custom span blot to prevent tag stripping
class SpanBlot extends Inline {
  static blotName: string;
  static tagName: string;
  static formats() {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  optimize(context: any) {}
}

class ProductBlot extends Inline {
  static blotName: string;
  static tagName: string;
  static className: string;
  static formats() {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  optimize(context: any) {}
}

class WrapperBlot extends Block {
  static create(value: any) {
    const node = super.create(value);
    node.setAttribute('style', 'font-family: InterstateCondensedBlack;');
    return node;
  }
}

export const fontOptions = [
  {
    key: 'INTERSTATE CONDENSED BLACK',
    value: 'InterstateCondensedBlack',
  },
  {
    key: 'Interstate Condensed Bold',
    value: 'InterstateCondensedBold',
  },
  { key: 'Interstate Bold', value: 'InterstateBold' },
];

export const colorOptions = [
  '#FFFFFF',
  '#FFED00',
  '#FF6400',
  '#00A086',
  '#EA7AEA',
  '#121212',
  '#FD222F',
  '#55BBFF',
  '#AC51FF',
  '#65E842',
];

export const configureQuill = () => {
  const FontStyle = Quill.import('attributors/style/font');
  const Icons = Quill.import('ui/icons');
  Icons['bold'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2499 11.8C15.7395 11.5018 16.1527 11.0934 16.4566 10.6073C16.7604 10.1212 16.9465 9.57077 16.9999 9C17.0092 8.48388 16.9167 7.971 16.7277 7.49063C16.5387 7.01027 16.257 6.57184 15.8985 6.20039C15.5401 5.82894 15.1119 5.53174 14.6386 5.32578C14.1653 5.11981 13.656 5.00911 13.1399 5H6.6499V19H13.6499C14.1411 18.9948 14.6264 18.8929 15.0781 18.7001C15.5299 18.5073 15.9392 18.2274 16.2828 17.8764C16.6264 17.5253 16.8975 17.1101 17.0806 16.6543C17.2637 16.1985 17.3552 15.7112 17.3499 15.22V15.1C17.3502 14.4071 17.1528 13.7285 16.7808 13.144C16.4088 12.5594 15.8777 12.0931 15.2499 11.8ZM8.6499 7H12.8499C13.2761 6.98681 13.6961 7.10428 14.0536 7.33665C14.4111 7.56902 14.6889 7.90517 14.8499 8.3C15.0128 8.82779 14.9601 9.39859 14.7034 9.88765C14.4467 10.3767 14.0068 10.7443 13.4799 10.91C13.2753 10.97 13.0631 11.0003 12.8499 11H8.6499V7ZM13.2499 17H8.6499V13H13.2499C13.6761 12.9868 14.0961 13.1043 14.4536 13.3367C14.8111 13.569 15.0889 13.9052 15.2499 14.3C15.4128 14.8278 15.3601 15.3986 15.1034 15.8877C14.8467 16.3767 14.4068 16.7443 13.8799 16.91C13.6753 16.97 13.4631 17.0003 13.2499 17Z" fill="black"/>
    </svg>
  `;
  Icons['italic'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7601 9H13.7601L11.5601 19H9.56006L11.7601 9ZM13.4401 5C13.2423 5 13.0489 5.05865 12.8845 5.16853C12.72 5.27841 12.5919 5.43459 12.5162 5.61732C12.4405 5.80004 12.4207 6.00111 12.4593 6.19509C12.4979 6.38907 12.5931 6.56725 12.733 6.70711C12.8728 6.84696 13.051 6.9422 13.245 6.98079C13.4389 7.01937 13.64 6.99957 13.8227 6.92388C14.0055 6.84819 14.1616 6.72002 14.2715 6.55557C14.3814 6.39112 14.4401 6.19778 14.4401 6C14.4401 5.73478 14.3347 5.48043 14.1472 5.29289C13.9596 5.10536 13.7053 5 13.4401 5Z" fill="black"/>
    </svg>
  `;
  Icons['underline'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 20V22H5V20H19ZM16 13.215C15.9671 13.875 15.7711 14.5165 15.4297 15.0823C15.0883 15.6481 14.6121 16.1205 14.0435 16.4572C13.475 16.794 12.8318 16.9847 12.1716 17.0122C11.5114 17.0397 10.8546 16.9033 10.26 16.615C9.57464 16.3185 8.99341 15.8241 8.59077 15.1952C8.18813 14.5663 7.98242 13.8315 8 13.085V5.005H6V13.215C6.03383 14.1564 6.28885 15.0766 6.74442 15.9012C7.19998 16.7257 7.84329 17.4314 8.62227 17.9611C9.40125 18.4908 10.294 18.8296 11.2283 18.9502C12.1625 19.0707 13.1121 18.9696 14 18.655C15.1811 18.2613 16.2059 17.5012 16.9252 16.485C17.6446 15.4689 18.0211 14.2498 18 13.005V5.005H16V13.215ZM16 5H18H16ZM8 5H6H8Z" fill="black"/>
    </svg>  
  `;
  Icons['strike'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12.2029H21V13.7029H16.6342C16.8711 14.2002 16.9906 14.7451 16.9836 15.2958C16.9977 15.8183 16.8855 16.3365 16.6567 16.8064C16.428 17.2764 16.0893 17.6843 15.6694 17.9955C14.6579 18.7072 13.4378 19.0607 12.2025 19C11.3 19.0061 10.4062 18.8224 9.57916 18.461C8.82387 18.1477 8.16917 17.6329 7.68652 16.9728C7.24212 16.3426 7.00762 15.5884 7.01636 14.8174V14.704H7.30359V14.7029H7.64508V14.704H9.02V14.8173C9.00433 15.1687 9.07382 15.5187 9.22253 15.8374C9.37125 16.1562 9.59479 16.4343 9.87413 16.648C10.5515 17.1303 11.372 17.3697 12.2025 17.3273C12.9356 17.3752 13.6645 17.1835 14.2792 16.7811C14.507 16.6126 14.6901 16.3908 14.8124 16.1351C14.9346 15.8794 14.9923 15.5977 14.9804 15.3145C14.9964 15.041 14.9459 14.7676 14.8332 14.5178C14.7205 14.268 14.5491 14.0493 14.3335 13.8801C14.2451 13.8161 14.1533 13.7569 14.0585 13.7029H3V12.2029ZM16.3447 7.0598C15.9232 6.40481 15.3271 5.88058 14.6236 5.54619C13.8394 5.1726 12.9793 4.98565 12.1107 4.99999C10.8996 4.94801 9.70883 5.32403 8.74719 6.06212C8.33435 6.38097 8.00134 6.79147 7.77448 7.26119C7.54762 7.73091 7.43313 8.24695 7.44006 8.76854C7.43656 9.26283 7.54659 9.75133 7.76166 10.1964H10.3583C10.275 10.1427 10.173 10.0906 10.1056 10.0358C9.90219 9.88567 9.73702 9.68968 9.62358 9.46372C9.51014 9.23777 9.45164 8.98823 9.45282 8.7354C9.43773 8.44679 9.49242 8.15879 9.61226 7.8958C9.73209 7.63282 9.91354 7.40258 10.1412 7.2246C10.7173 6.82506 11.4109 6.63053 12.1107 6.67217C12.8717 6.62379 13.6231 6.86239 14.2167 7.34087C14.465 7.57466 14.6594 7.85967 14.7865 8.17612C14.9136 8.49257 14.9703 8.83288 14.9527 9.17345V9.28686H16.9559V9.17345C16.9592 8.42485 16.7471 7.69109 16.3447 7.0598Z" fill="black"/>
    </svg>
  `;
  Icons['script']['super'] = `
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7347 10.674L18.8028 9.52746C18.9988 9.32605 19.1737 9.12007 19.3396 8.91866C19.4993 8.72449 19.6438 8.5182 19.7716 8.30166C19.8913 8.10149 19.9893 7.88917 20.0641 7.66826C20.1352 7.45334 20.1712 7.2284 20.1708 7.00205C20.1737 6.72068 20.1217 6.44143 20.0177 6.17999C19.9191 5.93424 19.765 5.71465 19.5674 5.53839C19.3576 5.35484 19.1122 5.21648 18.8465 5.13193C18.2085 4.94188 17.5267 4.9575 16.898 5.17658C16.611 5.28503 16.3506 5.45378 16.1343 5.67145C15.9255 5.88003 15.7636 6.13078 15.6595 6.40693C15.5595 6.67341 15.5067 6.95531 15.5036 7.23993L15.5 7.47693H17.0393L17.0457 7.24996C17.0473 7.1233 17.0654 6.99738 17.0995 6.87538C17.1329 6.76831 17.186 6.66845 17.2563 6.58101C17.3273 6.50065 17.4159 6.43766 17.5151 6.39691C17.7397 6.30792 17.9888 6.30274 18.2169 6.38233C18.2991 6.41841 18.3725 6.47191 18.432 6.53912C18.4927 6.61086 18.5394 6.69341 18.5696 6.78245C18.6053 6.89069 18.6237 7.00386 18.6243 7.11783C18.6232 7.20742 18.6125 7.29664 18.5924 7.38395C18.5633 7.48823 18.5216 7.5886 18.4684 7.68288C18.3911 7.81909 18.303 7.94891 18.205 8.07111C18.0706 8.24576 17.9273 8.41337 17.7757 8.57328L15.6194 10.9264V12H20.4223V10.674H17.7347Z" fill="black"/>
    <path d="M10.62 14L13.5 16.88L12.38 18L9.5 15.12L6.62 18L5.5 16.88L8.38 14L5.5 11.12L6.62 10L9.5 12.88L12.38 10L13.5 11.12L10.62 14Z" fill="black"/>
    </svg>
  `;

  Icons['product'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5801 6.96768C15.5391 6.8289 15.4816 6.70627 15.4074 6.59981C15.3333 6.49144 15.2425 6.40019 15.1352 6.32605C15.0298 6.25 14.9089 6.19202 14.7723 6.15209C14.6376 6.11217 14.4884 6.09221 14.3245 6.09221C14.0181 6.09221 13.7488 6.16635 13.5166 6.31464C13.2864 6.46293 13.1069 6.67871 12.9781 6.96198C12.8493 7.24335 12.7849 7.58745 12.7849 7.9943C12.7849 8.40114 12.8483 8.74715 12.9752 9.03232C13.102 9.31749 13.2815 9.53517 13.5137 9.68536C13.7459 9.83365 14.0201 9.90779 14.3362 9.90779C14.623 9.90779 14.8679 9.85837 15.0708 9.75951C15.2757 9.65875 15.4318 9.51711 15.5391 9.3346C15.6484 9.15209 15.703 8.93631 15.703 8.68726L15.9606 8.72433H14.4152V7.79468H16.9235V8.53042C16.9235 9.04373 16.8123 9.48479 16.5899 9.85361C16.3674 10.2205 16.0611 10.5038 15.6708 10.7034C15.2806 10.9011 14.8337 11 14.3303 11C13.7683 11 13.2747 10.8793 12.8493 10.6378C12.4239 10.3945 12.0922 10.0494 11.8542 9.60266C11.6181 9.15399 11.5 8.62167 11.5 8.0057C11.5 7.53232 11.5702 7.11027 11.7107 6.73954C11.8532 6.36692 12.0522 6.05133 12.3078 5.79278C12.5634 5.53422 12.861 5.33745 13.2005 5.20247C13.54 5.06749 13.9079 5 14.304 5C14.6435 5 14.9596 5.04848 15.2523 5.14544C15.545 5.24049 15.8045 5.37548 16.0308 5.55038C16.2591 5.72529 16.4455 5.93346 16.5899 6.1749C16.7343 6.41445 16.8269 6.67871 16.8679 6.96768H15.5801Z" fill="black"/>
    <path d="M17.8755 10.9202V5.07985H20.2756C20.7165 5.07985 21.0844 5.14354 21.379 5.27091C21.6736 5.39829 21.8951 5.5751 22.0434 5.80133C22.1917 6.02567 22.2658 6.28422 22.2658 6.577C22.2658 6.80513 22.219 7.0057 22.1254 7.17871C22.0317 7.34981 21.9029 7.49049 21.739 7.60076C21.5771 7.70913 21.3917 7.78612 21.1829 7.83175V7.88878C21.4112 7.89829 21.6249 7.96103 21.8239 8.077C22.0249 8.19297 22.1878 8.35551 22.3127 8.56464C22.4376 8.77186 22.5 9.01901 22.5 9.30608C22.5 9.61597 22.421 9.89259 22.2629 10.1359C22.1068 10.3774 21.8756 10.5684 21.5692 10.7091C21.2629 10.8498 20.8853 10.9202 20.4365 10.9202H17.8755ZM19.1429 9.91065H20.176C20.5292 9.91065 20.7868 9.84506 20.9487 9.71388C21.1107 9.5808 21.1917 9.40399 21.1917 9.18346C21.1917 9.02186 21.1517 8.87928 21.0717 8.7557C20.9917 8.63213 20.8775 8.53517 20.7292 8.46483C20.5829 8.39449 20.4082 8.35932 20.2053 8.35932H19.1429V9.91065ZM19.1429 7.52376H20.0824C20.256 7.52376 20.4102 7.4943 20.5448 7.43536C20.6814 7.37452 20.7887 7.28897 20.8668 7.17871C20.9468 7.06844 20.9868 6.93631 20.9868 6.78232C20.9868 6.57129 20.9097 6.40114 20.7556 6.27186C20.6034 6.14259 20.3868 6.07795 20.1058 6.07795H19.1429V7.52376Z" fill="black"/>
    <path d="M8.62 14L11.5 16.88L10.38 18L7.5 15.12L4.62 18L3.5 16.88L6.38 14L3.5 11.12L4.62 10L7.5 12.88L10.38 10L11.5 11.12L8.62 14Z" fill="black"/>
    </svg>
`;

  FontStyle.whitelist = fontOptions.map(font => font.value);
  Quill.register(FontStyle, true);

  SpanBlot.blotName = 'span';
  SpanBlot.tagName = 'span';

  ProductBlot.blotName = 'product';
  ProductBlot.tagName = 'sup';
  ProductBlot.className = 'sup-product';

  Quill.register(WrapperBlot);

  Quill.register({ 'formats/span': SpanBlot }, true);
  Quill.register({ 'formats/product': ProductBlot }, true);
};
