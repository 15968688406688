
import { computed, onUpdated, ref, defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { DateWrapper } from '@/core';
import { useUpdateCampaign } from '../../application/campaign/actions/useUpdateCampaign';
import { Campaign } from '../../domain/campaign';
import { campaignsTranslateByScope } from '../translationScope';
import { useFetchMotiveGroups } from '../../application/motive/actions/useFetchMotiveGroups';
import { useGetMotiveGroupRows } from '../../application/motive/list/useGetMotiveGroupRows';
import ConfirmDeleteMotiveGroupModal from './confirmDeleteMotiveGroupModal.vue';

export default defineComponent({
  components: {
    ConfirmDeleteMotiveGroupModal,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    campaign: { type: Campaign, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const t = campaignsTranslateByScope('editCampaign');

    useFetchMotiveGroups({});
    const motiveGroupRows = useGetMotiveGroupRows(props.campaign.id);

    const hasSubmitted = ref(false);
    const name = ref(props.campaign.name);
    const trackingParameter = ref(props.campaign.trackingParameter);
    const startDate = ref(props.campaign.startDate);
    const endDate = ref(props.campaign.endDate);

    const { isLoading, call } = useUpdateCampaign();

    const endDateErrorMessage = computed(() => {
      if (!hasSubmitted.value) return null;
      if (!endDate.value.isValid()) return t('invalidDateError');
      if (
        startDate.value.isValid() &&
        endDate.value.getValue() < startDate.value.getValue()
      )
        return t('startAfterEndError');
      return null;
    });

    const closeModal = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onEditCampaign = () => {
      hasSubmitted.value = true;
      if (
        name.value &&
        startDate.value.isValid() &&
        endDate.value.isValid() &&
        endDate.value.getValue() >= startDate.value.getValue()
      ) {
        call({
          id: props.campaign.id,
          name: name.value,
          trackingParameter: trackingParameter.value,
          startDate: startDate.value as DateWrapper,
          endDate: endDate.value as DateWrapper,
        });
        closeModal();
      }
    };

    const currentlyDeletedMotiveGroupId = ref('');
    const onDeleteMotiveGroup = (motiveGroupId: string) => {
      currentlyDeletedMotiveGroupId.value = motiveGroupId;
    };
    const resetCurrentlyDeletedMotiveGroupId = () => {
      currentlyDeletedMotiveGroupId.value = '';
    };

    onUpdated(() => {
      hasSubmitted.value = false;
      name.value = props.campaign.name;
      trackingParameter.value = props.campaign.trackingParameter;
      startDate.value = props.campaign.startDate;
      endDate.value = props.campaign.endDate;
    });

    return {
      t,
      name,
      trackingParameter,
      startDate,
      endDate,
      onEditCampaign,
      isLoading,
      hasSubmitted,
      endDateErrorMessage,
      closeModal,
      motiveGroupRows,
      onDeleteMotiveGroup,
      currentlyDeletedMotiveGroupId,
      resetCurrentlyDeletedMotiveGroupId,
    };
  },
});
