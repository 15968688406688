import { DateWrapper } from '@/core';
import { CampaignRow, CampaignStatus } from '.';

export class Campaign {
  constructor(
    public id: string,
    public name: string,
    public trackingParameter: string,
    public startDate: DateWrapper,
    public endDate: DateWrapper,
    public active: boolean,
    public deletedAt?: DateWrapper,
  ) {}

  static create(params: {
    id: string;
    name: string;
    trackingParameter: string;
    startDate: string;
    endDate: string;
    active: boolean;
    deletedAt?: string;
  }) {
    return new Campaign(
      params.id,
      params.name,
      params.trackingParameter,
      DateWrapper.create(params.startDate),
      DateWrapper.create(params.endDate),
      params.active,
      DateWrapper.create(params?.deletedAt ?? ''),
    );
  }

  static createForValues(params: {
    id: string;
    name: string;
    trackingParameter: string;
    startDate: DateWrapper;
    endDate: DateWrapper;
    active: boolean;
    deletedAt?: DateWrapper;
  }) {
    return new Campaign(
      params.id,
      params.name,
      params.trackingParameter,
      params.startDate,
      params.endDate,
      params.active,
      params.deletedAt,
    );
  }

  toCampaignRow(): CampaignRow {
    return {
      id: this.id,
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      status:
        this.isInsideActiveDates() && this.active
          ? CampaignStatus.ACTIVE
          : CampaignStatus.INACTIVE,
    };
  }

  isInsideActiveDates(): boolean {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return this.startDate.getValue() <= now && now <= this.endDate.getValue();
  }
}
