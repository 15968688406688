import { Contract } from './contract';
import { Device } from './device';

const ALLOWED_ENTITIES = ['contract', 'device'];
const ALLOWED_PROPERTIES_FOR_ENTITY = {
  contract: [
    'title',
    'subtitle',
    'oneTimePrice',
    'monthlyPrice',
    'bullet1',
    'bullet2',
    'bullet3',
    'dataVolumeGb',
  ],
  device: ['groupTitle', 'oneTimePrice', 'monthlyPrice'],
};
const PLUS_OPERATOR = '+';
const MINUS_OPERATOR = '-';
const OPERATORS = [PLUS_OPERATOR, MINUS_OPERATOR];

const splitItemIntoOperandsAndOperators = (item: string): string[] => {
  // Remove all whitespaces
  const trimmedItem = item.replace(/\s/g, '');

  const result = [];
  let operand = '';

  for (const symbol of trimmedItem) {
    if (OPERATORS.includes(symbol)) {
      result.push(operand);
      result.push(symbol);
      operand = '';
    } else {
      operand += symbol;
    }
  }

  if (operand) {
    result.push(operand);
  }

  return result;
};

const evaluateIndividualItem = (item: string, data: any) => {
  const [entity, property] = item?.split('.') || [];
  if (!ALLOWED_ENTITIES.includes(entity)) return item;
  if (
    !ALLOWED_PROPERTIES_FOR_ENTITY[
      entity as keyof typeof ALLOWED_PROPERTIES_FOR_ENTITY
    ].includes(property)
  )
    return item;
  return data[entity][property];
};

const evaluateItem = (item: string, data: any) => {
  // Split string into array of operands and operators
  const operatorsAndOperands = splitItemIntoOperandsAndOperators(item);

  // Extract first operand and use as initial value of price
  const firstOperand = operatorsAndOperands.shift();
  let computedPrice = evaluateIndividualItem(firstOperand as string, data);

  while (operatorsAndOperands.length > 0) {
    // Extract next operator and operand
    const operator = operatorsAndOperands.shift();
    const operand = operatorsAndOperands.shift();

    if (!operator || !operand) {
      break;
    }

    const parsedOperand = parseFloat(evaluateIndividualItem(operand, data));

    switch (operator) {
      case PLUS_OPERATOR:
        computedPrice += parsedOperand;
        break;
      case MINUS_OPERATOR:
        computedPrice -= parsedOperand;
        break;
    }
  }

  return computedPrice;
};

const rebuildString = (
  formula: string,
  originalItems: string[],
  evaluatedItems: string[],
): string => {
  let rebuiltString = formula;
  originalItems.forEach((item, index) => {
    rebuiltString = rebuiltString.replace(item, evaluatedItems[index]);
  });
  return rebuiltString;
};

export const evaluate = (
  formula: string | undefined,
  data: { contract: Record<string, any>; device: Record<string, any> },
): string => {
  if (!formula) {
    return '';
  }

  const originalItems = formula?.match(/\{([^{}]*)\}/gm);
  if (!originalItems) return formula;

  const evaluatedItems = originalItems.map(item =>
    evaluateItem(item.substring(1, item.length - 1), data),
  );

  return rebuildString(formula, originalItems, evaluatedItems);
};

export const formatContractData = (contract: Contract) => {
  if (!contract) return {};
  return {
    title: contract.title,
    subtitle: contract.subtitle,
    oneTimePrice: contract.oneTimePrice,
    monthlyPrice: contract.monthlyPrice,
    bullet1: contract?.bullets[0]?.description,
    bullet2: contract?.bullets[1]?.description,
    bullet3: contract?.bullets[2]?.description,
    dataVolumeGb: contract.dataVolumeGb,
  };
};

export const formatDeviceData = (device?: Device) => {
  if (!device) return {};
  return {
    groupTitle: device.groupTitle,
    oneTimePrice: device.oneTimePrice,
    monthlyPrice: device.monthlyPrice,
  };
};

export const convertFromBytesToKilobytes = (bytes: number) => {
  return (bytes / Math.pow(1024, 1)).toFixed(2);
};

export const mapValues = <T, S>(
  record: Record<string, T>,
  mapper: (input: T) => S,
): Record<string, S> => {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => [key, mapper(value)]),
  );
};
