import { Layout } from '../valueObjects';
import { CreateSlideParams } from '../valueObjects/slide';

export class FormatRaw {
  constructor(
    public id: string,
    public name: string,
    public motiveId: string,
    public isActive: boolean,
    public motiveName?: string,
    public size?: number,
    public layout?: Layout,
    public slides?: Record<string, Partial<CreateSlideParams> | undefined>,
    public slideOrder?: string[],
  ) {}

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    isActive: boolean;
    motiveName?: string;
    size?: number;
    layout?: Layout;
    slides?: Record<string, Partial<CreateSlideParams> | undefined>;
    slideOrder?: string[];
  }) {
    return new FormatRaw(
      params.id,
      params.name,
      params.motiveId,
      params.isActive,
      params.motiveName,
      params.size,
      params.layout,
      params.slides,
      params.slideOrder,
    );
  }
}
