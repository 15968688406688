import { Failure } from '@/core';

export class GetAssetsFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get assets failure', error, status);
  }
}

export class GetFoldersFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Get folders failure', error, status);
  }
}

export class CreateFolderFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Create folder failure', error, status);
  }
}

export class DeleteMediaFailure extends Failure {
  motivesUsingAsset?: string[];
  constructor(error: any, motivesUsingAsset?: string[], status?: number) {
    super('Delete media failure', error, status);
    this.motivesUsingAsset = motivesUsingAsset;
  }
}

export class UploadFileFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Upload file failure', error, status);
  }
}

export class MoveAssetFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Move image failure', error, status);
  }
}

export class MoveFolderFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Move folder failure', error, status);
  }
}

export class RenameAssetFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Rename asset failure', error, status);
  }
}

export class RenameFolderFailure extends Failure {
  isFolderNameTakenError?: boolean;
  constructor(error: any, isFolderNameTakenError?: boolean, status?: number) {
    super('Rename folder failure', error, status);
    this.isFolderNameTakenError = isFolderNameTakenError;
  }
}
