<template>
  <div
    class=" my-[2px] b-0 absolute z-10 w-full rounded-1 border border-primary bg-white"
  >
    <button
      v-for="(element, index) in SlideElementType"
      :key="index"
      class="flex py-2 px-4 justify-between w-full border-bottom border-bottom-sidebarElements"
      @click="() => addElement(element)"
    >
      <span> {{ element }}</span
      ><app-icon :alt="`element-${element}`" name="addBlue"></app-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { SlideElementType } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';

export default defineComponent({
  props: {
    slideId: { type: String, required: true },
    showMoreElements: { type: Function, required: true },
    toggleSlideSection: { type: Function, required: true },
    lastIndex: { type: Number, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { addSlideElement } = injectStrict(EditFormatsFormKey);

    const addElement = (type: SlideElementType) => {
      addSlideElement(props.slideId, type);
      props.showMoreElements();
      props.toggleSlideSection(props.lastIndex + 1);
    };

    return {
      t,
      SlideElementType,
      addElement,
    };
  },
});
</script>
