type CLICK_URLS = {
  slideIndex: number;
  clickUrl: string;
  globalUrl: string;
};

export const generateTooltipContent = (urls: CLICK_URLS[]) => {
  if (!urls || urls.length === 0) {
    return ``;
  }

  let slideLinks = '';
  let globalLink = '';
  let allSlidesSameAsGlobal = true;
  let globalUrl = '';

  urls.forEach(item => {
    if (item.globalUrl) {
      globalUrl = item.globalUrl;
      globalLink = `Global <br/><a href="${item.globalUrl}" onclick="event.stopPropagation(); return true;" target="_blank" rel="noopener noreferrer" class="link-url">${item.globalUrl}</a><br/><br/>`;
    }
  });

  urls.forEach(item => {
    if (item.slideIndex && item.clickUrl) {
      if (item.clickUrl !== globalUrl) {
        allSlidesSameAsGlobal = false;
      }
      slideLinks += `Slide ${item.slideIndex} <br/> <a href="${item.clickUrl}" onclick="event.stopPropagation(); return true;" target="_blank" rel="noopener noreferrer" class="link-url">${item.clickUrl}</a><br/><br/>`;
    }
  });

  if (allSlidesSameAsGlobal && globalUrl) {
    return `
      <p class="header-link">Click Through URL <br/><br/>
        ${globalLink}
      </p>
    `;
  } else {
    return `
      <p class="header-link">Click Through URL <br/><br/>
        ${globalLink}
        ${slideLinks}
      </p>
    `;
  }
};
