<template>
  <!-- <laptop-only> -->
  <div class="laptop-only">
    <header class="flex flex-row items-center pt-3">
      <img
        :src="topbarLogo"
        alt="company logo"
        class="w-topbarLogoLaptopWidth ml-4"
      />
      <a
        v-for="page in topbarPages"
        :key="page.label"
        :class="{
          ['app-link ml-4']: true,
          ['text-white']: page.to !== $route.name,
          ['text-primary font-semibold']: page.to === $route.name,
        }"
        @click="() => onPageClick(page.to, page.reloadOnClick)"
        >{{ page.label }}</a
      >
      <div class="ml-auto pl-4 flex flex-row items-center">
        <app-txt class="text-white">{{
          currentUser?.email
        }}</app-txt>
        <div class="relative ml-2.5 mr-3 flex items-center">
          <dropdown-wrapper class="top-3.5 right-0">
            <template v-slot:trigger>
              <app-icon name="chevronDown" alt="show dropdown icon" />
            </template>
            <template v-slot:dropdown>
              <dropdown-item
                class="flex flex-row"
                @click="() => setEnabledNotificationsModalOpen(true)"
                v-slot="{ active }"
              >
                <app-icon
                  alt="settings"
                  name="settings"
                  :class="{ 'active-icon': active }"
                />

                <app-txt variant="small" class="ml-1.5">
                  {{ $t('topbar.notifications') }}
                </app-txt>
              </dropdown-item>
              <dropdown-item
                class="flex flex-row"
                @click="logout"
                v-slot="{ active }"
              >
                <app-icon
                  alt="logout"
                  name="logout"
                  :class="{ 'active-icon': active }"
                />

                <app-txt variant="small" class="ml-1.5">
                  {{ $t('topbar.logout') }}
                </app-txt>
              </dropdown-item>
            </template>
          </dropdown-wrapper>
        </div>
      </div>
    </header>
  </div>
  <!-- </laptop-only> -->
  <!-- <mobile-only> -->
  <div class="mobile-only">
    <header class="flex flex-row justify-between items-center pt-4 px-2">
      <img
        :src="topbarLogo"
        alt="company logo"
        class="w-topbarLogoMobileWidth"
      />
      <button
        class="ignore-click-outside"
        @click="$emit(LayoutEvents.TOGGLE_SIDEBAR)"
      >
        <app-icon name="menu" alt="menu icon" class="ignore-click-outside" />
      </button>
    </header>
  </div>
  <!-- </mobile-only> -->
  <EnabledNotificationsModal
    :userId="currentUser.id"
    :userNotifications="currentUser.enabledNotifications"
    :is-modal-open="isNotificationsSettingsModalOpen"
    @close-modal="() => setEnabledNotificationsModalOpen(false)"
  />
</template>

<script lang="ts">
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { defineComponent, ref } from 'vue';
import topbarLogo from './assets/topbarLogo.png';
import { useRoute, useRouter } from 'vue-router';
import { useGetAccessiblePages } from './useGetAccessiblePages';
import { useLogout } from '@/features/session/application/useLogout';
import { LayoutEvents } from './layoutEvents';
import EnabledNotificationsModal from '@/features/users/presentation/enabledNotificationsModal.vue';

export default defineComponent({
  name: 'topbar',
  components: { EnabledNotificationsModal },
  emits: [LayoutEvents.TOGGLE_SIDEBAR],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { logout } = useLogout();
    const { currentUser } = useGetCurrentUser();
    if (!currentUser.value.enabledNotifications) {
      logout();
    }
    const topbarPages = useGetAccessiblePages();
    const isNotificationsSettingsModalOpen = ref(false);
    const setEnabledNotificationsModalOpen = (value: boolean) => {
      isNotificationsSettingsModalOpen.value = value;
    };
    const onPageClick = (newPageName: string, reloadPageOnClick?: boolean) => {
      if (newPageName === route.name && reloadPageOnClick) {
        router.go(0);
      } else {
        router.push({ name: newPageName });
      }
    };

    return {
      currentUser,
      topbarLogo,
      topbarPages,
      router,
      logout,
      isNotificationsSettingsModalOpen,
      setEnabledNotificationsModalOpen,
      LayoutEvents,
      onPageClick,
    };
  },
});
</script>
