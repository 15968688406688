import { DateWrapper } from '@/core';
import { Ref, ref } from 'vue';
import {
  defaultClickUrl,
  generateDefaultSlideForValuesParams,
} from './defaults/defaultSlide';
import { deepCopyJson } from '@/core/helpers';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { Slide } from '@/features/campaigns/domain/valueObjects/slide';

export const useGetMotiveFormData = ({
  name = '',
  description = '',
  isDefaultMotive = false,
  startDate = DateWrapper.empty(),
  endDate = DateWrapper.empty(),
  templateVersion = '1.0.0',
  layout = Layout.DEVICE,
}: Partial<Motive>) => {
  const slidesArr = [
    Slide.createForValues(generateDefaultSlideForValuesParams()),
    Slide.createForValues(generateDefaultSlideForValuesParams()),
  ];
  const slides = ref(
    slidesArr.reduce((map, slide) => {
      map[slide.id] = Slide.create(deepCopyJson(slide.toJson()));
      return map;
    }, {} as Record<string, Slide>),
  ) as Ref<Record<string, Slide>>;

  const nameRef = ref(name) as Ref<string>;
  const descriptionRef = ref(description) as Ref<string>;
  const isDefaultMotiveRef = ref(isDefaultMotive);
  const startDateRef = ref(startDate) as Ref<DateWrapper>;
  const endDateRef = ref(endDate) as Ref<DateWrapper>;
  const layoutRef = ref(layout);
  const templateVersionRef = ref(templateVersion);
  const clickUrl = ref(defaultClickUrl) as Ref<string>;
  const slideOrder = ref(slidesArr.map(slide => slide.id));

  return {
    name: nameRef,
    description: descriptionRef,
    isDefaultMotive: isDefaultMotiveRef,
    startDate: startDateRef,
    endDate: endDateRef,
    layout: layoutRef,
    templateVersion: templateVersionRef,
    clickUrl,
    slides,
    slideOrder,
  };
};
