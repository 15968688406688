import { FormatOverview } from '../domain/format/formatOverview';

export class FormatOverviewDTO {
  static toDomain(json: { [key: string]: any }): FormatOverview {
    return FormatOverview.create({
      id: json.id,
      name: json.name,
      size: json.size,
      motiveId: json.motiveId,
      isActive: json.isActive,
      devices: json.devices,
      contracts: json.contracts,
      clickUrls: json.clickUrls,
    });
  }

  static toJson(formatOverview: FormatOverview): { [key: string]: any } {
    return {
      id: formatOverview.id,
      name: formatOverview.name,
      motiveId: formatOverview.motiveId,
      isActive: formatOverview.isActive,
      size: formatOverview.size,
      devices: formatOverview.devices,
      contracts: formatOverview.contracts,
      clickUrls: formatOverview.clickUrls,
    };
  }
}
