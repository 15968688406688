import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../../infrastructure/campaign_http_facade';
import { updateCampaignSucceeded } from '../../../store/store';
import { ActivateCampaignFailure } from '@/features/campaigns/domain';

export const useActivateCampaign = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { id: string }) => {
    const result = await facade.activateCampaign({
      id: params.id,
    });

    if (result.isRight()) {
      const campaign = result.extract();
      store.dispatch(updateCampaignSucceeded, { campaign });
      toast.success(t('campaigns.useActivateCampaign.success'));
    } else {
      const error= result.extract() as ActivateCampaignFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useActivateCampaign.error'));
      }
    }
  });
};
