<template>
  <app-layout>
    <div class="flex flex-col m-auto">
      <!-- Laptop only -->
      <div class="laptop-only">
        <div class="flex flex-row justify-between mx-2 items-center">
          <div class="flex flex-col">
            <Breadcrumbs
              :breadcrumbs="breadcrumbs.slice(0, breadcrumbs.length - 1)"
              :refetchAssets="refetchAssets"
              :refetchFolders="refetchFolders"
              @folder-selected="onFolderSelected"
            />
            <app-txt variant="h1" class="text-white">{{
              breadcrumbs[breadcrumbs.length - 1]?.getName() ||
                $t('media.title')
            }}</app-txt>
          </div>
          <div class="flex-row flex items-center">
            <app-input
              :placeholder="$t('media.search')"
              leftIcon="search"
              v-model="searchedValue"
            />
            <app-button
              leftIcon="uploadFile"
              @click="() => setIsUploadMediaModalOpen(true)"
              class="ml-3"
              >{{ $t('media.uploadFile') }}</app-button
            >
            <app-button
              variant="secondary"
              leftIcon="newFolder"
              @click="() => setIsCreateFolderModalOpen(true)"
              class="ml-3"
              >{{ $t('media.newFolder') }}</app-button
            >
          </div>
        </div>
      </div>

      <!-- Mobile only -->

      <div class="mobile-only">
        <div class="mx-2">
          <Breadcrumbs
            :breadcrumbs="breadcrumbs.slice(0, breadcrumbs.length - 1)"
            @folder-selected="onFolderSelected"
          />
          <app-txt variant="h3" class="text-white">{{
            $t('media.title')
          }}</app-txt>
          <div class="flex-row flex items-center mt-2">
            <app-input
              :placeholder="$t('media.search')"
              leftIcon="search"
              v-model="searchedValue"
            />
            <app-button
              leftIcon="uploadFile"
              @click="() => setIsUploadMediaModalOpen(true)"
              class="ml-3 w-46%"
              >{{ $t('media.uploadFile') }}</app-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white p-2 md:p-6 rounded-1 mt-4 mx-2 relative">
      <div v-if="currentMedia.length === 0">
        <MediaEmptyState :isNoSearchResult="isNoSearchResult" />
      </div>
      <div class="grid grid-cols-2 md:grid-cols-6 gap-3" v-else>
        <!-- <Mobile only> -->
        <div class="mobile-only self-center">
          <DateSorter
            :sortOrder="sortOrder"
            @change-sort-order="onChangeSortOrder"
          />
        </div>
        <div class="mobile-only self-center">
          <app-button
            variant="secondary"
            leftIcon="newFolder"
            @click="() => setIsCreateFolderModalOpen(true)"
            class="w-full"
            >{{ $t('media.newFolder') }}</app-button
          >
        </div>
        <!-- </Mobile only> -->

        <div
          v-for="media in currentMedia"
          :key="media.isFile() ? media.id : media.getKey()"
        >
          <div v-if="media.isUploadPlaceholder()">
            <MediaUploadPlaceholder
              @click="() => setIsUploadMediaModalOpen(true)"
            />
          </div>
          <div v-if="media.isFolder()">
            <MediaFolder
              :folder="media"
              :isDropAllowed="true"
              :draggable="true"
              @folder-selected="onFolderSelected"
              @delete-media="onDeleteMedia"
              @dragstart="e => onDragFolder(e, media)"
              @drop="e => onDropMediaToFolder(e, media)"
              @rename-media="onRenameMedia"
            />
          </div>
          <div v-if="media.isFile()">
            <MediaFile
              :asset="media"
              :draggable="true"
              @dragstart="e => onDragFile(e, media)"
              @delete-media="onDeleteMedia"
              @file-clicked="onFileClicked"
              @rename-media="onRenameMedia"
            />
          </div>
        </div>
        <div class="laptop-only">
          <DateSorter
            :sortOrder="sortOrder"
            @change-sort-order="onChangeSortOrder"
          />
        </div>
      </div>
    </div>
    <paginator
      :currentPage="page"
      :maxPage="maxPage"
      @change-page="changePage"
    />
    <CreateFolderModal
      :isModalOpen="isCreateFolderModalOpen"
      :currentFolderPath="currentFolderPath"
      @close-modal="() => setIsCreateFolderModalOpen(false)"
    />
    <ConfirmDeleteNestedFilesModal
      :folder="selectedMedia"
      :isModalOpen="isConfirmDeleteNestedFilesModalOpen"
      @close-modal="() => setIsConfirmDeleteNestedFilesModalOpen(false)"
      @confirm-delete-nested-files="media => onDeleteMedia(media, true)"
    />
    <DeleteMediaModal
      :media="selectedMedia"
      :isModalOpen="isDeleteMediaModalOpen"
      @close-modal="() => setIsDeleteMediaModalOpen(false)"
    />
    <UploadMediaModal
      :isModalOpen="isUploadMediaModalOpen"
      @close-modal="() => setIsUploadMediaModalOpen(false)"
      :currentFolderPath="currentFolderPath"
    />
    <RenameMediaModal
      v-if="!!mediaToRename"
      :media="mediaToRename"
      :isModalOpen="!!mediaToRename"
      :refetchAssets="refetchAssets"
      :refetchFolders="refetchFolders"
      @close-modal="resetMediaToRename"
    />
  </app-layout>
</template>

<script lang="ts">
import { computed, ref, Ref } from 'vue';
import MediaEmptyState from './mediaEmptyState.vue';
import paginator from '../../../components/table/paginator.vue';
import MediaFile from './mediaFile.vue';
import MediaFolder from './mediaFolder.vue';
import MediaUploadPlaceholder from './mediaUploadPlaceholder.vue';
import CreateFolderModal from './createFolderModal.vue';
import ConfirmDeleteNestedFilesModal from './confirmDeleteNestedFilesModal.vue';
import DeleteMediaModal from './deleteMediaModal.vue';
import UploadMediaModal from './uploadMediaModal/uploadMediaModal.vue';
import Breadcrumbs from './breadcrumbs.vue';
import DateSorter from './dateSorter.vue';
import { useGetMediaListData } from '../application/useGetMediaListData';
import { useFilterByMediaName } from '../application/useFilterByMediaName';
import { useSortByMediaDate } from '../application/useSortByMediaDate';
import { usePaginateMedia } from '../application/usePaginateMedia';
import { useGetMedia } from '../application/useGetMedia';
import { useHandleMediaDeletion } from '../application/useHandleMediaDeletion';
import { Asset } from '../domain/asset';
import { useFetchAssets } from '../application/useFetchAssets';
import { useFetchFolders } from '../application/useFetchFolders';
import { AssetDTO } from '../infrastructure/asset_dto';
import { FolderDTO } from '../infrastructure/folder_dto';
import { useOnDropMediaToFolder } from '@/features/media/application/dragAndDrop/useOnDropMediaToFolder';
import { Media } from '../domain/media';
import RenameMediaModal from './renameMediaModal.vue';

export default {
  components: {
    paginator,
    MediaEmptyState,
    MediaFile,
    MediaFolder,
    CreateFolderModal,
    Breadcrumbs,
    DateSorter,
    ConfirmDeleteNestedFilesModal,
    DeleteMediaModal,
    UploadMediaModal,
    RenameMediaModal,
    MediaUploadPlaceholder,
  },
  setup() {
    const { refetch: refetchAssets } = useFetchAssets({});
    const { refetch: refetchFolders } = useFetchFolders({});
    const { media: allMedia } = useGetMedia();
    const { searchedValue, filteredMedia } = useFilterByMediaName(allMedia);

    const {
      onFolderSelected,
      breadcrumbs,
      currentFolder,
      currentMedia,
    } = useGetMediaListData(filteredMedia, searchedValue as Ref<string>);
    const { sortedMedia, onChangeSortOrder, sortOrder } = useSortByMediaDate(
      currentMedia,
    );
    const { paginatedMedia, page, maxPage, changePage } = usePaginateMedia(
      sortedMedia,
    );

    const isUploadMediaModalOpen = ref(false);
    const setIsUploadMediaModalOpen = (value: boolean) => {
      isUploadMediaModalOpen.value = value;
    };

    const {
      isConfirmDeleteNestedFilesModalOpen,
      setIsConfirmDeleteNestedFilesModalOpen,
      isDeleteMediaModalOpen,
      setIsDeleteMediaModalOpen,
      onDeleteMedia,
      selectedMedia,
    } = useHandleMediaDeletion(allMedia);

    const currentFolderPath = computed(() => currentFolder.value.getKey());
    const isCreateFolderModalOpen = ref(false);
    const setIsCreateFolderModalOpen = (value: boolean) => {
      isCreateFolderModalOpen.value = value;
    };

    const onFileClicked = (media: Asset) => {
      window.open(media.url, '_blank');
    };

    const isNoSearchResult = computed(
      () => !filteredMedia.value.length && !!searchedValue.value,
    );

    const onDragFile = (ev: any, asset: any) => {
      ev.dataTransfer.setData('asset', JSON.stringify(AssetDTO.toJson(asset)));
    };

    const onDragFolder = (ev: any, folder: any) => {
      ev.dataTransfer.setData(
        'folder',
        JSON.stringify(FolderDTO.toJson(folder)),
      );
    };

    const { onDropMediaToFolder } = useOnDropMediaToFolder({
      refetchFolders,
      refetchAssets,
    });

    const mediaToRename = ref<Media>();
    const resetMediaToRename = () => {
      mediaToRename.value = undefined;
    };
    const onRenameMedia = (media: Media) => {
      mediaToRename.value = media;
    };

    return {
      searchedValue,
      onFolderSelected,
      currentMedia: paginatedMedia,
      breadcrumbs,
      page,
      maxPage,
      changePage,
      isCreateFolderModalOpen,
      setIsCreateFolderModalOpen,
      currentFolderPath,
      onChangeSortOrder,
      sortOrder,
      isConfirmDeleteNestedFilesModalOpen,
      setIsConfirmDeleteNestedFilesModalOpen,
      onDeleteMedia,
      isDeleteMediaModalOpen,
      setIsDeleteMediaModalOpen,
      selectedMedia,
      isUploadMediaModalOpen,
      setIsUploadMediaModalOpen,
      onFileClicked,
      isNoSearchResult,
      onDragFile,
      onDropMediaToFolder,
      onDragFolder,
      refetchFolders,
      refetchAssets,
      onRenameMedia,
      mediaToRename,
      resetMediaToRename,
    };
  },
};
</script>
