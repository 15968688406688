
import { defineComponent } from 'vue';
import { TableEvents } from '../tableEvents';

export default defineComponent({
  emits: [TableEvents.SELECT_CHECKBOX],
  props: {
    checked: { type: Boolean, required: true },
  },
  setup(props, context) {
    const onChange = (event: Event) => {
      const value = (event.target as HTMLInputElement)?.checked;
      context.emit(TableEvents.SELECT_CHECKBOX, value);
    };
    return { onChange };
  },
});
