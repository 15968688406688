
import { defineComponent, provide, ref } from 'vue';

import { ModalEvents } from '@/components/modal/modalEvents';
import GeneralSettings from './generalSettings.vue';
import Variations from './variations.vue';
import CreateMotiveModalWrapper from './createMotiveModalWrapper.vue';
import CreateMotiveProgressBar from './createMotiveProgressBar.vue';
import { useFetchContracts } from '@/features/campaigns/application/contract/useFetchContracts';
import { useFetchDevices } from '@/features/campaigns/application/device/useFetchDevices';
import { useFetchFolders } from '@/features/media/application/useFetchFolders';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { useCreateMotiveForm } from '@/features/campaigns/application/motive/forms/useCreateMotiveForm';
import { CreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';
import { useCampaignHasDefaultMotive } from '@/features/campaigns/application/motive/forms/useCampaignHasDefaultMotive';

export default defineComponent({
  components: {
    GeneralSettings,
    Variations,
    CreateMotiveModalWrapper,
    CreateMotiveProgressBar
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    campaignId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { campaignId: string }, context) {
    useFetchContracts({});
    useFetchDevices({});
    useFetchFolders({});
    useFetchAssets({});

    const hasSubmitted = ref(false);

    const formData = useCreateMotiveForm({ campaignId: props.campaignId });
    provide(CreateMotiveFormKey, formData);

    const campaignHasDefaultMotive = useCampaignHasDefaultMotive(
      props.campaignId,
    );

    const {
      isSubmitDisabled,
      step,
      isLoading,
      onContinue,
      onBack,
      clearForm,
      createMotive,
    } = formData;

    const closeModal = () => {
      clearForm();
      hasSubmitted.value = false;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSubmit = async () => {
      await createMotive(props.campaignId);
      closeModal();
    };

    const setSubmitted = (value: boolean) => {
      hasSubmitted.value = value;
    }

    return {
      step,
      isLoading,
      closeModal,
      onSubmit,
      isSubmitDisabled,
      campaignHasDefaultMotive,
      hasSubmitted,
      onContinue,
      onBack,
      setSubmitted
    };
  },
});
