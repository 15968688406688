import { PreviewAttributes } from './iPreviewAttributes';
import { PreviewSlideContent } from './previewSlideContent';

export type PreviewSlideCreationParams = {
  clickUrl: string;
  duration: number;
  attributes?: PreviewAttributes;
  content: PreviewSlideContent[];
};

export class PreviewSlide {
  private constructor(
    public type: 'slide',
    public clickUrl: string,
    public duration: number,
    public attributes: PreviewAttributes | undefined,
    public content: PreviewSlideContent[],
  ) {}

  static create(params: PreviewSlideCreationParams) {
    const slideType = 'slide';
    return new PreviewSlide(
      slideType,
      params.clickUrl,
      params.duration,
      params.attributes,
      params.content,
    );
  }
}
