import { useStore } from '@/store';
import { computed } from 'vue';

export const useGetBreadcrumbs = (motiveId: string) => {
  const store = useStore();
  const motive = computed(() =>
    store.getters.motiveWithCampaignNameById(motiveId),
  );

  return computed(() => ({
    motiveName: motive.value?.motiveName,
    campaignName: motive.value?.campaignName,
    campaignId: motive.value?.campaignId,
  }));
};
