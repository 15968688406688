<template>
  <FullScreenModal
    :isOpen="isModalOpen"
    @close-modal="closeModal"
    :title="t('modalTitle')"
    :cancelLabel="t('cancel')"
    :confirmLabel="t('done')"
    :onConfirm="onSelectMedia"
  >
    <div class="flex flex-row justify-between items-center py-1">
      <Breadcrumbs
        :breadcrumbs="breadcrumbs"
        @folder-selected="onFolderSelected"
        class="mt-2"
      />
      <app-input
        :placeholder="$t('media.search')"
        leftIcon="search"
        v-model="searchedValue"
      />
    </div>

    <div class="flex flex-col justify-between h-full">
      <div class="grid grid-cols-2 md:grid-cols-6 gap-3">
        <div
          v-for="media in paginatedMedia"
          :key="media.isFile() ? media.id : media.getKey()"
        >
          <div v-if="media.isUploadPlaceholder()">
            <MediaUploadPlaceholder
              @click="() => setIsUploadMediaModalOpen(true)"
            />
          </div>
          <div v-if="media.isFolder()">
            <MediaFolder
              :folder="media"
              @folder-selected="onFolderSelected"
              :allowDeletion="false"
            />
          </div>
          <div v-if="media.isFile()">
            <MediaFile
              :asset="media"
              @file-clicked="onFileClicked"
              @file-double-clicked="onFileDoubleClicked"
              :isSelected="media.isFile() && selectedFile?.id === media.id"
              :allowDeletion="false"
            />
          </div>
        </div>
      </div>

      <paginator
        :currentPage="page"
        :maxPage="maxPage"
        @change-page="changePage"
      />
    </div>
    <UploadMediaModal
      :isModalOpen="isUploadMediaModalOpen"
      @close-modal="() => setIsUploadMediaModalOpen(false)"
      :currentFolderPath="currentFolderPath"
    />
  </FullScreenModal>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import FullScreenModal from './fullScreenModal.vue';
import { useGetMedia } from '../../application/useGetMedia';
import { useGetMediaListData } from '../../application/useGetMediaListData';
import { usePaginateMedia } from '../../application/usePaginateMedia';
import { useSortByMediaDate } from '../../application/useSortByMediaDate';
import MediaFolder from '../mediaFolder.vue';
import MediaUploadPlaceholder from '../mediaUploadPlaceholder.vue';
import MediaFile from '../mediaFile.vue';
import paginator from '@/components/table/paginator.vue';
import Breadcrumbs from '../breadcrumbs.vue';
import { computed, defineComponent, onUpdated, Ref, ref } from 'vue';
import { MediaPickerEvents } from './events';
import { useFetchFolders } from '../../application/useFetchFolders';
import { useFetchAssets } from '../../application/useFetchAssets';
import { Asset } from '../../domain/asset';
import { useFilterByMediaName } from '../../application/useFilterByMediaName';
import { useGetRootFolder } from '../../application/useGetRootFolder';
import UploadMediaModal from '../uploadMediaModal/uploadMediaModal.vue';

export default defineComponent({
  components: {
    FullScreenModal,
    MediaFolder,
    MediaFile,
    MediaUploadPlaceholder,
    paginator,
    Breadcrumbs,
    UploadMediaModal,
  },
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL, MediaPickerEvents.MEDIA_SELECTED],
  setup(props, context) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    const { rootFolder } = useGetRootFolder();
    const selectedFile = ref<Asset>();
    const isUploadMediaModalOpen = ref(false);

    onUpdated(() => {
      if (props.isModalOpen) {
        useFetchFolders({});
        useFetchAssets({});
      }
    });

    const { media: allMedia } = useGetMedia();
    const { searchedValue, filteredMedia } = useFilterByMediaName(allMedia);

    const {
      onFolderSelected,
      breadcrumbs,
      currentFolder,
      currentMedia,
    } = useGetMediaListData(
      filteredMedia,
      searchedValue as Ref<string>,
    );
    const { sortedMedia } = useSortByMediaDate(currentMedia);
    const { paginatedMedia, page, maxPage, changePage } = usePaginateMedia(
      sortedMedia,
    );
    const currentFolderPath = computed(() => currentFolder.value.getKey());

    const setIsUploadMediaModalOpen = (value: boolean) => {
      isUploadMediaModalOpen.value = value;
    };

    const closeModal = () => {
      currentFolder.value = rootFolder.value;
      selectedFile.value = undefined;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSelectMedia = () => {
      context.emit(MediaPickerEvents.MEDIA_SELECTED, selectedFile.value);
      closeModal();
    };

    const onFileClicked = (asset: Asset) => {
      selectedFile.value = asset;
    };

    const onFileDoubleClicked = (asset: Asset) => {
      selectedFile.value = asset;
      onSelectMedia();
    };

    return {
      t,
      onSelectMedia,
      closeModal,
      paginatedMedia,
      onFolderSelected,
      page,
      maxPage,
      changePage,
      breadcrumbs,
      onFileClicked,
      onFileDoubleClicked,
      selectedFile,
      searchedValue,
      currentFolderPath,
      isUploadMediaModalOpen,
      setIsUploadMediaModalOpen,
    };
  },
});
</script>
