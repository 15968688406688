import { CampaignRow, Tabs } from '@/features/campaigns/domain';
import { Campaign } from '@/features/campaigns/domain/campaign';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { computed, ComputedRef, Ref } from 'vue';

const filtersByTabId: Record<string, any> = {
  [Tabs.archive]: (campaign: Campaign) =>
    !campaign.active && !campaign?.deletedAt?.isValid(),
  [Tabs.bin]: (campaign: Campaign) => campaign.deletedAt?.isValid(),
  [Tabs.allCampaigns]: (campaign: Campaign) =>
    !campaign?.deletedAt?.isValid() && campaign.active,
};

export const useGetCampaignTableProps = (
  selectedTabId: Ref<string>,
): {
  campaigns: ComputedRef<CampaignRow[]>;
  tabs: ComputedRef<{ id: string; caption: string; count: number }[]>;
} => {
  const store = useStore();

  const campaigns = computed(() => store.getters.campaigns);
  const campaignsRows = computed(() =>
    campaigns.value
      .filter(filtersByTabId[selectedTabId.value])
      .map((campaign: Campaign) => campaign.toCampaignRow()),
  );

  const tabs = computed(() => {
    let archived = 0;
    let bin = 0;
    let allCampaigns = 0;
    campaigns.value.forEach((campaign: Campaign) => {
      if (!campaign.active && !campaign?.deletedAt?.isValid()) archived += 1;
      if (campaign.deletedAt?.isValid()) bin += 1;
      if (!campaign?.deletedAt?.isValid() && campaign.active) allCampaigns += 1;
    });
    return [
      {
        id: Tabs.allCampaigns,
        caption: t('campaigns.tabs.allCampaigns'),
        count: allCampaigns,
      },
      {
        id: Tabs.archive,
        caption: t('campaigns.tabs.archive'),
        count: archived,
      },
      {
        id: Tabs.bin,
        caption: t('campaigns.tabs.bin'),
        count: bin,
      },
    ];
  });

  return { campaigns: campaignsRows, tabs };
};
