import { computed, ComputedRef } from 'vue';
import { t } from '@/services/i18n';
import { useRouter } from 'vue-router';
import { useGetMotiveById } from '../actions/useGetMotiveById';
import { useDeactivateMotive } from '../actions/useDeactivateMotive';
import { useActivateMotive } from '../actions/useActivateMotive';
import { campaignsRouteNames } from '@/features/campaigns/presentation/routes/campaignsRouteNames';
import { CampaignEvents } from '@/features/campaigns/presentation/events';
import { MotiveStatus } from '@/features/campaigns/domain';

export const useGetOptionsForMotive = (
  motiveId: string,
  emit: (event: string, payload: string) => void,
): ComputedRef<{
  icon: string;
  iconAlt: string;
  label: string;
  onClick: () => void;
  isVisible: boolean;
}[]> => {
  const motive = useGetMotiveById(motiveId);
  const router = useRouter();

  const { call: callDeactivate } = useDeactivateMotive();
  const onDeactivateMotive = () => {
    callDeactivate(motiveId);
  };

  const { call: callActivate } = useActivateMotive();
  const onActivateMotive = () => {
    callActivate(motiveId);
  };

  const onEditMotive = () => {
    router.push({
      name: campaignsRouteNames.motiveEditor,
      query: { id: motiveId },
    });
  };

  const onDeleteMotive = () => {
    emit(CampaignEvents.DELETE_MOTIVE, motiveId);
  };

  const onDuplicateMotive = () => {
    emit(CampaignEvents.DUPLICATE_MOTIVE, motiveId);
  };

  return computed(() =>
    [
      {
        icon: 'copy',
        iconAlt: 'Duplicate motive',
        label: t('campaigns.dropdown.duplicate'),
        onClick: onDuplicateMotive,
        isVisible: true,
      },
      {
        icon: 'editPen',
        iconAlt: 'Edit motive',
        label: t('campaigns.dropdown.editMotive'),
        onClick: onEditMotive,
        isVisible: true,
      },
      {
        icon: 'deactivate',
        iconAlt: 'Deactivate motive',
        label: t('campaigns.dropdown.deactivate'),
        onClick: onDeactivateMotive,
        isVisible: motive.value?.status === MotiveStatus.ACTIVE,
      },
      {
        icon: 'activate',
        iconAlt: 'Activate motive',
        label: t('campaigns.dropdown.activate'),
        onClick: onActivateMotive,
        isVisible: motive.value?.status === MotiveStatus.INACTIVE || motive.value?.status === MotiveStatus.DEACTIVATED_BY_FEED,
      },
      {
        icon: 'delete',
        iconAlt: 'Delete motive',
        label: t('campaigns.dropdown.delete'),
        onClick: onDeleteMotive,
        isVisible: !motive.value?.motiveGroupId,
      },
    ].filter(option => option.isVisible),
  );
};
