<template>
  <app-layout>
    <div class="flex flex-col md:mx-2">
      <!-- Laptop only -->
      <div class="laptop-only">
        <div class="flex flex-row justify-between mb-3 items-center">
          <app-txt variant="h1" class="text-white">{{
            $t('users.title')
          }}</app-txt>
          <div class="flex-row flex items-center">
            <app-input
              :placeholder="$t('users.search')"
              leftIcon="search"
              v-model="searchedValue"
            />
            <app-button
              leftIcon="plusCircle"
              @click="onCreateNewUser"
              class="ml-3"
              >{{ $t('users.newUser') }}</app-button
            >
          </div>
        </div>
      </div>

      <!-- Mobile only -->

      <div class="mobile-only">
        <div class="mx-2 mb-3">
          <app-txt variant="h3" class="text-white">{{
            $t('users.title')
          }}</app-txt>
          <div class="flex-row flex items-center mt-2">
            <app-input
              :placeholder="$t('users.search')"
              leftIcon="search"
              v-model="searchedValue"
              class="w-mobileUserListSearch"
            />
            <app-button
              leftIcon="plusCircle"
              @click="onCreateNewUser"
              class="ml-2"
              >{{ $t('users.newUser') }}</app-button
            >
          </div>
        </div>
      </div>

      <app-table
        :headers="headers"
        :rows="users"
        :searchedValue="searchedValue"
        :initialSort="'creationDate'"
        class="mb-4"
      >
        <template v-slot:[TemplateNames.TEXT]="{ value }"
          ><app-txt>{{ value }}</app-txt></template
        >
        <template v-slot:[TemplateNames.STATUS]="{ value }"
          ><StatusCell :value="value"
        /></template>

        <template v-slot:[TemplateNames.DATE]="{ value }"
          ><DateCell :value="value"
        /></template>

        <template
          v-slot:[TemplateNames.TEXT_WITH_DESCRIPTION]="{ value, description }"
        >
          <TextWithDescriptionCell :value="value" :description="description" />
        </template>

        <template v-slot:[TemplateNames.EMPTY_STATE]>
          <UserEmptyState />
        </template>

        <template v-slot:[TemplateNames.ICON]="{ value }"
          ><IconCell :value="$t(`table.${value}`)" icon="role" />
        </template>

        <template v-slot:[TemplateNames.DROPDOWN]="{ rowId }">
          <UserListDropdownCell
            :userId="rowId"
            @change-role="onChangeUserRole"
          />
        </template>

        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
          ><SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
        /></template>
      </app-table>
    </div>
    <InviteUsersModal
      :isModalOpen="isInviteModalOpen"
      @close-modal="() => setIsInviteModalOpen(false)"
    />
    <ChangeUserRoleModal
      :isModalOpen="isChangeUserRoleModalOpen"
      :userId="selectedUserId"
      @close-modal="() => setIsChangeUserRoleModalOpen(false)"
    />
  </app-layout>
</template>

<script lang="ts">
import { TemplateNames } from '../../../components/table';
import { ref } from 'vue';
import {
  DateCell,
  StatusCell,
  TextWithDescriptionCell,
  IconCell,
  SortableHeaderCell,
} from '../../../components/table/cellTemplates';
import UserListDropdownCell from './userListDropdownCell.vue';
import { userListTableHeaders } from '../application/userListTableHeaders';
import { useFetchUsers } from '../application/useFetchUsers';
import { useGetUserRows } from '../application/useGetUserRows';
import UserEmptyState from './userEmptyState.vue';
import InviteUsersModal from './inviteUsersModal.vue';
import ChangeUserRoleModal from './changeUserRoleModal.vue';

export default {
  components: {
    StatusCell,
    DateCell,
    TextWithDescriptionCell,
    IconCell,
    UserListDropdownCell,
    UserEmptyState,
    InviteUsersModal,
    ChangeUserRoleModal,
    SortableHeaderCell,
  },
  setup() {
    useFetchUsers({});
    const users = useGetUserRows();
    const searchedValue = ref('');

    const isInviteModalOpen = ref(false);
    const setIsInviteModalOpen = (value: boolean) => {
      isInviteModalOpen.value = value;
    };

    const selectedUserId = ref('');

    const isChangeUserRoleModalOpen = ref(false);
    const setIsChangeUserRoleModalOpen = (value: boolean) => {
      isChangeUserRoleModalOpen.value = value;
    };
    const onChangeUserRole = (value: string) => {
      selectedUserId.value = value;
      setIsChangeUserRoleModalOpen(true);
    };

    const onCreateNewUser = () => {
      setIsInviteModalOpen(true);
    };

    return {
      TemplateNames,
      headers: userListTableHeaders,
      users,
      onCreateNewUser,
      isInviteModalOpen,
      setIsInviteModalOpen,
      isChangeUserRoleModalOpen,
      setIsChangeUserRoleModalOpen,
      searchedValue,
      selectedUserId,
      onChangeUserRole,
    };
  },
};
</script>
