export const validateEnum = (value: any, variantEnum: any) =>
  Object.values(variantEnum).includes(value);

export const updateEntity = (
  updatedEntity: Record<string, any>,
  entities: Record<string, any>[],
  attribute = 'id',
): Record<string, any>[] => {
  return entities.map(entity => {
    if (entity[attribute] === updatedEntity[attribute]) return updatedEntity;
    return entity;
  });
};

export const mergeWithDefaultValues = (
  object: Record<string, any>,
  defaultValues: Record<string, any>,
): Record<string, any> => {
  return Object.fromEntries(
    Object.keys(defaultValues).map(key => [
      key,
      object[key] ?? defaultValues[key],
    ]),
  );
};

export const deepCopyJson = (
  object: Record<string, any> | Record<string, any>[] = {},
) => JSON.parse(JSON.stringify(object));

export const unionBy = (
  updatedEntity: Record<string, any>,
  entities: Record<string, any>[],
  attribute = 'id',
) => {
  let isEntityPresent = false;
  const updated = entities.map(entity => {
    if (entity[attribute] === updatedEntity[attribute]) {
      isEntityPresent = true;
      return updatedEntity;
    }
    return entity;
  });
  if (isEntityPresent) return updated;
  return [updatedEntity, ...updated];
};
