import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { SyncHttpFacade } from '../infrastructure/sync_http_facade';
import { updateSyncEmailGroupSucceeded } from '../store/store';
import { UpdateSyncEmailGroupFailure } from '../domain';

export const useUpdateSyncEmailGroup = (
  facade: SyncHttpFacade = new SyncHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { emails: string[] }) => {
    const members = params.emails;
    const result = await facade.updateSyncEmailGroup(members);

    if (result.isRight()) {
      const updatedMembers = result.extract();
      store.dispatch(updateSyncEmailGroupSucceeded, {
        syncEmailGroup: updatedMembers,
      });
      toast.success(t('sync.useUpdateSyncEmailGroup.success'));
    } else {
      const error= result.extract() as UpdateSyncEmailGroupFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('sync.useUpdateSyncEmailGroup.error'));
      }
    }
  });
};
