export enum Layout {
  DEVICE = 'device',
  CONTRACT = 'contract',
}

export enum VerticalAlign {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum HorizontalAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum ContractSlideType {
  SUBLINE = 'subline',
  BULLETS = 'bullets',
}

export enum MotiveGroupType {
  ONE_CONTRACT = 'oneContract',
  ONE_DEVICE = 'oneDevice',
  ALL_POSSIBILITIES = 'allPossibilities',
}

export type InterfererAnimation = 'flip' | 'fade';
