import { useStore } from '@/store';
import { computed } from 'vue';

export const useGetMotiveById = (motiveId: string) => {
  const store = useStore();
  return computed(
    () =>
      store.getters.motiveById(motiveId) ||
      store.getters.motives.find((motive: any) => motive.id === motiveId),
  );
};
