<template>
  <section class="p-4">
    <div
      v-for="(slideId, index) in slideOrder"
      :key="index"
      :class="{
        relative: true,
        'border border-gray-200 rounded-1 bg-white relative': true,
        'mt-1': index !== 0,
      }"
    >
      <div
        v-if="index !== 0 && hasSlidesControls"
        class="absolute h-addSlideContainer -top-1 flex flex-row justify-center items-center w-full opacity-0 hover:opacity-100"
      >
        <div
          class="flex flex-row items-center justify-center h-addSlideLine w-full bg-primary cursor-pointer"
          @click="insertSlide(index)"
        >
          <div
            class="flex flex-row items-center justify-center w-insertSlideCircle h-insertSlideCircle bg-primary rounded-full"
          >
            <app-icon name="plus" alt="add slide" />
          </div>
        </div>
      </div>
      <div
        :class="{
          'flex flex-row justify-between items-center cursor-pointer p-2': true,
          'border-b border-gray-200': index === openSlideIndex,
        }"
        @click="() => !slides[slideId].removed && toggleSlide(index)"
      >
        <div
          :class="{
            'flex flex-row items-center': true,
            'opacity-50 cursor-not-allowed': slides[slideId].removed,
          }"
        >
          <app-icon
            v-if="index === openSlideIndex"
            name="chevronUp"
            :alt="`close slide ${index + 1} settings`"
            class="w-iconXs"
          />
          <app-icon
            v-if="!(index === openSlideIndex)"
            name="chevronDown"
            :alt="`open slide ${index + 1} settings`"
            class="w-iconXs"
          />
          <app-txt variant="small" class="text-black font-semibold ml-2">{{
            t('slideTitle', { slideNb: index + 1 })
          }}</app-txt>
        </div>
        <div v-if="!slides[slideId].removed">
          <div class="flex flex-row items-center">
            <button
              class="focus:outline-none"
              @click.stop="() => moveSlideDown(index)"
            >
              <app-icon alt="move slide down" name="arrowDown" />
            </button>
            <button
              class="focus:outline-none ml-2"
              @click.stop="() => moveSlideUp(index)"
            >
              <app-icon alt="move slide up" name="arrowUp" />
            </button>
            <button
              v-if="!isGlobalEdit"
              class="focus:outline-none ml-2"
              @click.stop="() => duplicateSlide(slideId)"
            >
              <app-icon alt="duplicate slide" name="copy" />
            </button>
            <button
              class="focus:outline-none ml-2"
              @click.stop="
                () =>
                  removeSlide(
                    index,
                    (slides[slideId] && slides[slideId].isDefault) || false,
                  )
              "
            >
              <app-icon alt="delete slide" name="delete" class="w-icon" />
            </button>
          </div>
        </div>
        <div v-else style="height: 24px;">
          <button
            class="focus:outline-none cursor-pointer"
            @click.stop="() => recoverSlide(slideId)"
          >
            <app-icon alt="get back deleted slide" name="replayGrayIcon" />
          </button>
        </div>
      </div>
      <div v-if="index === openSlideIndex">
        <div
          v-for="(slideSection, sectionIndex) in getFieldsForSlideType(
            slides[slideId],
          ).value"
          :key="sectionIndex"
        >
          <div
            v-if="slideSection.elementType !== SlideElementSimpleType.Other"
            class="pl-4 pr-2 py-2 border-t border-gray-200 bg-gray-100 cursor-pointer"
            @click="
              () => !slideSection.removed && toggleSlideSection(sectionIndex)
            "
          >
            <div
              class="
              flex flex-row justify-between items-center
              "
            >
              <div
                :class="{
                  'flex flex-row flex-1 items-center': true,
                  'opacity-50 cursor-not-allowed': slideSection.removed,
                }"
              >
                <app-icon
                  v-if="openSlideSectionIndices.includes(sectionIndex)"
                  name="chevronDown"
                  :alt="
                    `close slide ${index + 1} ${slideSection.label} settings`
                  "
                  class="w-iconXs"
                />
                <app-icon
                  v-if="!openSlideSectionIndices.includes(sectionIndex)"
                  name="chevronRightBold"
                  :alt="
                    `open slide ${index + 1} ${slideSection.label} settings`
                  "
                  class="w-iconXs"
                />
                <app-txt
                  variant="small"
                  class="text-black font-semibold ml-2 capitalize"
                  >{{ slideSection.label }}</app-txt
                >
              </div>
              <div v-if="!slideSection.removed">
                <div class="flex flex-row items-center">
                  <button
                    class="focus:outline-none ml-2"
                    @click.stop="
                      () => moveElementUp(slideId, slideSection.zIndex)
                    "
                  >
                    <app-icon
                      name="arrowUp"
                      :alt="
                        `move element ${
                          slideSection.label
                        } higher in slide ${index + 1}`
                      "
                      class="w-icon"
                    />
                  </button>
                  <button
                    class="focus:outline-none ml-2"
                    @click.stop="
                      () => moveElementDown(slideId, slideSection.zIndex)
                    "
                  >
                    <app-icon
                      name="arrowDown"
                      :alt="
                        `move element ${
                          slideSection.label
                        } lower in slide ${index + 1}`
                      "
                      class="w-icon"
                    />
                  </button>
                  <button
                    class="focus:outline-none ml-2"
                    @click.stop="
                      () =>
                        removeSection(
                          slideSection.elementType,
                          slideSection.label,
                        )
                    "
                  >
                    <app-icon
                      name="delete"
                      :alt="
                        `delete element ${
                          slideSection.label
                        } from slide ${index + 1}`
                      "
                      class="w-icon"
                    />
                  </button>
                </div>
              </div>
              <div v-else style="height: 24px;">
                <button
                  class="focus:outline-none cursor-pointer"
                  @click.stop="
                    () =>
                      recoverElement(
                        slideSection.elementType,
                        slideSection.label,
                      )
                  "
                >
                  <app-icon
                    alt="get back deleted slide"
                    name="replayGrayIcon"
                  />
                </button>
              </div>
            </div>
          </div>
          <div v-if="slideSection.elementType === SlideElementSimpleType.Other">
            <GeneralSettings
              :fields="slideSection.fields"
              styleClasses="grid gap-x-2 grid-cols-2"
              :slideIndex="index"
              :hasSubmitted="hasSubmitted"
            />
          </div>
          <div v-if="openSlideSectionIndices.includes(sectionIndex)">
            <div
              v-if="
                slideSection.elementType === SlideElementSimpleType.Text &&
                  !slideSection.removed
              "
            >
              <TextSettings
                :fields="slideSection.fields"
                styleClasses="grid gap-x-2 grid-cols-2"
                :slideIndex="index"
                :textIndex="slideSection.index ?? 0"
                :hasSubmitted="hasSubmitted"
                :text-label="slideSection.label"
              />
            </div>
            <div
              v-if="
                slideSection.elementType === SlideElementSimpleType.Price &&
                  !slideSection.removed
              "
            >
              <PriceSettings
                :fields="slideSection.fields"
                styleClasses="grid gap-x-2 grid-cols-2"
                :slideIndex="index"
                :priceIndex="slideSection.index ?? 0"
                :hasSubmitted="hasSubmitted"
                :price-label="slideSection.label"
              />
            </div>
            <div
              v-if="
                slideSection.elementType === SlideElementSimpleType.Image &&
                  !slideSection.removed
              "
            >
              <ImageSettings
                :fields="slideSection.fields"
                styleClasses="grid gap-x-2 grid-cols-2"
                :slideIndex="index"
                :image-label="slideSection.label"
                :hasSubmitted="hasSubmitted"
                :defaultVisual="defaultVisual"
              />
            </div>
            <div
              v-if="
                slideSection.elementType ===
                  SlideElementSimpleType.Interferer && !slideSection.removed
              "
            >
              <InterfererSettings
                :fields="slideSection.fields"
                styleClasses="grid gap-x-2 grid-cols-2"
                :slideIndex="index"
                :interfererIndex="slideSection.index ?? 0"
                :interfererLabel="slideSection.label"
                :hasSubmitted="hasSubmitted"
              />
            </div>
          </div>
        </div>
        <div
          :class="
            `w-full bg-primary-750 flex p-2 rounded-b-1 border ${
              addMoreElementsActive
                ? 'bg-blue-0 border-primary'
                : 'bg-primary-750 border-primary-750'
            }`
          "
        >
          <button
            class="
                  focus:outline-none flex w-full justify-center gap-1 items-center
                "
            @click="showMoreElements"
          >
            <app-icon
              v-if="addMoreElementsActive"
              name="addBlue"
              alt="add more elements"
            ></app-icon>
            <app-icon v-else alt="add more elements" name="addWhite" />
            <p :class="addMoreElementsActive ? 'text-primary' : 'text-white'">
              {{ t('addElements') }}
            </p>
          </button>
        </div>
        <AddMoreElements
          v-if="addMoreElementsActive"
          :showMoreElements="showMoreElements"
          :slideId="slideId"
          :toggle-slide-section="toggleSlideSection"
          :last-index="lastIndex"
        />
      </div>
    </div>
    <ConfirmResetModal
      :isModalOpen="resetModalOpen"
      @close-modal="resetModalOpen = false"
      @confirm="onResetCallback?.()"
    />
    <div class="w-full bg-primary-750 flex p-2 my-2 rounded-1">
      <button
        class="focus:outline-none flex w-full justify-center gap-1 text-white items-center"
        @click="addSlide"
      >
        <app-icon alt="add another slide" name="addWhite" />
        <p>{{ t('addSlide') }}</p>
      </button>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../../events';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes } from '@/components/formGenerator';
import { CreateSlideForValuesParams } from '@/features/campaigns/domain/valueObjects/slide';
import ConfirmResetModal from '../confirmResetModal.vue';
import TextSettings from './elementsSettings/textSettings.vue';
import ImageSettings from './elementsSettings/imageSettings.vue';
import GeneralSettings from './elementsSettings/generalSettings.vue';
import PriceSettings from './elementsSettings/priceSettings.vue';
import AddMoreElements from './elementsSettings/addMoreElements.vue';
import InterfererSettings from './elementsSettings/interfererSettings.vue';
import { SlideElementSimpleType } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import { getSlideSettingsSections } from '@/features/campaigns/application/motive/forms/getSlideSettingsFields';

export default defineComponent({
  props: {
    isCompleted: { type: Boolean, required: false },
    hasSubmitted: { type: Boolean, default: false },
    hasSlidesControls: { type: Boolean, default: false },
    isGlobalEdit: { type: Boolean, default: false },
    defaultVisual: String,
  },
  components: {
    ConfirmResetModal,
    TextSettings,
    ImageSettings,
    GeneralSettings,
    PriceSettings,
    AddMoreElements,
    InterfererSettings,
  },
  emits: [
    CampaignEvents.COMPLETE_SECTION,
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
  ],
  setup(props) {
    const hasSubmitted = computed(() => props.hasSubmitted);
    const t = campaignsTranslateByScope('editMotive.slideSettings');
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const addMoreElementsActive = ref<boolean>(false);
    const {
      slides,
      slideOrder,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      openSlideIndex,
      openSlideSectionIndices,
      toggleSlide,
      toggleSlideSection,
      increaseElementZIndex,
      decreaseElementZIndex,
      moveSlideUp,
      moveSlideDown,
      layout,
      updateSlidesSectionState,
      formState,
      slideAllowAnimations,
      removeSlideElement,
      onContractChanged,
      onDeviceChanged,
    } = injectStrict(EditFormatsFormKey);

    const getFieldsForSlideType = (slide: CreateSlideForValuesParams) => {
      return getSlideSettingsSections({
        layout,
        hasSubmitted,
        images: slide.images,
        texts: slide.texts,
        prices: slide.prices,
        interferers: slide.interferers,
        onContractChanged,
        onDeviceChanged,
        isGlobalEdit: props.isGlobalEdit,
      });
    };

    const showMoreElements = () => {
      addMoreElementsActive.value = !addMoreElementsActive.value;
    };

    const removeSection = (type: SlideElementSimpleType, label: string) => {
      const slideId = slideOrder.value[openSlideIndex.value];
      removeSlideElement(slideId, type, label);
    };

    const lastIndex = computed(() => {
      const slideId = slideOrder.value[openSlideIndex.value];
      return getFieldsForSlideType(slides.value[slideId]).value.length - 1;
    });

    const recoverSlide = (slideId: string) => {
      slides.value[slideId].removed = false;
    };

    const recoverElement = (type: SlideElementSimpleType, label: string) => {
      const slideId = slideOrder.value[openSlideIndex.value];
      const slide = slides.value[slideId];
      let element = null;
      switch (type) {
        case SlideElementSimpleType.Text:
          element = slide.texts.find(text => text.label === label);
          break;
        case SlideElementSimpleType.Image:
          element = slide.images.find(image => image.label === label);
          break;
        case SlideElementSimpleType.Price:
          element = slide.prices.find(price => price.label === label);
          break;
        case SlideElementSimpleType.Interferer:
          element = slide.interferers.find(
            interferer => interferer.label === label,
          );
          break;
        default:
          break;
      }
      if (element) {
        element.removed = false;
      }
    };

    return {
      lastIndex,
      updateSlidesSectionState,
      formState,
      t,
      CampaignEvents,
      slides,
      slideOrder,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      getFieldsForSlideType,
      fieldTypes,
      openSlideIndex,
      openSlideSectionIndices,
      toggleSlide,
      toggleSlideSection,
      moveSlideUp,
      moveSlideDown,
      resetModalOpen,
      onResetCallback,
      slideAllowAnimations,
      addMoreElementsActive,
      showMoreElements,
      removeSection,
      moveElementUp: increaseElementZIndex,
      moveElementDown: decreaseElementZIndex,
      SlideElementSimpleType,
      recoverSlide,
      recoverElement,
    };
  },
});
</script>
