<template>
  <div
    class="grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-items-center px-3 md:px-5"
  >
    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.INPUT]="{
          label,
          placeholder,
          errorMessage,
          id,
          styleClasses,
        }"
      >
        <app-input
          :class="styleClasses || ''"
          :label="label"
          :placeholder="placeholder"
          :errorMessage="errorMessage"
          v-model="models[id].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.SELECT]="{
          id,
          placeholder,
          label,
          options,
        }"
      >
        <app-select
          class="w-full md:w-modalInputWidth"
          :placeholder="placeholder"
          :label="label"
          :options="options"
          v-model="models[id].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.DATEPICKER]="{
          id,
          errorMessage,
          placeholder,
          label,
        }"
      >
        <app-datepicker
          :label="label"
          :placeholder="placeholder"
          v-model="models[id].value"
          :options="datePickerOptions"
          :errorMessage="errorMessage"
        />
      </template>

      <!--      <template v-slot:[fieldTypes.RADIO_BUTTON_GROUP]="{ id, options, label }">-->
      <!--        <radio-button-group-->
      <!--          class="justify-self-start"-->
      <!--          v-model="models[id].value"-->
      <!--          :options="options"-->
      <!--          :label="label"-->
      <!--        />-->
      <!--      </template>-->
    </FormGenerator>
  </div>
</template>

<script lang="ts">
import { FormGenerator, fieldTypes } from '@/components/formGenerator';
import { DateWrapper } from '@/core';
import { useGetMotive } from '@/features/campaigns/application/motive/actions/useGetMotive';
import { getGeneralSettingsFields } from '@/features/campaigns/application/motive/forms/getGeneralSettingsFields';
import { Ref, defineComponent, ref, watchEffect } from 'vue';

const SUBMIT_EVENT = 'submit-values';

export default defineComponent({
  name: 'UpdateGeneralSettingsForm',
  components: { FormGenerator },
  props: {
    hasSubmitted: { type: Boolean, required: true },
  },
  emits: [SUBMIT_EVENT],
  setup(props, context) {
    const { value: motive } = useGetMotive();

    const hasSubmitted = ref(false);
    const endDate = ref(motive.endDate) as Ref<DateWrapper>;
    const startDate = ref(motive.startDate) as Ref<DateWrapper>;
    const name = ref(motive.name);
    const clickUrl = ref(motive.clickUrl);
    const isDefaultMotive = ref(motive.isDefaultMotive);
    const description = ref(motive.description);
    // const layout = ref(Layout.DEVICE);

    const fields = getGeneralSettingsFields({
      hasSubmitted,
      endDate,
      startDate,
      name,
      clickUrl,
      description,
    });

    const datePickerOptions = {
      inputFormat: 'dd.MM.yyyy HH:mm',
      minimumView: 'time',
    };

    const models = {
      isDefaultMotive,
      endDate,
      startDate,
      name,
      description,
      clickUrl,
    };

    watchEffect(() => {
      if (props.hasSubmitted) {
        context.emit(SUBMIT_EVENT, {
          endDate,
          startDate,
          name,
          description,
          clickUrl,
          isDefaultMotive,
        });
      }
    });

    return {
      fields,
      datePickerOptions,
      models,
      fieldTypes,
    };
  },
});
</script>
