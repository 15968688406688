import {
  CreateSlidePriceForValuesParams,
  CreateSlidePriceParams,
} from '@/features/campaigns/domain/valueObjects/blocks/slidePrice';
import { Position } from '@/features/campaigns/domain/valueObjects/position';

export const generateDefaultPriceParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlidePriceParams => ({
  label: params.label,
  value: '',
  interval: '',
  overline: '',
  underline: '',
  transition: {
    type: 'scale',
    delay: 1000,
    x: 0,
    y: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
  scaling: 4,
  zIndex: params?.zIndex,
  removed: false,
});

export const generateDefaultPriceForValuesParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlidePriceForValuesParams => ({
  label: params.label,
  value: '',
  interval: '',
  overline: '',
  underline: '',
  scaling: 4,
  transition: {
    type: 'scale',
    delay: 1000,
    x: 0,
    y: 0,
  },
  position: Position.create({
    x: 0,
    y: 0,
  }),
  zIndex: params?.zIndex,
  removed: false,
});
