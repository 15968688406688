
import { defineComponent, ref, watch } from 'vue';
import { getEnvVariable } from '@/utils/getEnvVariable';
import { deepCopyJson } from '@/core/helpers';

const clickEvent = 'click';

export default defineComponent({
  props: {
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    version: { type: String, required: true },
    dataObj: { type: Object },
    isInteractive: { type: Boolean, default: true },
    shouldReplay: { type: Boolean, default: false },
    areVisualsDraggable: { type: Boolean, default: false },
  },
  emits: [clickEvent],
  setup(props, context) {
    const templatesBucketUrl = getEnvVariable(
      'VUE_APP_AWS_TEMPLATES_BUCKET_BASE_URL',
    );
    const iframeId = `iframe-format-${props.width}x${props.height}`;
    const iframeRef = ref<HTMLIFrameElement>();

    const injectData = (iframe: HTMLIFrameElement) => {
      if (iframe && iframe.tagName === 'IFRAME') {
        const formatNameMessage = {
          type: 'updateFormat',
          content: `${props.width}x${props.height}`,
        };
        const dataMessage = {
          type: 'updateData',
          content: deepCopyJson(props.dataObj?.slides),
        };
        iframe.contentWindow?.postMessage(formatNameMessage, '*');
        iframe.contentWindow?.postMessage(dataMessage, '*');
      }
    };

    const updateIframe = () => {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement;

      if (iframe && iframe.tagName === 'IFRAME') {
        iframe.onload = () => injectData(iframe);
        injectData(iframe);
      }
    };

    const onClick = () => {
      if (!props.isInteractive) {
        context.emit(clickEvent);
      }
    };

    watch(
      () => props.dataObj,
      () => {
        updateIframe();
      },
      {
        flush: 'post',
      },
    );

    return {
      onClick,
      iframeId,
      iframeRef,
      templatesBucketUrl,
    };
  },
});
