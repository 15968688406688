export class Device {
  constructor(
    public id: string,
    public title: string,
    public groupTitle: string,
    public imageId: string,
    public monthlyPrice: number,
    public oneTimePrice: number,
  ) {}

  static create(params: {
    id: string;
    title: string;
    groupTitle: string;
    imageId: string;
    monthlyPrice: number;
    oneTimePrice: number;
  }) {
    return new Device(
      params.id,
      params.title,
      params.groupTitle,
      params.imageId,
      params.monthlyPrice,
      params.oneTimePrice,
    );
  }

  static createForValues(params: {
    id: string;
    title: string;
    groupTitle: string;
    imageId: string;
    monthlyPrice: number;
    oneTimePrice: number;
  }) {
    return new Device(
      params.id,
      params.title,
      params.groupTitle,
      params.imageId,
      params.monthlyPrice,
      params.oneTimePrice,
    );
  }
}
