import { DeleteMotiveGroupFailure } from '@/features/campaigns/domain';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { deleteMotiveGroupSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useDeleteMotiveGroup = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { motiveGroupId: string }) => {
    const result = await facade.deleteMotiveGroup(params.motiveGroupId);

    if (result.isRight()) {
      store.dispatch(deleteMotiveGroupSucceeded, {
        motiveGroupId: params.motiveGroupId,
      });
      toast.success(t('campaigns.useDeleteMotiveGroup.success'));
    } else {
      const error= result.extract() as DeleteMotiveGroupFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useDeleteMotiveGroup.error'));
      }
    }
  });
};
