import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CreateUsersFailure, UserInvite } from '../domain';
import { UserHttpFacade } from '../infrastructure/user_http_facade';
import { createUsersSucceeded } from '../store/store';

export const useCreateUsers = (
  facade: UserHttpFacade = new UserHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (users: UserInvite[]) => {
    const usersToCreate = users.map((user: UserInvite) => ({
      email: user.email,
      userRole: user.role.getValue(),
    }));
    const result = await facade.createUsers(usersToCreate);

    if (result.isRight()) {
      const users = result.extract();
      store.dispatch(createUsersSucceeded, { users });
      toast.success(t('users.useCreateUsers.success', { count: users.length }));
    } else {
      const error= result.extract() as CreateUsersFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('users.useCreateUsers.error'));
      }
    }
  });
};
