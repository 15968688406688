
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { computed, defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import ConfirmResetModal from '../../confirmResetModal.vue';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import { getImageContentIndexes } from '@/features/campaigns/domain/valueObjects/blocks/helpers/slideImageHelpers';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import { SlideImage } from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import { useImageSettingsModels } from '@/features/campaigns/application/motive/editor/useImageSettingsModels';

export default defineComponent({
  components: {
    FormGenerator,
    ConfirmResetModal,
    MediaPickerGroup,
  },
  props: {
    fields: { type: Array, required: true },
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    defaultVisual: { type: String, default: '' },
    imageLabel: { type: String, default: '' },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { slides, slideOrder, getSlideResetValues } = injectStrict(
      EditFormatsFormKey,
    );
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));
    const emptySlideImage = {} as SlideImage;

    const slideImageRef = ref<SlideImage>(
      slides.value[slideId.value].images.find(
        image => image.label === props.imageLabel,
      ) || emptySlideImage,
    );

    const imageContentIndexes = getImageContentIndexes(
      slideImageRef.value.content,
    );

    const resetValuesRef = ref<SlideImage>(
      resetValues.value.images?.find(
        image => image.label === props.imageLabel,
      ) || emptySlideImage,
    );

    const imageIndex = computed(() => {
      return slides.value[slideId.value].images.findIndex(
        image => image.label === props.imageLabel,
      );
    });

    const {
      transitionModel,
      squareVisualModel,
      horizontalVisualModel,
      verticalVisualModel,
      positionXModel,
      positionYModel,
      transitionOriginXModal,
      transitionOriginYModal
    } = useImageSettingsModels(slideImageRef, imageContentIndexes);

    return {
      imageIndex,
      positionXModel,
      positionYModel,
      transitionModel,
      squareVisualModel,
      horizontalVisualModel,
      verticalVisualModel,
      resetValuesRef,
      slideImageRef,
      slideId,
      imageContentIndexes,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      t,
      resetValues,
      resetModalOpen,
      onResetCallback,
      transitionOriginXModal,
      transitionOriginYModal
    };
  },
});
