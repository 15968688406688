import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { CampaignDetailsRow } from '../../../domain';

export const useGetCampaignDetailsRows = (
  campaignId: string,
): ComputedRef<CampaignDetailsRow[]> => {
  const store = useStore();

  const motiveGroupsMapping = computed(() =>
    Object.fromEntries(
      store.getters
        .motiveGroupsByCampaignId(campaignId)
        .map(group => [group.id, group.name]),
    ),
  );

  const campaignDetailsRows = computed(() =>
    store.getters
      .motivesByCampaignId(campaignId)
      .map(motive => motive.toCampaignDetailsRow(motiveGroupsMapping.value)),
  );

  return campaignDetailsRows;
};
