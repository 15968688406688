
import { useGetOptionsForCampaign } from '../../application/campaign/list/useGetOptionsForCampaign';
import { useConfirmationModal } from '../../../shared/application/useConfirmationModal';
import { CampaignEvents } from '../events';
import { defineComponent } from 'vue';
import ConfirmDeletionModal from '@/components/confirmDeletionModal/confirmDeletionModal.vue';
import { useHardDeleteCampaign } from '@/features/campaigns/application/campaign/actions/useHardDeleteCampaign';

export default defineComponent({
  components: { ConfirmDeletionModal },
  props: {
    campaignId: { type: String, required: true },
  },
  emits: [CampaignEvents.EDIT_CAMPAIGN, CampaignEvents.ADD_MOTIVE],
  setup(props, context) {
    const {
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForCampaign(
      props.campaignId,
      {
        onHardDelete: withConfirm,
      },
      context.emit,
    );

    const { call: hardDelete } = useHardDeleteCampaign();

    const onConfirmDeletion = async () => {
      await hardDelete({ id: props.campaignId });
      onCancel();
    };

    return {
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onCancel,
      onConfirmDeletion,
    };
  },
});
