<template>
  <modal :isOpen="isModalOpen" @close-modal="closeModal" :title="$t('overview.exportModal.title')"
    :cancelLabel="$t('campaigns.createCampaign.cancel')" :confirmLabel="$t('overview.exportButton')"
    :onConfirm="onExportMotives">
    <div class="grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-items-center">
      <app-searchable-select-multi class="w-full" :placeholder="$t('overview.exportModal.campaignPlaceholder')"
        :label="$t('overview.exportModal.campaignLabel')" :options="campaignDropdownOptions"
        v-model="campaignIdSelected" @update:isValid="setIsSelectValid" :errorMessage="hasSubmitted && !isSelectValid
            ? $t('campaigns.duplicateMotiveModal.invalidCampaignSelected')
            : null
          " />
      <app-select class="w-full" :placeholder="$t('overview.exportModal.statusPlaceholder')"
        :label="$t('overview.exportModal.statusLabel')" :options="statusDropdownOptions" v-model="statusSelected"
        @update:isValid="setIsStatusSelectValid" :errorMessage="hasSubmitted && !isStatusSelectValid
            ? $t('overview.exportModal.invalidStatusSelected')
            : null
          " />
    </div>
  </modal>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import { t } from '@/services/i18n';
import { computed, defineComponent, ref } from 'vue';
import { useGetCampaignDropdown } from '../../application/campaign/list/useGetCampaignDropdown';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { useToast } from 'vue-toastification';

export default defineComponent({
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(_, context) {
    const hasSubmitted = ref(false);
    const name = ref('');
    const trackingParameter = ref('');
    const isStatusSelectValid = ref(true);

    const { campaignDropdownOptions } = useGetCampaignDropdown({onlyActive: true});
    const campaignIdSelected = ref([]);

    const isSelectValid = ref(false);
    const setIsSelectValid = (value: boolean) => {
      isSelectValid.value = value && campaignIdSelected.value.length > 0;
    };
    const statusSelected = ref('');
    const statusDropdownOptions = computed(() => [
      { value: 'all', label: t('overview.exportModal.allStatus') },
      { value: 'active', label: t('overview.exportModal.statusActive') },
      { value: 'inactive', label: t('overview.exportModal.statusInactive') },
    ]);

    const setIsStatusSelectValid = (value: boolean) => {
      isStatusSelectValid.value = value;
    };

    const closeModal = () => {
      campaignIdSelected.value = [];
      statusSelected.value = '';
      isSelectValid.value = false;
      isStatusSelectValid.value = true;
      hasSubmitted.value = false;
      name.value = '';
      trackingParameter.value = '';
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onExportMotives = async () => {
      hasSubmitted.value = true;
      const toast = useToast();
      if (campaignIdSelected.value) {
        try {
          const campaignHttpFacade = new CampaignHttpFacade();
          const campaignIds = campaignIdSelected.value.join(',');

          await campaignHttpFacade.exportMotivesOverview(
            campaignIds,
            statusSelected.value,
          );
          closeModal();
          toast.success(t('overview.exportModal.success'));
        } catch (e) {
          console.error('Failed to export motives overview', e);
          toast.error(t('overview.exportModal.error'));
        }
      }
    };

    return {
      name,
      trackingParameter,
      hasSubmitted,
      closeModal,
      campaignDropdownOptions,
      campaignIdSelected,
      setIsSelectValid,
      isSelectValid,
      statusDropdownOptions,
      isStatusSelectValid,
      setIsStatusSelectValid,
      statusSelected,
      onExportMotives,
    };
  },
});
</script>
