
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import './editor.css';
import { ref, defineComponent, watch, computed, nextTick } from 'vue';
import { fontOptions, colorOptions } from './config';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'rich-text-editor',
  components: {
    QuillEditor,
  },
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    label: String,
    placeholder: String,
    modelValue: { type: String, required: false },
    id: { type: String, required: true },
    hasReset: { type: Boolean, default: false },
    errorMessage: String,
    initialModelValue: { type: String, required: false },
    initialFont: { type: String, default: fontOptions[0].value },
    fontSize: { type: Number, default: 13 },
  },
  setup(props, context) {
    const quillRef = ref<any>(null);
    const fontRef = ref<any>(null);
    const htmlEdit = ref(false);
    const didReset = ref(false);
    const renderQuillEditor = ref(true);

    const setStyling = () => {
      const editor = document.querySelector(
        `#${props.id}-text-editor .ql-editor`,
      ) as HTMLElement;
      if (editor) {
        editor.style.fontFamily = props.initialFont;
        editor.style.fontSize = `${props.fontSize}px`;
      }
    };

    const updateContent = (value: string) => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };

    const setHtmlEdit = (value: boolean) => {
      htmlEdit.value = value;
    };

    const onReset = () => {
      didReset.value = true;
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue !== undefined &&
        props.initialModelValue !== props.modelValue
      );
    });

    watch([props], () => {
      if (didReset.value) {
        didReset.value = false;
        renderQuillEditor.value = false;
        nextTick(() => {
          renderQuillEditor.value = true;
        });
      }
    });

    watch([fontRef, quillRef], () => {
      setStyling();
    });

    return {
      isResetVisible,
      setHtmlEdit,
      htmlEdit,
      fontOptions,
      colorOptions,
      fontRef,
      quillRef,
      updateContent,
      onReset,
      renderQuillEditor,
      setStyling,
    };
  },
});
