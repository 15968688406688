import { BulkCreateMotivesFailure } from '@/features/campaigns/domain';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { createMotiveGroupSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useBulkCreateMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: {
      name: string;
      baseMotiveId: string;
      campaignId: string;
      isAutoUpdateOn: boolean;
      deviceId?: string;
      contractId?: string;
    }) => {
      const result = await facade.bulkCreateMotives(params);

      if (result.isRight()) {
        const motiveGroup = result.extract();
        store.dispatch(createMotiveGroupSucceeded, { motiveGroup });
        toast.success(t('campaigns.useBulkCreateMotive.success'));
      } else {
        const error= result.extract() as BulkCreateMotivesFailure;
      
        switch (error.status) {
          case 503:
            toast.error(t('common.errors.serviceUnavailable')); break;
          case 500:
            toast.error(t('common.errors.internalServer')); break;
          case 400:
            toast.error(t('common.errors.badRequest')); break;
          default:
            toast.error(t('campaigns.useBulkCreateMotive.error'));
        }
      }
    },
  );
};
