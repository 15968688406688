import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { DeleteMediaFailure } from '../domain';
import { Folder } from '../domain/folder';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { deleteFolderSucceeded } from '../store/store';

export const useDeleteFolder = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (folder: Folder) => {
    const result = await facade.deleteFolder(folder);

    if (result.isRight()) {
      const deletedFolderKey = result.extract();

      store.dispatch(deleteFolderSucceeded, {
        deletedFolderKey,
      });

      toast.success(t('media.useDeleteMedia.success'));
    } else {
      const error = result.extract() as DeleteMediaFailure;
      if (error.motivesUsingAsset) {
        toast.error(
          t('media.useDeleteMedia.assetUsedError', {
            motivesUsingAsset: error.motivesUsingAsset.slice(0, 5).join(', '),
          }),
        );
      } else {
        const error= result.extract() as DeleteMediaFailure;
      
        switch (error.status) {
          case 503:
            toast.error(t('common.errors.serviceUnavailable')); break;
          case 500:
            toast.error(t('common.errors.internalServer')); break;
          case 400:
            toast.error(t('common.errors.badRequest')); break;
          default:
            toast.error(t('media.useDeleteMedia.error'));
        }
      }
    }
  });
};
