
import { computed, ref, defineComponent } from 'vue';
import { useCreateCampaign } from '../../application/campaign/actions/useCreateCampaign';
import { t } from '@/services/i18n';
import { ModalEvents } from '@/components/modal/modalEvents';
import { DateWrapper } from '@/core';

export default defineComponent({
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(_, context) {
    const hasSubmitted = ref(false);
    const name = ref('');
    const trackingParameter = ref('');
    const startDate = ref(DateWrapper.empty());
    const endDate = ref(DateWrapper.empty());

    const { isLoading, call } = useCreateCampaign();

    const endDateErrorMessage = computed(() => {
      if (!hasSubmitted.value) return null;
      if (!endDate.value.isValid())
        return t('campaigns.createCampaign.invalidDateError');
      if (
        startDate.value.isValid() &&
        endDate.value.getValue() < startDate.value.getValue()
      )
        return t('campaigns.createCampaign.startAfterEndError');
      return null;
    });

    const closeModal = () => {
      hasSubmitted.value = false;
      name.value = '';
      trackingParameter.value = '';
      startDate.value = DateWrapper.empty();
      endDate.value = DateWrapper.empty();
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onCreateCampaign = () => {
      hasSubmitted.value = true;
      if (
        name.value &&
        startDate.value.isValid() &&
        endDate.value.isValid() &&
        endDate.value.getValue() >= startDate.value.getValue()
      ) {
        call({
          name: name.value,
          trackingParameter: trackingParameter.value,
          startDate: startDate.value as DateWrapper,
          endDate: endDate.value as DateWrapper,
        });
        closeModal();
      }
    };

    return {
      name,
      trackingParameter,
      startDate,
      endDate,
      onCreateCampaign,
      isLoading,
      hasSubmitted,
      endDateErrorMessage,
      closeModal,
    };
  },
});
