import { DateWrapper } from '@/core';
import { EnabledNotifications, UserRow } from '.';
import { UserRoles, UserStatus } from './value_objects';

export class User {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public userRole: UserRoles,
    public createdAt: DateWrapper,
    public status: UserStatus,
    public enabledNotifications: EnabledNotifications,
  ) {}

  static create(params: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userRole: string;
    createdAt: string;
    status: string;
    enabledNotifications: EnabledNotifications;
  }) {
    return new User(
      params.id,
      params.firstName,
      params.lastName,
      params.email,
      UserRoles.create(params.userRole),
      DateWrapper.create(params.createdAt),
      UserStatus.create(params.status),
      params.enabledNotifications,
    );
  }

  static createForValues(params: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userRole: UserRoles;
    createdAt: DateWrapper;
    status: UserStatus;
    enabledNotifications: EnabledNotifications;
  }) {
    return new User(
      params.id,
      params.firstName,
      params.lastName,
      params.email,
      params.userRole,
      params.createdAt,
      params.status,
      params.enabledNotifications,
    );
  }
  public static validateUserEmailAndGetFullname(email: string) {
    const fullName = email.split('@')[0];
    const regex = /^[a-zA-Z0-9_]+\.[a-zA-Z0-9_]/i;
    if (regex.test(fullName)) {
      return fullName
        .split('.')
        .map(name => name.charAt(0).toUpperCase() + name.slice(1))
        .join(' ');
    }
    return email;
  }
  toUserRow(): UserRow {
    return {
      id: this.id,
      email: this.email,
      role: this.userRole.getValue(),
      creationDate: this.createdAt,
      status: this.status.getValue(),
    };
  }
}
