
import { TableEvents } from '../tableEvents';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: [TableEvents.SELECT_CHECKBOX],
  props: {
    rowsToSelect: { type: Array, required: true },
  },
  setup(props, context) {
    const onChange = (event: Event) => {
      const value = (event.target as HTMLInputElement)?.checked;
      context.emit(TableEvents.SELECT_CHECKBOX, {
        selectAll: value,
        rowsToSelect: props.rowsToSelect,
      });
    };
    return { onChange };
  },
});
