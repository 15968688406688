<template>
  <Dialog :open="isOpen" class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen md:h-auto">
      <DialogOverlay class="fixed inset-0">
        <div class="absolute inset-0 bg-gray-600 opacity-70" />
      </DialogOverlay>

      <div
        class="relative flex flex-col min-h-screen md:min-h-0 h-full w-full md:w-modalWidth md:max-w-largeModalMaxWidth bg-white md:rounded-2 z-20 py-3 md:py-5 shadow-lg"
        v-bind="$attrs"
      >
        <button
          class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
          @click="$emit(ModalEvents.CLOSE_MODAL)"
        >
          <app-icon name="closeCircle" alt="close modal" />
        </button>

        <DialogTitle
          v-if="title"
          class="text-h3 md:text-h1 font-semibold md:text-center text-black-basic mt-4.5 md:mt-0 mb-4.5 px-3"
        >
          {{ title }}
        </DialogTitle>

        <slot />

        <div class="flex flex-row justify-end mt-4 md:mt-5 px-3 md:px-5">
          <div class="laptop-only">
            <app-button
              v-if="cancelLabel"
              @click="$emit(ModalEvents.CLOSE_MODAL)"
              variant="secondary"
              class="mr-3 shadow-sm"
              >{{ cancelLabel }}</app-button
            >
          </div>

          <div v-if="step === 1">
            <app-button
              @click="onContinuePressed()"
              :class="'w-full md:w-max focus:outline-none ' + confirmBtnStyle"
              v-if="confirmLabel"
              :disabled="isContinueDisabled"
              >{{ continueLabel }}</app-button
            >
          </div>

          <div v-if="step === 2">
            <app-button
              @click="onConfirmPressed()"
              :isLoading="isLoading"
              :class="'w-full md:w-max focus:outline-none ' + confirmBtnStyle"
              v-if="confirmLabel"
              :disabled="isSubmitDisabled"
              >{{ confirmLabel }}</app-button
            >
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { injectStrict } from '@/utils/injectStrict';
import { FormEvents } from '@/components/formGenerator/formEvents';
import { CreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';

export default defineComponent({
  name: 'modal',
  inheritAttrs: false,
  components: { Dialog, DialogOverlay, DialogTitle },
  props: {
    step: Number,
    isOpen: Boolean,
    title: String,
    cancelLabel: String,
    continueLabel: String,
    confirmLabel: String,
    onConfirm: { type: Function, required: true },
    onContinue: { type: Function, required: true },
    isLoading: Boolean,
    confirmBtnStyle: { type: String, required: false },
    isSubmitDisabled: Boolean,
    isContinueDisabled: Boolean,
  },
  emits: [ModalEvents.CLOSE_MODAL, FormEvents.SET_SUBMITTED],
  setup(props, context) {
    const {
      isGeneralSectionValid,
      isVariationsSectionValid
    } = injectStrict(CreateMotiveFormKey);

    const onContinuePressed = () => {
      context.emit(FormEvents.SET_SUBMITTED, true);

      if (isGeneralSectionValid.value) {
        context.emit(FormEvents.SET_SUBMITTED, false);
        props.onContinue();
      }
    }

    const onConfirmPressed = () => {
      context.emit(FormEvents.SET_SUBMITTED, true);

      if (isVariationsSectionValid.value) {
        context.emit(FormEvents.SET_SUBMITTED, false);
        props.onConfirm();
      }
    }
    
    return { ModalEvents, isGeneralSectionValid, isVariationsSectionValid, onContinuePressed, onConfirmPressed };
  },
});
</script>
