import { campaignsRouteNames } from '@/features/campaigns/presentation/routes/campaignsRouteNames';
import { mediaRouteNames } from '@/features/media/presentation/routes/mediaRouteNames';
import { syncRouteNames } from '@/features/sync/presentation/routes/syncRouteNames';
import { templateRouteNames } from '@/features/templates/presentation/routes/templateRouteNames';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { usersRouteNames } from '@/features/users/presentation/routes/usersRouteNames';

import { t } from '@/services/i18n';

const pages = [
  { label: t('topbar.overview'), to: campaignsRouteNames.overview },
  { label: t('topbar.campaigns'), to: campaignsRouteNames.campaignList },
  {
    label: t('topbar.assets'),
    to: mediaRouteNames.mediaList,
    reloadOnClick: true,
  },
  { label: t('topbar.sync'), to: syncRouteNames.syncList, isAdminOnly: true },
  {
    label: t('topbar.users'),
    to: usersRouteNames.usersList,
    isAdminOnly: true,
  },
  {
    label: t('topbar.templates'),
    to: templateRouteNames.templates,
    isAdminOnly: true,
  },
];

export const useGetAccessiblePages = () => {
  const { currentUser } = useGetCurrentUser();
  const isUserAdmin = currentUser.value.userRole.isAdmin();
  return pages.filter(page => isUserAdmin || !page.isAdminOnly);
};
