<template>
  <div class="flex justify-left">
    <app-icon v-if="isError" name="statusRedDot" alt="Error status dot" />
    <app-icon v-else name="statusDot" alt="Active status dot" />
    <app-txt
      variant="small"
      :class="{
        'rounded-5 flex justify-center items-center p-1 pl-0.5 pt-0.5 font-medium bg-opacity-70': true,
        'text-red-600': isError,
        'text-green-700': isSuccess,
      }"
      >{{ text }}</app-txt
    >
  </div>
</template>

<script lang="ts">
import { CampaignStatus, MotiveStatus } from '@/features/campaigns/domain';
import { SyncStatus } from '@/features/sync/domain/value_objects';
import { UserStatus } from '@/features/users/domain/value_objects';
import { computed, defineComponent } from 'vue';
import { t } from '@/services/i18n';

export default defineComponent({
  props: {
    value: String,
  },
  setup(props) {
    const isSuccess = computed(() =>
      [
        CampaignStatus.ACTIVE,
        MotiveStatus.ACTIVE,
        UserStatus.Active,
        SyncStatus.Success,
      ].includes(props.value ?? ''),
    );
    const isError = computed(() =>
      [
        CampaignStatus.INACTIVE,
        MotiveStatus.INACTIVE,
        UserStatus.Inactive,
        SyncStatus.Error,
        MotiveStatus.DEACTIVATED_BY_FEED,
      ].includes(props.value ?? ''),
    );

    const isInvited = computed(() =>
      [UserStatus.Invited].includes(props.value ?? ''),
    );

    const text = computed(() => {
      if (props.value === MotiveStatus.DEACTIVATED_BY_FEED) {
        return t('table.deactivatedByFeed');
      }
      
      return t(`table.${props.value}`);
    });

    return {
      isSuccess,
      isError,
      isInvited,
      text
    };
  },
});
</script>
