
import { computed, defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import ConfirmResetModal from '../../confirmResetModal.vue';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import AppCollapsableInput from '@/components/collapsable-input/collapsableInput.vue';

export default defineComponent({
  components: {
    AppCollapsableInput,
    FormGenerator,
    ConfirmResetModal,
  },
  props: {
    fields: { type: Array, required: true },
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { slides, slideOrder, getSlideResetValues } = injectStrict(
      EditFormatsFormKey,
    );
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));

    return {
      fieldTypes,
      slideId,
      slides,
      t,
      resetValues,
      resetModalOpen,
      onResetCallback,
    };
  },
});
