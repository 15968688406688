import { createI18n } from 'vue-i18n';
import de from '../locales/de';
import en from '../locales/en';
import zu from '../locales/zu';

const messages = {
  de,
  en,
  zu,
};

const i18n = createI18n({
  locale: process.env.NODE_ENV == 'development' ? 'en' : navigator.language,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages,
});

export const t = i18n.global.t;

export default i18n;
