import { PreviewAttributes } from './iPreviewAttributes';
import { PreviewSlide } from './previewSlide';

export type PreviewCreationParams = {
  attributes: PreviewAttributes;
  content: PreviewSlide[];
  paused: boolean;
  startSlide: number;
};

export class Preview {
  public elements: {
    type: 'slider';
    attributes: PreviewAttributes;
    content: PreviewSlide[];
    paused: boolean;
    startSlide: number;
  }[];

  private constructor(
    type: 'slider',
    attributes: PreviewAttributes,
    content: PreviewSlide[],
    paused: boolean,
    startSlide: number,
  ) {
    this.elements = [
      { type, attributes, content, paused, startSlide },
    ];
  }

  static create(params: PreviewCreationParams) {
    return new Preview(
      'slider',
      params.attributes,
      params.content,
      params.paused,
      params.startSlide,
    );
  }
}
