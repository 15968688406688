import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { SyncHttpFacade } from '../infrastructure/sync_http_facade';
import { StartManualSyncFailure } from '../domain';

export const useStartManualSync = (
  facade: SyncHttpFacade = new SyncHttpFacade(),
) => {
  const toast = useToast();

  return useMutation(async () => {
    const result = await facade.startManualSync();

    if (result.isRight()) {
      toast.success(t('sync.useStartManualSync.success'));
    } else {
      const error= result.extract() as StartManualSyncFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('sync.useStartManualSync.error'));
      }
    }
  });
};
