import { DateWrapper } from '@/core';
import { CreateMotive } from '@/features/campaigns/domain/motive/createMotive';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { CreateSlideForValuesParams } from '@/features/campaigns/domain/valueObjects/slide';
import { Ref } from 'vue';
import { useCreateMotive } from '../actions/useCreateMotive';

export const useCreateMotiveFunction = ({
  name,
  description,
  isDefaultMotive,
  clickUrl,
  startDate,
  endDate,
  layout,
  slides,
  slideOrder,
}: {
  name: Ref<string>;
  description: Ref<string>;
  isDefaultMotive: Ref<boolean>;
  startDate: Ref<DateWrapper>;
  endDate: Ref<DateWrapper>;
  layout: Ref<Layout>;
  clickUrl: Ref<string>;
  slides: Ref<Record<string, CreateSlideForValuesParams>>;
  slideOrder: Ref<string[]>;
}) => {
  const {
    isLoading: isCreateMotiveLoading,
    call: createMotiveCall,
  } = useCreateMotive();

  const isMotiveActive = (startDate: DateWrapper, endDate: DateWrapper) => {
    const currentDate = new Date();
    return (
      currentDate >= startDate.getValue() && currentDate <= endDate.getValue()
    );
  };

  const createMotive = async (campaignId: string) => {
    return await createMotiveCall(
      CreateMotive.createForValues({
        name: name.value,
        description: description.value,
        isDefaultMotive: isDefaultMotive.value,
        startDate: startDate.value,
        endDate: endDate.value,
        layout: layout.value,
        clickUrl: clickUrl.value,
        slides: slideOrder.value.map(id => slides.value[id]),
        campaignId: campaignId,
        isActive: isMotiveActive(startDate.value, endDate.value),
      }),
    );
  };

  return {
    isLoading: isCreateMotiveLoading,
    createMotive,
  };
};
