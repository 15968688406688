<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('campaigns.duplicateMotiveModal.title')"
    :cancelLabel="$t('campaigns.duplicateMotiveModal.cancel')"
    :confirmLabel="$t('campaigns.duplicateMotiveModal.confirm')"
    :onConfirm="onSubmit"
    :isLoading="isLoading"
  >
    <app-searchable-select
      class="w-full"
      :placeholder="$t('campaigns.duplicateMotiveModal.campaignPlaceholder')"
      :label="$t('campaigns.duplicateMotiveModal.campaignLabel')"
      :options="campaignDropdownOptions"
      v-model="campaignIdSelected"
      @update:isValid="setIsSelectValid"
      :errorMessage="
        hasSubmitted && !isSelectValid
          ? $t('campaigns.duplicateMotiveModal.invalidCampaignSelected')
          : null
      "
    />
  </modal>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { useDuplicateMotive } from '../../application/motive/actions/useDuplicateMotive';
import { useFetchCampaigns } from '../../application/campaign/actions/useFetchCampaigns';
import { useGetCampaignDropdown } from '../../application/campaign/list/useGetCampaignDropdown';

export default defineComponent({
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveId: { type: String, required: true },
    campaignId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    useFetchCampaigns({});

    const { campaignDropdownOptions } = useGetCampaignDropdown();
    const campaignIdSelected = ref(props.campaignId);
    const hasSubmitted = ref(false);

    const { isLoading, call: callDuplicate } = useDuplicateMotive();

    const isSelectValid = ref(true);
    const setIsSelectValid = (value: boolean) => {
      isSelectValid.value = value;
    };

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      campaignIdSelected.value = props.campaignId;
      hasSubmitted.value = false;
    };

    const onSubmit = () => {
      hasSubmitted.value = true;
      if (isSelectValid.value) {
        callDuplicate({
          motiveId: props.motiveId,
          campaignId: campaignIdSelected.value,
        });
        onCancel();
      }
    };

    return {
      isLoading,
      hasSubmitted,
      onCancel,
      onSubmit,
      campaignIdSelected,
      campaignDropdownOptions,
      isSelectValid,
      setIsSelectValid,
    };
  },
});
</script>
