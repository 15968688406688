
import { FormGenerator, fieldTypes } from '@/components/formGenerator';
import { DateWrapper } from '@/core';
import { useGetMotive } from '@/features/campaigns/application/motive/actions/useGetMotive';
import { getGeneralSettingsFields } from '@/features/campaigns/application/motive/forms/getGeneralSettingsFields';
import { Ref, defineComponent, ref, watchEffect } from 'vue';

const SUBMIT_EVENT = 'submit-values';

export default defineComponent({
  name: 'UpdateGeneralSettingsForm',
  components: { FormGenerator },
  props: {
    hasSubmitted: { type: Boolean, required: true },
  },
  emits: [SUBMIT_EVENT],
  setup(props, context) {
    const { value: motive } = useGetMotive();

    const hasSubmitted = ref(false);
    const endDate = ref(motive.endDate) as Ref<DateWrapper>;
    const startDate = ref(motive.startDate) as Ref<DateWrapper>;
    const name = ref(motive.name);
    const clickUrl = ref(motive.clickUrl);
    const isDefaultMotive = ref(motive.isDefaultMotive);
    const description = ref(motive.description);
    // const layout = ref(Layout.DEVICE);

    const fields = getGeneralSettingsFields({
      hasSubmitted,
      endDate,
      startDate,
      name,
      clickUrl,
      description,
    });

    const datePickerOptions = {
      inputFormat: 'dd.MM.yyyy HH:mm',
      minimumView: 'time',
    };

    const models = {
      isDefaultMotive,
      endDate,
      startDate,
      name,
      description,
      clickUrl,
    };

    watchEffect(() => {
      if (props.hasSubmitted) {
        context.emit(SUBMIT_EVENT, {
          endDate,
          startDate,
          name,
          description,
          clickUrl,
          isDefaultMotive,
        });
      }
    });

    return {
      fields,
      datePickerOptions,
      models,
      fieldTypes,
    };
  },
});
