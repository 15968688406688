import { Asset } from '@/features/media/domain/asset';
import { FORMATS_MAPPING } from '../../../constants';
import { ImageContentType, SlideImageContent } from '../slideImage';
import { VisualInfo } from '../../visualInfo';

export const getImageContentIndexes = (
  imageContentArray?: SlideImageContent[],
) => {
  return {
    square:
      imageContentArray?.findIndex(
        imageContent => imageContent.type === ImageContentType.Square,
      ) ?? -1,
    vertical:
      imageContentArray?.findIndex(
        imageContent => imageContent.type === ImageContentType.Vertical,
      ) ?? -1,
    horizontal:
      imageContentArray?.findIndex(
        imageContent => imageContent.type === ImageContentType.Horizontal,
      ) ?? -1,
  };
};

export const getVisualInfoForFormat = (params: {
  formatName: string;
  imagesById: Record<string, Asset>;
  square?: string;
  horizontal?: string;
  vertical?: string;
}) => {
  const visualWanted = FORMATS_MAPPING[
    params.formatName as keyof typeof FORMATS_MAPPING
  ].visual as ImageContentType;
  const visualId = params[visualWanted] as string;

  if (!visualId) {
    return VisualInfo.createEmptyVisual(visualWanted);
  }
  const visual = params.imagesById[visualId];

  if (!visual) {
    return VisualInfo.createEmptyVisual(visualWanted);
  }

  return VisualInfo.createFromAsset(visual, visualWanted);
};
