
import Sidebar from '../../sidebar.vue';
import SlideSettings from './slideSettings.vue';
import { AdjustablePreviewKey } from '@/features/campaigns/application/motive/editor/types';
import { useFetchMotiveById } from '@/features/campaigns/application/motive/actions/useFetchMotiveById';
import { useFetchRawFormatsForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsForMotiveId';
import { useGetMotive } from '@/features/campaigns/application/motive/actions/useGetMotive';
import { FORMATS_MAPPING } from '@/features/campaigns/domain/constants';
import { FormatRaw } from '@/features/campaigns/domain/format/formatRaw';
import { injectStrict } from '@/utils/injectStrict';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { EditorEvents } from '../../../events';
import { campaignsTranslateByScope } from '../../../translationScope';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { useFetchMotiveVersions } from '@/features/campaigns/application/motive/actions/useFetchMotiveVersions';
import { useFetchRawFormatsDefaultsInstancesForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsDefaultInstanceForMotiveId';
import { FormatDefault } from '@/features/campaigns/domain/format/formatDefault';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import GeneralSettingsModal from '@/features/campaigns/presentation/motiveEditor/siderbar/edit/generalSettings/generalSettingsModal.vue';

export default defineComponent({
  components: {
    GeneralSettingsModal,
    Sidebar,
    SlideSettings,
  },
  props: {
    motiveId: { type: String, required: true },
    campaignId: { type: String, required: true },
    selectedFormat: { type: String, required: true },
    formats: { type: Array as PropType<Array<FormatRaw>>, required: true },
    formatsDefaultsInstances: {
      type: Array as PropType<Array<FormatDefault>>,
      required: true,
    },
    shouldSave: { type: Boolean, default: false },
  },
  emits: [
    EditorEvents.CLOSE,
    EditorEvents.RESET_CHANGES,
    EditorEvents.RETURN,
    EditorEvents.UPDATE_HAS_CHANGES,
  ],
  setup(props, context) {
    const toast = useToast();
    const t = campaignsTranslateByScope('editMotive');
    const hasSubmitted = ref(false);
    const isSaving = ref(false);
    const isGeneralSettingsModalOpen = ref(false);
    const sidebarTitle = computed(() => {
      return props.selectedFormat
        ? t('titleFormat', { format: props.selectedFormat })
        : t('titleMotive');
    });

    const computedMotiveId = computed(() => {
      return props.motiveId;
    });

    const { currentUser } = useGetCurrentUser();
    const {
      updateFormat,
      updateFormatDefaults,
      createMotiveVersion,
      isValid,
      errorMessageOnInvalidSlideSettings,
      hasChanges,
      updateOpenedSettings,
    } = injectStrict(EditFormatsFormKey);
    const adjustablePreview = injectStrict(AdjustablePreviewKey);
    const motive = useGetMotive();

    const setIsGeneralSettingsModalOpen = (value: boolean) => {
      isGeneralSettingsModalOpen.value = value;
    };
    const onEditGeneralSettings = () => {
      isGeneralSettingsModalOpen.value = true;
    };

    const defaultVisual = computed(() => {
      return FORMATS_MAPPING[props.selectedFormat]?.visual;
    });

    const onSave = async () => {
      hasSubmitted.value = true;
      isSaving.value = true;

      if (!isValid.value) {
        isSaving.value = false;
        return toast.error(errorMessageOnInvalidSlideSettings.value);
      }

      await createMotiveVersion({
        author: currentUser.value.email,
        type: versionType.Autosaved,
        motiveId: props.motiveId,
        ...(props.selectedFormat && { changedFormat: props.selectedFormat }),
      });

      if (!props.selectedFormat) {
        await updateFormatDefaults(
          motive.value.toFormat({
            rawFormatDefaultInstance: FormatDefault.createIntersect(
              props.formatsDefaultsInstances,
              motive.value.name,
            ),
          }),
        );
      } else {
        const byName = (f: FormatRaw | FormatDefault) =>
          f.name === props.selectedFormat;

        const initialFormat = props.formats.filter(byName)[0];
        const initialFormatDefaultInstance = props.formatsDefaultsInstances.filter(
          byName,
        )[0];

        if (initialFormat) {
          await updateFormat(
            motive.value.toFormat({
              rawFormat: initialFormat,
              rawFormatDefaultInstance: initialFormatDefaultInstance,
            }),
          );
        }
      }

      // TODO: Fix that it happens on success
      useFetchRawFormatsForMotiveId({
        motiveId: props.motiveId,
      });
      useFetchRawFormatsDefaultsInstancesForMotiveId({
        motiveId: props.motiveId,
      });
      useFetchMotiveById({
        motiveId: props.motiveId,
      });
      useFetchMotiveVersions({
        motiveId: props.motiveId,
      });
      hasSubmitted.value = false;
      isSaving.value = false;
      context.emit(EditorEvents.CHANGES_SAVED);
    };

    watch([hasChanges], () => {
      context.emit(EditorEvents.UPDATE_HAS_CHANGES, hasChanges.value);
    });

    watch([adjustablePreview.adjustedSettings], () => {
      updateOpenedSettings(adjustablePreview.adjustedSettings.value as string);
    });

    watch([props], () => {
      if (props.shouldSave) {
        onSave();
      }
    });

    return {
      t,
      onSave,
      hasSubmitted,
      isGeneralSettingsModalOpen,
      sidebarTitle,
      onEditGeneralSettings,
      setIsGeneralSettingsModalOpen,
      EditorEvents,
      hasChanges,
      isSaving,
      defaultVisual,
      computedMotiveId,
    };
  },
});
