import { MotiveDetailRow } from '..';
import { convertFromBytesToKilobytes } from '../utils';

const LIST_SEPARATOR = ', ';

export class FormatOverview {
  constructor(
    public id: string,
    public name: string,
    public motiveId: string,
    public isActive: boolean,
    public size: number,
    public devices: string[],
    public contracts: string[],
    public clickUrls: string[],
  ) {}

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    isActive: boolean;
    size: number;
    devices: string[];
    contracts: string[];
    clickUrls: string[];
  }) {
    return new FormatOverview(
      params.id,
      params.name,
      params.motiveId,
      params.isActive,
      params.size,
      params.devices,
      params.contracts,
      params.clickUrls,
    );
  }

  static createForValues(params: {
    id: string;
    name: string;
    motiveId: string;
    isActive: boolean;
    size: number;
    devices: string[];
    contracts: string[];
    clickUrls: string[];
  }) {
    return new FormatOverview(
      params.id,
      params.name,
      params.motiveId,
      params.isActive,
      params.size,
      params.devices,
      params.contracts,
      params.clickUrls,
    );
  }

  toMotiveDetailRow(): MotiveDetailRow {
    return {
      id: this.id,
      name: this.name,
      isActive: this.isActive,
      size: convertFromBytesToKilobytes(this.size),
      devices: this.devices.join(LIST_SEPARATOR),
      contracts: this.contracts.join(LIST_SEPARATOR),
      clickUrls: this.clickUrls,
    };
  }
}
