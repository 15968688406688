
import { TemplateNames } from '../../../components/table';
import { ref } from 'vue';
import {
  DateCell,
  StatusCell,
  TextWithDescriptionCell,
  IconCell,
  SortableHeaderCell,
} from '../../../components/table/cellTemplates';
import UserListDropdownCell from './userListDropdownCell.vue';
import { userListTableHeaders } from '../application/userListTableHeaders';
import { useFetchUsers } from '../application/useFetchUsers';
import { useGetUserRows } from '../application/useGetUserRows';
import UserEmptyState from './userEmptyState.vue';
import InviteUsersModal from './inviteUsersModal.vue';
import ChangeUserRoleModal from './changeUserRoleModal.vue';

export default {
  components: {
    StatusCell,
    DateCell,
    TextWithDescriptionCell,
    IconCell,
    UserListDropdownCell,
    UserEmptyState,
    InviteUsersModal,
    ChangeUserRoleModal,
    SortableHeaderCell,
  },
  setup() {
    useFetchUsers({});
    const users = useGetUserRows();
    const searchedValue = ref('');

    const isInviteModalOpen = ref(false);
    const setIsInviteModalOpen = (value: boolean) => {
      isInviteModalOpen.value = value;
    };

    const selectedUserId = ref('');

    const isChangeUserRoleModalOpen = ref(false);
    const setIsChangeUserRoleModalOpen = (value: boolean) => {
      isChangeUserRoleModalOpen.value = value;
    };
    const onChangeUserRole = (value: string) => {
      selectedUserId.value = value;
      setIsChangeUserRoleModalOpen(true);
    };

    const onCreateNewUser = () => {
      setIsInviteModalOpen(true);
    };

    return {
      TemplateNames,
      headers: userListTableHeaders,
      users,
      onCreateNewUser,
      isInviteModalOpen,
      setIsInviteModalOpen,
      isChangeUserRoleModalOpen,
      setIsChangeUserRoleModalOpen,
      searchedValue,
      selectedUserId,
      onChangeUserRole,
    };
  },
};
