
import { computed, defineComponent, PropType, provide, watch } from 'vue';
import { FormatRaw } from '../../domain/format/formatRaw';
import { Motive } from '../../domain/motive/motive';
import { FormState } from '../../application/motive/editor/types';
import { FormatDefault } from '../../domain/format/formatDefault';
import { useEditFormatsForm } from '../../application/motive/forms/useEditFormatsForm';
import { EditFormatsFormKey } from '../../application/motive/forms/types';
import { useGetRawMotiveFormatsDefaultsInstances } from '@/features/campaigns/application/format/useGetRawMotiveFormatsDefaultsInstances';
import { useGetRawMotiveFormats } from '@/features/campaigns/application/format/useGetRawMotiveFormats';
import { deepCopyJson } from '@/core/helpers';

const updatePreviewFormatEvent = 'update:previewFormat';
const updatePreviewSettingsEvent = 'update:previewSettings';
const updateFormStateEvent = 'update:formState';

export default defineComponent({
  props: {
    motive: { type: Motive, required: true },
    formats: { type: Array as PropType<Array<FormatRaw>>, required: true },
    formatsDefaultsInstances: {
      type: Array as PropType<Array<FormatDefault>>,
      required: true,
    },
    selectedFormat: { type: String, required: true },
    formState: { type: Object, required: false },
  },
  emits: [
    updatePreviewFormatEvent,
    updatePreviewSettingsEvent,
    updateFormStateEvent,
  ],
  setup(props, context) {
    const formatsDefaultsInstances = useGetRawMotiveFormatsDefaultsInstances(
      props.motive.id,
    );
    const formats = useGetRawMotiveFormats(props.motive.id);
    const selectedFormatRef = computed(() => props.selectedFormat);

    const rawFormat = computed(() => {
      return props.selectedFormat
        ? formats.value.filter(
            format => format?.name === props.selectedFormat,
          )[0]
        : undefined;
    });

    const rawFormatDefaultInstance = computed(() => {
      return props.selectedFormat
        ? (formatsDefaultsInstances.value as FormatDefault[]).filter(
            (format: FormatDefault) => format?.name === props.selectedFormat,
          )[0]
        : FormatDefault.createIntersect(
            formatsDefaultsInstances.value,
            props.motive.name,
          );
    });

    const formData = useEditFormatsForm({
      motive: props.motive,
      format: props.motive.toFormat({
        rawFormat: deepCopyJson(rawFormat.value),
        rawFormatDefaultInstance: deepCopyJson(rawFormatDefaultInstance.value),
      }),
      campaignId: props.motive?.campaignId,
      initialState: props.formState as FormState,
      selectedFormat: selectedFormatRef,
    });

    provide(EditFormatsFormKey, formData);

    watch(
      [formData.asFormat],
      () => {
        context.emit(updatePreviewFormatEvent, formData.asFormat.value);
      },
      {
        immediate: true,
      },
    );

    watch(
      [formData.previewSettings],
      () => {
        context.emit(
          updatePreviewSettingsEvent,
          formData.previewSettings.value,
        );
      },
      {
        immediate: true,
      },
    );

    watch([formData.formState], () => {
      context.emit(updateFormStateEvent, formData.formState.value);
    });

    return {};
  },
});
