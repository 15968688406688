import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import store from '@/store';
import { Ref } from 'vue';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { fetchRawFormatsForMotiveIdSucceeded } from '@/features/campaigns/store/store';

const fetch = (facade: CampaignHttpFacade, motiveId: string) => async () => {
  const result = await facade.getRawFormatsForMotiveId(motiveId);
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchRawFormatsForMotiveId = ({
  motiveId,
  facade = new CampaignHttpFacade(),
}: {
  motiveId: string;
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade, motiveId),
    rawFormats => {
      store.dispatch(fetchRawFormatsForMotiveIdSucceeded, {
        motiveId,
        rawFormats,
      });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
