<template>
  <div :class="styleClasses">
    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.RICH_TEXT_EDITOR]="{
          placeholder,
          styleClasses,
          hasReset,
          initialFont,
          fontSize,
        }"
      >
        <rich-text-editor
          :id="`slide-${slideIndex}-interferer-${interfererIndex}-firstGraph`"
          :label="
            t('interferers.textLabel', {
              interfererNumber: t(`interferers.0`),
            })
          "
          :class="styleClasses || ''"
          :placeholder="placeholder"
          v-model="interfererByLabel.firstImage.text"
          :hasReset="hasReset"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.firstImage?.text
          "
          :initialFont="initialFont"
          :fontSize="fontSize"
        />
      </template>

      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
          disabled,
          hasSecondaryValue,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="interfererByLabel.firstImage[id]"
          :hasReset="hasReset"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.firstImage?.[id]
          "
          :disabled="disabled"
          :hasSecondaryValue="hasSecondaryValue"
          :second-value-provider="emToPixels"
        />
      </template>

      <template
        v-slot:[fieldTypes.MEDIA_PICKER]="{
          hasError,
          hasReset,
          styleClasses,
          labelStyleClasses,
        }"
      >
        <MediaPicker
          :class="styleClasses || ''"
          v-model="interfererByLabel.firstImage.content"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.firstImage?.content
          "
          :hasReset="hasReset"
          :hasError="hasError"
          :labelStyleClasses="labelStyleClasses"
          :label="
            t('interferers.graphicLabel', {
              interfererNumber: t(`interferers.0`),
            })
          "
          labelIcon="media"
          :buttonIcon="
            slides[slideId].interferers.length > 1 ? 'deleteGray' : undefined
          "
          @click:buttonIcon="
            removeSlideElement(
              slideId,
              SlideElementSimpleType.Interferer,
              interfererLabel,
            )
          "
        />
      </template>

      <template
        v-slot:[fieldTypes.OFFSET_PICKER]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="interfererByLabel.firstImage['position']['x']"
          v-model:vertical="interfererByLabel.firstImage['position']['y']"
          :label="label"
          :hasReset="hasReset"
          :initialHorizontal="
            resetValues.interferers?.[interfererIndex]?.firstImage?.[
              'position'
            ]?.['x']
          "
          :initialVertical="
            resetValues.interferers?.[interfererIndex]?.firstImage?.[
              'position'
            ]?.['y']
          "
        />
      </template>
    </FormGenerator>
    <div
      class="border-t border-gray-200 col-span-2"
      v-if="!interfererByLabel.hasSecondImage()"
    >
      <button
        @click="() => addSecondGraphic(slideId, interfererLabel)"
        class="flex flex-row items-center justify-center w-full p-2 focus:outline-none"
      >
        <app-icon
          name="plusCirclePrimary"
          alt="Add interferer graphic"
          class="w-iconXs"
        />
        <app-txt variant="small" class="text-primary pl-1"
          >Add second graphic</app-txt
        >
      </button>
    </div>

    <!-- SECOND GRAPH -->
    <FormGenerator v-if="interfererByLabel.hasSecondImage()" :fields="fields">
      <template
        v-slot:[fieldTypes.RICH_TEXT_EDITOR]="{
          id,
          placeholder,
          styleClasses,
          hasReset,
          initialFont,
          fontSize,
        }"
      >
        <rich-text-editor
          :id="`slide-${slideIndex}-interferer-${interfererIndex}-secondGraph`"
          :label="
            t('interferers.textLabel', {
              interfererNumber: t(`interferers.1`),
            })
          "
          :class="styleClasses || ''"
          :placeholder="placeholder"
          v-model="interfererByLabel.secondImage[id]"
          :hasReset="hasReset"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.secondImage?.[id]
          "
          :initialFont="initialFont"
          :fontSize="fontSize"
        />
      </template>

      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
          disabled,
          hasSecondaryValue,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="interfererByLabel.secondImage[id]"
          :hasReset="hasReset"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.secondImage?.[id]
          "
          :disabled="disabled"
          :hasSecondaryValue="hasSecondaryValue"
          :second-value-provider="emToPixels"
        />
      </template>

      <template
        v-slot:[fieldTypes.MEDIA_PICKER]="{
          id,
          hasError,
          hasReset,
          styleClasses,
          labelStyleClasses,
        }"
      >
        <MediaPicker
          :class="styleClasses || ''"
          v-model="interfererByLabel.secondImage[id]"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.secondImage?.[id]
          "
          :hasReset="hasReset"
          :hasError="hasError"
          :labelStyleClasses="labelStyleClasses"
          :label="
            t('interferers.graphicLabel', {
              interfererNumber: t(`interferers.1`),
            })
          "
          labelIcon="media"
          :buttonIcon="
            interfererByLabel.hasSecondImage() ? 'deleteGray' : undefined
          "
          @click:buttonIcon="removeSecondGraphic(slideId, interfererLabel)"
        />
      </template>

      <template
        v-slot:[fieldTypes.OFFSET_PICKER]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="interfererByLabel.secondImage['position']['x']"
          v-model:vertical="interfererByLabel.secondImage['position']['y']"
          :label="label"
          :hasReset="hasReset"
          :initialHorizontal="
            resetValues.interferers?.[interfererIndex]?.secondImage?.[
              'position'
            ]?.['x']
          "
          :initialVertical="
            resetValues.interferers?.[interfererIndex]?.secondImage?.[
              'position'
            ]?.['y']
          "
        />
      </template>
    </FormGenerator>

    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.SELECT]="{
          id,
          label,
          options,
          hasReset,
          styleClasses,
        }"
      >
        <app-select
          :class="styleClasses || ''"
          :label="label"
          :options="options"
          :modelValue="get(interfererByLabel, id)"
          @update:modelValue="value => updateTransitionOrAnimation(value, id)"
          :hasReset="hasReset"
          :initialModelValue="
            resetValues.interferers?.[interfererIndex]?.animation
          "
        />
      </template>

      <template
        v-slot:[fieldTypes.TRANSITION_ORIGIN]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="transitionOriginXModal"
          v-model:vertical="transitionOriginYModal"
          :label="label"
          :hasReset="hasReset"
        />
      </template>
    </FormGenerator>
  </div>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import {
  HorizontalAlign,
  InterfererAnimation,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { PropType, defineComponent, ref, computed, watch } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { Field, fieldTypes, FormGenerator } from '@/components/formGenerator';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import ConfirmResetModal from '../../confirmResetModal.vue';
import { SlideElementSimpleType } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import get from 'lodash.get';
import { emToPixels } from '@/utils/emToPixels';
import { SlideInterferer } from '@/features/campaigns/domain/valueObjects/blocks/slideInterferer';
import { TransitionType } from '@/features/campaigns/domain/valueObjects/preview/iPreviewTransition';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPicker,
    ConfirmResetModal,
  },
  props: {
    slideIndex: { type: Number, required: true },
    interfererIndex: { type: Number, required: true },
    fields: { type: Array as PropType<Field[]>, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    interfererLabel: { type: String, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');
    const {
      slides,
      slideOrder,
      getSlideResetValues,
      removeSlideElement,
      addSecondGraphic,
      removeSecondGraphic,
    } = injectStrict(EditFormatsFormKey);
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));
    const interfererByLabel = ref<SlideInterferer>(
      slides.value[slideId.value].interferers.find(
        interferer => interferer.label === props.interfererLabel,
      ) || ({} as SlideInterferer),
    );

    const updateTransitionOrAnimation = (
      value: InterfererAnimation | TransitionType,
      id: string,
    ) => {
      if (id.includes('animation')) {
        interfererByLabel.value.animation = value as InterfererAnimation;
      } else if (interfererByLabel.value.transition?.type) {
        interfererByLabel.value.transition.type = value;
      }
    };

    const transitionOriginXModal = ref<number>(
      interfererByLabel.value?.transition?.x || 0,
    );
    const transitionOriginYModal = ref<number>(
      interfererByLabel.value?.transition?.y || 0,
    );

    watch(transitionOriginXModal, () => {
      if (interfererByLabel.value && interfererByLabel.value.transition) {
        interfererByLabel.value.transition.x = transitionOriginXModal.value;
      }
    });
    watch(transitionOriginYModal, () => {
      if (interfererByLabel.value && interfererByLabel.value.transition) {
        interfererByLabel.value.transition.y = transitionOriginYModal.value;
      }
    });

    return {
      slideId,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      addSecondGraphic,
      removeSecondGraphic,
      t,
      SlideElementSimpleType,
      removeSlideElement,
      resetValues,
      resetModalOpen,
      onResetCallback,
      get,
      updateTransitionOrAnimation,
      emToPixels,
      interfererByLabel,
      transitionOriginXModal,
      transitionOriginYModal,
    };
  },
});
</script>
