import {
  CreateSlideTextParams,
  SlideText,
} from '@/features/campaigns/domain/valueObjects/blocks/slideText';
import {
  CreateSlideImageParams,
  SlideImage,
} from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import {
  CreateSlideInterfererParams,
  SlideInterferer,
} from '@/features/campaigns/domain/valueObjects/blocks/slideInterferer';
import {
  CreateSlidePriceParams,
  SlidePrice,
} from '@/features/campaigns/domain/valueObjects/blocks/slidePrice';
import { Layout } from '@/features/campaigns/domain/valueObjects';

export enum FormatDefaultType {
  DEVICE = 'device',
  PRODUCT = 'product',
}

export const MapLayoutToFormatDefaultType = new Map([
  [Layout.DEVICE, FormatDefaultType.DEVICE],
  [Layout.CONTRACT, FormatDefaultType.PRODUCT],
]);

type FormatStaticSlideParams = {
  texts: CreateSlideTextParams[];
  images: CreateSlideImageParams[];
  interferers: CreateSlideInterfererParams[];
  prices: CreateSlidePriceParams[];
};

export type FormatStaticCreationParams = {
  type: FormatDefaultType;
  formatSize: string;
  slides: FormatStaticSlideParams[];
};

class DefaultSlide {
  constructor(
    public texts: SlideText[],
    public images: SlideImage[],
    public interferers: SlideInterferer[],
    public prices: SlidePrice[],
  ) {}
}

export class FormatStaticDefault {
  private constructor(
    public type: FormatDefaultType,
    public formatSize: string,
    public slides: DefaultSlide[],
  ) {}

  private static createDefaultSlides(slide: FormatStaticSlideParams) {
    const texts = slide.texts.map(text => SlideText.create(text));
    const images = slide.images.map(image => SlideImage.create(image));
    const interferers = slide.interferers.map(interferer =>
      SlideInterferer.create(interferer),
    );
    const prices = slide.prices.map(price => SlidePrice.create(price));
    return new DefaultSlide(texts, images, interferers, prices);
  }

  static create(params: FormatStaticCreationParams): FormatStaticDefault {
    return new FormatStaticDefault(
      params.type,
      params.formatSize,
      params.slides.map(FormatStaticDefault.createDefaultSlides),
    );
  }

  isCompatibleWith(layout: Layout) {
    return MapLayoutToFormatDefaultType.get(layout) === this.type;
  }

  toJSON() {
    return {
      type: this.type,
      formatSize: this.formatSize,
      slides: this.slides.map(slide => ({
        texts: slide.texts.map(text => text.toJson()),
        images: slide.images.map(image => image.toJson()),
        interferers: slide.interferers.map(interferer => interferer.toJson()),
        prices: slide.prices.map(price => price.toJson()),
      })),
    };
  }
}
