import { Format } from '@/features/campaigns/domain/format/format';
import { CreateSlideForValuesParams } from '@/features/campaigns/domain/valueObjects/slide';
import { CreateVersion } from '@/features/campaigns/domain/valueObjects/version';
import { Ref } from 'vue';
import { useCreateMotiveVersion } from '../actions/useCreateMotiveVersion';
import { compareFormats } from '../utils/compareFormats';
import { useUpdateFormat } from '../../format/useUpdateFormat';
import { useUpdateFormatDefaults } from '../../format/useUpdateFormatDefaults';

export const useEditFormatsFunctions = ({
  slides,
  slideOrder,
}: {
  slides: Ref<Record<string, CreateSlideForValuesParams>>;
  slideOrder: Ref<string[]>;
}) => {
  const {
    isLoading: isLoadingCreateVersion,
    call: createVersionCall,
  } = useCreateMotiveVersion();

  const createMotiveVersion = async (params: CreateVersion) => {
    return await createVersionCall(params);
  };

  const {
    isLoading: isUpdateFormatLoading,
    call: updateFormatCall,
  } = useUpdateFormat();

  const updateFormat = async (initialFormat: Format) => {
    const updatedFormat = Format.createForValues({
      id: initialFormat.id,
      name: initialFormat.name,
      motiveId: initialFormat.motiveId,
      motiveName: initialFormat.motiveName,
      isActive: initialFormat.isActive,
      slides: slides.value,
      slideOrder: slideOrder.value,
    });

    const comparedFormat = compareFormats(initialFormat, updatedFormat);
    return await updateFormatCall(comparedFormat);
  };

  const {
    isLoading: isUpdateFormatDefaultsLoading,
    call: updateFormatDefaultsCall,
  } = useUpdateFormatDefaults();

  const updateFormatDefaults = async (initialFormat: Format) => {
    const updatedFormat = Format.createForValues({
      id: initialFormat.id,
      name: initialFormat.name,
      motiveId: initialFormat.motiveId,
      motiveName: initialFormat.motiveName,
      isActive: initialFormat.isActive,
      slides: slides.value,
      slideOrder: slideOrder.value,
    });

    const comparedFormat = compareFormats(initialFormat, updatedFormat);

    return await updateFormatDefaultsCall(comparedFormat);
  };

  return {
    isLoading:
      isUpdateFormatLoading ||
      isLoadingCreateVersion ||
      isUpdateFormatDefaultsLoading,
    createMotiveVersion,
    updateFormat,
    updateFormatDefaults,
  };
};
