
import { onUpdated, ref, defineComponent, PropType, Ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import EmailGroupInput from './emailGroupInput.vue';
import { useUpdateSyncEmailGroup } from '../application/useUpdateSyncEmailGroup';
import { isEmailValid } from '@/features/users/application/utils';

const DEL_KEY_CODE = 8;

export default defineComponent({
  components: {
    EmailGroupInput,
  },
  props: {
    isModalOpen: Boolean,
    initialEmails: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const emails = ref(props.initialEmails) as Ref<string[]>;
    const input = ref('');

    const { isLoading, call } = useUpdateSyncEmailGroup();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      input.value = '';
    };

    const onRemoveEmail = (index: number) => {
      emails.value.splice(index, 1);
    };

    const onInputChanged = (e: { target: HTMLInputElement }) => {
      input.value = e.target.value;
      if (
        input.value.endsWith(' ') &&
        isEmailValid( input.value.trim())
      ) {
        emails.value.push(input.value.trim());
        input.value = '';
      }
    };

    const onInputKeyDown = (event: any) => {
      const keyPressed = event.keyCode || event.charCode;
      if (keyPressed === DEL_KEY_CODE && input.value === '') {
        onRemoveEmail(emails.value.length - 1);
      }
    };

    const onConfirm = () => {
      if (isEmailValid(input.value.trim())) {
        // Add the last email that hasn't been added yet
        emails.value.push(input.value.trim());
      }
      call({ emails: emails.value });
      onCancel();
    };

    onUpdated(() => {
      emails.value = [...props.initialEmails];
    });

    return {
      isLoading,
      onCancel,
      onConfirm,
      emails,
      onRemoveEmail,
      input,
      onInputChanged,
      onInputKeyDown,
    };
  },
});
