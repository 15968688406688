
import { computed, defineComponent } from 'vue';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { AxisEvents } from '@/core/domain/events';
import TopLeftArrow from './icons/topLeftArrow.vue';
import TopArrow from './icons/topArrow.vue';
import TopRightArrow from './icons/topRightArrow.vue';
import CenterLeftArrow from './icons/centerLeftArrow.vue';
import Center from './icons/center.vue';
import CenterRightArrow from './icons/centerRightArrow.vue';
import BottomLeftArrow from './icons/bottomLeftArrow.vue';
import BottomArrow from './icons/bottomArrow.vue';
import BottomRightArrow from './icons/bottomRightArrow.vue';

const alignmentComponents = [
  {
    vertical: VerticalAlign.TOP,
    horizontal: HorizontalAlign.LEFT,
    icon: 'top-left-arrow',
  },
  {
    vertical: VerticalAlign.TOP,
    horizontal: HorizontalAlign.CENTER,
    icon: 'top-arrow',
  },
  {
    vertical: VerticalAlign.TOP,
    horizontal: HorizontalAlign.RIGHT,
    icon: 'top-right-arrow',
  },
  {
    vertical: VerticalAlign.CENTER,
    horizontal: HorizontalAlign.LEFT,
    icon: 'center-left-arrow',
  },
  {
    vertical: VerticalAlign.CENTER,
    horizontal: HorizontalAlign.CENTER,
    icon: 'center',
  },
  {
    vertical: VerticalAlign.CENTER,
    horizontal: HorizontalAlign.RIGHT,
    icon: 'center-right-arrow',
  },
  {
    vertical: VerticalAlign.BOTTOM,
    horizontal: HorizontalAlign.LEFT,
    icon: 'bottom-left-arrow',
  },
  {
    vertical: VerticalAlign.BOTTOM,
    horizontal: HorizontalAlign.CENTER,
    icon: 'bottom-arrow',
  },
  {
    vertical: VerticalAlign.BOTTOM,
    horizontal: HorizontalAlign.RIGHT,
    icon: 'bottom-right-arrow',
  },
];

export default defineComponent({
  name: 'alignment-picker',
  emits: [
    AxisEvents.UPDATE_VERTICAL,
    AxisEvents.UPDATE_HORIZONTAL,
  ],
  components: {
    TopLeftArrow,
    TopArrow,
    TopRightArrow,
    CenterLeftArrow,
    Center,
    CenterRightArrow,
    BottomLeftArrow,
    BottomArrow,
    BottomRightArrow,
  },
  props: {
    horizontal: { type: String, required: true },
    vertical: { type: String, required: true },
    label: String,
    hasReset: { type: Boolean, default: false },
    initialHorizontal: { type: String, required: false },
    initialVertical: { type: String, required: false },
  },
  setup(props, context) {
    const alignments = computed(() =>
      alignmentComponents.map(component => ({
        ...component,
        isSelected:
          component.vertical === props.vertical &&
          component.horizontal === props.horizontal,
      })),
    );

    const onClick = (vertical: VerticalAlign, horizontal: HorizontalAlign) => {
      context.emit(AxisEvents.UPDATE_VERTICAL, vertical);
      context.emit(AxisEvents.UPDATE_HORIZONTAL, horizontal);
    };

    const onReset = () => {
      context.emit(AxisEvents.UPDATE_VERTICAL, props.initialVertical);
      context.emit(AxisEvents.UPDATE_HORIZONTAL, props.initialHorizontal);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialHorizontal &&
        props.initialVertical &&
        (props.initialHorizontal !== props.horizontal ||
          props.initialVertical !== props.vertical)
      );
    });

    return {
      isResetVisible,
      onReset,
      onClick,
      alignments,
    };
  },
});
