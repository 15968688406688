export class Contract {
  constructor(
    public id: string,
    public title: string,
    public subtitle: string,
    public bullets: { description: string }[],
    public monthlyPrice: number,
    public oneTimePrice: number,
    public dataVolumeGb: number,
  ) {}

  static create(params: {
    id: string;
    title: string;
    subtitle: string;
    bullets: { description: string }[];
    monthlyPrice: number;
    oneTimePrice: number;
    dataVolumeGb: number;
  }) {
    return new Contract(
      params.id,
      params.title,
      params.subtitle,
      params.bullets,
      params.monthlyPrice,
      params.oneTimePrice,
      params.dataVolumeGb,
    );
  }

  static createForValues(params: {
    id: string;
    title: string;
    subtitle: string;
    bullets: { description: string }[];
    monthlyPrice: number;
    oneTimePrice: number;
    dataVolumeGb: number;
  }) {
    return new Contract(
      params.id,
      params.title,
      params.subtitle,
      params.bullets,
      params.monthlyPrice,
      params.oneTimePrice,
      params.dataVolumeGb,
    );
  }
}
