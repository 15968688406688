<template>
  <app-txt variant="small" class="text-gray-700 font-medium">{{
    $t('sync.emailGroup.emailGroupLabel')
  }}</app-txt>
  <div
    :class="{
      'flex flex-row items-center flex-wrap border border-gray-300 rounded-1 shadow-sm mt-1 w-full': true,
      'px-1.5 pb-1.5 pt-1': emails.length,
      'p-1.5': !emails.length,
    }"
  >
    <div
      class="flex flex-row bg-gray-100 rounded-8 items-center py-0.5 px-1 mr-1 mt-0.5 max-w-full"
      v-for="(email, index) in emails"
      :key="email"
    >
      <app-txt variant="small" class="text-gray-700 mr-1 truncate max-w-full">{{
        email
      }}</app-txt>
      <button class="focus:outline-none" @click="() => removeEmail(index)">
        <app-icon alt="close" name="closeCircleEmail" class="max-w-icon" />
      </button>
    </div>
    <input
      :class="{
        'flex-grow focus:outline-none active:outline-none h-inputSm': true,
        'mt-0.5': emails.length,
      }"
      :value="input"
      @input="$emit('input', $event)"
      @keydown="$emit('keydown', $event)"
      v-autowidth="{ maxWidth: '600px', minWidth: '20px', comfortZone: 0 }"
    />
  </div>
</template>

<script lang="ts">
import { SyncEvents } from './events';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  emits: [SyncEvents.REMOVE_EMAIL, 'input', 'keydown'],
  props: {
    emails: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    input: { type: String, required: true },
  },
  setup(props, context) {
    const removeEmail = (index: number) => {
      context.emit(SyncEvents.REMOVE_EMAIL, index);
    };

    return {
      removeEmail,
    };
  },
});
</script>
