
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../../events';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes } from '@/components/formGenerator';
import { CreateSlideForValuesParams } from '@/features/campaigns/domain/valueObjects/slide';
import ConfirmResetModal from '../confirmResetModal.vue';
import TextSettings from './elementsSettings/textSettings.vue';
import ImageSettings from './elementsSettings/imageSettings.vue';
import GeneralSettings from './elementsSettings/generalSettings.vue';
import PriceSettings from './elementsSettings/priceSettings.vue';
import AddMoreElements from './elementsSettings/addMoreElements.vue';
import InterfererSettings from './elementsSettings/interfererSettings.vue';
import { SlideElementSimpleType } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import { getSlideSettingsSections } from '@/features/campaigns/application/motive/forms/getSlideSettingsFields';

export default defineComponent({
  props: {
    isCompleted: { type: Boolean, required: false },
    hasSubmitted: { type: Boolean, default: false },
    hasSlidesControls: { type: Boolean, default: false },
    isGlobalEdit: { type: Boolean, default: false },
    defaultVisual: String,
  },
  components: {
    ConfirmResetModal,
    TextSettings,
    ImageSettings,
    GeneralSettings,
    PriceSettings,
    AddMoreElements,
    InterfererSettings,
  },
  emits: [
    CampaignEvents.COMPLETE_SECTION,
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
  ],
  setup(props) {
    const hasSubmitted = computed(() => props.hasSubmitted);
    const t = campaignsTranslateByScope('editMotive.slideSettings');
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const addMoreElementsActive = ref<boolean>(false);
    const {
      slides,
      slideOrder,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      openSlideIndex,
      openSlideSectionIndices,
      toggleSlide,
      toggleSlideSection,
      increaseElementZIndex,
      decreaseElementZIndex,
      moveSlideUp,
      moveSlideDown,
      layout,
      updateSlidesSectionState,
      formState,
      slideAllowAnimations,
      removeSlideElement,
      onContractChanged,
      onDeviceChanged,
    } = injectStrict(EditFormatsFormKey);

    const getFieldsForSlideType = (slide: CreateSlideForValuesParams) => {
      return getSlideSettingsSections({
        layout,
        hasSubmitted,
        images: slide.images,
        texts: slide.texts,
        prices: slide.prices,
        interferers: slide.interferers,
        onContractChanged,
        onDeviceChanged,
        isGlobalEdit: props.isGlobalEdit,
      });
    };

    const showMoreElements = () => {
      addMoreElementsActive.value = !addMoreElementsActive.value;
    };

    const removeSection = (type: SlideElementSimpleType, label: string) => {
      const slideId = slideOrder.value[openSlideIndex.value];
      removeSlideElement(slideId, type, label);
    };

    const lastIndex = computed(() => {
      const slideId = slideOrder.value[openSlideIndex.value];
      return getFieldsForSlideType(slides.value[slideId]).value.length - 1;
    });

    const recoverSlide = (slideId: string) => {
      slides.value[slideId].removed = false;
    };

    const recoverElement = (type: SlideElementSimpleType, label: string) => {
      const slideId = slideOrder.value[openSlideIndex.value];
      const slide = slides.value[slideId];
      let element = null;
      switch (type) {
        case SlideElementSimpleType.Text:
          element = slide.texts.find(text => text.label === label);
          break;
        case SlideElementSimpleType.Image:
          element = slide.images.find(image => image.label === label);
          break;
        case SlideElementSimpleType.Price:
          element = slide.prices.find(price => price.label === label);
          break;
        case SlideElementSimpleType.Interferer:
          element = slide.interferers.find(
            interferer => interferer.label === label,
          );
          break;
        default:
          break;
      }
      if (element) {
        element.removed = false;
      }
    };

    return {
      lastIndex,
      updateSlidesSectionState,
      formState,
      t,
      CampaignEvents,
      slides,
      slideOrder,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      getFieldsForSlideType,
      fieldTypes,
      openSlideIndex,
      openSlideSectionIndices,
      toggleSlide,
      toggleSlideSection,
      moveSlideUp,
      moveSlideDown,
      resetModalOpen,
      onResetCallback,
      slideAllowAnimations,
      addMoreElementsActive,
      showMoreElements,
      removeSection,
      moveElementUp: increaseElementZIndex,
      moveElementDown: decreaseElementZIndex,
      SlideElementSimpleType,
      recoverSlide,
      recoverElement,
    };
  },
});
