import { DateWrapper } from '@/core';
import { CreateCampaignFailure } from '@/features/campaigns/domain';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { createCampaignSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useCreateCampaign = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: {
      name: string;
      trackingParameter: string;
      startDate: DateWrapper;
      endDate: DateWrapper;
    }) => {
      const result = await facade.createCampaign(params);

      if (result.isRight()) {
        const campaign = result.extract();
        store.dispatch(createCampaignSucceeded, { campaign });
        toast.success(t('campaigns.useCreateCampaign.success'));
      } else {
        const error= result.extract() as CreateCampaignFailure;
      
        switch (error.status) {
          case 503:
            toast.error(t('common.errors.serviceUnavailable')); break;
          case 500:
            toast.error(t('common.errors.internalServer')); break;
          case 400:
            toast.error(t('common.errors.badRequest')); break;
          default:
            toast.error(t('campaigns.useCreateCampaign.error'));
        }
      }
    },
  );
};
