import { useStore } from '@/store';
import { computed } from 'vue';

export const useGetRawMotiveFormatsDefaultsInstances = (
  motiveId: string | undefined,
) => {
  const store = useStore();
  return computed(() =>
    motiveId ? store.getters.rawMotiveFormatsDefaultsInstances(motiveId) : [],
  );
};
