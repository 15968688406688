import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import store from '@/store';
import { Ref } from 'vue';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { fetchRawFormatsDefaultsInstancesForMotiveIdSucceeded } from '../../store/store';

const fetch = (facade: CampaignHttpFacade, motiveId: string) => async () => {
  const result = await facade.getRawFormatsDefaultsInstancesForMotiveId(
    motiveId,
  );
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchRawFormatsDefaultsInstancesForMotiveId = ({
  motiveId,
  facade = new CampaignHttpFacade(),
}: {
  motiveId: string;
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade, motiveId),
    rawFormatsDefaultsInstances => {
      store.dispatch(fetchRawFormatsDefaultsInstancesForMotiveIdSucceeded, {
        motiveId,
        rawFormatsDefaultsInstances,
      });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
