import { DateWrapper } from '@/core';
import { Ref, ComputedRef, InjectionKey } from 'vue';
import { useGetFormatsFormData } from './useGetFormatsFormData';

import { Format } from '@/features/campaigns/domain/format/format';
import { PreviewSettings } from '@/features/campaigns/domain/format/previewSettings';
import {
  SlideElementType,
  SlideElementSimpleType,
} from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { Slide } from '@/features/campaigns/domain/valueObjects/slide';
import { CreateVersion } from '@/features/campaigns/domain/valueObjects/version';
import { FormState } from '../editor/types';
import {
  Layout,
  MotiveGroupType,
} from '@/features/campaigns/domain/valueObjects';

export type CreateMotiveForm = {
  step: Ref<number>;
  isLoading: Ref<boolean>;
  onContinue: () => void;
  onBack: () => void;

  name: Ref<string>;
  description: Ref<string>;
  isDefaultMotive: Ref<boolean>;
  startDate: Ref<DateWrapper>;
  endDate: Ref<DateWrapper>;
  layout: Ref<Layout>;

  slides: Ref<Record<string, Slide>>;
  slideOrder: Ref<string[]>;
  onContractChanged: (slideId: string, contractId: string) => void;
  onDeviceChanged: (slideId: string, deviceId: string) => void;

  clickUrl: Ref<string>;

  isGeneralSectionValid: Ref<boolean>;
  isVariationsSectionValid: Ref<boolean>;
  isSlideSectionValid: Ref<boolean>;
  isValid: Ref<boolean>;
  errorMessageOnInvalidSlideSettings: Ref<string>;

  clearForm: () => void;
  createMotive: (campaignId: string) => Promise<void>;
  resetGeneralSettings: () => Promise<void>;

  isSubmitDisabled: ComputedRef<boolean>;
};

export type EditFormatsForm = {
  isLoading: Ref<boolean>;
  layout: Ref<Layout>;

  slides: Ref<Record<string, Slide>>;
  slideOrder: Ref<string[]>;
  addSlide: () => void;
  addSlideElement: (slideId: string, type: SlideElementType) => void;
  removeSlideElement: (
    slideId: string,
    type: SlideElementSimpleType,
    label: string,
  ) => void;
  addSecondGraphic: (slideId: string, label: string) => void;
  removeSecondGraphic: (slideId: string, label: string) => void;
  insertSlide: (index: number) => void;
  removeSlide: (index: number, isDefaultSlide: boolean) => void;
  onContractChanged: (slideId: string, contractId: string) => void;
  onDeviceChanged: (slideId: string, deviceId: string) => void;
  increaseElementZIndex: (slideId: string, zIndex: number) => void;
  decreaseElementZIndex: (slideId: string, zIndex: number) => void;

  openSlideIndex: Ref<number>;
  openSlideSectionIndices: Ref<number[]>;
  toggleSlide: (index: number) => void;
  toggleSlideSection: (index: number) => void;
  duplicateSlide: (slideId: string) => void;
  moveSlideUp: (index: number) => void;
  moveSlideDown: (index: number) => void;

  isVariationsSectionValid: Ref<boolean>;
  isSlideSectionValid: Ref<boolean>;
  isValid: Ref<boolean>;
  errorMessageOnInvalidSlideSettings: Ref<string>;
  hasChanges: Ref<boolean>;
  slideAllowAnimations: boolean;

  createMotiveVersion: (params: CreateVersion) => Promise<void>;
  updateFormat: (initialFormat: Format) => Promise<void>;
  updateFormatDefaults: (initial: Format) => Promise<void>;

  isSubmitDisabled: ComputedRef<boolean>;
  initialFormData: ReturnType<typeof useGetFormatsFormData>;
  getSlideResetValues: (slideId: string) => Slide;
  asFormat: ComputedRef<Format>;
  previewSettings: ComputedRef<PreviewSettings>;

  formState: ComputedRef<FormState>;
  updateSlidesSectionState: (isOpen: boolean) => void;
  updateOpenedSettings: (key: string) => void;
};

export type BulkCreateMotiveForm = {
  isLoading: Ref<boolean>;
  isBaseMotiveSectionOpen: Ref<boolean>;
  isBaseMotiveSectionDone: Ref<boolean>;
  baseMotiveId: Ref<string>;
  isSelectValid: Ref<boolean>;
  setIsSelectValid: (value: boolean) => void;
  motiveGroupName: Ref<string>;
  isBaseMotiveSectionValid: ComputedRef<boolean>;
  isSubmitDisabled: Ref<boolean>;
  motives: ComputedRef<{ value: string; label: string }[]>;
  clearForm: () => void;
  isVariationsSectionValid: ComputedRef<boolean>;
  isVariationsSectionOpen: Ref<boolean>;
  isVariationsSectionDone: Ref<boolean>;
  selectedVariation: Ref<MotiveGroupType | undefined>;
  setSelectedVariation: (value: MotiveGroupType) => void;
  contractId: Ref<string>;
  contracts: ComputedRef<{ value: string; label: string }[]>;
  isContractSelectValid: Ref<boolean>;
  setIsContractSelectValid: (value: boolean) => void;
  deviceId: Ref<string>;
  devices: ComputedRef<{ value: string; label: string }[]>;
  isDeviceSelectValid: Ref<boolean>;
  setIsDeviceSelectValid: (value: boolean) => void;
  baseMotiveLayout: ComputedRef<Layout | undefined>;
  onBulkCreateMotive: () => Promise<void>;
  isAutoUpdateSectionOpen: Ref<boolean>;
  isAutoUpdateOn: Ref<boolean>;
};

export const CreateMotiveFormKey: InjectionKey<CreateMotiveForm> = Symbol(
  'createMotiveForm',
);

export const EditFormatsFormKey: InjectionKey<EditFormatsForm> = Symbol(
  'editFormatsForm',
);

export const BulkCreateMotiveFormKey: InjectionKey<BulkCreateMotiveForm> = Symbol(
  'bulkCreateMotiveForm',
);
