import { MotiveDetailRow } from '@/features/campaigns/domain';
import { AVAILABLE_FORMATS } from '@/features/campaigns/domain/constants';
import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';

export const useGetMotiveDetailsRows = (
  motiveId: string,
): ComputedRef<MotiveDetailRow[]> => {
  const store = useStore();
  const motiveDetailsRows = computed(() => {
    const motive = store.getters.motives.find(motive => motive.id === motiveId);
    return store.getters
      .formatOverviewByMotiveId(motiveId)
      .filter(formatOverview => AVAILABLE_FORMATS.includes(formatOverview.name))
      .map(formatOverview => {
        const detailRow = formatOverview.toMotiveDetailRow();
        return {
          ...detailRow,
          description: motive?.description,
        };
      });
  });

  return motiveDetailsRows;
};
