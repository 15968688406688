import { AxiosService, HttpService } from '@/core';
import { devLog } from '@/utils/devLog';
import { Either, Left, Right } from 'purify-ts';
import {
  DownloadTemplateFailure,
  GetAllTemplateVersionsFailure,
  UpdateTemplateFailure,
} from '../domain';
import { Template } from '../domain/template';
import { TemplateDTO } from './template_dto';

const logError = (e: Error) => {
  devLog(`[SyncHttpFacade]: Failed with: ${e}`);
};

const endpoints = {
  getAllTemplateVersions: '/template-version/all',
  downloadTemplate: (version: string) =>
    `/template-version/download/${version}`,
  updateTemplate: (version: string) => `/template-version/update/${version}`,
};

export class TemplateHTTPFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async getAllTemplateVersions(): Promise<
    Either<GetAllTemplateVersionsFailure, Template[]>
  > {
    try {
      const response = await this.httpService.get(
        endpoints.getAllTemplateVersions,
      );
      const templateVersions = response.data.map(
        (version: Record<string, any>) => TemplateDTO.toDomain(version),
      );
      return Right(templateVersions);
    } catch (e) {
      logError(e);
      return Left(new GetAllTemplateVersionsFailure(e, e.code));
    }
  }
  async downloadTemplate(
    version: string,
  ): Promise<Either<DownloadTemplateFailure, Blob>> {
    try {
      const response = await this.httpService.get(
        endpoints.downloadTemplate(version),
        { responseType: 'blob' },
      );
      return Right(response.data);
    } catch (e) {
      logError(e);
      return Left(new DownloadTemplateFailure(e, e.code));
    }
  }

  async updateTemplate(
    template: Template,
  ): Promise<Either<UpdateTemplateFailure, Template>> {
    try {
      const response = await this.httpService.put(
        endpoints.updateTemplate(template.version.getValue()),
        TemplateDTO.toJSON(template),
      );

      const updatedTemplate = TemplateDTO.toDomain(response.data);
      return Right(updatedTemplate);
    } catch (e) {
      logError(e);
      return Left(new UpdateTemplateFailure(e, e.code));
    }
  }
}
