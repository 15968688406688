import { User } from '../domain/user';

export class UserDTO {
  static toDomain(json: { [key: string]: any }): User {
    return User.create({
      id: json.id,
      firstName: json.firstName,
      lastName: json.lastName,
      email: json.email,
      userRole: json.userRole,
      createdAt: json.createdAt,
      status: json.status,
      enabledNotifications: json.enabledNotifications,
    });
  }

  static toJson(user: User): { [key: string]: any } {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userRole: user.userRole.getOrEmpty(),
      createdAt: user.createdAt.getOrEmpty()?.toString(),
      status: user.status.getOrEmpty(),
      enabledNotifications: user.enabledNotifications,
    };
  }
}
