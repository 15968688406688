import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { Template } from '../domain/template';
import { TemplateHTTPFacade } from '../infraestructure/template_http_facade';
import { updateTemplateVersionSucceeded } from '../store/store';
import { t } from '@/services/i18n';
import { UpdateTemplateFailure } from '../domain';

export const useUpdateTemplate = (facade = new TemplateHTTPFacade()) => {
  const store = useStore();
  const toast = useToast();
  return useMutation(async (template: Template) => {
    const result = await facade.updateTemplate(template);
    if (result.isRight()) {
      const updatedTemplate = result.extract();
      store.dispatch(updateTemplateVersionSucceeded, {
        updatedTemplate,
      });
      toast.success(t('templates.successUpdate'));
    }
    if (result.isLeft()) {
      const error= result.extract() as UpdateTemplateFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('templates.errorUpdate'));
      }
    }
  });
};
