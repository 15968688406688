import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from '@/store';
import { Ref } from 'vue';
import { fetchFormatsForMotiveIdSucceeded } from '@/features/campaigns/store/store';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';

const fetch = (facade: CampaignHttpFacade, motiveId: string) => async () => {
  const result = await facade.getFormatsForMotiveId(motiveId);
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchFormatsForMotiveId = ({
  motiveId,
  facade = new CampaignHttpFacade(),
}: {
  motiveId: string;
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade, motiveId),
    formatOverviews => {
      store.dispatch(fetchFormatsForMotiveIdSucceeded, {
        motiveId,
        formatOverviews,
      });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
