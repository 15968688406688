
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../events';
import Section from './section.vue';
import { campaignsTranslateByScope } from '../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { useGetContracts } from '@/features/campaigns/application/contract/useGetContracts';
import { useGetDevices } from '@/features/campaigns/application/device/useGetDevices';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { CreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';

export default defineComponent({
  props: {
    disableAddRemoveSlides: { type: Boolean, default: false },
    isProductDeviceChangeAllowed: { type: Boolean, default: true },
    onBack: { type: Function, required: false },
  },
  components: {
    Section,
  },
  setup() {
    const hasSubmitted = ref(false);
    const t = campaignsTranslateByScope('createMotive.variations');

    const {
      layout,
      slides,
      slideOrder,
      onContractChanged,
      onDeviceChanged,
    } = injectStrict(CreateMotiveFormKey);

    const { contracts } = useGetContracts();
    const contractOptions = computed(() =>
      contracts.value.map(contract => ({
        value: contract.id,
        label: `${contract.id} ${contract.title}`,
      })),
    );

    const { devices } = useGetDevices();
    const deviceOptions = computed(() =>
      devices.value.map(device => ({
        value: device.id,
        label: `${device.id} ${device.title}`,
      })),
    );

    const showDeviceSelect = computed(() => layout.value === Layout.DEVICE);

    return {
      t,
      hasSubmitted,
      showDeviceSelect,
      slides,
      slideOrder,
      layout,
      CampaignEvents,
      contractOptions,
      onContractChanged,
      deviceOptions,
      onDeviceChanged,
    };
  },
});
