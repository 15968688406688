import { deepCopyJson } from '@/core/helpers';
import { Contract } from '../domain/contract';
import { Device } from '../domain/device';
import { CampaignDTO } from '../infrastructure/campaign_dto';
import { ContractDTO } from '../infrastructure/contract_dto';
import { DeviceDTO } from '../infrastructure/device-dto';
import { FormatDTO } from '../infrastructure/format_dto';
import { FormatOverviewDTO } from '../infrastructure/format_overview_dto';
import { FormatRawDTO } from '../infrastructure/format_raw_dto';
import { MotiveGroupDTO } from '../infrastructure/motiveGroup_dto';
import { MotiveDTO } from '../infrastructure/motive_dto';
import { VersionDTO } from '../infrastructure/version_dto';
import { CampaignState } from './store';
import { FormatDefaultDTO } from '../infrastructure/format_default_dto';
import {
  FormatStaticCreationParams,
  FormatStaticDefault,
} from '@/features/campaigns/domain/format/formatStaticDefault';

export const getters = {
  campaigns: (state: CampaignState) => {
    return state.campaigns.map((campaign: Record<string, any>) =>
      CampaignDTO.toDomain(campaign),
    );
  },
  motives: (state: CampaignState) => {
    return state.motives.map((motive: Record<string, any>) =>
      MotiveDTO.toDomain(motive),
    );
  },
  userPreferences: (state: CampaignState) => {
    return state.userPreferences;
  },
  campaignById: (state: CampaignState) => (id: string) => {
    const campaign = state.campaigns.find(
      (campaign: Record<string, any>) => campaign.id === id,
    );
    return campaign ? CampaignDTO.toDomain(campaign) : undefined;
  },
  contracts: (state: CampaignState) => {
    return state.contracts.map(contract => ContractDTO.toDomain(contract));
  },
  contractsById: (state: CampaignState) => () => {
    const contracts = state.contracts.map((contract: Record<string, any>) =>
      ContractDTO.toDomain(contract),
    );

    return contracts.reduce((map, current) => {
      if (current?.id) {
        map[current.id] = current;
      }

      return map;
    }, {} as Record<string, Contract>);
  },
  contractById: (state: CampaignState) => (id: string) => {
    const contract = state.contracts.find(
      (contract: Record<string, any>) => contract.id === id,
    );
    return contract ? ContractDTO.toDomain(contract) : undefined;
  },
  devices: (state: CampaignState) => {
    return state.devices.map(device => DeviceDTO.toDomain(device));
  },
  devicesById: (state: CampaignState) => () => {
    const devices = state.devices.map((device: Record<string, any>) =>
      DeviceDTO.toDomain(device),
    );

    return devices.reduce((map, current) => {
      if (current?.id) {
        map[current.id] = current;
      }

      return map;
    }, {} as Record<string, Device>);
  },
  deviceById: (state: CampaignState) => (id: string) => {
    const device = state.devices.find(
      (device: Record<string, any>) => device.id === id,
    );
    return device ? DeviceDTO.toDomain(device) : undefined;
  },
  motivesByCampaignId: (state: CampaignState) => (campaignId: string) => {
    return (
      state.motivesByCampaignId[campaignId]?.map(motive =>
        MotiveDTO.toDomain(motive),
      ) ?? []
    );
  },
  formatOverviewByMotiveId: (state: CampaignState) => (motiveId: string) => {
    return (
      state.formatOverviewByMotiveId[motiveId]?.map(formatOverview =>
        FormatOverviewDTO.toDomain(formatOverview),
      ) ?? []
    );
  },
  motiveById: (state: CampaignState) => (motiveId: string) => {
    for (const motives of Object.values(state.motivesByCampaignId)) {
      const wantedMotive = motives.find(motive => {
        return motive.id === motiveId;
      });
      if (wantedMotive) {
        return MotiveDTO.toDomain(wantedMotive);
      }
    }
  },
  motiveWithCampaignNameById: (state: CampaignState) => (motiveId: string) => {
    const motive = state.motives.find(
      (motive: Record<string, any>) => motive.id === motiveId,
    );
    if (motive) {
      const campaign = state.campaigns.find(
        (campaign: Record<string, any>) => campaign.id === motive.campaignId,
      );
      return {
        motiveName: motive.name,
        campaignName: campaign?.name,
        campaignId: campaign?.id,
      };
    }
  },
  formatById: (state: CampaignState) => (id: string) => {
    const format = state.formats.find(
      (format: Record<string, any>) => format.id === id,
    );
    return format ? FormatDTO.toDomain(format) : undefined;
  },
  motiveGroupsByCampaignId: (state: CampaignState) => (campaignId: string) => {
    return state.motiveGroups
      .filter(groupJson => groupJson.campaignId === campaignId)
      .map(motiveGroup => MotiveGroupDTO.toDomain(motiveGroup));
  },
  rawMotiveFormats: (state: CampaignState) => (motiveId: string) => {
    return (
      state.rawFormats
        ?.filter?.(rawFormat => rawFormat.motiveId === motiveId)
        .map(rawFormat => FormatRawDTO.toDomain(deepCopyJson(rawFormat))) || []
    );
  },
  rawMotiveFormatsDefaultsInstances: (state: CampaignState) => (
    motiveId: string,
  ) => {
    return (
      state.rawFormatsDefaultsInstances
        ?.filter?.(
          rawFormatDefaultInstance =>
            rawFormatDefaultInstance.motiveId === motiveId,
        )
        .map(rawFormatDefaultInstance =>
          FormatDefaultDTO.toDomain(deepCopyJson(rawFormatDefaultInstance)),
        ) || []
    );
  },
  motive: (state: CampaignState) => () => {
    if (state.motive) {
      return MotiveDTO.toDomain(state.motive);
    }
  },
  motiveVersions: (state: CampaignState) => () => {
    if (state.motiveVersions) {
      return state.motiveVersions.map(v => VersionDTO.toDomain(v));
    }
  },
  formatStaticDefaults: (state: CampaignState) => () => {
    return state.formatStaticDefaults.map(format => {
      return FormatStaticDefault.create(format as FormatStaticCreationParams);
    });
  },
};
