
import { defineComponent } from 'vue';
import { CampaignEvents } from '../events';

export default defineComponent({
  emits: [CampaignEvents.BULK_EDIT],
  props: {
    header: { type: Object, required: true },
    showBulkEdit: { type: Boolean, required: true },
  },
  setup(_, context) {
    const onBulkEdit = () => {
      context.emit(CampaignEvents.BULK_EDIT);
    };
    return { onBulkEdit };
  },
});
