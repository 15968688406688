<template>
  <Section
    :title="t('title')"
    :isSectionOpen="true"
    :isCompleted="false"
    :onButtonClick="onBack"
  >
    <div
      v-for="(slideId, index) in slideOrder"
      :key="index"
      :class="[
          'pt-3 pb-4 border-gray-200 pr-3 ml-3 md:pr-5 md:ml-5',
          index != 1 && 'border-b',
        ]"
    >
      <div class="flex flex-row justify-between items-center mb-3">
        <div class="flex flex-row items-center ">
          <app-icon alt="Slide" name="slide" />
          <app-txt variant="small" class="text-black font-semibold ml-1.5">{{
            t('slideTitle', { slideNb: index + 1 })
          }}</app-txt>
        </div>
      </div>

      <div class="flex flex-row">
        <app-searchable-select
          class="w-full md:w-modalInputWidth mr-5"
          :placeholder="t('contractPlaceholder')"
          :label="t('contractLabel')"
          :options="contractOptions"
          :modelValue="slides[slideId].contractId"
          @update:modelValue="value => onContractChanged(slideId, value)"
          :errorMessage="
            hasSubmitted && !slides[slideId].contractId
              ? t('emptyContractError')
              : null
          "
          :disabled="!isProductDeviceChangeAllowed"
        />

        <app-searchable-select
          v-if="showDeviceSelect"
          class="w-full md:w-modalInputWidth "
          :placeholder="t('devicePlaceholder')"
          :label="t('deviceLabel')"
          :options="deviceOptions"
          :modelValue="slides[slideId].deviceId"
          @update:modelValue="value => onDeviceChanged(slideId, value)"
          :errorMessage="
            hasSubmitted && !slides[slideId].deviceId
              ? t('emptyDeviceError')
              : null
          "
          :disabled="!isProductDeviceChangeAllowed"
        />
      </div>
    </div>
  </Section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../events';
import Section from './section.vue';
import { campaignsTranslateByScope } from '../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { useGetContracts } from '@/features/campaigns/application/contract/useGetContracts';
import { useGetDevices } from '@/features/campaigns/application/device/useGetDevices';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { CreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';

export default defineComponent({
  props: {
    disableAddRemoveSlides: { type: Boolean, default: false },
    isProductDeviceChangeAllowed: { type: Boolean, default: true },
    onBack: { type: Function, required: false },
  },
  components: {
    Section,
  },
  setup() {
    const hasSubmitted = ref(false);
    const t = campaignsTranslateByScope('createMotive.variations');

    const {
      layout,
      slides,
      slideOrder,
      onContractChanged,
      onDeviceChanged,
    } = injectStrict(CreateMotiveFormKey);

    const { contracts } = useGetContracts();
    const contractOptions = computed(() =>
      contracts.value.map(contract => ({
        value: contract.id,
        label: `${contract.id} ${contract.title}`,
      })),
    );

    const { devices } = useGetDevices();
    const deviceOptions = computed(() =>
      devices.value.map(device => ({
        value: device.id,
        label: `${device.id} ${device.title}`,
      })),
    );

    const showDeviceSelect = computed(() => layout.value === Layout.DEVICE);

    return {
      t,
      hasSubmitted,
      showDeviceSelect,
      slides,
      slideOrder,
      layout,
      CampaignEvents,
      contractOptions,
      onContractChanged,
      deviceOptions,
      onDeviceChanged,
    };
  },
});
</script>
