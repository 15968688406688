import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from '@/store';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import {
  FormatStaticCreationParams,
  FormatStaticDefault,
} from '@/features/campaigns/domain/format/formatStaticDefault';
import { fetchFormatStaticDefaultsSucceeded } from '@/features/campaigns/store/store';

const fetch = async () => {
  const facade = new CampaignHttpFacade();
  const result = await facade.fetchFormatStaticDefault();
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchFormatStaticDefaults = () => {
  const store = useStore();
  const onSuccess = (result: FormatStaticCreationParams[] | undefined) => {
    if (!result) return;
    const defaults = result.map(FormatStaticDefault.create);
    store.dispatch(fetchFormatStaticDefaultsSucceeded, {
      formatStaticDefaults: defaults,
    });
  };
  const { isLoading } = useRequestWithLoading(fetch, onSuccess);
  return {
    isLoading,
  };
};
