import { computed, Ref, ref } from 'vue';
import { EditFormatsForm } from './types';
import { useManageSections } from './useManageSections';
import { useHandleFieldsPrefill } from './useHandleFieldsPrefill';
import { useEditFormatsFunctions } from './useEditFormatsFunctions';
import { useHandleSlides } from './useHandleSlides';
import { deepCopyJson } from '@/core/helpers';
import { useGetFormatsFormData } from './useGetFormatsFormData';
import { Format } from '@/features/campaigns/domain/format/format';
import { PreviewSettings } from '@/features/campaigns/domain/format/previewSettings';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import {
  CreateSlideParams,
  DEFAULT_SLIDE_DURATION_IN_MS,
  Slide,
} from '@/features/campaigns/domain/valueObjects/slide';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useGetDevicesById } from '../../device/useGetDevicesById';
import { FormState } from '../editor/types';
import { useGetRawMotiveFormatsDefaultsInstances } from '../../format/useGetRawMotiveFormatsDefaultsInstances';
import { generateDefaultSlideParams } from './defaults/defaultSlide';
import { useGetFormatStaticDefaults } from '@/features/campaigns/application/format/useGetFormatStaticDefaults';

export const useEditFormatsForm = (params: {
  motive: Motive;
  format: Format;
  campaignId?: string;
  initialState?: FormState;
  selectedFormat: Ref<string>;
}): EditFormatsForm => {
  const devicesById = useGetDevicesById();
  const formatsDefaultsInstances = useGetRawMotiveFormatsDefaultsInstances(
    params.motive?.id,
  );
  const formatStaticDefaults = useGetFormatStaticDefaults();

  const formatDefaultSlides: Ref<Record<string, CreateSlideParams>> = computed(
    () => {
      if (params.format && !params.selectedFormat.value) {
        return params.format.slides ?? {};
      } else {
        return (
          formatsDefaultsInstances.value.find(
            formatDefaultInstance =>
              formatDefaultInstance.name === params.selectedFormat.value,
          )?.slides ?? {}
        );
      }
    },
  );

  const t = campaignsTranslateByScope('editMotive');

  const motiveCopy = Motive.create(deepCopyJson(params.motive?.toJson()));
  const formatCopy = Format.create(deepCopyJson(params.format?.toJson()));

  const manageSectionProps = useManageSections();
  const sectionsState = ref({
    slidesSectionExpanded: true,
  });

  const initialFormData = useGetFormatsFormData(motiveCopy, formatCopy);
  const { slides, layout, slideOrder } = useGetFormatsFormData(
    motiveCopy,
    formatCopy,
  );

  const { onContractChanged, onDeviceChanged } = useHandleFieldsPrefill({
    slideOrder,
    slides,
    layout,
    devicesById,
    defaultSlides: formatDefaultSlides,
  });

  const handleSlidesProps = useHandleSlides(
    slideOrder,
    initialFormData.slideOrder,
    slides,
    initialFormData.slides,
    formatDefaultSlides,
    params.initialState?.slides,
  );

  const isVariationsSectionValid = computed(() => {
    return slideOrder.value.every(id => {
      if (!slides.value[id].contractId) return false;
      return layout.value === Layout.CONTRACT || slides.value[id].deviceId;
    });
  });
  const isSlideSectionValid = computed(() => slideOrder.value.length > 0);

  const isSubmitDisabled = computed(
    () => !isVariationsSectionValid.value || !isSlideSectionValid.value,
  );

  const asFormat = computed(() => {
    let id = formatCopy?.id;

    if (!id) {
      id = '';
    }

    return Format.createForValues({
      id,
      name: formatCopy.name,
      isActive: false,
      motiveId: motiveCopy.id,
      motiveName: motiveCopy.name,
      slides: slides.value,
      slideOrder: slideOrder.value,
    });
  });

  const isValid = computed(() => {
    return handleSlidesProps.isValid.value && isVariationsSectionValid.value;
  });

  const errorMessageOnInvalidSlideSettings = computed(() => {
    if (!isVariationsSectionValid.value) {
      return t('invalidSettingsError.variationSection');
    }
    return t('invalidSettingsError.generic');
  });

  const hasChanges = computed(() => {
    return handleSlidesProps.slidesHaveChanges.value;
  });

  const {
    isLoading,
    createMotiveVersion,
    updateFormat,
    updateFormatDefaults,
  } = useEditFormatsFunctions({
    slideOrder,
    slides,
  });

  const previewSettings = computed<PreviewSettings>(() => {
    const keepSpecifics = !params.selectedFormat.value;

    let selectedSlideId: string | undefined =
      slideOrder.value[handleSlidesProps.openSlideIndex.value];

    if (!selectedSlideId || !slides.value[selectedSlideId]) {
      selectedSlideId = undefined;
    }

    return {
      selectedSlideId,
      keepSpecifics,
    };
  });

  const updateSlidesSectionState = (isOpen: boolean) => {
    sectionsState.value = {
      slidesSectionExpanded: isOpen,
    };
  };

  const updateOpenedSettings = (key: string) => {
    const slideKeyRegex = /slide.([a-zA-Z0-9-]+).(general|visual|cta|price)/;
    const slideMatch = key.match(slideKeyRegex);

    if (slideMatch) {
      updateSlidesSectionState(true);
      handleSlidesProps.openSettings(slideMatch[1], slideMatch[2]);
    }
  };

  const formState = computed<FormState>(() => {
    return {
      slidesSectionExpanded: sectionsState.value.slidesSectionExpanded,
      slides: handleSlidesProps.slidesFormState.value,
    };
  });

  const handleGlobalReset = (
    slideId: string,
    defaultSlideParams: CreateSlideParams,
  ) => {
    const slideIndex = slideOrder.value.findIndex(id => id === slideId);
    const [format] = formatStaticDefaults.value.filter(format =>
      format.isCompatibleWith(params.motive.layout),
    );
    const hasPrice = defaultSlideParams?.prices?.length > 0;
    if (slideIndex === 0 && !hasPrice) {
      return Slide.create({
        id: slideId,
        contractId: '',
        deviceId: '',
        clickUrl: params.motive.clickUrl,
        duration: DEFAULT_SLIDE_DURATION_IN_MS,
        ...format.slides[0],
      });
    }
    return Slide.create({
      id: slideId,
      contractId: '',
      deviceId: '',
      clickUrl: params.motive.clickUrl,
      duration: DEFAULT_SLIDE_DURATION_IN_MS,
      ...format.slides[1],
    });
  };

  const getSlideResetValues = (slideId: string) => {
    let defaultSlideParams = formatDefaultSlides?.value?.[slideId];
    const isGlobalReset = !params.selectedFormat.value;
    if (isGlobalReset) {
      return handleGlobalReset(slideId, defaultSlideParams);
    }
    if (!defaultSlideParams) {
      const defaultSlidesArr = Object.values(formatDefaultSlides?.value ?? {});
      const defaultLastSlide = defaultSlidesArr[defaultSlidesArr.length - 1];
      defaultSlideParams = defaultLastSlide
        ? deepCopyJson(defaultLastSlide)
        : generateDefaultSlideParams();
    }
    return Slide.create(defaultSlideParams);
  };

  return {
    ...manageSectionProps,
    layout,
    isLoading,
    slides,
    slideOrder,
    onContractChanged,
    onDeviceChanged,
    ...handleSlidesProps,
    isVariationsSectionValid,
    isSlideSectionValid,
    createMotiveVersion,
    updateFormat,
    updateFormatDefaults,
    isSubmitDisabled,
    initialFormData,
    asFormat,
    isValid,
    errorMessageOnInvalidSlideSettings,
    hasChanges,
    previewSettings,
    formState,
    updateSlidesSectionState,
    updateOpenedSettings,
    getSlideResetValues,
  };
};
