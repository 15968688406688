
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { CoreEvents } from '@/core/domain/events';
import Beak from './assets/beak.svg';
import {
  getDisplayedValue,
  getOffsetChange,
  getOffsetProgress,
  getProgress,
  getValue,
} from './utils';
import AppTxt from '@/components/typography/typography.vue';

const knobSize = 24;

export default defineComponent({
  name: 'slider',
  components: { AppTxt },
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    from: { type: Number, required: true },
    to: { type: Number, required: true },
    unit: { type: String, required: true },
    multiplier: { type: Number, required: true },
    tooltipPrecision: { type: Number, required: true },
    valuePrecision: { type: Number, required: true },
    label: String,
    disabled: { type: Boolean, default: false },
    modelValue: { type: Number, required: true },
    hasReset: { type: Boolean, default: false },
    initialModelValue: { type: Number, required: false },
    hasSecondaryValue: { type: Boolean, default: false },
    secondValueProvider: { type: Function, required: false },
  },
  setup(props, context) {
    const progress = ref(getProgress(props.modelValue, props));
    const dragging = ref(false);
    const dragStart = ref(0);
    const dragCurrent = ref(0);
    const displayedValue = ref(getDisplayedValue(progress.value, props));
    const sliderRef = ref<HTMLElement | null>(null);
    const tooltipRef = ref<HTMLElement | null>(null);
    const hasReset = ref(false);

    const onDragStart = (clientX: number) => {
      if (clientX) {
        dragStart.value = clientX;
        dragCurrent.value = clientX;
        dragging.value = true;
      }
    };

    const onMouseDragStart = (event: MouseEvent) => {
      onDragStart(event.clientX);
    };

    const onFingerDragStart = (event: TouchEvent) => {
      const touch = event.touches[0] || event.changedTouches[0];
      onDragStart(touch?.clientX);
    };

    const onDrag = (clientX: number) => {
      if (dragging.value && clientX) {
        dragCurrent.value = clientX;

        const offsetChange = getOffsetChange(
          dragCurrent.value,
          dragStart.value,
          sliderRef?.value?.clientWidth || 0,
        );

        progress.value = getOffsetProgress(
          offsetChange,
          getProgress(props.modelValue, props),
        );
        displayedValue.value = getDisplayedValue(progress.value, props);
      }
    };

    const onMouseDrag = (event: MouseEvent) => {
      onDrag(event.clientX);
    };

    const onFingerDrag = (event: TouchEvent) => {
      const touch = event.touches[0] || event.changedTouches[0];
      onDrag(touch.clientX);
    };

    const onDragEnd = () => {
      if (dragging.value) {
        dragging.value = false;
        context.emit(
          CoreEvents.UPDATE_MODEL_VALUE,
          getValue(progress.value, props),
        );
      }
    };

    const barWidth = computed(() => `${progress.value}%`);
    const tooltipValue = computed(() =>
      getDisplayedValue(progress.value, props),
    );
    const tooltipOffset = computed(
      () => `-${(tooltipRef?.value?.clientWidth || 0) / 2}px`,
    );

    const onInput = (value: number) => {
      displayedValue.value = value;

      progress.value = getProgress(value / props.multiplier, props);
      context.emit(
        CoreEvents.UPDATE_MODEL_VALUE,
        getValue(progress.value, props),
      );
    };

    const onReset = () => {
      hasReset.value = true;
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue !== undefined &&
        props.initialModelValue !== props.modelValue
      );
    });

    watch([props], () => {
      if (hasReset.value) {
        progress.value = getProgress(props.modelValue, props);
        displayedValue.value = getDisplayedValue(progress.value, props);
        hasReset.value = false;
      }
    });

    onMounted(() => {
      window.addEventListener('mousemove', onMouseDrag);
      window.addEventListener('mouseup', onDragEnd);
      window.addEventListener('touchmove', onFingerDrag);
      window.addEventListener('touchend', onDragEnd);
    });

    onUnmounted(() => {
      window.removeEventListener('mousemove', onMouseDrag);
      window.removeEventListener('mouseup', onDragEnd);
      window.removeEventListener('touchmove', onFingerDrag);
      window.removeEventListener('touchend', onDragEnd);
    });

    const calculatedSecondaryValue = computed(() => {
      return props.hasSecondaryValue
        ? props.secondValueProvider?.(getValue(progress.value, props))
        : '';
    });

    return {
      isResetVisible,
      tooltipValue,
      displayedValue,
      progress,
      onInput,
      tooltipOffset,
      knobSize,
      tooltipRef,
      sliderRef,
      barWidth,
      onMouseDragStart,
      onFingerDragStart,
      onReset,
      Beak,
      calculatedSecondaryValue,
    };
  },
});
