
import { computed, defineComponent, ref, watch } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import ConfirmResetModal from '../../confirmResetModal.vue';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import { emToPixels } from '@/utils/emToPixels';
import { SlidePrice } from '@/features/campaigns/domain/valueObjects/blocks/slidePrice';

export default defineComponent({
  components: {
    FormGenerator,
    ConfirmResetModal,
  },
  props: {
    fields: { type: Array, required: true },
    slideIndex: { type: Number, required: true },
    priceIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    priceLabel: { type: String, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { slides, slideOrder, getSlideResetValues } = injectStrict(
      EditFormatsFormKey,
    );
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));

    const priceByLabel = ref<SlidePrice>(
      slides.value[slideId.value].prices.find(
        (price: SlidePrice) => price.label === props.priceLabel,
      ) || ({} as SlidePrice),
    );

    const transitionOriginXModal = ref<number>(
      priceByLabel.value?.transition?.x || 0,
    );
    const transitionOriginYModal = ref<number>(
      priceByLabel.value?.transition?.y || 0,
    );

    watch(transitionOriginXModal, () => {
      if (priceByLabel.value && priceByLabel.value.transition) {
        priceByLabel.value.transition.x = transitionOriginXModal.value;
      }
    });
    watch(transitionOriginYModal, () => {
      if (priceByLabel.value && priceByLabel.value.transition) {
        priceByLabel.value.transition.y = transitionOriginYModal.value;
      }
    });

    return {
      priceByLabel,
      slideId,
      fieldTypes,
      slides,
      t,
      resetValues,
      resetModalOpen,
      onResetCallback,
      emToPixels,
      transitionOriginXModal,
      transitionOriginYModal,
    };
  },
});
