
import { campaignsTranslateByScope } from '../translationScope';
import FormatPreview from './formatPreview.vue';
import { useGetFormatFilter } from '@/features/campaigns/application/format/useGetFormatFilter';
import { useRoute } from 'vue-router';
import { useGetBreadcrumbs } from '../../application/motive/utils/useGetBreadcrumbs';
import { useFetchRawFormatsForMotiveId } from '../../application/format/useFetchRawFormatsForMotiveId';
import { useGetPreviewsByFormatName } from '../../application/motive/details/useGetPreviewsByFormatName';
import { useFetchMotiveById } from '../../application/motive/actions/useFetchMotiveById';
import { useGetMotive } from '../../application/motive/actions/useGetMotive';
import { useGetAssetsById } from '@/features/media/application/useGetAssetsById';
import { useGetDevicesById } from '../../application/device/useGetDevicesById';
import { useGetContractsById } from '../../application/contract/useGetContractsById';
import { useGetCampaignById } from '../../application/campaign/actions/useGetCampaignById';
import { FORMATS_LAYOUT } from '../../domain/constants';
import { useFetchRawFormatsDefaultsInstancesForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsDefaultInstanceForMotiveId';
import { computed, ref } from 'vue';

export default {
  components: {
    FormatPreview,
  },
  setup() {
    const t = campaignsTranslateByScope('previewMotive');

    const route = useRoute();
    const motiveId = route.query.motiveId as string;
    const breadcrumbs = useGetBreadcrumbs(motiveId);

    const {
      isLoading: defaultsLoading,
    } = useFetchRawFormatsDefaultsInstancesForMotiveId({ motiveId });
    const { isLoading: motiveLoading } = useFetchMotiveById({ motiveId });
    const { isLoading: rawFormatsLoading } = useFetchRawFormatsForMotiveId({
      motiveId,
    });
    useFetchRawFormatsDefaultsInstancesForMotiveId({
      motiveId,
    });
    useFetchMotiveById({ motiveId });
    const isComponentLoading = computed(() => {
      return (
        defaultsLoading.value || motiveLoading.value || rawFormatsLoading.value
      );
    });
    const imagesById = useGetAssetsById();
    const devicesById = useGetDevicesById();
    const contractsById = useGetContractsById();
    const motive = useGetMotive();
    const { campaign } = useGetCampaignById(motive.value.campaignId);
    const previewsByFormatName = useGetPreviewsByFormatName(
      motive,
      ref(undefined),
      imagesById,
      devicesById,
      contractsById,
      campaign.value && campaign.value.trackingParameter,
    );

    const formatFilterOptions = FORMATS_LAYOUT.reduce(
      (previous, formats) => [
        ...previous,
        ...formats.map(format => ({
          value: `${format.width}x${format.height}`,
          label: `${format.width}x${format.height}`,
        })),
      ],
      [{ value: '', label: t('allFormatsFilterLabel') }],
    );

    const selectedFormat = useGetFormatFilter();
    const ACTIVE_FORMATS_LAYOUT_PREVIEW = computed(() => {
      return FORMATS_LAYOUT.map(formats => {
        return formats.map(format => ({
          ...format,
          isActive:
            previewsByFormatName.value[`${format.width}x${format.height}`]
              ?.format.isActive,
        }));
      });
    });
    return {
      isComponentLoading,
      t,
      formatFilterOptions,
      selectedFormat,
      ACTIVE_FORMATS_LAYOUT_PREVIEW,
      previewsByFormatName,
      breadcrumbs,
      motive,
    };
  },
};
