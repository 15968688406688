
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useResetPassword } from '../../application/useResetPassword';
import { isPasswordValid } from '@/features/users/application/utils';
export default {
  setup() {
    const route = useRoute();
    const token = route.query.token;
    const password = ref('');
    const confirmPassword = ref('');
    const hasSubmitted = ref(false);
    const { call, isLoading } = useResetPassword();

    const onSubmit = () => {
      hasSubmitted.value = true;
      if (
        confirmPassword.value === password.value &&
        isPasswordValid(password.value)
      ) {
        call({
          password: password.value,
          confirmPassword: confirmPassword.value,
          token: token as string,
        });
      }
    };
    return {
      onSubmit,
      hasSubmitted,
      isLoading,
      password,
      confirmPassword,
      token,
      isPasswordValid
    };
  },
};
