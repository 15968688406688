import { Asset } from '@/features/media/domain/asset';
import { CSSInlineStyle } from '../preview/iPreviewAttributes';
import {
  PreviewInterfererImage,
  PreviewInterferer,
} from '../preview/previewInterferer';
import { PreviewSlideContent } from '../preview/previewSlideContent';
import { CreateSlideElementParams, SlideElement } from './slideElement';
import {
  InterfererImage,
  interfererImageParams,
} from './interferer/interfererImage';
import { PreviewTransition } from '../preview/iPreviewTransition';
import { getVisualInfoForFormat } from './helpers/slideImageHelpers';
import { InterfererAnimation } from '..';

export type CreateSlideInterfererParams = CreateSlideElementParams & {
  firstImage?: interfererImageParams;
  secondImage?: interfererImageParams;
  animation?: InterfererAnimation;
  transition?: PreviewTransition | undefined;
};

export type CreateSlideInterfererForValuesParams = CreateSlideElementParams & {
  firstImage?: InterfererImage;
  secondImage?: InterfererImage;
  animation?: InterfererAnimation;
  transition?: PreviewTransition | undefined;
  removed?: boolean;
  isDefault?: boolean;
};

const defaultLabel = 'Interferer';

export class SlideInterferer extends SlideElement {
  public firstImage?: InterfererImage;
  public secondImage?: InterfererImage;
  public animation?: InterfererAnimation;
  public transition?: PreviewTransition;

  private constructor(params: CreateSlideInterfererForValuesParams) {
    super(params.label, params.zIndex, params.removed);
    this.firstImage = params.firstImage;
    this.secondImage = params.secondImage;
    this.animation = params.animation;
    this.transition = params.transition;
    this.isDefault = params.isDefault;
  }

  static create(params: CreateSlideInterfererParams): SlideInterferer {
    return new SlideInterferer({
      label: params.label ?? defaultLabel,
      zIndex: params.zIndex,
      removed: params.removed,
      firstImage:
        params.firstImage && InterfererImage.create(params.firstImage),
      secondImage:
        params.secondImage && InterfererImage.create(params.secondImage),
      animation: params.animation,
      transition: params.transition,
      isDefault: params.isDefault,
    });
  }

  static createForValues(params: CreateSlideInterfererForValuesParams) {
    return new SlideInterferer({
      label: params.label,
      zIndex: params.zIndex,
      removed: params.removed,
      firstImage: params.firstImage,
      secondImage: params.secondImage,
      animation: params.animation,
      transition: params.transition,
      isDefault: params.isDefault,
    });
  }

  hasSecondImage(): boolean {
    return !!this.secondImage;
  }

  addSecondImage(params?: interfererImageParams) {
    const defaultParams = {
      content: '',
      position: {
        x: this.firstImage?.position?.x ?? null,
        y: this.firstImage?.position?.y ?? null,
      },
      scaling: this.firstImage?.scaling,
      text: '',
      textSize: 1,
    };
    this.secondImage = InterfererImage.create({ ...defaultParams, ...params });
  }
  removeSecondImage() {
    this.secondImage = undefined;
  }

  public toPreviewContent({
    formatName,
    imagesById,
  }: {
    formatName: string;
    imagesById: Record<string, Asset>;
  }): PreviewSlideContent {
    const firstImageVisualInfo = getVisualInfoForFormat({
      formatName: formatName,
      imagesById: imagesById,
      square: this.firstImage?.content,
      vertical: this.firstImage?.content,
      horizontal: this.firstImage?.content,
    });
    const secondImageVisualInfo = getVisualInfoForFormat({
      formatName: formatName,
      imagesById: imagesById,
      square: this.secondImage?.content,
      vertical: this.secondImage?.content,
      horizontal: this.secondImage?.content,
    });

    const firstImage = PreviewInterfererImage.create({
      content: firstImageVisualInfo.visualUrl,
      caption: {
        content: this.firstImage?.text,
      },
    });

    const firstImageStyle = new CSSInlineStyle()
      .addPosition(this.firstImage?.position?.toJson())
      .addFontSize(this.firstImage?.textSize)
      .addWidth(this.firstImage?.scaling)
      .addZIndex(this.zIndex)
      .build();

    const firstPreviewInterferer = PreviewInterferer.create({
      attributes: {
        style: firstImageStyle,
      },
      content: [firstImage],
      transition: this.transition,
    });

    if (this.secondImage) {
      const secondImage = PreviewInterfererImage.create({
        content: secondImageVisualInfo.visualUrl,
        caption: {
          content: this.secondImage?.text,
        },
      });

      const secondImageStyle = new CSSInlineStyle()
        .addPosition(
          this.secondImage.position?.toJson().x === undefined &&
            this.secondImage.position?.toJson().y === undefined
            ? this.firstImage?.position?.toJson()
            : this.secondImage.position?.toJson(),
        )
        .addFontSize(this.secondImage.textSize)
        .addWidth(this.secondImage.scaling ?? this.firstImage?.scaling)
        .addZIndex(this.zIndex)
        .build();

      const secondPreviewInterferer = PreviewInterferer.create({
        attributes: {
          style: secondImageStyle,
        },
        content: [secondImage],
        transition: {
          type: this.animation || 'flip',
        },
      });

      return PreviewSlideContent.createAsInterferer({
        content: [firstPreviewInterferer, secondPreviewInterferer],
      });
    }

    return PreviewSlideContent.createAsInterferer({
      content: [firstPreviewInterferer],
    });
  }

  public toJson() {
    return {
      label: this.label,
      firstImage: this.firstImage?.toJson(),
      secondImage: this.secondImage?.toJson(),
      animation: this.animation,
      transition: this.transition,
      zIndex: this.zIndex,
      removed: this.removed,
      isDefault: this.isDefault,
    };
  }
}
