import { DeactivateMotiveFailure } from '@/features/campaigns/domain';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { updateMotiveSucceeded } from '@/features/campaigns/store/store';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export const useDeactivateMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();
  const router = useRouter();
  return useMutation(async (motiveId: string) => {
    const result = await facade.deactivateMotive(motiveId);

    if (result.isRight()) {
      const motive = result.extract();
      store.dispatch(updateMotiveSucceeded, { motive });
      toast.success(t('campaigns.useDeactivateMotive.success'));
      setTimeout(() => {
        router.go(0);
      }, 1000);
    } else {
      const error = result.extract() as DeactivateMotiveFailure;

      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable'));
          break;
        case 500:
          toast.error(t('common.errors.internalServer'));
          break;
        case 400:
          toast.error(t('common.errors.badRequest'));
          break;
        default:
          toast.error(t('campaigns.useDeactivateMotive.error'));
      }
    }
  });
};
