import { DateWrapper } from '@/core';
import { Layout } from '../valueObjects';
import { CampaignDetailsRow, MotiveStatus } from '..';
import { FormatRaw } from '../format/formatRaw';
import { Format } from '../format/format';
import { FormatDefault } from '../format/formatDefault';
import {
  CreateSlideParams,
  CreateSlideForValuesParams,
  Slide,
} from '../valueObjects/slide';

export class Motive {
  public uniqueClickUrls: string[] = [];
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public status: MotiveStatus,
    public isDefaultMotive: boolean,
    public startDate: DateWrapper,
    public endDate: DateWrapper,
    public layout: Layout,
    public clickUrl: string,
    public campaignId: string,
    public isActive: boolean,
    public templateVersion: string,
    public motiveGroupId?: string,
    public campaignName?: string,
    public slides?: Slide[],
  ) {}

  static create(params: {
    id: string;
    name: string;
    isDefaultMotive: boolean;
    status: MotiveStatus;
    startDate: string;
    endDate: string;
    layout: Layout;
    clickUrl: string;
    campaignId: string;
    isActive: boolean;
    templateVersion: string;
    motiveGroupId?: string;
    description: string;
    campaignName?: string;
    slides?: Slide[];
  }) {
    return new Motive(
      params.id,
      params.name,
      params.description,
      params.status,
      params.isDefaultMotive,
      DateWrapper.create(params.startDate),
      DateWrapper.create(params.endDate),
      params.layout,
      params.clickUrl,
      params.campaignId,
      params.isActive,
      params.templateVersion,
      params.motiveGroupId,
      params.campaignName,
      params.slides,
    );
  }

  static createForValues(params: {
    id: string;
    name: string;
    isDefaultMotive: boolean;
    status: MotiveStatus;
    startDate: DateWrapper;
    endDate: DateWrapper;
    layout: Layout;
    clickUrl: string;
    slides: Slide[];
    campaignId: string;
    isActive: boolean;
    templateVersion: string;
    motiveGroupId?: string;
    description: string;
    campaignName?: string;
  }) {
    return new Motive(
      params.id,
      params.name,
      params.description,
      params.status,
      params.isDefaultMotive,
      params.startDate,
      params.endDate,
      params.layout,
      params.clickUrl,
      params.campaignId,
      params.isActive,
      params.templateVersion,
      params.motiveGroupId,
      params.campaignName,
      params.slides,
    );
  }

  toOverviewRow(): CampaignDetailsRow {
    return {
      id: this.id,
      motiveGroupName: '',
      name: this.name,
      campaignId: this.campaignId,
      campaignName: this.campaignName,
      clickUrl: this.clickUrl,
      description: this.description,
      startDate: this.startDate,
      endDate: this.endDate,
      isDefaultMotive: this.isDefaultMotive,
      templateVersion: this.templateVersion,
      status: this.status,
      uniqueClickUrls: this.uniqueClickUrls,
    };
  }

  toCampaignDetailsRow(
    motiveGroupsMapping: Record<string, string>,
  ): CampaignDetailsRow {
    return {
      id: this.id,
      motiveGroupName: motiveGroupsMapping[this.motiveGroupId ?? ''] ?? '',
      name: this.name,
      clickUrl: this.clickUrl,
      description: this.description,
      startDate: this.startDate,
      endDate: this.endDate,
      isDefaultMotive: this.isDefaultMotive,
      templateVersion: this.templateVersion,
      status: this.status,
      uniqueClickUrls: this.uniqueClickUrls,
    };
  }

  isInsideActiveDates(): boolean {
    const now = new Date();
    return this.startDate.getValue() <= now && now <= this.endDate.getValue();
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      isDefaultMotive: this.isDefaultMotive,
      startDate: this.startDate.getValue().toString(),
      endDate: this.endDate.getValue().toString(),
      layout: this.layout,
      clickUrl: this.clickUrl,
      campaignId: this.campaignId,
      isActive: this.isActive,
      templateVersion: this.templateVersion,
      motiveGroupId: this.motiveGroupId,
      slides: this.slides,
    };
  }

  toFormat({
    rawFormat,
    rawFormatDefaultInstance,
  }: {
    rawFormat?: FormatRaw;
    rawFormatDefaultInstance: FormatDefault;
  }): Format {
    const slideIds = Object.entries(rawFormat?.slides || {}).reduce(
      (ids, [slideId, slide]) => {
        if (!slide) {
          // Default slide is deleted
          ids = ids.filter(id => id !== slideId);
        } else if (!ids.includes(slideId)) {
          // Slide was added to format
          ids.push(slideId);
        }

        return ids;
      },
      Object.keys(rawFormatDefaultInstance.slides || {}),
    );

    const mergedFormat = FormatDefault.merge(
      rawFormatDefaultInstance,
      rawFormat,
    );

    const slides = Object.fromEntries(
      slideIds.map(slideId => {
        return [
          slideId,
          {
            id: mergedFormat.slides?.[slideId]?.id,
            contractId: mergedFormat.slides?.[slideId]?.contractId,
            deviceId: mergedFormat.slides?.[slideId]?.deviceId,
            clickUrl: mergedFormat.slides?.[slideId]?.clickUrl,
            duration: mergedFormat.slides?.[slideId]?.duration,
            interferers: mergedFormat.slides?.[slideId]?.interferers,
            texts: mergedFormat.slides?.[slideId]?.texts,
            prices: mergedFormat.slides?.[slideId]?.prices,
            images: mergedFormat.slides?.[slideId]?.images,
            removed: mergedFormat.slides?.[slideId]?.removed,
            isDefault: mergedFormat.slides?.[slideId]?.isDefault,
          } as CreateSlideParams,
        ];
      }),
    );

    return Format.create({
      id: rawFormat?.id ?? rawFormatDefaultInstance.id,
      name: rawFormat?.name ?? rawFormatDefaultInstance.name,
      motiveId: this.id,
      motiveName: this.name,
      isActive: rawFormat?.isActive ?? rawFormatDefaultInstance.isActive,
      slides,
      slideOrder: mergedFormat.slideOrder,
    });
  }
}
