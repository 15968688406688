<template>
  <Section
    :isSectionOpen="isSectionOpen"
    @update:isSectionOpen="$emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, $event)"
    :title="t('variations')"
    :isCompleted="isCompleted"
  >
    <div class="pr-3 ml-3 md:pr-5 md:ml-5 border-t border-gray-200 pt-3">
      <button
        v-if="baseMotiveLayout !== Layout.CONTRACT"
        :class="{
          'w-full p-3 mb-2 border border-gray-300 shadow-sm rounded-1 focus:outline-none text-left': true,
          'bg-blue-50 border-blue-400':
            selectedVariation === MotiveGroupType.ONE_CONTRACT,
        }"
        @click="() => setSelectedVariation(MotiveGroupType.ONE_CONTRACT)"
      >
        <div class="flex flex-row items-center">
          <app-icon alt="Choose 1 contract" name="contract" />
          <app-txt variant="small" class="ml-3 text-gray-700 font-medium ">
            {{ t('oneContractOption') }}
          </app-txt>
        </div>

        <app-searchable-select
          v-if="selectedVariation === MotiveGroupType.ONE_CONTRACT"
          class="w-full mt-3"
          :options="contracts"
          v-model="contractId"
          :placeholder="t('contractPlaceholder')"
          @update:isValid="setIsContractSelectValid"
          :errorMessage="
            hasSubmitted && !isContractSelectValid
              ? t('emptyContractError')
              : null
          "
        />
      </button>

      <button
        v-if="baseMotiveLayout !== Layout.CONTRACT"
        :class="{
          'w-full p-3 mb-2 border border-gray-300 shadow-sm rounded-1 focus:outline-none text-left': true,
          'bg-blue-50 border-blue-400':
            selectedVariation === MotiveGroupType.ONE_DEVICE,
        }"
        @click="() => setSelectedVariation(MotiveGroupType.ONE_DEVICE)"
      >
        <div class="flex flex-row items-center">
          <app-icon alt="Choose 1 device" name="device" />
          <app-txt variant="small" class="ml-3 text-gray-700 font-medium ">
            {{ t('oneDeviceOption') }}
          </app-txt>
        </div>

        <app-searchable-select
          v-if="selectedVariation === MotiveGroupType.ONE_DEVICE"
          class="w-full mt-3"
          :options="devices"
          v-model="deviceId"
          :placeholder="t('devicePlaceholder')"
          @update:isValid="setIsDeviceSelectValid"
          :errorMessage="
            hasSubmitted && !isDeviceSelectValid ? t('emptyDeviceError') : null
          "
        />
      </button>

      <button
        :class="{
          'w-full p-3 mb-2 flex flex-row items-center border border-gray-300 shadow-sm rounded-1 focus:outline-none': true,
          'bg-blue-50 border-blue-400':
            selectedVariation === MotiveGroupType.ALL_POSSIBILITIES,
        }"
        @click="() => setSelectedVariation(MotiveGroupType.ALL_POSSIBILITIES)"
      >
        <app-icon alt="Create all possibilities" name="fileText" />
        <app-txt variant="small" class="ml-3 text-gray-700 font-medium ">
          {{ t('allPossibilitiesOption') }}
        </app-txt>
      </button>
    </div>
    <div class="laptop-only px-3 md:px-5">
      <div class="flex justify-end mt-3 mb-2">
        <app-button @click="onSectionDone">{{ t('done') }}</app-button>
      </div>
    </div>
  </Section>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '../../translationScope';
import { defineComponent, ref } from 'vue';
import Section from '../createMotiveModal/section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { CampaignEvents } from '../../events';
import { MotiveGroupType } from '@/features/campaigns/domain/valueObjects';
import { Layout } from '../../../domain/valueObjects';
import { BulkCreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';

export default defineComponent({
  components: {
    Section,
  },
  props: {
    isSectionOpen: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true },
  },
  emits: [
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
    CampaignEvents.COMPLETE_SECTION,
  ],
  setup(props, context) {
    const t = campaignsTranslateByScope('bulkMotiveCreation');
    const hasSubmitted = ref(false);
    const {
      isVariationsSectionValid,
      selectedVariation,
      setSelectedVariation,
      contractId,
      contracts,
      isContractSelectValid,
      setIsContractSelectValid,
      deviceId,
      devices,
      isDeviceSelectValid,
      setIsDeviceSelectValid,
      baseMotiveLayout,
    } = injectStrict(BulkCreateMotiveFormKey);

    const onSectionDone = () => {
      hasSubmitted.value = true;
      if (isVariationsSectionValid.value) {
        hasSubmitted.value = false;
        context.emit(CampaignEvents.COMPLETE_SECTION);
      }
    };

    return {
      t,
      CampaignEvents,
      hasSubmitted,
      onSectionDone,
      selectedVariation,
      MotiveGroupType,
      setSelectedVariation,
      contractId,
      contracts,
      isContractSelectValid,
      setIsContractSelectValid,
      deviceId,
      devices,
      isDeviceSelectValid,
      setIsDeviceSelectValid,
      Layout,
      baseMotiveLayout,
    };
  },
});
</script>