import { Sync } from '../domain/sync';
import { SyncDTO } from '../infrastructure/sync_dto';

export interface SyncState {
  syncList: Record<string, any>[];
  syncEmailGroup: string[];
}

export const fetchSyncListSucceededMutation = 'fetchSyncListSucceededMutation';
export const fetchSyncListSucceeded = 'fetchSyncListSucceeded';
export const fetchSyncEmailGroupSucceededMutation =
  'fetchSyncEmailGroupSucceededMutation';
export const fetchSyncEmailGroupSucceeded = 'fetchSyncEmailGroupSucceeded';
export const updateSyncEmailGroupSucceededMutation =
  'updateSyncEmailGroupSucceededMutation';
export const updateSyncEmailGroupSucceeded = 'updateSyncEmailGroupSucceeded';

export const initialState: SyncState = {
  syncList: [],
  syncEmailGroup: [],
};

export const getters = {
  syncList: (state: SyncState) => {
    return state.syncList.map((sync: Record<string, any>) =>
      SyncDTO.toDomain(sync),
    );
  },
  syncEmailGroup: (state: SyncState) => {
    return state.syncEmailGroup.map((email: string) => email);
  },
};

export const mutations = {
  [fetchSyncListSucceededMutation](
    state: SyncState,
    args: { syncList: Sync[] },
  ) {
    state.syncList = args.syncList.map((sync: Sync) => SyncDTO.toJson(sync));
  },
  [fetchSyncEmailGroupSucceededMutation](
    state: SyncState,
    args: { syncEmailGroup: string[] },
  ) {
    state.syncEmailGroup = args.syncEmailGroup;
  },
  [updateSyncEmailGroupSucceededMutation](
    state: SyncState,
    args: { syncEmailGroup: string[] },
  ) {
    state.syncEmailGroup = args.syncEmailGroup.map((email: string) => email);
  },
};

export const actions = {
  [fetchSyncListSucceeded](
    { commit }: { commit: any },
    args: { syncList: Sync[] },
  ) {
    commit(fetchSyncListSucceededMutation, {
      syncList: args.syncList,
    });
  },
  [fetchSyncEmailGroupSucceeded](
    { commit }: { commit: any },
    args: { syncEmailGroup: string[] },
  ) {
    commit(fetchSyncEmailGroupSucceededMutation, {
      syncEmailGroup: args.syncEmailGroup,
    });
  },
  [updateSyncEmailGroupSucceeded](
    { commit }: { commit: any },
    args: { syncEmailGroup: string[] },
  ) {
    commit(updateSyncEmailGroupSucceededMutation, {
      syncEmailGroup: args.syncEmailGroup,
    });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
