import * as uuid from 'uuid';
import {
  CreateSlideForValuesParams,
  CreateSlideParams,
} from '@/features/campaigns/domain/valueObjects/slide';
import {
  generateDefaultTextForValuesParams,
  generateDefaultTextParams,
} from './elements/defaultTexts';
import {
  generateDefaultPriceForValuesParams,
  generateDefaultPriceParams,
} from './elements/defaultPrices';
import {
  generateDefaultInterfererForValuesParams,
  generateDefaultInterfererParams,
} from './elements/defaultInterferer';
import {
  generateDefaultImageForValuesParams,
  generateDefaultImageParams,
} from './elements/defaultImages';

export const defaultClickUrl = 'https://www.congstar.de/portal/';

export const generateDefaultSlideParams = (): CreateSlideParams => ({
  id: uuid.v4(),
  contractId: '',
  deviceId: '',
  clickUrl: '',
  duration: 5000,
  interferers: [generateDefaultInterfererParams({ label: 'Interferer' })],
  texts: [
    generateDefaultTextParams({ label: 'Headline' }),
    generateDefaultTextParams({ label: 'Subline' }),
  ],
  images: [
    generateDefaultImageParams({ label: 'Visual' }),
    generateDefaultImageParams({ label: 'CTA' }),
    generateDefaultImageParams({ label: 'Logo' }),
  ],
  prices: [generateDefaultPriceParams({ label: 'Price' })],
  removed: false,
  isDefault: false,
});

export const generateDefaultSlideForValuesParams = (): CreateSlideForValuesParams => ({
  id: uuid.v4(),
  contractId: '',
  deviceId: '',
  clickUrl: '',
  duration: 5000,
  interferers: [
    generateDefaultInterfererForValuesParams({ label: 'Interferer' }),
  ],
  texts: [
    generateDefaultTextForValuesParams({ label: 'Headline' }),
    generateDefaultTextForValuesParams({ label: 'Subline' }),
  ],
  images: [
    generateDefaultImageForValuesParams({ label: 'Visual' }),
    generateDefaultImageForValuesParams({ label: 'CTA' }),
    generateDefaultImageForValuesParams({ label: 'Logo' }),
  ],
  prices: [generateDefaultPriceForValuesParams({ label: 'Price' })],
  removed: false,
  isDefault: false,
});
