import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UserHttpFacade } from '@/features/users/infrastructure/user_http_facade';
import { EnabledNotifications, UpdateEnabledNotificationsFailure } from '@/features/users/domain';
import { updateUserNotificationsSucceeded } from '@/features/session/store/store';

export const useUpdateEnabledNotifications = (
  facade: UserHttpFacade = new UserHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: {
      id: string;
      enabledNotifications: EnabledNotifications;
    }) => {
      const result = await facade.updateEnabledNotifications(params.id, {
        motivesNotifications: params.enabledNotifications.motivesNotifications,
        motivesEditingNotifications:
          params.enabledNotifications.motivesEditingNotifications,
        motivesDeactivationNotifications:
          params.enabledNotifications.motivesDeactivationNotifications,
        campaignsNotifications:
          params.enabledNotifications.campaignsNotifications,
      });

      if (result.isRight()) {
        const user = result.extract();
        store.dispatch(updateUserNotificationsSucceeded, { user });
        toast.success(t('users.useUpdateEnabledNotifications.success'));
      } else {
        const error= result.extract() as UpdateEnabledNotificationsFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('users.useUpdateEnabledNotifications.error'));
      }
      }
    },
  );
};
