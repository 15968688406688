<template>
  <app-layout>
    <div class="flex flex-col md:mx-4">
      <!-- Laptop only -->
      <div class="laptop-only">
        <div class="flex flex-row justify-between items-center cursor-pointer">
          <app-txt variant="h1" class="text-white">{{
            $t('overview.title')
          }}</app-txt>
          <div class="flex-row flex items-center cursor-pointer">
            <dropdown-wrapper class="top-17 right-18">
              <template #trigger="{ isOpen }">
                <div
                  class="flex text-primary-700 font-medium !text-small cursor-pointer bg-black rounded"
                >
                  {{ $t('overview.filter.filterBy') }}
                  {{
                    selectedStatus === ''
                      ? $t('overview.filter.status')
                      : selectedStatus === 'deactivated_by_feed'
                      ? $t(`overview.filter.deactivatedByFeed`)
                      : $t(`overview.filter.${selectedStatus}`)
                  }}
                  <app-icon
                    :name="isOpen ? 'arrowUpBlue' : 'arrowDownBlue'"
                    alt="sort icon"
                    class="pl-1"
                  />
                </div>
              </template>
              <template #dropdown>
                <dropdown-item
                  v-for="option in statusOptions"
                  :key="option.value"
                  @click="updateSelectedStatus(option.value)"
                >
                  {{ option.label }}
                </dropdown-item>
              </template>
            </dropdown-wrapper>
            <app-button
              leftIcon="exportIcon"
              @click="setIsModalOpen(true)"
              class="ml-3 px-3"
              >{{ $t('overview.exportButton') }}</app-button
            >
          </div>
        </div>
      </div>

      <!-- Mobile only -->

      <div class="mobile-only">
        <div class="mx-2">
          <app-txt variant="h3" class="text-white">{{
            $t('overview.title')
          }}</app-txt>
        </div>
      </div>

      <app-table
        :headers="headers"
        :rows="motiveOverviewRows"
        :searchedValue="searchedValue"
        :onRowClick="onRowClick"
        class="mt-3 mb-4"
      >
        <template v-slot:[TemplateNames.TEXT]="{ value }"
          ><app-txt class="!text-small">{{ value }}</app-txt></template
        >
        <template v-slot:[TemplateNames.CLICK_URLS]="{ value }"
          ><app-txt>
            <img
              src="../../../../components/icon/assets/link.svg"
              class="link-icon cursor-pointer ml-2"
              alt="Link Icon"
              v-tippy="{
                content: generateTooltipContent(value),
                allowHTML: true,
                interactive: true,
                theme: 'custom',
                placement: 'top-start',
              }"
            /> </app-txt
        ></template>

        <template v-slot:[TemplateNames.CAMPAIGN_LINK]="{ value }"
          ><app-txt
            @click="openCompanyLink($event, value)"
            class="!text-small text-primary-750 underline cursor-pointer"
          >
            {{ value }}
          </app-txt></template
        >

        <template v-slot:[TemplateNames.STATUS]="{ value }"
          ><StatusCell :value="value"
        /></template>

        <template v-slot:[TemplateNames.DATE]="{ value }"
          ><DateCellWithEndingDays :value="value"
        /></template>

        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
          ><SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
        /></template>

        <template v-slot:[TemplateNames.DROPDOWN]="{ rowId }"
          ><OverviewListDropdownCell
            :motiveId="rowId"
            @delete-motive="onDeleteMotive"
            @duplicate-motive="onDuplicateMotive"
          />
        </template>
      </app-table>
    </div>
    <DuplicateMotiveModal
      :isModalOpen="!!currentlyDuplicatedMotiveId"
      @close-modal="resetCurrentlyDuplicatedMotiveId"
      :motiveId="currentlyDuplicatedMotiveId"
      :campaignId="
        motives.find(motive => motive.id === currentlyDuplicatedMotiveId)
          ?.campaignId
      "
    />
    <confirm-deletion-modal
      :title="$t('common.deletionModal.title')"
      :confirm-label="$t('common.deletionModal.confirmDeleteLabel')"
      :cancel-label="$t('common.deletionModal.cancel')"
      :description="$t('common.deletionModal.description')"
      :delete-function="deleteMotive"
      :is-modal-open="!!currentlyDeletedMotiveId"
      @close-modal="resetCurrentlyDeletedMotiveId"
    />
    <ExportModal
      :isModalOpen="isModalOpen"
      @close-modal="() => setIsModalOpen(false)"
    />
  </app-layout>
</template>

<script lang="ts">
import { TemplateNames } from '@/components/table';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  DateCellWithEndingDays,
  SortableHeaderCell,
  StatusCell,
} from '../../../../components/table/cellTemplates';
import { useFetchCampaigns } from '../../application/campaign/actions/useFetchCampaigns';
import { useGetActiveTab } from '../../application/campaign/list/useGetActiveTab';
import { useDeleteMotive } from '../../application/motive/actions/useDeleteMotive';
import { useFetchMotives } from '../../application/motive/actions/useFetchMotives';
import { overviewListTableHeaders } from '../../application/motive/list/overviewListTableHeaders';
import { Slide } from '../../domain/valueObjects/slide';
import DuplicateMotiveModal from '../campaignDetails/duplicateMotiveModal.vue';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import ExportModal from './exportModal.vue';
import OverviewListDropdownCell from './overviewListDropdownCell.vue';
import { generateTooltipContent } from '@/utils/generateTooltipContent';

export default {
  components: {
    StatusCell,
    OverviewListDropdownCell,
    DuplicateMotiveModal,
    SortableHeaderCell,
    DateCellWithEndingDays,
    ExportModal,
  },
  methods: {
    generateTooltipContent,
  },
  setup() {
    const router = useRouter();
    useFetchMotives({});
    useFetchCampaigns({});
    const statusOptions = ref([
      { label: t('overview.filter.all'), value: 'all' },
      { label: t('overview.filter.active'), value: 'active' },
      { label: t('overview.filter.inactive'), value: 'inactive' },
      {
        label: t('overview.filter.deactivatedByFeed'),
        value: 'deactivated_by_feed',
      },
    ]);

    const searchedValue = ref('');
    const isModalOpen = ref(false);
    const setIsModalOpen = (value: boolean) => {
      isModalOpen.value = value;
    };

    const selectedTabId = useGetActiveTab();
    const store = useStore();

    const motives = computed(() => store.getters.motives);

    const selectedStatus = computed({
      get: () => store.getters.userPreferences.selectedStatus,
      set: value => store.dispatch('updateSelectedStatus', value),
    });

    const motiveOverviewRows = computed(() => {
      return store.getters.motives
        .map(motive => motive.toOverviewRow())
        .filter(motive => {
          if (selectedStatus.value === 'all') {
            return true;
          }
          return motive.status === selectedStatus.value;
        });
    });

    const currentlyDeletedMotiveId = ref('');

    const { call } = useDeleteMotive();
    const onDeleteMotive = (motiveId: string) => {
      currentlyDeletedMotiveId.value = motiveId;
    };
    const deleteMotive = async () => {
      await call(currentlyDeletedMotiveId.value);
      currentlyDeletedMotiveId.value = '';
    };

    const resetCurrentlyDeletedMotiveId = () => {
      currentlyDeletedMotiveId.value = '';
    };

    const currentlyDuplicatedMotiveId = ref('');
    const onDuplicateMotive = (motiveId: string) => {
      currentlyDuplicatedMotiveId.value = motiveId;
    };
    const resetCurrentlyDuplicatedMotiveId = () => {
      currentlyDuplicatedMotiveId.value = '';
    };

    const openCompanyLink = (event: Event, companyName: string) => {
      event.stopPropagation();
      const companyId = motiveOverviewRows.value.find(
        motive => motive.campaignName === companyName,
      )?.campaignId;
      router.push(`/campaigns/details?id=${companyId}`);
    };

    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.motiveDetails, query: { id } });
    };

    const updateSelectedStatus = (status: any) => {
      store.dispatch('updateSelectedStatus', status);
    };

    return {
      TemplateNames,
      headers: overviewListTableHeaders,
      motives,
      motiveOverviewRows,
      selectedTabId,
      isModalOpen,
      setIsModalOpen,
      searchedValue,
      openCompanyLink,
      onDeleteMotive,
      onDuplicateMotive,
      currentlyDuplicatedMotiveId,
      resetCurrentlyDuplicatedMotiveId,
      deleteMotive,
      currentlyDeletedMotiveId,
      resetCurrentlyDeletedMotiveId,
      statusOptions,
      selectedStatus,
      updateSelectedStatus,
      onRowClick,
    };
  },
};
</script>
