
import { computed, defineComponent } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-select',
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    placeholder: String,
    label: String,
    errorMessage: String,
    options: Object({ value: String, label: String }),
    modelValue: [String, Boolean, Number],
    disabled: { type: Boolean, default: false },
    hasReset: { type: Boolean, default: false },
    initialModelValue: [String, Boolean, Number],
  },
  setup(props, context) {
    const selectedLabel = computed(
      () =>
        props.options.find(
          (option: { value: string; label: string }) =>
            option.value === props.modelValue,
        )?.label,
    );

    const onSelect = (value: string | boolean | number) => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };

    const onReset = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue !== props.modelValue
      );
    });

    return {
      isResetVisible,
      onReset,
      onSelect,
      selectedLabel,
    };
  },
});
