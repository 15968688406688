<template>
  <div class="w-full">
    <div
      :class="[labelStyleClasses, 'flex flex-row justify-between items-center']"
    >
      <div class="flex flex-row items-center justify-between flex-grow">
        <div>
          <app-icon
            v-if="labelIcon"
            :name="labelIcon"
            alt="label icon"
            class="w-iconXs mr-1.5"
          />
          <app-txt
            v-if="label"
            variant="small"
            class="text-gray-700 font-bold"
            >{{ label }}</app-txt
          >
        </div>
        <button
          v-if="isResetVisible"
          @click="onReset"
          class="focus:outline-none"
        >
          <reset-icon color="#6B7280" />
        </button>
      </div>
      <div class="flex flex-row">
        <app-txt variant="small" class="text-gray-700" v-if="isRequired">
          {{ t('requiredLabel') }}
        </app-txt>
        <button
          @click="$emit(buttonIconClickedEvent)"
          v-if="buttonIcon"
          class="ml-2 focus:outline-none"
        >
          <app-icon :name="buttonIcon" alt="button icon" class="w-iconXs" />
        </button>
      </div>
    </div>
    <div
      :class="{
        'group relative flex items-center justify-center bg-gray-100 h-mediaPicker w-full p-2': true,
        'border-2 border-red-600 rounded-1': hasError,
      }"
    >
      <div
        v-if="modelValue"
        class="absolute flex items-center justify-center inset-0 group-hover:bg-black group-hover:opacity-50"
      ></div>

      <app-button
        class="absolute inset-auto hidden group-hover:flex"
        v-show="modelValue"
        variant="secondary"
        leftIcon="media"
        @click="() => setIsSelectMediaModalOpen(true)"
        >{{ t('replaceButtonLabel') }}</app-button
      >

      <iframe
        class="max-h-full"
        :src="assetRef.value?.getPublicUrl()"
        scrolling="no"
        v-if="assetRef.value?.type === 'animation' && modelValue"
      ></iframe>
      <img
        v-else-if="modelValue"
        class="max-h-full"
        :src="assetRef.value?.getPublicUrl() || 'imagenotfound'"
      />
      <app-button
        v-else
        variant="secondary"
        leftIcon="media"
        @click="() => setIsSelectMediaModalOpen(true)"
        >{{ t('chooseButtonLabel') }}</app-button
      >
    </div>

    <SelectMediaModal
      :isModalOpen="isSelectMediaModalOpen"
      @close-modal="() => setIsSelectMediaModalOpen(false)"
      @media-selected="onMediaSelected"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import SelectMediaModal from './selectMediaModal.vue';
import { CoreEvents } from '@/core/domain/events';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useGetAssetById } from '@/features/media/application/useGetAssetById';
import { Asset } from '../../domain/asset';
import { useToast } from 'vue-toastification';

const buttonIconClickedEvent = 'click:buttonIcon';

export default defineComponent({
  name: 'media-picker',
  components: {
    SelectMediaModal,
  },
  emits: [CoreEvents.UPDATE_MODEL_VALUE, buttonIconClickedEvent],
  props: {
    label: String,
    labelIcon: String,
    buttonIcon: String,
    labelStyleClasses: String,
    modelValue: { type: String, required: false, default: '' },
    hasError: { type: Boolean, default: false },
    hasReset: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    allowAnimations: { type: Boolean, default: true, required: false },
    initialModelValue: { type: String, required: false, default: '' },
  },
  setup(props, context) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    const assetRef = computed(() => useGetAssetById(props.modelValue));
    const toast = useToast();
    const iframeRef = ref<HTMLIFrameElement>();

    const onMediaSelected = (asset?: Asset) => {
      if (asset) {
        if (asset.isAnimation() && !props.allowAnimations) {
          toast.error(t('animationInSlideException'));
          return;
        }
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, asset.id);
      }
    };

    const isSelectMediaModalOpen = ref(false);
    const setIsSelectMediaModalOpen = (value: boolean) => {
      isSelectMediaModalOpen.value = value;
    };

    const onReset = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        !!props.initialModelValue &&
        props.initialModelValue !== props.modelValue
      );
    });

    return {
      buttonIconClickedEvent,
      t,
      iframeRef,
      onMediaSelected,
      isSelectMediaModalOpen,
      setIsSelectMediaModalOpen,
      assetRef,
      onReset,
      isResetVisible,
    };
  },
});
</script>
