import { useStore } from '@/store';
import { computed, Ref, ref } from 'vue';
import { BulkCreateMotiveForm } from './types';
import { MotiveGroupType } from '@/features/campaigns/domain/valueObjects';
import { useFetchContracts } from '../../contract/useFetchContracts';
import { useFetchDevices } from '../../device/useFetchDevices';
import { useBulkCreateMotive } from '../actions/useBulkCreateMotive';
import { useFetchMotivesForCampaignId } from '../actions/useFetchMotivesForCampaignId';

export const useBulkCreateMotiveForm = (
  campaignId: string,
): BulkCreateMotiveForm => {
  const store = useStore();

  const isBaseMotiveSectionOpen = ref(true);
  const isBaseMotiveSectionDone = ref(false);
  const isVariationsSectionOpen = ref(false);
  const isVariationsSectionDone = ref(false);
  const isAutoUpdateSectionOpen = ref(false);

  const motiveGroupName = ref('') as Ref<string>;

  const baseMotiveId = ref('');
  const baseMotiveLayout = computed(
    () => store.getters.motiveById(baseMotiveId.value)?.layout,
  );
  const isSelectValid = ref(false);
  const setIsSelectValid = (value: boolean) => {
    isSelectValid.value = value;
  };
  const { refetch: refetchMotives } = useFetchMotivesForCampaignId({
    campaignId,
  });
  const motives = computed(() =>
    store.getters.motivesByCampaignId(campaignId).map(motive => ({
      value: motive.id,
      label: motive.name,
    })),
  );

  const selectedVariation = ref<MotiveGroupType>();
  const setSelectedVariation = (value: MotiveGroupType) => {
    selectedVariation.value = value;
  };

  const contractId = ref('');
  useFetchContracts({});
  const contracts = computed(() =>
    store.getters.contracts.map(contract => ({
      value: contract.id,
      label: contract.title,
    })),
  );
  const isContractSelectValid = ref(false);
  const setIsContractSelectValid = (value: boolean) => {
    isContractSelectValid.value = value;
  };

  const deviceId = ref('');
  useFetchDevices({});
  const devices = computed(() =>
    store.getters.devices.map(device => ({
      value: device.id,
      label: device.title,
    })),
  );
  const isDeviceSelectValid = ref(false);
  const setIsDeviceSelectValid = (value: boolean) => {
    isDeviceSelectValid.value = value;
  };

  const isAutoUpdateOn = ref(false);

  const isBaseMotiveSectionValid = computed(
    () => isSelectValid.value && !!motiveGroupName.value,
  );
  const isVariationsSectionValid = computed(() => {
    if (!selectedVariation.value) return false;
    if (
      selectedVariation.value === MotiveGroupType.ONE_DEVICE &&
      !isDeviceSelectValid.value
    )
      return false;
    if (
      selectedVariation.value === MotiveGroupType.ONE_CONTRACT &&
      !isContractSelectValid.value
    )
      return false;
    return true;
  });

  const isSubmitDisabled = computed(
    () => !isBaseMotiveSectionValid.value || !isVariationsSectionValid.value,
  );

  const clearForm = () => {
    motiveGroupName.value = '';
    baseMotiveId.value = '';
    isBaseMotiveSectionDone.value = false;
    isBaseMotiveSectionOpen.value = true;
    isVariationsSectionDone.value = false;
    isVariationsSectionOpen.value = false;
    deviceId.value = '';
    contractId.value = '';
    selectedVariation.value = undefined;
  };

  const { isLoading, call } = useBulkCreateMotive();
  const onBulkCreateMotive = async () => {
    await call({
      name: motiveGroupName.value,
      baseMotiveId: baseMotiveId.value,
      campaignId,
      isAutoUpdateOn: isAutoUpdateOn.value,
      deviceId: deviceId.value,
      contractId: contractId.value,
    });

    refetchMotives();
  };

  return {
    isLoading,
    isBaseMotiveSectionOpen,
    isBaseMotiveSectionDone,
    baseMotiveId,
    isSelectValid,
    setIsSelectValid,
    motiveGroupName,
    isBaseMotiveSectionValid,
    isSubmitDisabled,
    motives,
    clearForm,
    isVariationsSectionValid,
    isVariationsSectionOpen,
    isVariationsSectionDone,
    selectedVariation,
    setSelectedVariation,
    contractId,
    contracts,
    isContractSelectValid,
    setIsContractSelectValid,
    deviceId,
    devices,
    isDeviceSelectValid,
    setIsDeviceSelectValid,
    baseMotiveLayout,
    onBulkCreateMotive,
    isAutoUpdateSectionOpen,
    isAutoUpdateOn,
  };
};
