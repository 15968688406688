import { Ref } from 'vue';
import { generateDefaultInterfererForValuesParams } from './defaults/elements/defaultInterferer';
import { generateDefaultTextForValuesParams } from './defaults/elements/defaultTexts';
import { generateDefaultPriceForValuesParams } from './defaults/elements/defaultPrices';
import { generateDefaultImageForValuesParams } from './defaults/elements/defaultImages';
import {
  SlideElement,
  SlideElementSimpleType,
  SlideElementType,
} from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { SlidePrice } from '@/features/campaigns/domain/valueObjects/blocks/slidePrice';
import { SlideImage } from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import { SlideText } from '@/features/campaigns/domain/valueObjects/blocks/slideText';
import { Slide } from '@/features/campaigns/domain/valueObjects/slide';
import { SlideInterferer } from '@/features/campaigns/domain/valueObjects/blocks/slideInterferer';

const removeSlideElementByLabel = (
  slides: Ref<Record<string, Slide>>,
  slideId: string,
  label: string,
  element: 'texts' | 'images' | 'prices' | 'interferers',
) => {
  const item: SlideElement[] = slides.value[slideId][element];

  if (item) {
    item.find((item: SlideElement) => item.label === label)?.remove();
  }
};

export const useHandleSlideElements = (slides: Ref<Record<string, Slide>>) => {
  const getLabel = (defaultLabel: string, elements: SlideElement[]) => {
    const numberOfDefaultLabels = elements.filter(element =>
      element.label.startsWith(defaultLabel),
    ).length;

    return numberOfDefaultLabels > 0
      ? `${defaultLabel}-${numberOfDefaultLabels}`
      : defaultLabel;
  };

  const addSlideElement = (slideId: string, type: SlideElementType) => {
    const defaultLabel = type.toString();

    switch (type) {
      case SlideElementType.Text: {
        const textParams = generateDefaultTextForValuesParams({
          label: getLabel(defaultLabel, slides.value[slideId].texts),
        });
        slides.value[slideId].texts.push(SlideText.createForValues(textParams));
        break;
      }
      case SlideElementType.CTA:
      case SlideElementType.Image:
      case SlideElementType.Logo: {
        const imageParams = generateDefaultImageForValuesParams({
          label: getLabel(defaultLabel, slides.value[slideId].images),
        });
        slides.value[slideId].images.push(
          SlideImage.createForValues(imageParams),
        );
        break;
      }
      case SlideElementType.Price: {
        const priceParams = generateDefaultPriceForValuesParams({
          label: getLabel(defaultLabel, slides.value[slideId].prices),
        });
        slides.value[slideId].prices.push(
          SlidePrice.createForValues(priceParams),
        );
        break;
      }
      case SlideElementType.Interferer: {
        const interfererParams = generateDefaultInterfererForValuesParams({
          label: getLabel(defaultLabel, slides.value[slideId].interferers),
        });
        slides.value[slideId].interferers.push(
          SlideInterferer.createForValues(interfererParams),
        );
        break;
      }
    }

    slides.value[slideId].adjustZIndices();
  };

  const removeSlideElement = (
    slideId: string,
    type: SlideElementSimpleType,
    label: string,
  ) => {
    switch (type) {
      case SlideElementSimpleType.Text:
        removeSlideElementByLabel(slides, slideId, label, 'texts');
        break;
      case SlideElementSimpleType.Image:
        removeSlideElementByLabel(slides, slideId, label, 'images');
        break;
      case SlideElementSimpleType.Price:
        removeSlideElementByLabel(slides, slideId, label, 'prices');
        break;
      case SlideElementSimpleType.Interferer:
        removeSlideElementByLabel(slides, slideId, label, 'interferers');
        break;
    }

    slides.value[slideId].adjustZIndices();
  };

  const addSecondGraphic = (slideId: string, label: string) => {
    const interfererIndex = slides.value[slideId].interferers.findIndex(
      interferer => interferer.label === label,
    );
    slides.value[slideId].interferers[interfererIndex].addSecondImage();
  };

  const removeSecondGraphic = (slideId: string, label: string) => {
    const interfererIndex = slides.value[slideId].interferers.findIndex(
      interferer => interferer.label === label,
    );
    slides.value[slideId].interferers[interfererIndex].removeSecondImage();
  };

  const increaseElementZIndex = (slideId: string, zIndex: number) => {
    const maxZIndex = slides.value[slideId].getHighestZIndex() + 1;
    if (zIndex < maxZIndex) {
      slides.value[slideId].swapZIndices(zIndex, zIndex + 1);
    }
  };

  const decreaseElementZIndex = (slideId: string, zIndex: number) => {
    if (zIndex > 1) {
      slides.value[slideId].swapZIndices(zIndex, zIndex - 1);
    }
  };

  return {
    addSecondGraphic,
    removeSecondGraphic,
    addSlideElement,
    removeSlideElement,
    increaseElementZIndex,
    decreaseElementZIndex,
  };
};
