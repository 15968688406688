import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UpdateFormatDTO } from '../../domain/format/format';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { UpdateFormatFailure } from '../../domain';

export const useUpdateFormatDefaults = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();

  return useMutation(async (updateFormat: UpdateFormatDTO) => {
    const result = await facade.updateFormatDefaults(updateFormat);

    if (result.isRight()) {
      toast.success(t('campaigns.useUpdateFormat.success'));
    } else {
      const error= result.extract() as UpdateFormatFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useUpdateFormat.error'));
      }
    }
  });
};
