<template>
  <div class="relative flex items-center justify-center" v-if="!isCurrentUser">
    <dropdown-wrapper class="top-3.5 right-0">
      <template v-slot:trigger>
        <app-icon name="threeDots" alt="Open dropdown icon" class="mx-auto" />
      </template>
      <template v-slot:dropdown>
        <dropdown-item
          v-for="option in options"
          :key="option.label"
          class="flex flex-row"
          @click="option.onClick"
          v-slot="{ active }"
        >
          <app-icon
            :name="option.icon"
            :alt="option.iconAlt"
            :class="{
              'active-icon': active,
            }"
          />

          <app-txt variant="small" class="ml-1.5">
            {{ option.label }}
          </app-txt>
        </dropdown-item>
      </template>
    </dropdown-wrapper>
  </div>

  <confirm-deletion-modal
    :title="$t('common.deletionModal.title')"
    :confirm-label="$t('common.deletionModal.confirmDeleteLabel')"
    :cancel-label="$t('common.deletionModal.cancel')"
    :description="$t('common.deletionModal.description')"
    :delete-function="deleteUser"
    :is-modal-open="isConfirmDeleteOpen"
    @close-modal="onCancel"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { UserEvents } from './events';
import { useGetCurrentUser } from '../application/useGetCurrentUser';
import { useGetOptionsForUser } from '../application/useGetOptionsForUser';
import { useConfirmationModal } from '@/features/shared/application/useConfirmationModal';
import { useDeleteUser } from '@/features/users/application/useDeleteUser';

export default defineComponent({
  props: {
    userId: { type: String, required: true },
  },
  emits: [UserEvents.CHANGE_ROLE],
  setup(props, context) {
    const { currentUser } = useGetCurrentUser();
    // Current user becomes undefined when login out
    const isCurrentUser = computed(
      () => props.userId === currentUser.value?.id,
    );

    const {
      onConfirm,
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForUser(props.userId, context.emit, {
      onDelete: withConfirm,
    });

    const { call: callDelete } = useDeleteUser();
    const deleteUser = async () => {
      await callDelete({
        id: props.userId,
      });
      onCancel();
    };

    return {
      deleteUser,
      isCurrentUser,
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onConfirmDeletion: onConfirm,
      onCancel,
    };
  },
});
</script>
