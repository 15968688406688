import { Ref, ref, watch } from 'vue';
import { TransitionType } from '@/features/campaigns/domain/valueObjects/preview/iPreviewTransition';
import { SlideImage } from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import { getImageContentIndexes } from '@/features/campaigns/domain/valueObjects/blocks/helpers/slideImageHelpers';

type ImageContentIndexes = ReturnType<typeof getImageContentIndexes>;

export const useImageSettingsModels = (
  slideImage: Ref<SlideImage>,
  imageContentIndexes: ImageContentIndexes,
) => {
  const transitionModel = ref<TransitionType>(
    slideImage.value?.transition?.type || 'scale',
  );
  const squareVisualModel = ref<string>(
    slideImage.value.content?.[imageContentIndexes.square].id || '',
  );
  const horizontalVisualModel = ref<string>(
    slideImage.value.content?.[imageContentIndexes.horizontal].id || '',
  );
  const verticalVisualModel = ref<string>(
    slideImage.value.content?.[imageContentIndexes.vertical].id || '',
  );
  const positionYModel = ref(slideImage.value.position?.y || 0);
  const positionXModel = ref(slideImage.value.position?.x || 0);
  const transitionOriginXModal = ref<number>(
    slideImage.value?.transition?.x || 0,
  );
  const transitionOriginYModal = ref<number>(
    slideImage.value?.transition?.y || 0,
  );

  watch(positionYModel, () => {
    if (slideImage.value && slideImage.value.position) {
      slideImage.value.position.y = positionYModel.value;
    }
  });
  watch(positionXModel, () => {
    if (slideImage.value && slideImage.value.position) {
      slideImage.value.position.x = positionXModel.value;
    }
  });
  watch(verticalVisualModel, () => {
    if (slideImage.value && slideImage.value.content) {
      slideImage.value.content[imageContentIndexes.vertical].id =
        verticalVisualModel.value;
    }
  });
  watch(horizontalVisualModel, () => {
    if (slideImage.value && slideImage.value.content) {
      slideImage.value.content[imageContentIndexes.horizontal].id =
        horizontalVisualModel.value;
    }
  });
  watch(squareVisualModel, () => {
    if (slideImage.value && slideImage.value.content) {
      slideImage.value.content[imageContentIndexes.square].id =
        squareVisualModel.value;
    }
  });
  watch(transitionModel, () => {
    if (slideImage.value && slideImage.value.transition) {
      slideImage.value.transition.type = transitionModel.value;
    }
  });
  watch(transitionOriginXModal, () => {
    if (slideImage.value && slideImage.value.transition) {
      slideImage.value.transition.x = transitionOriginXModal.value;
    }
  });
  watch(transitionOriginYModal, () => {
    if (slideImage.value && slideImage.value.transition) {
      slideImage.value.transition.y = transitionOriginYModal.value;
    }
  });

  return {
    transitionModel,
    squareVisualModel,
    horizontalVisualModel,
    verticalVisualModel,
    positionXModel,
    positionYModel,
    transitionOriginXModal,
    transitionOriginYModal
  };
};
