import { Field, fieldTypes } from '@/components/formGenerator';
import { computed, ComputedRef, Ref } from 'vue';
import { Unit } from './unit';
import { CreateSlideTextForValuesParams } from '@/features/campaigns/domain/valueObjects/blocks/slideText';
import { CreateSlideImageForValuesParams } from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { CreateSlidePriceForValuesParams } from '@/features/campaigns/domain/valueObjects/blocks/slidePrice';
import { SlideElementSimpleType } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useGetContracts } from '../../contract/useGetContracts';
import { useGetDevices } from '../../device/useGetDevices';
import { fontOptions } from '@/components/richTextEditor/config';
import { CreateSlideInterfererForValuesParams } from '@/features/campaigns/domain/valueObjects/blocks/slideInterferer';

const removedFilter = (element: { removed?: boolean; isDefault?: boolean }) =>
  element.isDefault || !element.removed;

export const getSlideSettingsSections = (params: {
  layout: Ref<Layout>;
  hasSubmitted: Ref<boolean>;
  texts: CreateSlideTextForValuesParams[];
  images: CreateSlideImageForValuesParams[];
  prices: CreateSlidePriceForValuesParams[];
  interferers: CreateSlideInterfererForValuesParams[];
  onContractChanged: (slideId: string, contractId: string) => void;
  onDeviceChanged: (slideId: string, deviceId: string) => void;
  isGlobalEdit: boolean;
}): ComputedRef<{
  zIndex: number;
  index: number;
  elementType: SlideElementSimpleType;
  label: string;
  fields: Field[];
  removed?: boolean;
  isDefault?: boolean;
}[]> => {
  const t = campaignsTranslateByScope('editMotive.slideSettings');
  const { contracts } = useGetContracts();
  const contractOptions = computed(() =>
    contracts.value.map(contract => ({
      value: contract.id,
      label: `${contract.id} ${contract.title}`,
    })),
  );

  const { devices } = useGetDevices();
  const deviceOptions = computed(() =>
    devices.value.map(device => ({
      value: device.id,
      label: `${device.id} ${device.title}`,
    })),
  );

  const isHiddenFilter = (element: { isHidden?: boolean }) => !element.isHidden;

  const interfererAnimationOptions = [
    {
      label: 'Flip',
      value: 'flip',
    },
    {
      label: 'Fade',
      value: 'fade',
    },
  ];

  const transitionTypeOptions = [
    {
      label: 'Bounce',
      value: 'bounce',
    },
    {
      label: 'Fade',
      value: 'fade',
    },
    {
      label: 'Slide',
      value: 'slide',
    },
    {
      label: 'Scale',
      value: 'scale',
    },
    {
      label: 'Flip',
      value: 'flip',
    },
    {
      label: 'Fly',
      value: 'fly',
    },
  ];

  const sortedElements = computed(() => {
    const mappedElements = [
      ...params.texts.filter(removedFilter).map((textElement, index) => ({
        zIndex: textElement.zIndex ?? 1,
        index,
        elementType: SlideElementSimpleType.Text,
        label: textElement.label,
        removed: textElement.removed,
        isDefault: textElement.isDefault,
        fields: [
          {
            id: 'content',
            type: fieldTypes.RICH_TEXT_EDITOR,
            props: {
              label: t('text.label'),
              placeholder: t('headlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
              initialFont: fontOptions[0].value,
              fontSize: 24,
            },
          },
          {
            id: 'size',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.SLIDER,
            props: {
              label: t('text.size'),
              from: 0,
              to: 15,
              unit: Unit.EM,
              multiplier: 1,
              tooltipPrecision: 2,
              valuePrecision: 2,
              styleClasses: 'px-2 py-2 col-span-2',
              hasReset: true,
              hasSecondaryValue: true,
            },
          },
          {
            id: 'lineSpacing',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.SLIDER,
            props: {
              label: t('text.lineSpacing'),
              from: 0,
              to: 3,
              unit: Unit.EM,
              multiplier: 1,
              tooltipPrecision: 2,
              valuePrecision: 2,
              styleClasses: 'px-2 py-2 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'position',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.OFFSET_PICKER,
            props: {
              label: t('text.position'),
              horizontalId: 'x',
              verticalId: 'y',
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'blockWidth',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.SLIDER,
            props: {
              label: t('text.blockWidth'),
              from: 0,
              to: 1000,
              unit: Unit.PX,
              multiplier: 1,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'transition',
            isHidden: textElement.transition?.type === undefined,
            type: fieldTypes.SELECT,
            props: {
              label: t('transitionTypeLabel'),
              options: transitionTypeOptions,
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'transitionOrigin',
            isHidden:
              textElement.transition?.x === undefined ||
              textElement.transition?.y === undefined,
            type: fieldTypes.TRANSITION_ORIGIN,
            props: {
              label: t('transitionOriginLabel'),
              horizontalId: 'x',
              verticalId: 'y',
              styleClasses: 'px-2 pb-3 col-span-2',
              hasReset: true,
            },
          },
        ].filter(isHiddenFilter),
      })),
      ...params.images.filter(removedFilter).map((imageElement, index) => ({
        zIndex: imageElement.zIndex ?? 1,
        elementType: SlideElementSimpleType.Image,
        index,
        label: imageElement.label,
        removed: imageElement.removed,
        isDefault: imageElement.isDefault,
        fields: [
          {
            id: 'content',
            type: fieldTypes.MEDIA_PICKER_GROUP,
            props: {
              hasReset: true,
              styleClasses: 'border-t border-gray-200 col-span-2',
              labelStyleClasses: 'p-2',
            },
          },
          {
            id: 'scaling',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.SLIDER,
            props: {
              label: t('imageZoomLabel'),
              from: 0,
              to: 1000,
              unit: Unit.PX,
              multiplier: 1,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-3 col-span-2',
              hasReset: true,
              disabled: true,
            },
          },
          {
            id: 'position',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.OFFSET_PICKER,
            props: {
              label: t('image.positionLabel'),
              horizontalId: 'x',
              verticalId: 'y',
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'transition',
            isHidden: imageElement.transition?.type === undefined,
            type: fieldTypes.SELECT,
            props: {
              label: t('transitionTypeLabel'),
              options: transitionTypeOptions,
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'transitionOrigin',
            isHidden:
              imageElement.transition?.x === undefined ||
              imageElement.transition?.y === undefined,
            type: fieldTypes.TRANSITION_ORIGIN,
            props: {
              label: t('transitionOriginLabel'),
              horizontalId: 'x',
              verticalId: 'y',
              styleClasses: 'px-2 pb-3 col-span-2',
              hasReset: true,
            },
          },
        ].filter(isHiddenFilter),
      })),
      ...params.prices.filter(removedFilter).map((priceElement, index) => ({
        zIndex: priceElement.zIndex ?? 1,
        elementType: SlideElementSimpleType.Price,
        index,
        label: priceElement.label,
        removed: priceElement.removed,
        isDefault: priceElement.isDefault,
        fields: [
          {
            id: 'overline',
            isHidden: priceElement.overline === undefined,
            type: fieldTypes.RICH_TEXT_EDITOR,
            props: {
              label: t('price.overlineLabel'),
              placeholder: t('headlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 py-2 col-span-2',
              hasReset: true,
              initialFont: fontOptions[0].value,
              fontSize: 24,
            },
          },
          {
            id: 'value',
            isHidden: priceElement.value === undefined,
            type: fieldTypes.RICH_TEXT_EDITOR,
            props: {
              label: t('price.valueLabel'),
              placeholder: t('headlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 py-2 col-span-2',
              hasReset: true,
              initialFont: fontOptions[0].value,
              fontSize: 24,
            },
          },
          {
            id: 'interval',
            isHidden: priceElement.interval === undefined,
            type: fieldTypes.RICH_TEXT_EDITOR,
            props: {
              label: t('price.intervalLabel'),
              placeholder: t('headlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 py-2 col-span-2',
              hasReset: true,
              initialFont: fontOptions[0].value,
              fontSize: 24,
            },
          },
          {
            id: 'underline',
            isHidden: priceElement.underline === undefined,
            type: fieldTypes.RICH_TEXT_EDITOR,
            props: {
              label: t('price.underlineLabel'),
              placeholder: t('headlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 py-2 col-span-2',
              hasReset: true,
              initialFont: fontOptions[0].value,
              fontSize: 24,
            },
          },
          {
            id: 'position',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.OFFSET_PICKER,
            props: {
              label: t('price.positionLabel'),
              horizontalId: 'x',
              verticalId: 'y',
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'scaling',
            isHidden: params.isGlobalEdit,
            type: fieldTypes.SLIDER,
            props: {
              label: t('price.scaling'),
              from: 0,
              to: 10,
              unit: Unit.EM,
              multiplier: 1,
              tooltipPrecision: 2,
              valuePrecision: 2,
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
              hasSecondaryValue: true,
            },
          },
          {
            id: 'transition',
            isHidden: priceElement.transition?.type === undefined,
            type: fieldTypes.SELECT,
            props: {
              label: t('transitionTypeLabel'),
              options: transitionTypeOptions,
              styleClasses: 'px-2 py-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'transitionOrigin',
            isHidden:
              priceElement.transition?.x === undefined ||
              priceElement.transition?.y === undefined,
            type: fieldTypes.TRANSITION_ORIGIN,
            props: {
              label: t('transitionOriginLabel'),
              horizontalId: 'x',
              verticalId: 'y',
              styleClasses: 'px-2 pb-3 col-span-2',
              hasReset: true,
            },
          },
        ].filter(isHiddenFilter),
      })),
      ...params.interferers
        .filter(removedFilter)
        .map((interfererElement, index) => ({
          zIndex: interfererElement.zIndex ?? 1,
          index,
          label: interfererElement.label,
          elementType: SlideElementSimpleType.Interferer,
          removed: interfererElement.removed,
          isDefault: interfererElement.isDefault,
          fields: [
            {
              id: 'transition.type',
              isHidden: interfererElement.transition?.type === undefined,
              type: fieldTypes.SELECT,
              props: {
                label: t('transitionTypeLabel'),
                options: transitionTypeOptions,
                styleClasses:
                  'border-t border-gray-200 px-2 pt-2 pb-3 col-span-2',
                hasReset: true,
              },
            },
            {
              id: 'transitionOrigin',
              isHidden:
                interfererElement.transition?.x === undefined ||
                interfererElement.transition?.y === undefined,
              type: fieldTypes.TRANSITION_ORIGIN,
              props: {
                label: t('transitionOriginLabel'),
                horizontalId: 'x',
                verticalId: 'y',
                styleClasses: 'px-2 pb-3 col-span-2',
                hasReset: true,
              },
            },
            {
              id: 'animation',
              isHidden:
                interfererElement.animation === undefined ||
                !interfererElement.secondImage,
              type: fieldTypes.SELECT,
              props: {
                label: t('interfererAnimationLabel'),
                options: interfererAnimationOptions,
                styleClasses: 'px-2 pt-2 pb-3 col-span-2',
                hasReset: true,
              },
            },
            {
              id: 'content',
              isHidden: interfererElement.firstImage === undefined,
              type: fieldTypes.MEDIA_PICKER,
              props: {
                hasError: false,
                hasReset: true,
                styleClasses: 'border-t border-gray-200 col-span-2',
                labelStyleClasses: 'p-2',
              },
            },
            {
              id: 'scaling',
              isHidden: params.isGlobalEdit,
              type: fieldTypes.SLIDER,
              props: {
                label: t('imageZoomLabel'),
                disabled: true,
                from: 0,
                to: 1000,
                unit: Unit.PX,
                multiplier: 1,
                tooltipPrecision: 0,
                valuePrecision: 2,
                styleClasses: 'px-2 pt-3 col-span-2',
                hasReset: true,
              },
            },
            {
              id: 'position',
              isHidden: params.isGlobalEdit,
              type: fieldTypes.OFFSET_PICKER,
              props: {
                label: t('image.positionLabel'),
                horizontalId: 'x',
                verticalId: 'y',
                styleClasses: 'px-2 py-2 col-span-2',
                hasReset: true,
              },
            },
            {
              id: 'text',
              isHidden: interfererElement.firstImage?.text === undefined,
              type: fieldTypes.RICH_TEXT_EDITOR,
              props: {
                label: t('price.valueLabel'),
                placeholder: t('headlinePlaceholder'),
                errorMessage: null,
                styleClasses: 'px-2 py-3 col-span-2',
                hasReset: true,
                initialFont: fontOptions[0].value,
                fontSize: 24,
              },
            },
            {
              id: 'textSize',
              isHidden: params.isGlobalEdit,
              type: fieldTypes.SLIDER,
              props: {
                label: t('text.size'),
                from: 0,
                to: 15,
                unit: Unit.EM,
                multiplier: 1,
                tooltipPrecision: 2,
                valuePrecision: 2,
                styleClasses: 'px-2 py-2 col-span-2',
                hasReset: true,
                hasSecondaryValue: true,
              },
            },
          ].filter(isHiddenFilter),
        })),
    ];

    mappedElements.sort((a, b) => (b.zIndex ?? 0) - (a.zIndex ?? 0));

    return mappedElements;
  });

  return computed(() => [
    {
      zIndex: -1,
      index: -1,
      label: t('generalSectionLabel'),
      elementType: SlideElementSimpleType.Other,
      fields: [
        {
          id: 'contractId',
          type: fieldTypes.SELECT,
          props: {
            callback: params.onContractChanged,
            label: t('contractLabel'),
            placeholder: t('contractPlaceholder'),
            options: contractOptions,
            errorMessage: t('emptyContractError'),
            styleClasses: 'px-2 pt-3',
            containerStyleClasses: 'col-span-2',
            hasReset: true,
          },
        },
        {
          id: 'deviceId',
          type: fieldTypes.SELECT,
          isHidden: params.layout.value !== Layout.DEVICE,
          props: {
            callback: params.onDeviceChanged,
            label: t('deviceLabel'),
            placeholder: t('devicePlaceholder'),
            options: deviceOptions,
            errorMessage: t('emptyDeviceError'),
            styleClasses: 'px-2 pt-3',
            containerStyleClasses: 'col-span-2',
            hasReset: true,
          },
        },
        {
          id: 'clickUrl',
          type: fieldTypes.COLLAPSABLE_INPUT,
          props: {
            label: t('clickUrlLabel'),
            placeholder: t('clickUrlPlaceholder'),
            errorMessage: null,
            styleClasses: 'px-2 py-3',
            containerStyleClasses: 'col-span-2',
            hasReset: true,
          },
        },
        {
          id: 'duration',
          type: fieldTypes.SLIDER,
          props: {
            label: t('slideDurationLabel'),
            from: 1000,
            to: 15000,
            unit: Unit.SECONDS,
            multiplier: 0.001,
            tooltipPrecision: 1,
            valuePrecision: 1,
            styleClasses: 'px-2 pt-2 pb-3 col-span-2',
            hasReset: true,
          },
        },
      ].filter(s => !s.isHidden) as Field[],
    },
    ...sortedElements.value,
  ]);
};
