import {
  Format,
  UpdateFormatDTO,
} from '@/features/campaigns/domain/format/format';
import { CreateSlideElementParams } from '@/features/campaigns/domain/valueObjects/blocks/slideElement';
import { Slide } from '@/features/campaigns/domain/valueObjects/slide';

const updatedOrUndefined = <T>(updated: T, initial?: T): T | undefined => {
  if (initial === updated) return undefined;
  return updated;
};

const updatedOrUndefinedDeep = (updated: any, initial?: any, propName?: string) => {
  if (Array.isArray(updated)) {
    const comparison = updated.map((val, index) =>
      updatedOrUndefinedDeep(val, initial?.[index]),
    );

    if (comparison.every(v => v === undefined)) {
      return undefined;
    } else {
      return updated;
    }
  } else if (
    typeof updated === 'object' &&
    updated !== undefined &&
    updated !== null
  ) {
    const map: Record<string, any> = {};
    const props = Object.keys(updated);

    for (const prop of props) {
      const value = updated[prop as keyof typeof props];
      const initialValue = initial?.[prop as keyof typeof props];

      const difference = updatedOrUndefinedDeep(value, initialValue, prop);

      if (difference !== undefined) {
        map[prop] = difference;
      }
    }

    return Object.keys(map).length > 0 ? map : undefined;
  } else {
    if (propName === 'secondImage') {
      return updated === initial ? undefined : (updated ?? null);
    }
    
    return updated === initial ? undefined : updated;
  }
};

export const updatedOrUndefinedObject = <T>(
  updated: T,
  initial?: T,
): T | undefined => {
  if (JSON.stringify(initial) === JSON.stringify(updated)) return undefined;
  return updated;
};

const compareSlideElements = <T extends CreateSlideElementParams>(
  initialSlideElement: T[],
  updatedSlideElement: T[],
) => {
  const labels = [
    ...new Set([
      ...initialSlideElement.map(e => e.label),
      ...updatedSlideElement.map(e => e.label),
    ]),
  ];

  const initialMap = initialSlideElement.reduce((map, element) => {
    map[element.label] = element;
    return map;
  }, {} as Record<string, T>);

  const updatedMap = updatedSlideElement.reduce((map, element) => {
    map[element.label] = element;
    return map;
  }, {} as Record<string, T>);

  const changes = labels.reduce((arr, label) => {
    if (!updatedMap[label]) {
      // Item has been deleted
      arr.push({ label, removed: true });
    } else if (!initialMap[label]) {
      // Item is new
      arr.push(updatedMap[label]);
    } else {
      // Item is changed and we need deep comparison
      const difference = updatedOrUndefinedDeep(
        updatedMap[label],
        initialMap[label],
      );

      if (difference !== undefined) {
        arr.push({ label, ...difference });
      }
    }

    return arr;
  }, [] as (Partial<T> | { label: string; removed: boolean })[]);

  return changes.length > 0 ? changes : undefined;
};

export const compareSlides = (params: {
  initialSlide: Slide;
  updatedSlide: Slide;
}) => {
  const initialSlideJson: Record<string, any> = params.initialSlide.toJson();
  const updatedSlideJson: Record<string, any> = params.updatedSlide.toJson();

  return Object.fromEntries(
    Object.keys(updatedSlideJson).map(key => {
      if (['texts', 'images', 'prices', 'interferers'].includes(key)) {
        return [
          key,
          compareSlideElements(initialSlideJson[key], updatedSlideJson[key]),
        ];
      } else {
        return [
          key,
          updatedOrUndefined(updatedSlideJson[key], initialSlideJson[key]),
        ];
      }
    }),
  );
};

const compareSlideArrays = (params: {
  initialSlides: Slide[];
  updatedSlides: Slide[];
}) => {
  const result: Record<string, unknown> = {};

  const slideIds = [
    ...new Set([
      ...params.initialSlides.map(s => s.id),
      ...params.updatedSlides.map(s => s.id),
    ]),
  ];

  for (const slideId of slideIds) {
    const initialSlide = params.initialSlides.find(
      slide => slide.id === slideId,
    );
    const updatedSlide = params.updatedSlides.find(
      slide => slide.id === slideId,
    );

    if (!initialSlide) {
      // A new slide was added
      result[slideId] = updatedSlide?.toJson();
    } else if (!updatedSlide) {
      // A slide was removed
      result[slideId] = null;
    } else {
      // A slide was changed
      result[slideId] = compareSlides({
        initialSlide,
        updatedSlide,
      });
    }
  }

  return result as Record<string, Slide>;
};

export const compareFormats = (
  initialFormat: Format,
  updatedFormat: Format,
): UpdateFormatDTO => {
  return {
    id: updatedFormat.id,
    name: updatedFormat.name,
    motiveId: updatedFormat.motiveId,
    slides: compareSlideArrays({
      initialSlides: initialFormat.slideOrder.map(
        id => initialFormat.slides[id],
      ),
      updatedSlides: updatedFormat.slideOrder.map(
        id => updatedFormat.slides[id],
      ),
    }),
    slideOrder: updatedOrUndefinedObject(
      updatedFormat.slideOrder,
      initialFormat.slideOrder,
    ),
  };
};
