import { Contract } from '../domain/contract';

export class ContractDTO {
  static toDomain(json: { [key: string]: any }): Contract {
    return Contract.create({
      id: json.id,
      title: json.title,
      subtitle: json.subtitle,
      bullets: json.bullets,
      monthlyPrice: json.monthlyPrice,
      oneTimePrice: json.oneTimePrice,
      dataVolumeGb: json.dataVolumeGb,
    });
  }

  static toJson(contract: Contract): { [key: string]: any } {
    return {
      id: contract.id,
      title: contract.title,
      subtitle: contract.subtitle,
      bullets: contract.bullets,
      monthlyPrice: contract.monthlyPrice,
      oneTimePrice: contract.oneTimePrice,
      dataVolumeGb: contract.dataVolumeGb,
    };
  }
}
