<template>
  <transition name="slide">
    <aside
      class="mobile-only absolute top-0 z-50 right-0 h-screen w-sidebarWidth bg-gray-sidebarDark"
      v-click-outside="toggleSidebar"
      v-if="isMobileSidebarShown"
    >
      <div class="flex flex-col h-full">
        <section
          class="flex flex-col relative bg-gray-sidebarLight pt-6 pr-3 pl-5 pb-3"
        >
          <button @click="toggleSidebar" class="self-end">
            <app-icon name="close" alt="close menu" />
          </button>
          <app-txt variant="small" class="text-white mt-3">{{
            currentUser?.email
          }}</app-txt>
        </section>
        <section class="flex flex-col pl-5 pt-4.5">
          <a
            v-for="page in pages"
            :key="page.label"
            :class="{
              ['app-link mb-4']: true,
              ['text-white']: page.to !== $route.name,
              ['text-primary font-semibold']: page.to === $route.name,
            }"
            @click="() => onPageClick(page.to)"
            >{{ page.label }}</a
          >
        </section>

        <section
          class="mt-auto flex justify-center align-center border-t-2 border-gray-sidebarLight"
        >
          <button class="mt-4 mb-5 text-white" @click="logout">
            {{ $t('topbar.logout') }}
          </button>
        </section>
      </div>
    </aside>
  </transition>
</template>

<script lang="ts">
import { useLogout } from '@/features/session/application/useLogout';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { defineComponent } from 'vue';
import { useGetAccessiblePages } from './useGetAccessiblePages';
import { LayoutEvents } from './layoutEvents';

export default defineComponent({
  name: 'sidebar',
  emits: [LayoutEvents.TOGGLE_SIDEBAR],
  methods: {
    toggleSidebar() {
      this.$emit(LayoutEvents.TOGGLE_SIDEBAR);
    },
    onPageClick(pageName: string) {
      this.$emit(LayoutEvents.TOGGLE_SIDEBAR);
      this.$router.push({ name: pageName });
    },
  },
  props: {
    isMobileSidebarShown: Boolean,
  },
  setup() {
    const { currentUser } = useGetCurrentUser();
    const pages = useGetAccessiblePages();
    const { logout } = useLogout();

    return {
      currentUser,
      pages,
      logout,
    };
  },
});
</script>
