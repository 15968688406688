import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useRestoreMotiveToVersion = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  const t = campaignsTranslateByScope('editMotive.versionHistory');
  return useMutation(
    async (params: { author: string; motiveId: string; versionId: string }) => {
      const result = await facade.restoreMotiveToVersion({
        author: params.author,
        motiveId: params.motiveId,
        versionId: params.versionId,
      });
      if (result.isRight()) {
        toast.success(t('succeed'));
      }
    },
  );
};
