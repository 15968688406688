<template>
  <div>
    <div>
      <div class="flex flex-row justify-between">
        <div class="flex flex-row cursor-pointer" @click="toggleExpanded">
          <app-txt
            v-if="label"
            variant="small"
            class="text-gray-700 font-medium mr-1"
            >{{ label }}</app-txt
          >
          <app-icon v-if="isExpanded" name="chevronDown" class="w-iconXs" />
          <app-icon v-else name="chevronRightBold" class="w-iconXs" />
        </div>
        <button
          v-if="isResetVisible"
          @click="onReset"
          class="focus:outline-none"
        >
          <reset-icon color="#6B7280" />
        </button>
      </div>
      <div
        v-if="isExpanded"
        :class="{
          'relative flex flex-row items-center': true,
          'mt-1': !!label,
        }"
      >
        <div class="absolute pl-2" v-if="leftIcon">
          <app-icon :name="leftIcon" alt="left Icon" />
        </div>
        <input
          :type="inputType"
          :class="{
            ['w-full py-2 px-2 rounded-1 border rounded-md text-gray-500 h-buttonHeight text-small']: true,
            ['border-red-600']: errorMessage,
            ['border-gray-300']: !errorMessage,
            'px-5': !!leftIcon,
          }"
          :disabled="disabled"
          :value="modelValue"
          :placeholder="placeholder"
          :step="step"
          @input="onInput"
        />
        <div class="absolute pr-2 right-0" v-if="errorMessage">
          <app-icon name="invalid" alt="invalid icon" />
        </div>
        <div class="absolute right-0 pr-2" v-else-if="rightIcon">
          <app-icon :name="rightIcon" alt="right icon" />
        </div>
        <div
          v-else-if="type === 'password'"
          class="absolute pr-2 cursor-pointer right-0"
          @click="onToggleShowPassword()"
        >
          <app-icon alt="show password icon" name="showPassword" />
        </div>
      </div>
    </div>
    <transition name="fade-bottom">
      <span class="text-red-600" v-if="errorMessage">{{ errorMessage }}</span>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-collapsable-input',
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    label: String,
    disabled: Boolean,
    modelValue: { type: String, required: true },
    errorMessage: String,
    leftIcon: { type: String, required: false },
    rightIcon: { type: String, required: false },
    step: { type: Number, required: false },
    hasReset: { type: Boolean, default: false },
    initialModelValue: { type: String, required: false },
  },
  setup(props, context) {
    const inputType = ref(props.type);
    const onToggleShowPassword = () => {
      inputType.value = inputType.value === 'password' ? 'text' : 'password';
    };

    const onInput = (event: Event) => {
      const value = (event?.target as HTMLInputElement)?.value;

      if (props.type === 'number' && isNaN(parseFloat(value))) return;

      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };

    const onReset = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue !== props.modelValue
      );
    });

    const isExpanded = ref(false);
    const toggleExpanded = () => {
      isExpanded.value = !isExpanded.value;
    };

    return {
      toggleExpanded,
      isExpanded,
      isResetVisible,
      onReset,
      inputType,
      onToggleShowPassword,
      onInput,
    };
  },
});
</script>
