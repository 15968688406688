import { CampaignRow, CampaignStatus } from '@/features/campaigns/domain';
import { TemplateNames } from '@/components/table';
import { t } from '@/services/i18n';
import { sortByDate } from '@/components/table/utils/sortRows';

export const sortByStatus = (row1: CampaignRow, row2: CampaignRow) => {
  if (row1.status === row2.status) return 0;
  if (row1.status === CampaignStatus.ACTIVE) return -1;
  return 1;
};

export const campaignListTableHeaders = [
  {
    id: 'name',
    label: t('campaigns.headers.campaignName'),
    templateName: TemplateNames.TEXT,
    sort: (row1: CampaignRow, row2: CampaignRow) =>
      row1.name.localeCompare(row2.name),
    laptopOnly: true,
    extraClasses: 'w-laptopCampaignColWidth rounded-b-2 truncate text-ellipsis',
    searchable: true,
  },
  {
    id: 'name',
    label: t('campaigns.headers.campaignName'),
    templateName: TemplateNames.TEXT_WITH_DESCRIPTION,
    mobileOnly: true,
    description: (row: CampaignRow) =>
      `${row.startDate.toTableFormat()} - ${row.endDate.toTableFormat()}`,
    extraClasses: 'w-mobileCampaignColWidth',
    searchable: true,
  },
  {
    id: 'startDate',
    label: t('campaigns.headers.startDate'),
    sort: (row1: CampaignRow, row2: CampaignRow) =>
      sortByDate(row1, row2, 'startDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    extraClasses: 'w-dateColWidth',
  },
  {
    id: 'endDate',
    label: t('campaigns.headers.endDate'),
    sort: (row1: CampaignRow, row2: CampaignRow) =>
      sortByDate(row1, row2, 'endDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    extraClasses: 'w-dateColWidth',
  },
  {
    id: 'status',
    label: t('campaigns.headers.status'),
    sort: sortByStatus,
    templateName: TemplateNames.STATUS,
    extraClasses: 'w-mobileStatusColWidth md:w-laptopStatusColWidth',
  },
  {
    id: '',
    label: '',
    templateName: TemplateNames.DROPDOWN,
    headerTemplateName: TemplateNames.EMPTY_BIN_HEADER,
    extraClasses: 'rounded-2',
  },
];
