import { PreviewContent } from './iPreviewContent';

export type PreviewPriceCreationParams = {
  price?: PreviewContent;
  decimals?: PreviewContent;
  currency?: PreviewContent;
  cycle?: PreviewContent;
  bottom?: PreviewContent;
  top?: PreviewContent;
};

export class PreviewPrice {
  public price?: PreviewContent;
  public decimals?: PreviewContent;
  public currency?: PreviewContent;
  public cycle?: PreviewContent;
  public bottom?: PreviewContent;
  public top?: PreviewContent;

  private constructor(
    price?: PreviewContent,
    decimals?: PreviewContent,
    currency?: PreviewContent,
    cycle?: PreviewContent,
    bottom?: PreviewContent,
    top?: PreviewContent,
  ) {
    this.price = price;
    this.decimals = decimals;
    this.currency = currency;
    this.cycle = cycle;
    this.bottom = bottom;
    this.top = top;
  }

  static create(params: PreviewPriceCreationParams) {
    return new PreviewPrice(
      params.price,
      params.decimals,
      params.currency,
      params.cycle,
      params.bottom,
      params.top,
    );
  }
}
