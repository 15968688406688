
import { computed, ref, defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { Asset } from '../domain/asset';
import { Folder } from '../domain/folder';
import { useRenameAsset } from '../application/useRenameAsset';
import { useRenameFolder } from '../application/useRenameFolder';

export default defineComponent({
  props: {
    isModalOpen: { type: Boolean, required: true },
    media: { type: [Asset, Folder], required: true },
    refetchFolders: { type: Function, required: true },
    refetchAssets: { type: Function, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const hasSubmitted = ref(false);
    const newMediaName = ref(props.media.getName());
    const {
      isLoading: isRenameAssetLoading,
      call: renameAssetCall,
    } = useRenameAsset();
    const {
      isLoading: isRenameFolderLoading,
      call: renameFolderCall,
    } = useRenameFolder();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
      newMediaName.value = props.media.getName();
    };

    const onSubmit = async () => {
      hasSubmitted.value = true;
      if (newMediaName.value) {
        if (props.media.isFile()) {
          renameAssetCall({
            asset: props.media as Asset,
            newAssetName: newMediaName.value,
          });
        } else {
          await renameFolderCall({
            folder: props.media as Folder,
            newFolderName: newMediaName.value,
          });
          props.refetchFolders();
          props.refetchAssets();
        }
        onCancel();
      }
    };

    const isLoading = computed(
      () => isRenameAssetLoading.value || isRenameFolderLoading.value,
    );

    return {
      isLoading,
      hasSubmitted,
      onCancel,
      onSubmit,
      newMediaName,
    };
  },
});
