import { CreateMotiveVersionFailure } from '@/features/campaigns/domain';
import { CreateVersion } from '@/features/campaigns/domain/valueObjects/version';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';

export const useCreateMotiveVersion = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  return useMutation(async (versionInfo: CreateVersion) => {
    const result = await facade.createMotiveVersion(versionInfo);
    if (result.isLeft()) {
      const error= result.extract() as CreateMotiveVersionFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('campaigns.useCreateMotiveVersion.error'));
      }
    }
  });
};
