import FormGenerator from './formGenerator.vue';

export enum fieldTypes {
  INPUT = 'input',
  COLLAPSABLE_INPUT = 'collapsible_input',
  TOGGLE = 'toggle',
  SELECT = 'select',
  DATEPICKER = 'datepicker',
  RADIO_BUTTON_GROUP = 'radioButtonGroup',
  MEDIA_PICKER_GROUP = 'mediaPickerGroup',
  MEDIA_PICKER = 'mediaPicker',
  SMALL_MEDIA_PICKER = 'smallMediaPicker',
  SLIDER = 'slider',
  RICH_TEXT_EDITOR = 'richTextEditor',
  ALIGNMENT_PICKER = 'alignmentPicker',
  OFFSET_PICKER = 'offsetPicker',
  TRANSITION_ORIGIN = 'transitionOrigin',
  BACKGROUND_PLACEHOLDER = 'backgroundPlaceholder',
  INTERFERER_PLACEHOLDER = 'interfererPlaceholder',
  IMAGE = 'image',
}

export type Field = {
  id: string;
  type: fieldTypes;
  props: {
    options?: { value: string | boolean | number; label: string }[];
    label?: string;
    errorMessage?: string | null;
    type?: string;
    styleClasses?: string;
    labelStyleClasses?: string;
    containerStyleClasses?: string;
    placeholder?: string;
    from?: number;
    to?: number;
    unit?: string;
    multiplier?: number;
    tooltipPrecision?: number;
    valuePrecision?: number;
    horizontalId?: string;
    verticalId?: string;
    hasReset?: boolean;
    initialFont?: string;
    fontSize?: number;
    hasSecondaryValue?: boolean;
  };
};

export { FormGenerator };
