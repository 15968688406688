import { TemplateNames } from '../../../../../components/table';
import { t } from '@/services/i18n';
import { sortByDate } from '@/components/table/utils/sortRows';
import { CampaignDetailsRow, MotiveStatus } from '../../../domain';
import { sortByVersion } from '@/features/templates/application/templateTableHeaders';

export const sortByStatus = (
  row1: CampaignDetailsRow,
  row2: CampaignDetailsRow,
) => {
  if (row1.status === row2.status) return 0;
  if (row1.status === MotiveStatus.ACTIVE) return -1;
  return 1;
};
const sortByIsDefaultMotive = (
  row1: CampaignDetailsRow,
  row2: CampaignDetailsRow,
) => {
  if (row1.isDefaultMotive === row2.isDefaultMotive) return 0;
  if (row1.isDefaultMotive === true) return -1;
  return 1;
};

const sortByBulkMotive = (
  row1: CampaignDetailsRow,
  row2: CampaignDetailsRow,
) => {
  if (!row1.motiveGroupName && !row2.motiveGroupName) return 0;
  return row1.motiveGroupName < row2.motiveGroupName ? -1 : 1;
};

export const campaignDetailsTableHeaders = [
  {
    id: '',
    label: '',
    templateName: TemplateNames.CHECKBOX,
    headerTemplateName: TemplateNames.SELECT_ALL_HEADER,
    extraClasses: 'w-mobileCheckboxWidth md:w-laptopCheckboxWidth',
  },
  {
    id: 'name',
    label: t('campaigns.headers.motives'),
    templateName: TemplateNames.TEXT,
    sort: (row1: CampaignDetailsRow, row2: CampaignDetailsRow) =>
      row1.name.localeCompare(row2.name),
    laptopOnly: true,
    searchable: true,
    extraClasses: 'w-laptopMotiveColWidth truncate text-ellipsis',
  },
  {
    id: 'name',
    label: t('campaigns.headers.motives'),
    templateName: TemplateNames.TEXT_WITH_DESCRIPTION,
    mobileOnly: true,
    description: (row: CampaignDetailsRow) =>
      `${row.startDate.toTableFormatWithHours()} - ${row.endDate.toTableFormatWithHours()}`,
    searchable: true,
    extraClasses: 'w-mobileMotiveColWidth',
    headerTemplateName: TemplateNames.MOTIVE_HEADER,
  },
  {
    id: 'uniqueClickUrls',
    label: t('overview.tableHeaders.clickUrl'),
    templateName: TemplateNames.CLICK_URLS,
    laptopOnly: true,
    extraClasses: 'w-motiveDateColWidth',
    searchable: true,
  },
  {
    id: 'description',
    label: t('overview.tableHeaders.description'),
    templateName: TemplateNames.TEXT,
    sort: (row1: CampaignDetailsRow, row2: CampaignDetailsRow) =>
      row1.description?.localeCompare(row2.description),
    laptopOnly: true,
    extraClasses: 'w-laptopCampaignColWidth truncate text-ellipsis',
    searchable: true,
  },
  {
    id: 'startDate',
    label: t('campaigns.headers.startDate'),
    sort: (row1: CampaignDetailsRow, row2: CampaignDetailsRow) =>
      sortByDate(row1, row2, 'startDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    extraClasses: 'w-motiveDateColWidth',
  },
  {
    id: 'endDate',
    label: t('campaigns.headers.endDate'),
    sort: (row1: CampaignDetailsRow, row2: CampaignDetailsRow) =>
      sortByDate(row1, row2, 'endDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    extraClasses: 'w-motiveDateColWidth',
  },
  {
    id: 'isDefaultMotive',
    label: t('campaigns.headers.isDefaultMotive'),
    sort: sortByIsDefaultMotive,
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    extraClasses: 'w-isDefaultMotiveCol',
  },
  {
    id: 'templateVersion',
    label: t('campaigns.headers.templateVersion'),
    sort: (row1: CampaignDetailsRow, row2: CampaignDetailsRow) =>
      sortByVersion(row1.templateVersion, row2.templateVersion),
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    extraClasses: 'w-templateVersionCol',
  },
  {
    id: 'status',
    label: t('campaigns.headers.status'),
    sort: sortByStatus,
    templateName: TemplateNames.STATUS,
    laptopOnly: true,
    extraClasses: 'w-motiveStatusCol',
  },
  {
    id: '',
    label: '',
    templateName: TemplateNames.DROPDOWN,
    extraClasses: 'w-mobileCheckboxWidth',
  },
];
