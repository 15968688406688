
import { campaignsTranslateByScope } from '../../translationScope';
import { ref, defineComponent } from 'vue';
import Section from '../createMotiveModal/section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { BulkCreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';
import { CampaignEvents } from '../../events';

export default defineComponent({
  components: {
    Section,
  },
  props: {
    isSectionOpen: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true },
  },
  emits: [
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
    CampaignEvents.COMPLETE_SECTION,
  ],
  setup(_, context) {
    const t = campaignsTranslateByScope('bulkMotiveCreation');
    const hasSubmitted = ref(false);
    const {
      isBaseMotiveSectionValid,
      motiveGroupName,
      motives,
      baseMotiveId,
      isSelectValid,
      setIsSelectValid,
    } = injectStrict(BulkCreateMotiveFormKey);

    const onSectionDone = () => {
      hasSubmitted.value = true;
      if (isBaseMotiveSectionValid.value) {
        hasSubmitted.value = false;
        context.emit(CampaignEvents.COMPLETE_SECTION);
      }
    };

    return {
      t,
      CampaignEvents,
      motiveGroupName,
      hasSubmitted,
      motives,
      baseMotiveId,
      isSelectValid,
      setIsSelectValid,
      onSectionDone,
    };
  },
});
