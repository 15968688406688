import { Campaign } from '@/features/campaigns/domain/campaign';
import { useStore } from '@/store';
import { computed } from 'vue';

export const useGetCampaignDropdown = (
  params: {
    onlyActive: boolean;
  } = {
    onlyActive: false,
  },
) => {
  const store = useStore();

  const campaigns = computed(() => store.getters.campaigns);
  const campaignDropdownOptions = computed(() =>
    campaigns.value
      .filter((campaign: Campaign) => {
        if (params.onlyActive) {
          return campaign.active;
        }

        return true;
      })
      .map((campaign: Campaign) => ({
        value: campaign.id,
        label: campaign.name,
      })),
  );

  return { campaignDropdownOptions };
};
