import { PreviewAttributes } from './iPreviewAttributes';
import { PreviewTransition } from './iPreviewTransition';

export type PreviewInterfererCreationParams = {
  content: PreviewInterfererImage[];
  duration?: number | undefined;
  attributes?: PreviewAttributes;
  transition?: PreviewTransition;
};

export class PreviewInterferer {
  private constructor(
    public type: 'slide',
    public content: PreviewInterfererImage[],
    public duration?: number | undefined,
    public attributes?: PreviewAttributes | undefined,
    public transition?: PreviewTransition | undefined,
  ) {}

  static create(params: PreviewInterfererCreationParams) {
    return new PreviewInterferer(
      'slide',
      params.content,
      params.duration,
      params.attributes,
      params.transition,
    );
  }
}

export type PreviewInterfererImageCreationParams = {
  content?: string;
  caption: {
    content?: string;
    attributes?: PreviewAttributes;
  };
};

export class PreviewInterfererImage {
  private constructor(
    public type: 'image',
    public content: string,
    public caption: {
      type: 'text';
      content: string;
      attributes?: PreviewAttributes;
    },
  ) {}

  static create(params: PreviewInterfererImageCreationParams) {
    return new PreviewInterfererImage('image', params.content ?? '', {
      type: 'text',
      content: params.caption.content ?? '',
      attributes: params.caption.attributes,
    });
  }
}
