import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from '@/store';
import { Ref } from 'vue';
import { fetchMotivesForCampaignIdSucceeded } from '@/features/campaigns/store/store';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';

const fetch = (facade: CampaignHttpFacade, campaignId: string) => async () => {
  const result = await facade.getMotivesForCampaignId(campaignId);
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchMotivesForCampaignId = ({
  campaignId,
  facade = new CampaignHttpFacade(),
}: {
  campaignId: string;
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade, campaignId),
    motives => {
      store.dispatch(fetchMotivesForCampaignIdSucceeded, {
        campaignId,
        motives,
      });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
