import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Contract } from '../../domain/contract';

export const useGetContractsById = (): ComputedRef<Record<
  string,
  Contract
>> => {
  const store = useStore();

  return computed(() => store.getters.contractsById());
};
