import { SessionHttpFacade } from '../infrastructure/session_http_facade';
import { useToast } from 'vue-toastification';
import { ref } from 'vue';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { SendResetMailFailure } from '../domain';

export const useSendResetMail = (
  onSuccess: () => void,
  facade: SessionHttpFacade = new SessionHttpFacade(),
) => {
  const toast = useToast();
  const isLoading = ref(false);

  return useMutation(async (email: string) => {
    isLoading.value = true;
    const result = await facade.sendResetMail(email);

    isLoading.value = false;
    if (result.isRight()) {
      onSuccess();
    } else {
      const error= result.extract() as SendResetMailFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('password.useSendResetMail.error'));
      }
    }
  });
};
