<template>
  <app-layout>
    <div class="flex flex-col md:mx-2">
      <div class="flex flex-row mb-2 md:mb-0.5 mx-2 md:mx-0">
        <app-txt
          variant="small"
          class="text-gray-500 cursor-pointer"
          @click="returnToCampaignOverview"
          >{{ $t('campaigns.title') }}</app-txt
        >
        <app-txt variant="small" class="text-gray-400 ml-2 mr-2">/</app-txt>
      </div>
      <!-- Laptop only -->
      <div class="laptop-only">
        <div class="flex flex-row justify-between items-center">
          <div class="flex flex-row items-center">
            <app-txt variant="h1" class="text-white">{{ name }}</app-txt>
            <app-button
              variant="base"
              class="ml-3 border border-primary text-primary focus:outline-none px-2 py-10px text-small"
              rightIcon="editPenPrimary"
              @click="() => setIsEditCampaignModalOpen(true)"
              >{{ $t('campaigns.details.edit') }}</app-button
            >
          </div>
          <div class="flex-row flex items-center">
            <app-input
              :placeholder="$t('campaigns.details.search')"
              leftIcon="search"
              v-model="searchedValue"
            />
            <app-button
              leftIcon="plusCircle"
              @click="() => setIsCreateMotiveModalOpen(true)"
              class="ml-3 mt-1"
              >{{ $t('campaigns.details.newMotive') }}</app-button
            >
          </div>
        </div>
      </div>

      <!-- Mobile only -->

      <div class="mobile-only">
        <div class="mx-2">
          <div class="flex flex-row items-center">
            <app-txt variant="h3" class="text-white">{{ name }}</app-txt>
            <app-button
              variant="base"
              class="ml-1.5 text-primary focus:outline-none text-small"
              rightIcon="editPenPrimary"
              @click="() => setIsEditCampaignModalOpen(true)"
            ></app-button>
          </div>

          <app-input
            :placeholder="$t('campaigns.search')"
            leftIcon="search"
            v-model="searchedValue"
            class="w-full mt-2"
          />

          <div class="flex flew-row items-center mt-2 w-full justify-between">
            <app-button
              leftIcon="plusCircle"
              @click="() => setIsCreateMotiveModalOpen(true)"
              class="w-full"
              >{{ $t('campaigns.details.newMotive') }}</app-button
            >
          </div>
        </div>
      </div>

      <app-table
        :headers="headers"
        :rows="rows"
        :searchedValue="searchedValue"
        class="mt-3 mb-4"
        :pageSize="CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE"
        :onRowClick="onRowClick"
      >
        <template v-slot:[TemplateNames.CHECKBOX]="{ rowId }"
          ><CheckboxCell
            @select-checkbox="
              isSelected => onCheckboxSelected(rowId, isSelected)
            "
            :checked="!!selectedRows[rowId]"
        /></template>

        <template v-slot:[TemplateNames.SELECT_ALL_HEADER]="{ formattedRows }">
          <SelectAllHeaderCell
            @select-checkbox="onSelectAll"
            :rowsToSelect="formattedRows"
          />
        </template>

        <template v-slot:[TemplateNames.CLICK_URLS]="{ value }"
          ><app-txt>
            <img
              src="../../../../components/icon/assets/link.svg"
              class="link-icon cursor-pointer ml-2"
              alt="Link Icon"
              v-tippy="{
                content: generateTooltipContent(value),
                allowHTML: true,
                interactive: true,
                theme: 'custom',
                placement: 'top-start',
              }"
            /> </app-txt
        ></template>
        <template v-slot:[TemplateNames.TEXT]="{ value }"
          ><app-txt>{{ value }}</app-txt></template
        >

        <template v-slot:[TemplateNames.DATE]="{ value }"
          ><DateCell :value="value" :displayTime="true"
        /></template>

        <template
          v-slot:[TemplateNames.TEXT_WITH_DESCRIPTION]="{ value, description }"
        >
          <TextWithDescriptionCell :value="value" :description="description" />
        </template>

        <template v-slot:[TemplateNames.STATUS]="{ value }"
          ><StatusCell :value="value"
        /></template>

        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
          ><SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
        /></template>

        <template v-slot:[TemplateNames.MOTIVE_HEADER]="{ header }"
          ><MotiveHeaderCell
            :header="header"
            :showBulkEdit="showBulkEdit"
            @bulk-edit="onBulkEdit"
        /></template>

        <template v-slot:[TemplateNames.DROPDOWN]="{ rowId }"
          ><CampaignDetailDropdownCell
            :motiveId="rowId"
            @delete-motive="onDeleteMotive"
            @duplicate-motive="onDuplicateMotive"
        /></template>

        <template v-slot:[TemplateNames.MOTIVE_GROUP]="{ value }"
          ><MotiveGroupCell :motiveGroupName="value"
        /></template>

        <template v-slot:[TemplateNames.EMPTY_STATE]>
          <CampaignDetailsEmptyState
            @create-motive="() => setIsCreateMotiveModalOpen(true)"
            :isNoSearchResult="!!searchedValue && !!rows.length"
          />
        </template>
      </app-table>
    </div>
    <EditCampaignModal
      :campaign="campaign"
      :isModalOpen="isEditCampaignModalOpen"
      @close-modal="() => setIsEditCampaignModalOpen(false)"
    />
    <CreateMotiveModal
      :isModalOpen="isCreateMotiveModalOpen"
      @close-modal="() => setIsCreateMotiveModalOpen(false)"
      :campaignId="campaign.id"
    />
    <DuplicateMotiveModal
      :isModalOpen="!!currentlyDuplicatedMotiveId"
      @close-modal="resetCurrentlyDuplicatedMotiveId"
      :motiveId="currentlyDuplicatedMotiveId"
      :campaignId="campaign.id"
    />
    <BulkCreateMotiveModal
      v-if="isBulkCreateMotiveModalOpen"
      :isModalOpen="isBulkCreateMotiveModalOpen"
      @close-modal="() => setIsBulkCreateMotiveModalOpen(false)"
      :campaignId="campaign.id"
    />
    <confirm-deletion-modal
      :title="$t('common.deletionModal.title')"
      :confirm-label="$t('common.deletionModal.confirmDeleteLabel')"
      :cancel-label="$t('common.deletionModal.cancel')"
      :description="$t('common.deletionModal.description')"
      :delete-function="deleteMotive"
      :is-modal-open="!!currentlyDeletedMotiveId"
      @close-modal="resetCurrentlyDeletedMotiveId"
    />
  </app-layout>
</template>

<script lang="ts">
import ConfirmDeletionModal from '@/components/confirmDeletionModal/confirmDeletionModal.vue';
import { TemplateNames } from '@/components/table';
import { DateWrapper } from '@/core';
import { useDeleteMotive } from '@/features/campaigns/application/motive/actions/useDeleteMotive';
import { t } from '@/services/i18n';
import { generateTooltipContent } from '@/utils/generateTooltipContent';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  CheckboxCell,
  DateCell,
  SelectAllHeaderCell,
  SortableHeaderCell,
  StatusCell,
  TextWithDescriptionCell,
} from '../../../../components/table/cellTemplates';
import { useGetCampaignById } from '../../application/campaign/actions/useGetCampaignById';
import { campaignDetailsTableHeaders } from '../../application/campaign/details/campaignDetailsTableHeaders';
import { useGetCampaignDetailsRows } from '../../application/campaign/list/useGetCampaignDetailsRows';
import { useFetchMotivesForCampaignId } from '../../application/motive/actions/useFetchMotivesForCampaignId';
import CampaignDetailDropdownCell from '../campaignDetails/campaignDetailsDropdownCell.vue';
import CampaignDetailsEmptyState from '../campaignDetails/campaignDetailsEmptyState.vue';
import MotiveHeaderCell from '../campaignDetails/motiveHeaderCell.vue';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import BulkCreateMotiveModal from './bulkCreateMotiveModal/bulkCreateMotiveModal.vue';
import CreateMotiveModal from './createMotiveModal/createMotiveModal.vue';
import DuplicateMotiveModal from './duplicateMotiveModal.vue';
import EditCampaignModal from './editCampaignModal.vue';
import MotiveGroupCell from './motiveGroupCell.vue';

const CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    ConfirmDeletionModal,
    DateCell,
    TextWithDescriptionCell,
    CheckboxCell,
    SortableHeaderCell,
    SelectAllHeaderCell,
    MotiveHeaderCell,
    CampaignDetailDropdownCell,
    CampaignDetailsEmptyState,
    EditCampaignModal,
    CreateMotiveModal,
    StatusCell,
    DuplicateMotiveModal,
    BulkCreateMotiveModal,
    MotiveGroupCell,
  },
  methods: {
    generateTooltipContent,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { campaign } = useGetCampaignById(route.query.id as string);
    const campaignName = computed(() => campaign?.value?.name);

    useFetchMotivesForCampaignId({ campaignId: campaign.value.id });
    const rows = useGetCampaignDetailsRows(campaign.value.id);
    const searchedValue = ref('');

    const isEditCampaignModalOpen = ref(false);
    const setIsEditCampaignModalOpen = (value: boolean) => {
      isEditCampaignModalOpen.value = value;
    };

    const isCreateMotiveModalOpen = ref(false);
    const setIsCreateMotiveModalOpen = (value: boolean) => {
      isCreateMotiveModalOpen.value = value;
    };

    const onBulkEdit = () => {
      console.log('Bulk edit');
    };

    const selectedRows = ref<Record<string, boolean>>({});
    const onCheckboxSelected = (rowId: string, isSelected: boolean) => {
      selectedRows.value[rowId] = isSelected;
    };
    const onSelectAll = (params: {
      selectAll: boolean;
      rowsToSelect: { id: string }[];
    }) => {
      if (!params.selectAll) {
        selectedRows.value = {};
      } else {
        selectedRows.value = Object.fromEntries(
          params.rowsToSelect.map(row => [row.id, true]),
        );
      }
    };
    const formattedRows = computed(() =>
      rows.value
        .map((row: { id: string; startDate: DateWrapper }) => {
          return {
            ...row,
            isSelected: !!selectedRows.value[row.id],
          };
        })
        .sort((a, b) => {
          const time1 = a.startDate.getValue()?.getTime();
          const time2 = b.startDate.getValue()?.getTime();
          if (!time1 || !time2) return 0;
          if (time1 < time2) return 1;
          if (time1 > time2) return -1;
          return 0;
        }),
    );

    const showBulkEdit = computed(
      () => formattedRows.value.filter(row => row.isSelected).length > 1,
    );

    const returnToCampaignOverview = () => {
      router.push({ name: campaignsRouteNames.campaignList });
    };
    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.motiveDetails, query: { id } });
    };

    const currentlyDeletedMotiveId = ref('');

    const { call } = useDeleteMotive();
    const onDeleteMotive = (motiveId: string) => {
      currentlyDeletedMotiveId.value = motiveId;
    };
    const deleteMotive = async () => {
      await call(currentlyDeletedMotiveId.value);
      currentlyDeletedMotiveId.value = '';
    };

    const resetCurrentlyDeletedMotiveId = () => {
      currentlyDeletedMotiveId.value = '';
    };

    const currentlyDuplicatedMotiveId = ref('');
    const onDuplicateMotive = (motiveId: string) => {
      currentlyDuplicatedMotiveId.value = motiveId;
    };
    const resetCurrentlyDuplicatedMotiveId = () => {
      currentlyDuplicatedMotiveId.value = '';
    };

    const isBulkCreateMotiveModalOpen = ref(false);
    const setIsBulkCreateMotiveModalOpen = (value: boolean) => {
      isBulkCreateMotiveModalOpen.value = value;
    };

    return {
      t,
      name: campaignName,
      campaign,
      searchedValue,
      returnToCampaignOverview,
      TemplateNames,
      headers: campaignDetailsTableHeaders,
      rows: formattedRows,
      onCheckboxSelected,
      onSelectAll,
      selectedRows,
      CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE,
      showBulkEdit,
      onBulkEdit,
      isEditCampaignModalOpen,
      setIsEditCampaignModalOpen,
      isCreateMotiveModalOpen,
      setIsCreateMotiveModalOpen,
      onRowClick,
      currentlyDeletedMotiveId,
      onDeleteMotive,
      resetCurrentlyDeletedMotiveId,
      onDuplicateMotive,
      currentlyDuplicatedMotiveId,
      resetCurrentlyDuplicatedMotiveId,
      isBulkCreateMotiveModalOpen,
      setIsBulkCreateMotiveModalOpen,
      deleteMotive,
    };
  },
};
</script>
