import { computed, Ref, ref } from 'vue';
import { moveArrayElement } from './utils';
import * as uuid from 'uuid';
import {
  generateDefaultSlideForValuesParams,
  generateDefaultSlideParams,
} from './defaults/defaultSlide';
import { deepCopyJson } from '@/core/helpers';
import { useHandleSlideElements } from './useHandleSlideElements';
import { FormSlidesState } from '../editor/types';
import {
  Slide,
  CreateSlideParams,
  DEFAULT_SALES_SLIDE_INDEX,
} from '@/features/campaigns/domain/valueObjects/slide';

export const useHandleSlides = (
  slideOrder: Ref<string[]>,
  initialSlideOrder: Ref<string[]>,
  slides: Ref<Record<string, Slide>>,
  initialSlides: Ref<Record<string, Slide>>,
  defaultSlides: Ref<Record<string, CreateSlideParams | undefined>>,
  initialState?: FormSlidesState,
) => {
  const openSlideIndex = ref(initialState?.openSlideIndex ?? -1);
  const openSlideSectionIndices = ref<number[]>(
    initialState?.openSlideSectionIndices ?? [],
  );

  const openSlide = (index: number) => {
    openSlideSectionIndices.value = [];
    openSlideIndex.value = index;
  };

  const closeSlide = () => {
    openSlideSectionIndices.value = [];
    openSlideIndex.value = -1;
  };

  const toggleSlide = (index: number) => {
    if (index === openSlideIndex.value) {
      closeSlide();
    } else {
      openSlide(index);
    }
  };

  const addSlide = () => {
    const defaultSlidesArr = Object.values(defaultSlides?.value ?? {});
    const length = defaultSlidesArr?.length;
    // New slide should be cloned from the sales slide
    const defaultLastSlide = defaultSlidesArr[length - 1];
    defaultLastSlide!.isDefault = false;
    defaultLastSlide!.removed = false;
    const slideParams = defaultLastSlide
      ? deepCopyJson(defaultLastSlide)
      : generateDefaultSlideParams();

    slideParams.id = uuid.v4();
    slides.value[slideParams.id] = Slide.create(slideParams);
    slideOrder.value.push(slideParams.id);
  };

  const insertSlide = (index: number) => {
    const slideParams = generateDefaultSlideForValuesParams();
    slides.value[slideParams.id] = Slide.createForValues(slideParams);
    slideOrder.value.splice(index, 0, slideParams.id);
    openSlide(index);
  };

  const removeSlide = (index: number, isDefaultSlide: boolean) => {
    const slideId = slideOrder.value[index];

    if (isDefaultSlide) {
      slides.value[slideId].removed = true;
      closeSlide();
      return;
    }
    slideOrder.value.splice(index, 1);
    delete slides.value[slideId];

    if (index === openSlideIndex.value) {
      closeSlide();
    } else {
      openSlideIndex.value = openSlideIndex.value - 1;
    }
  };

  const toggleSlideSection = (index: number) => {
    if (!openSlideSectionIndices.value.includes(index)) {
      openSlideSectionIndices.value.push(index);
    } else {
      openSlideSectionIndices.value = openSlideSectionIndices.value.filter(
        sectionIndex => sectionIndex !== index,
      );
    }
  };

  const duplicateSlide = (slideId: string) => {
    const slideParams = {
      ...slides.value[slideId],
      id: uuid.v4(),
    };
    slides.value[slideParams.id] = Slide.createForValues(slideParams);
    slideOrder.value.push(slideParams.id);
    openSlide(slideOrder.value.length - 1);
  };

  const moveSlideUp = (index: number) => {
    slideOrder.value = moveArrayElement(index, index - 1, slideOrder.value);
    openSlide(Math.max(index - 1, 0));
  };

  const moveSlideDown = (index: number) => {
    slideOrder.value = moveArrayElement(index, index + 1, slideOrder.value);
    openSlide(Math.min(index + 1, slideOrder.value.length - 1));
  };

  const handleSlideElements = useHandleSlideElements(slides);

  const isValid = computed(() => {
    return true;
  });

  const propsAreEqual = (props: object, initialProps: object): boolean => {
    if (!props || !initialProps) {
      return props === initialProps;
    }

    const isArray = Array.isArray(props);

    const isSameLength =
      (props as object[]).length === (initialProps as object[]).length;

    if (isArray && !isSameLength) {
      return false;
    }

    let hasChanges = false;

    for (const key in props) {
      const value = props[key as keyof typeof props];
      const initialValue = initialProps[key as keyof typeof initialProps];
      if (typeof value === 'object' && value !== null) {
        hasChanges = hasChanges || !propsAreEqual(value, initialValue);
      } else if (value !== initialValue) {
        hasChanges = true;
      }
    }

    return !hasChanges;
  };

  const slideHasChanges = (slideId: string) => {
    const slide = slides.value[slideId];
    const initialSlide = initialSlides.value[slideId];
    const slideProps = slide?.toJson();
    const initialSlideProps = initialSlide?.toJson();

    return !propsAreEqual(slideProps, initialSlideProps);
  };

  const slidesHaveChanges = computed(() => {
    if (
      JSON.stringify(slideOrder.value) !==
      JSON.stringify(initialSlideOrder.value)
    ) {
      return true;
    }

    return slideOrder.value.some(id => slideHasChanges(id));
  });

  const slidesWithChanges = computed(() => {
    return slideOrder.value.filter(id => slideHasChanges(id));
  });

  const slidesFormState = computed(() => {
    return {
      openSlideIndex: openSlideIndex.value,
      openSlideSectionIndices: openSlideSectionIndices.value,
    };
  });

  const openSettings = (slideId: string, section: string) => {
    const sections = ['general', 'visual', 'price', 'cta'];
    const sectionIndex = sections.indexOf(section);
    openSlideIndex.value = slideOrder.value.findIndex(id => id === slideId);
    openSlideSectionIndices.value = sectionIndex >= 0 ? [sectionIndex] : [];
  };

  const slideAllowAnimations = false;

  return {
    slidesHaveChanges,
    slidesWithChanges,
    isValid,
    addSlide,
    insertSlide,
    removeSlide,
    openSlideIndex,
    openSlideSectionIndices,
    toggleSlide,
    toggleSlideSection,
    duplicateSlide,
    moveSlideUp,
    moveSlideDown,
    slidesFormState,
    openSettings,
    slideAllowAnimations,
    ...handleSlideElements,
  };
};
