import { TemplateVersion } from './value_objects';

export class Template {
  private constructor(
    public version: TemplateVersion,
    public description: string,
  ) {}

  static create(params: { version: string; description: string }) {
    return new Template(
      TemplateVersion.create(params.version),
      params.description,
    );
  }

  public toRow() {
    return {
      version: this.version.getOrCrash(),
      description: this.description,
    };
  }
}

export type TemplateRow = {
  version: string;
  description: string;
};
