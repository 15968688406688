<template>
  <BulkCreateMotiveModalWrapper
    :isOpen="isModalOpen"
    @close-modal="closeModal"
    :title="t('title')"
    :cancelLabel="t('cancel')"
    :confirmLabel="t('confirm')"
    :onConfirm="onSubmit"
    :isLoading="isLoading"
    :disabled="isSubmitDisabled"
  >
    <BaseMotive
      v-model:isSectionOpen="isBaseMotiveSectionOpen"
      :isCompleted="isBaseMotiveSectionDone"
      @complete-section="onBaseMotiveSectionDone"
    />

    <Variations
      v-model:isSectionOpen="isVariationsSectionOpen"
      :isCompleted="isVariationsSectionDone"
      @complete-section="onVariationsSectionDone"
    />

    <AutoUpdate v-model:isSectionOpen="isAutoUpdateSectionOpen" />
  </BulkCreateMotiveModalWrapper>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import { campaignsTranslateByScope } from '../../translationScope';
import { defineComponent, provide } from 'vue';
import { useBulkCreateMotiveForm } from '../../../application/motive/forms/useBulkCreateMotiveForm';
import { BulkCreateMotiveFormKey } from '../../../application/motive/forms/types';
import BulkCreateMotiveModalWrapper from './bulkCreateMotiveModalWrapper.vue';
import BaseMotive from './baseMotive.vue';
import Variations from './variations.vue';
import AutoUpdate from './autoUpdate.vue';

export default defineComponent({
  components: {
    BulkCreateMotiveModalWrapper,
    BaseMotive,
    Variations,
    AutoUpdate,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    campaignId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { campaignId: string }, context) {
    const t = campaignsTranslateByScope('bulkMotiveCreation');

    const formData = useBulkCreateMotiveForm(props.campaignId);
    provide(BulkCreateMotiveFormKey, formData);

    const {
      isBaseMotiveSectionOpen,
      isBaseMotiveSectionDone,
      clearForm,
      isLoading,
      isSubmitDisabled,
      isVariationsSectionOpen,
      isVariationsSectionDone,
      onBulkCreateMotive,
      isAutoUpdateSectionOpen,
    } = formData;

    const onBaseMotiveSectionDone = () => {
      isBaseMotiveSectionOpen.value = false;
      isBaseMotiveSectionDone.value = true;
      isVariationsSectionOpen.value = true;
    };

    const onVariationsSectionDone = () => {
      isVariationsSectionOpen.value = false;
      isVariationsSectionDone.value = true;
      isAutoUpdateSectionOpen.value = true;
    };

    const closeModal = () => {
      clearForm();
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSubmit = async () => {
      await onBulkCreateMotive();
      closeModal();
    };

    return {
      t,
      isLoading,
      closeModal,
      onSubmit,
      isSubmitDisabled,
      isBaseMotiveSectionOpen,
      isBaseMotiveSectionDone,
      onBaseMotiveSectionDone,
      isVariationsSectionOpen,
      isVariationsSectionDone,
      onVariationsSectionDone,
      isAutoUpdateSectionOpen,
    };
  },
});
</script>
../../../application/motive/forms/types