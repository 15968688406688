import { Position, PositionCreationParams } from '../position';
import { CreateSlideElementParams, SlideElement } from './slideElement';
import { CSSInlineStyle } from '../preview/iPreviewAttributes';
import { Asset } from '@/features/media/domain/asset';
import {
  getImageContentIndexes,
  getVisualInfoForFormat,
} from './helpers/slideImageHelpers';
import { PreviewTransition } from '../preview/iPreviewTransition';
import { PreviewSlideContent } from '../preview/previewSlideContent';

export enum ImageContentType {
  Square = 'square',
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}
export interface SlideImageContent {
  type?: ImageContentType;
  id?: string;
}

export type CreateSlideImageParams = CreateSlideElementParams & {
  content?: SlideImageContent[];
  scaling?: number;
  transition?: PreviewTransition;
  position?: PositionCreationParams;
};

export type CreateSlideImageForValuesParams = CreateSlideElementParams & {
  content?: SlideImageContent[];
  scaling?: number;
  transition?: PreviewTransition;
  position?: Position;
  removed?: boolean;
  isDefault?: boolean;
};

const defaultLabel = 'Image';

export class SlideImage extends SlideElement {
  public content?: SlideImageContent[];
  public scaling?: number;
  public transition?: PreviewTransition | undefined;
  public position?: Position;

  private constructor(params: CreateSlideImageForValuesParams) {
    super(params.label, params.zIndex, params.removed);
    this.content = params.content;
    this.scaling = params.scaling;
    this.transition = params.transition;
    this.position = params.position;
    this.isDefault = params.isDefault;
  }

  public toPreviewContent({
    formatName,
    imagesById,
  }: {
    formatName: string;
    imagesById: Record<string, Asset>;
  }): PreviewSlideContent {
    const imageContentIndexes = getImageContentIndexes(this.content);
    const visualInfo = getVisualInfoForFormat({
      formatName: formatName,
      imagesById: imagesById,
      square: this.content?.[imageContentIndexes.square]?.id,
      vertical: this.content?.[imageContentIndexes.vertical]?.id,
      horizontal: this.content?.[imageContentIndexes.horizontal]?.id,
    });

    const style = new CSSInlineStyle()
      .addPosition(this.position?.toJson())
      .addWidth(this.scaling)
      .addZIndex(this.zIndex)
      .build();

    return PreviewSlideContent.createAsImage({
      transition: this.transition,
      attributes: {
        style,
      },
      content: visualInfo.visualUrl ?? '',
    });
  }

  static create(params: CreateSlideImageParams): SlideImage {
    return new SlideImage({
      label: params.label ?? defaultLabel,
      content: params.content,
      transition: params.transition,
      position: params.position ? Position.create(params.position) : undefined,
      scaling: params.scaling,
      zIndex: params.zIndex,
      removed: params.removed,
      isDefault: params.isDefault,
    });
  }

  static createForValues(params: CreateSlideImageForValuesParams): SlideImage {
    return new SlideImage({
      label: params.label ?? defaultLabel,
      content: params.content,
      position: params.position,
      transition: params.transition,
      scaling: params.scaling,
      zIndex: params.zIndex,
      removed: params.removed,
      isDefault: params.isDefault,
    });
  }

  public toJson() {
    return {
      label: this.label,
      content: this.content,
      transition: this.transition,
      position: this.position?.toJson(),
      scaling: this.scaling,
      zIndex: this.zIndex,
      removed: this.removed,
      isDefault: this.isDefault,
    };
  }
}
