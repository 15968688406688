
import { onUpdated, ref, defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { notificationsTranslateByScope } from './translationScope';
import { useUpdateEnabledNotifications } from "@/features/users/application/useUpdateEnabledNotifications";
import Toggle from '@vueform/toggle';
import { EnabledNotifications } from '../domain';

export default defineComponent({
  props: {
    isModalOpen: { type: Boolean, required: true },
    userNotifications: { type: Object as () => EnabledNotifications, required: true},
    userId: {type: String, required: true}
  },
  components: {
    Toggle
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const { isLoading, call } = useUpdateEnabledNotifications();
    const motivesNotifications = ref(props.userNotifications.motivesNotifications);
    const campaignsNotifications = ref(props.userNotifications.campaignsNotifications);
    const motivesEditingNotifications = ref(props.userNotifications.motivesEditingNotifications);
    const motivesDeactivationNotifications = ref(props.userNotifications.motivesDeactivationNotifications);
    const t = notificationsTranslateByScope('notifications');
    const hasSubmitted = ref(false);
    const turnOffAllNotifications = ref(!(motivesNotifications.value || campaignsNotifications.value || motivesEditingNotifications.value || motivesDeactivationNotifications.value));
    const closeModal = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const turnOffAllNotificationsHandler = () => {
      motivesNotifications.value = false;
      motivesEditingNotifications.value = false;
      motivesDeactivationNotifications.value = false;
      campaignsNotifications.value = false;
    }
    const turnOnNotificationsHandler = () => {
      turnOffAllNotifications.value = !(motivesNotifications.value || campaignsNotifications.value || motivesEditingNotifications.value || motivesDeactivationNotifications.value);
    }

    const onEditEnabledNotifications = () => {
      hasSubmitted.value = true;
      call({id: props.userId, enabledNotifications: {motivesNotifications: motivesNotifications.value, motivesEditingNotifications: motivesEditingNotifications.value, motivesDeactivationNotifications: motivesDeactivationNotifications.value, campaignsNotifications: campaignsNotifications.value}});
      closeModal();
    };

    onUpdated(() => {
      setTimeout(() => {
        hasSubmitted.value = false;
        motivesNotifications.value = props.userNotifications.motivesNotifications;
        motivesEditingNotifications.value = props.userNotifications.motivesEditingNotifications;
        motivesDeactivationNotifications.value = props.userNotifications.motivesDeactivationNotifications;
        campaignsNotifications.value = props.userNotifications.campaignsNotifications;
        turnOffAllNotifications.value = !(motivesNotifications.value || campaignsNotifications.value || motivesEditingNotifications.value || motivesDeactivationNotifications.value);
      }, 300)
    });

    return {
      t,
      onEditEnabledNotifications,
      hasSubmitted,
      closeModal,
      Toggle,
      turnOffAllNotifications,
      turnOffAllNotificationsHandler,
      turnOnNotificationsHandler,
      motivesEditingNotifications,
      motivesDeactivationNotifications,
      campaignsNotifications,
      motivesNotifications,
      isLoading,
      props
    };
  },
});
