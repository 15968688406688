import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { Asset } from '../domain/asset';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { updateAssetSucceeded } from '../store/store';
import { RenameAssetFailure } from '../domain';

export const useRenameAsset = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { asset: Asset; newAssetName: string }) => {
    const result = await facade.renameAsset(
      params.asset.id,
      `${params.newAssetName}.${params.asset.getFileExtension()}`,
    );

    if (result.isRight()) {
      const asset = result.extract();
      store.dispatch(updateAssetSucceeded, {
        asset,
      });

      toast.success(t('media.useRenameAsset.success'));
    } else {
      const error= result.extract() as RenameAssetFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('media.useRenameAsset.error'));
      }
    }
  });
};
