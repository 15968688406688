<template>
  <div class="w-full">
    <div class="w-full relative">
      <span v-if="label" class="text-gray-700 font-medium text-small">{{
        label
      }}</span>
      <dropdown-wrapper
        :class="{
          'left-0 right-0 max-h-select overflow-y-auto': true,
          'top-10': label,
          'top-6': !label,
        }"
        :disabled="disabled"
        :isStatic="true"
        v-model:isOpen="isDropdownOpen"
      >
        <template v-slot:trigger>
          <div
            :class="{
              'relative flex flex-row items-center w-full py-2 px-2 rounded-1 border rounded-md  h-buttonHeight bg-white': true,
              'mt-1': !!label,
              'border-red-600': errorMessage,
              'border-gray-300': !errorMessage,
              'bg-gray-disabled': disabled,
            }"
            :title="selectedLabels.join(', ')"
          >
            <input
              class="text-gray-500 text-small truncate pr-2 w-full h-searchableSelect mr-2 focus:outline-none"
              :id="inputId"
              :value="selectedLabels.join(', ')"
              :placeholder="placeholder"
              :disabled="disabled"
              @click.stop="toggleDropdown"
              @keydown.stop
              readonly
            />

            <div class="absolute pr-2 right-0" v-if="errorMessage">
              <app-icon name="invalid" alt="invalid icon" />
            </div>
            <div class="absolute right-0 pr-2" v-else>
              <app-icon name="chevronDown" alt="chevron down icon" />
            </div>
          </div>
        </template>
        <template v-slot:dropdown>
          <div>
            <div
              class="flex items-center text-gray-700 space-x-2 mx-1 border-b border-gray-slider"
            >
              <div class="mt-1 flex gap-1 pb-1">
                <app-icon
                  name="search"
                  alt="Search icon"
                  class="text-gray-500"
                />
                <input
                  type="text"
                  class="flex-grow text-small text-gray-400 border-none rounded focus:outline-none focus:border-blue-500"
                  :placeholder="t('searchForCampaign')"
                  v-model="searchedValue"
                />
              </div>
            </div>
            <div class="p-1 flex">
              <input
                type="checkbox"
                id="select-all"
                :checked="isAllSelected"
                @change="toggleSelectAll($event)"
              />
              <label
                for="select-all"
                class="ml-1 cursor-pointer text-gray-700 text-small"
                >{{ t('selectAll') }}</label
              >
            </div>
            <div
              v-for="option in filteredOptions"
              :key="option.value"
              class="flex flex-row items-center w-full p-1"
            >
              <input
                type="checkbox"
                :id="option.value"
                v-model="selectedValues"
                :value="option.value"
                class="mr-1"
              />
              <label
                :for="option.value"
                class="flex-grow text-gray-700 cursor-pointer text-small"
                >{{ option.label }}</label
              >
            </div>
          </div>
        </template>
      </dropdown-wrapper>
    </div>
    <span class="text-red-600" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref, watch } from 'vue';
import { CoreEvents } from '@/core/domain/events';
import { t } from '@/services/i18n';
import { overviewTranslateByScope } from '@/features/campaigns/presentation/overviewTranslationScope';

interface Option {
  value: string;
  label: string;
}

export default defineComponent({
  components: {},
  name: 'app-searchable-select-multi',
  emits: [CoreEvents.UPDATE_MODEL_VALUE, 'update:isValid'],
  props: {
    placeholder: { type: String, default: '' },
    label: String,
    errorMessage: String,
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    disabled: { type: Boolean, default: false },
  },
  setup(props, context) {
    const t = overviewTranslateByScope('exportModal');
    const isDropdownOpen = ref(false);
    const selectedValues = ref(props.modelValue || []);

    const selectedLabels = computed(() =>
      props.options
        .filter(option => selectedValues.value.includes(option.value))
        .map(option => option.label),
    );

    const searchedValue = ref('');

    watch(
      selectedValues,
      newVal => {
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, newVal);
        context.emit('update:isValid', newVal.length > 0);
      },
      { deep: true },
    );

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const filteredOptions = computed(() => {
      if (!searchedValue.value) return props.options;
      return props.options.filter(option =>
        option.label.toLowerCase().includes(searchedValue.value.toLowerCase()),
      );
    });

    const toggleSelectAll = (event: Event) => {
      if (event.target && (event.target as HTMLInputElement).checked) {
        selectedValues.value = filteredOptions.value.map(
          option => option.value,
        );
      } else {
        selectedValues.value = [];
      }
    };

    const isAllSelected = computed(() => {
      return filteredOptions.value.every(option =>
        selectedValues.value.includes(option.value),
      );
    });

    return {
      toggleDropdown,
      selectedLabels,
      searchedValue,
      filteredOptions,
      isDropdownOpen,
      selectedValues,
      t,
      toggleSelectAll,
      isAllSelected,
    };
  },
});
</script>
