<template>
  <div class="relative flex items-center justify-center">
    <dropdown-wrapper class="top-3.5 right-0">
      <template v-slot:trigger>
        <app-icon
          name="threeDots"
          alt="open dropdown icon"
          class="h-campaignDetailsDropdownCell"
        />
      </template>
      <template v-slot:dropdown>
        <dropdown-item
          v-for="option in options"
          :key="option.label"
          class="flex flex-row"
          @click.stop="option.onClick"
          v-slot="{ active }"
        >
          <app-icon
            :name="option.icon"
            :alt="option.iconAlt"
            :class="{ 'active-icon': active, 'w-iconSm': true }"
          />

          <app-txt variant="small" class="ml-1.5">
            {{ option.label }}
          </app-txt>
        </dropdown-item>
      </template>
    </dropdown-wrapper>
  </div>
</template>

<script lang="ts">
import { CampaignEvents } from '../events';
import { defineComponent } from 'vue';
import { useGetOptionsForMotive } from '../../application/motive/list/useGetOptionsForMotive';

export default defineComponent({
  props: {
    motiveId: { type: String, required: true },
  },
  emits: [CampaignEvents.DELETE_MOTIVE, CampaignEvents.DUPLICATE_MOTIVE],
  setup(props, context) {
    const options = useGetOptionsForMotive(props.motiveId, context.emit);

    return { options };
  },
});
</script>
