import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { MotiveGroupRow } from '../../../domain/motive/motiveGroup';

export const useGetMotiveGroupRows = (
  campaignId: string,
): ComputedRef<MotiveGroupRow[]> => {
  const store = useStore();
  const motiveGroupRows = computed(() =>
    store.getters
      .motiveGroupsByCampaignId(campaignId)
      .map(motiveGroup => motiveGroup.toMotiveGroupRow()),
  );
  return motiveGroupRows;
};
