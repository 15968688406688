import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UserHttpFacade } from '../infrastructure/user_http_facade';
import { deleteUserSucceeded } from '../store/store';
import { DeleteUserFailure } from '../domain';

export const useDeleteUser = (
  facade: UserHttpFacade = new UserHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { id: string }) => {
    const result = await facade.deleteUser(params.id);

    if (result.isRight()) {
      store.dispatch(deleteUserSucceeded, { userId: params.id });
      toast.success(t('users.useDeleteUser.success'));
    } else {
      const error= result.extract() as DeleteUserFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('users.useDeleteUser.error'));
      }
    }
  });
};
