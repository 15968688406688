import { Ref, ref } from 'vue';
import { deepCopyJson } from '@/core/helpers';
import { Format } from '@/features/campaigns/domain/format/format';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { Slide } from '@/features/campaigns/domain/valueObjects/slide';
import { mapValues } from '@/features/campaigns/domain/utils';

export const useGetFormatsFormData = (motive: Motive, format: Format) => {
  const slides = ref<Record<string, Slide>>(
    mapValues(format.slides, slide =>
      Slide.create(deepCopyJson(slide.toJson())),
    ),
  ) as Ref<Record<string, Slide>>;
  const slideOrder = ref<string[]>([...format.slideOrder]);
  const layout = ref(motive.layout);
  const templateVersion = ref(motive.templateVersion);

  return {
    slideOrder,
    slides,
    layout,
    templateVersion,
  };
};
