import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { loginSucceeded } from '../store/store';
import { SessionHttpFacade } from '../infrastructure/session_http_facade';
import { useToast } from 'vue-toastification';
import { LocalStorageService, StorageService } from '@/core';
import { campaignsRouteNames } from '@/features/campaigns/presentation/routes/campaignsRouteNames';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { SessionLoginFailure } from '../domain';

export const useLogin = (
  facade: SessionHttpFacade = new SessionHttpFacade(),
  storageService: StorageService = new LocalStorageService(),
) => {
  const store = useStore();
  const router = useRouter();
  const toast = useToast();

  return useMutation(async (params: { password: string; email: string }) => {
    const result = await facade.login(params);

    if (result.isRight()) {
      const { meta, user } = result.extract();
      store.dispatch(loginSucceeded, { user });
      storageService.set('accessToken', meta.accessToken);
      storageService.set('refreshToken', meta.refreshToken);
      router.push({ name: campaignsRouteNames.campaignList });
      toast.success(t('login.useLogin.success'));
    } else {
      const error= result.extract() as SessionLoginFailure;
      
      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable')); break;
        case 500:
          toast.error(t('common.errors.internalServer')); break;
        case 400:
          toast.error(t('common.errors.badRequest')); break;
        default:
          toast.error(t('login.useLogin.error'));
      }
    }
  });
};
