<template>
  <div :class="styleClasses">
    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="slides[slideId][id]"
          :hasReset="hasReset"
          :initialModelValue="resetValues[id]"
        />
      </template>

      <template
        v-slot:[fieldTypes.INPUT]="{
          label,
          placeholder,
          errorMessage,
          styleClasses,
          containerStyleClasses,
          id,
          type,
          hasReset,
        }"
      >
        <div :class="containerStyleClasses || ''">
          <app-input
            :class="styleClasses || ''"
            :label="label"
            :placeholder="placeholder"
            :errorMessage="errorMessage"
            v-model="slides[slideId][id]"
            :type="type"
            :hasReset="hasReset"
            :initialModelValue="resetValues[id]"
          />
        </div>
      </template>

      <template
        v-slot:[fieldTypes.COLLAPSABLE_INPUT]="{
          label,
          placeholder,
          errorMessage,
          styleClasses,
          containerStyleClasses,
          id,
          type,
          hasReset,
        }"
      >
        <div :class="containerStyleClasses || ''">
          <app-collapsable-input
            :class="styleClasses || ''"
            :label="label"
            :placeholder="placeholder"
            :errorMessage="errorMessage"
            v-model="slides[slideId][id]"
            :type="type"
            :hasReset="hasReset"
            :initialModelValue="resetValues[id]"
          />
        </div>
      </template>

      <template
        v-slot:[fieldTypes.SELECT]="{
          placeholder,
          label,
          callback,
          styleClasses,
          containerStyleClasses,
          id,
          options,
        }"
      >
        <div :class="containerStyleClasses || ''">
          <app-searchable-select
            :class="styleClasses || ''"
            :placeholder="placeholder"
            :label="label"
            :options="options.value"
            :modelValue="slides[slideId][id]"
            @update:modelValue="value => callback(slideId, value)"
          />
        </div>
      </template>
    </FormGenerator>
  </div>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import ConfirmResetModal from '../../confirmResetModal.vue';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import AppCollapsableInput from '@/components/collapsable-input/collapsableInput.vue';

export default defineComponent({
  components: {
    AppCollapsableInput,
    FormGenerator,
    ConfirmResetModal,
  },
  props: {
    fields: { type: Array, required: true },
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { slides, slideOrder, getSlideResetValues } = injectStrict(
      EditFormatsFormKey,
    );
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));

    return {
      fieldTypes,
      slideId,
      slides,
      t,
      resetValues,
      resetModalOpen,
      onResetCallback,
    };
  },
});
</script>
