/* eslint-disable @typescript-eslint/camelcase */
import { Asset } from '@/features/media/domain/asset';
import { Layout } from '../valueObjects';
import { Format } from './format';
import { FormatDefault } from './formatDefault';
import { FormatRaw } from './formatRaw';
import { Device } from '../device';
import { Contract } from '../contract';
import { PreviewSettings } from './previewSettings';
import { Motive } from '../motive/motive';
import { Preview } from '../valueObjects/preview/preview';
import { Slide } from '../valueObjects/slide';

export class FormatPreview {
  Tracking_Params: string | undefined;
  slides: Preview;
  format: { name: string; width: string; height: string; isActive: boolean };
  sliderType: Layout;

  private constructor(
    format: { name: string; width: string; height: string; isActive: boolean },
    sliderType: Layout,
    slides: Preview,
    Tracking_Params?: string,
  ) {
    this.slides = slides;
    this.format = format;
    this.sliderType = sliderType;
    this.Tracking_Params = Tracking_Params;
  }

  static create({
    motive,
    sidebarFormat,
    specificFormat,
    defaultFormat,
    imagesById,
    devicesById,
    contractsById,
    campaignTrackingParam,
    previewSettings = {
      selectedSlideId: undefined,
      keepSpecifics: false,
    },
  }: {
    motive: Motive;
    sidebarFormat?: Format;
    specificFormat: FormatRaw;
    defaultFormat: FormatDefault;
    imagesById: Record<string, Asset>;
    devicesById: Record<string, Device>;
    contractsById: Record<string, Contract>;
    campaignTrackingParam?: string;
    previewSettings?: PreviewSettings;
  }): FormatPreview {
    const [formatWidth, formatHeight] = defaultFormat.name.split('x');

    // Merge default with specific
    let mergedFormat = FormatDefault.merge(defaultFormat, specificFormat);

    if (sidebarFormat) {
      mergedFormat = FormatDefault.merge(mergedFormat, sidebarFormat.toRaw());
    }

    if (previewSettings?.keepSpecifics) {
      mergedFormat = FormatDefault.merge(mergedFormat, specificFormat);
    }

    // Create preview from specific and default
    const previewContent = mergedFormat.slideOrder
      .filter(id => {
        const isIncludedInPreview =
          !previewSettings?.selectedSlideId ||
          previewSettings.selectedSlideId === id;
        const mergedFormatHasSlide = !!mergedFormat.slides?.[id];
        const isNotRemoved = mergedFormat.slides?.[id]?.removed === false;

        return isIncludedInPreview && mergedFormatHasSlide && isNotRemoved;
      })
      .map(id => {
        return Slide.create(mergedFormat.slides?.[id]).toPreviewSlideDTO({
          devicesById,
          contractsById,
          imagesById,
          formatName: defaultFormat.name,
          layout: motive.layout,
        });
      });

    return new FormatPreview(
      {
        name: defaultFormat.name,
        width: formatWidth,
        height: formatHeight,
        isActive: defaultFormat.isActive,
      },
      motive.layout,
      Preview.create({
        attributes: {
          style: '',
        },
        content: previewContent,
        paused: false,
        startSlide: 0,
      }),
      campaignTrackingParam,
    );
  }
}
