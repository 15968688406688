
import { defineComponent, ref } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    disabled: Boolean,
    modelValue: { type: String, required: true },
    rightIcon: { type: String, required: false },
    initialModelValue: { type: String, required: false },
    onPressEnter: { type: Function, required: true },
  },
  setup(props, context) {
    const inputType = ref(props.type);

    const onInput = (event: Event) => {
      const value = (event.target as HTMLInputElement).value
      if (props.type === 'number' && isNaN(parseFloat(value))) return;
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };
    return {
      inputType,
      onInput,
    };
  },
});
