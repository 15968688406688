<template>
  <div :class="styleClasses">
    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.MEDIA_PICKER_GROUP]="{
          hasReset,
          styleClasses,
          labelStyleClasses,
        }"
      >
        <MediaPickerGroup
          :class="styleClasses || ''"
          v-model:squareVisual="squareVisualModel"
          v-model:horizontalVisual="horizontalVisualModel"
          v-model:verticalVisual="verticalVisualModel"
          :initialSquareVisual="
            resetValuesRef.content?.[imageContentIndexes.square]?.id
          "
          :initialHorizontalVisual="
            resetValuesRef.content?.[imageContentIndexes.horizontal]?.id
          "
          :initialVerticalVisual="
            resetValuesRef.content?.[imageContentIndexes.vertical]?.id
          "
          :defaultVisual="defaultVisual"
          :allowAnimations="false"
          :hasReset="hasReset"
          :hasError="false"
          :labelStyleClasses="labelStyleClasses"
        />
      </template>

      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
          disabled,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="slides[slideId].images[imageIndex][id]"
          :hasReset="hasReset"
          :initialModelValue="resetValues.images?.[imageIndex]?.[id]"
          :disabled="disabled"
        />
      </template>

      <template
        v-slot:[fieldTypes.SELECT]="{
          label,
          options,
          hasReset,
          styleClasses,
        }"
      >
        <app-select
          :class="styleClasses || ''"
          :label="label"
          :options="options"
          v-model="transitionModel"
          :hasReset="hasReset"
          :initialModelValue="
            resetValues.images?.[imageIndex]?.transition?.type
          "
        />
      </template>

      <template
        v-slot:[fieldTypes.OFFSET_PICKER]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="positionXModel"
          v-model:vertical="positionYModel"
          :label="label"
          :hasReset="hasReset"
        />
      </template>

      <template
        v-slot:[fieldTypes.TRANSITION_ORIGIN]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="transitionOriginXModal"
          v-model:vertical="transitionOriginYModal"
          :label="label"
          :hasReset="hasReset"
        />
      </template>
    </FormGenerator>
  </div>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { computed, defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import ConfirmResetModal from '../../confirmResetModal.vue';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import { getImageContentIndexes } from '@/features/campaigns/domain/valueObjects/blocks/helpers/slideImageHelpers';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import { SlideImage } from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import { useImageSettingsModels } from '@/features/campaigns/application/motive/editor/useImageSettingsModels';

export default defineComponent({
  components: {
    FormGenerator,
    ConfirmResetModal,
    MediaPickerGroup,
  },
  props: {
    fields: { type: Array, required: true },
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    defaultVisual: { type: String, default: '' },
    imageLabel: { type: String, default: '' },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { slides, slideOrder, getSlideResetValues } = injectStrict(
      EditFormatsFormKey,
    );
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));
    const emptySlideImage = {} as SlideImage;

    const slideImageRef = ref<SlideImage>(
      slides.value[slideId.value].images.find(
        image => image.label === props.imageLabel,
      ) || emptySlideImage,
    );

    const imageContentIndexes = getImageContentIndexes(
      slideImageRef.value.content,
    );

    const resetValuesRef = ref<SlideImage>(
      resetValues.value.images?.find(
        image => image.label === props.imageLabel,
      ) || emptySlideImage,
    );

    const imageIndex = computed(() => {
      return slides.value[slideId.value].images.findIndex(
        image => image.label === props.imageLabel,
      );
    });

    const {
      transitionModel,
      squareVisualModel,
      horizontalVisualModel,
      verticalVisualModel,
      positionXModel,
      positionYModel,
      transitionOriginXModal,
      transitionOriginYModal
    } = useImageSettingsModels(slideImageRef, imageContentIndexes);

    return {
      imageIndex,
      positionXModel,
      positionYModel,
      transitionModel,
      squareVisualModel,
      horizontalVisualModel,
      verticalVisualModel,
      resetValuesRef,
      slideImageRef,
      slideId,
      imageContentIndexes,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      t,
      resetValues,
      resetModalOpen,
      onResetCallback,
      transitionOriginXModal,
      transitionOriginYModal
    };
  },
});
</script>
