
import { ref } from 'vue';
import { useLogin } from '../application/useLogin';
import { useRouter } from 'vue-router';
import { sessionRouteNames } from './routes/sessionRouteNames';
import { validateEmailAddress, validateMinLength } from '@/core';
import { isEmailValid, isPasswordValid } from '@/features/users/application/utils';

export default {
  setup() {
    const { call, isLoading } = useLogin();
    const router = useRouter();

    const email = ref('');
    const password = ref('');
    const hasSubmitted = ref(false);

    const handleLogin = () => {
      hasSubmitted.value = true;
      const passwordIsValid = validateMinLength(password.value, 8).isRight();
      const emailIsValid = validateEmailAddress(email.value).isRight();

      if (passwordIsValid && emailIsValid) {
        call({
          email: email.value,
          password: password.value,
        });
      }
    };

    const onForgotPassword = () => {
      router.push({ name: sessionRouteNames.password.askResetPassword });
    };

    return {
      email,
      password,
      handleLogin,
      hasSubmitted,
      isLoading,
      onForgotPassword,
      isEmailValid,
      isPasswordValid
    };
  },
};
