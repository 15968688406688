import { endpoints } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { t } from '@/services/i18n';
import { getEnvVariable } from '@/utils/getEnvVariable';
import { useToast } from 'vue-toastification';

const baseUrl = getEnvVariable('VUE_APP_API_BASE_URL');

export const useExportCampaign = (campaignId: string) => {
  const toast = useToast();

  navigator.clipboard
    .writeText(`${baseUrl}${endpoints.exportCampaign(campaignId)}`)
    .then(
      () => toast.success(t('campaigns.useExportCampaign.success')),
      () => toast.error(t('campaigns.useExportCampaign.error')),
    );
};
