import {
  CreateSlideImageForValuesParams,
  CreateSlideImageParams,
  ImageContentType,
} from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import { Position } from '@/features/campaigns/domain/valueObjects/position';

export const defaultDeviceImageLabel = 'Device';

export const generateDefaultImageParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlideImageParams => ({
  label: params.label,
  content: [
    { id: '', type: ImageContentType.Square },
    { id: '', type: ImageContentType.Vertical },
    { id: '', type: ImageContentType.Horizontal },
  ],
  scaling: 100,
  position: {
    x: 0,
    y: 0,
  },
  transition: {
    type: 'fly',
    delay: 200,
    x: 0,
    y: 0,
  },
  zIndex: params?.zIndex,
  removed: false,
});

export const generateDefaultImageForValuesParams = (params?: {
  label?: string;
  zIndex?: number;
}): CreateSlideImageForValuesParams => ({
  label: params?.label ?? 'Image',
  content: [
    { id: '', type: ImageContentType.Square },
    { id: '', type: ImageContentType.Vertical },
    { id: '', type: ImageContentType.Horizontal },
  ],
  scaling: 100,
  position: Position.create({
    x: 0,
    y: 0,
  }),
  zIndex: params?.zIndex,
  removed: false,
  transition: {
    type: 'fly',
    delay: 600,
    x: 0,
    y: 0,
  },
});
