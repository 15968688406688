import { Position, PositionCreationParams } from '../../position';

export type interfererImageParams = {
  content: string;
  scaling?: number;
  position?: PositionCreationParams;
  text: string;
  textSize: number;
};

export type interfererImageForValuesParams = {
  content: string;
  scaling: number;
  text: string;
  textSize: number;
  position?: Position;
};

export class InterfererImage {
  private constructor(
    public content: string,
    public text: string,
    public textSize: number,
    public scaling?: number,
    public position?: Position,
  ) {}

  static create(params: interfererImageParams) {
    return new InterfererImage(
      params.content,
      params.text,
      params.textSize ?? 1,
      params.scaling,
      params.position ? Position.create(params.position) : undefined,
    );
  }

  static createForValues(params: interfererImageForValuesParams) {
    return new InterfererImage(
      params.content,
      params.text,
      params.textSize ?? 1,
      params.scaling,
      params.position,
    );
  }

  public toJson() {
    return {
      content: this.content,
      scaling: this.scaling,
      text: this.text,
      textSize: this.textSize,
      position: this.position?.toJson(),
    };
  }
}
