<template>
  <Dialog
    :initial-focus="$refs.closeButton"
    :open="isModalOpen"
    class="fixed inset-0 z-10 overflow-y-auto"
  >
    <div class="flex items-center justify-center min-h-screen md:h-auto">
      <DialogOverlay class="fixed inset-0">
        <div class="absolute inset-0 bg-gray-600 opacity-70" />
      </DialogOverlay>

      <div
        class="relative flex flex-col min-h-screen md:min-h-0 h-full w-full md:w-modalWidth md:max-w-largeModalMaxWidth bg-white md:rounded-2 z-20 py-3 md:py-5 shadow-lg"
        v-bind="$attrs"
      >
        <button
          ref="closeButton"
          class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
          @click="closeModal"
        >
          <app-icon name="closeCircle" alt="close modal" />
        </button>

        <DialogTitle
          class="text-h3 md:text-h1 font-semibold md:text-center text-black-basic mt-4.5 md:mt-0 mb-4.5 px-3"
        >
          {{ $t('campaigns.updateMotive.title') }}
        </DialogTitle>

        <div class="border-b border-gray-200 py-3">
          <update-general-settings-form
            :has-submitted="hasSubmitted"
            @submit-values="handleSubmitted"
          />
        </div>

        <div class="flex flex-row justify-end mt-4 md:mt-5 px-3 md:px-5">
          <app-button
            @click="submit"
            class="w-full md:w-max focus:outline-none"
            :is-loading="isLoading"
            >Continue</app-button
          >
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import UpdateGeneralSettingsForm from '@/features/campaigns/presentation/motiveEditor/siderbar/edit/generalSettings/form.vue';
import { SubmitEventData } from '@/features/campaigns/presentation/motiveEditor/siderbar/edit/generalSettings/types';
import { useUpdateMotiveGeneralSettings } from '@/features/campaigns/application/motive/actions/useUpdateMotiveGeneralSettings';
import { useFetchMotiveById } from '@/features/campaigns/application/motive/actions/useFetchMotiveById';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { useCreateMotiveVersion } from '@/features/campaigns/application/motive/actions/useCreateMotiveVersion';
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { useFetchRawFormatsForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsForMotiveId';
import { useFetchRawFormatsDefaultsInstancesForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsDefaultInstanceForMotiveId';

const CLOSE_MODAL = 'close-modal';

export default defineComponent({
  name: 'GeneralSettingsModal',
  inheritAttrs: false,
  components: {
    UpdateGeneralSettingsForm,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  emits: [CLOSE_MODAL],
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveId: { type: String, required: true },
  },
  setup(props, context) {
    const hasSubmitted = ref(false);

    const closeModal = () => context.emit(CLOSE_MODAL);

    const submit = () => {
      hasSubmitted.value = true;
    };

    const onUpdateSuccess = () => {
      useFetchMotiveById({
        motiveId: props.motiveId,
      });
      useFetchRawFormatsForMotiveId({
        motiveId: props.motiveId,
      });
      useFetchRawFormatsDefaultsInstancesForMotiveId({
        motiveId: props.motiveId,
      });
      closeModal();
    };

    const {
      call: updateMotiveGeneralSettings,
      isLoading: isLoadingUpdateMotive,
    } = useUpdateMotiveGeneralSettings({
      onSuccess: onUpdateSuccess,
    });
    const {
      call: createMotiveVersion,
      isLoading: isLoadingCreateMotiveVersion,
    } = useCreateMotiveVersion();

    const { currentUser } = useGetCurrentUser();

    const handleSubmitted = async (event: SubmitEventData) => {
      await createMotiveVersion({
        author: currentUser.value.email,
        motiveId: props.motiveId,
        type: versionType.Autosaved,
      });

      await updateMotiveGeneralSettings({
        clickUrl: event.clickUrl.value,
        endDate: event.endDate.value.getValue(),
        startDate: event.startDate.value.getValue(),
        name: event.name.value,
        description: event.description.value,
        isDefaultMotive: event.isDefaultMotive.value,
        id: props.motiveId,
      });

      hasSubmitted.value = false;
    };

    return {
      closeModal,
      submit,
      handleSubmitted,
      hasSubmitted,
      isLoading: isLoadingUpdateMotive || isLoadingCreateMotiveVersion,
    };
  },
});
</script>
