<template>
  <app-txt
    variant="small"
    :class="{
      'flex cursor-pointer h-breadcrumb items-center justify-center': true,
      'border border-primary text-primary rounded-1 px-0.5': isDragEnter,
      'text-gray-400': !isDragEnter,
    }"
    @click="$emit('click')"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    {{ name }}
  </app-txt>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: { name: { type: String, required: true } },
  emits: ['click', 'drop'],
  setup(_, context) {
    const isDragEnter = ref(false);
    const onDragOver = (e: any) => {
      if (
        !e.dataTransfer.types.includes('asset') &&
        !e.dataTransfer.types.includes('folder')
      ) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = true;
    };

    const onDragLeave = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
    };

    const onDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
      context.emit('drop', e);
    };

    return {
      onDragOver,
      onDragLeave,
      isDragEnter,
      onDrop,
    };
  },
});
</script>
