<template>
  <div
    :class="{
      'cursor-pointer rounded rounded-2 h-mediaHeight flex justify-center items-center': true,
      'bg-blue-100': !folder.isSyncedAssetsFolder(),
      'bg-green-100': folder.isSyncedAssetsFolder(),
      'border-2 border-primary border-solid': isDragEnter,
    }"
    @click="$emit(MediaEvents.FOLDER_SELECTED, folder)"
    @dragstart="onDragStart"
    @dragover="onDragOver"
    @drop="onDrop"
    @dragleave="onDragLeave"
    @dragend="onDragEnd"
    :draggable="draggable"
  >
    <app-icon
      v-if="!isDragEnter"
      alt="folder icon"
      :name="folder.isSyncedAssetsFolder() ? 'greenFolder' : 'folder'"
    />
    <app-icon v-if="isDragEnter" alt="folder icon" name="arrowUpCircle" />
  </div>
  <div class="flex justify-space-between items-center mt-2 w-full">
    <app-txt class="truncate w-full" :title="folder.getName()">{{
      folder.getName()
    }}</app-txt>
    <MediaListDots
      v-if="allowDeletion && !folder.isSyncedAssetsFolder()"
      :media="folder"
      @delete-media="
        media => {
          $emit(MediaEvents.DELETE_MEDIA, media);
        }
      "
      @rename-media="
        media => {
          $emit(MediaEvents.RENAME_MEDIA, media);
        }
      "
    />
  </div>
</template>

<script lang="ts">
import MediaListDots from './mediaListDots.vue';
import { MediaEvents } from './events';
import { getNumberOfFilesInsideFolder } from '../application/utils';
import { useGetMedia } from '../application/useGetMedia';
import { Folder } from '../domain/folder';
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: {
    folder: { type: Folder, required: true },
    allowDeletion: { type: Boolean, default: true },
    isDropAllowed: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
  },
  emits: [
    MediaEvents.FOLDER_SELECTED,
    MediaEvents.DELETE_MEDIA,
    'dragstart',
    'drop',
    MediaEvents.RENAME_MEDIA,
  ],
  components: { MediaListDots },
  setup(
    props,
    context,
  ) {
    const { media: mediaList } = useGetMedia();
    const isDragEnter = ref(false);
    const isDragged = ref(false);

    const onDragOver = (e: any) => {
      if (
        !props.isDropAllowed ||
        isDragged.value ||
        (!e.dataTransfer.types.includes('asset') &&
          !e.dataTransfer.types.includes('folder'))
      ) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = true;
    };

    const onDragLeave = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
    };

    const onDragEnd = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
      isDragged.value = false;
    };

    const onDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
      context.emit('drop', e);
    };

    const onDragStart = (e: any) => {
      isDragged.value = true;
      context.emit('dragstart', e);
    };

    return {
      MediaEvents,
      getNumberOfFilesInsideFolder,
      mediaList,
      onDragOver,
      onDragLeave,
      isDragEnter,
      onDrop,
      onDragStart,
      isDragged,
      onDragEnd,
    };
  },
});
</script>
