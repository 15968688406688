import { useMutation } from '@/utils/useMutation';
import { CampaignHttpFacade } from '@/features/campaigns/infrastructure/campaign_http_facade';
import { useToast } from 'vue-toastification';
import { t } from '@/services/i18n';
import { UpdateMotiveFailure } from '@/features/campaigns/domain';

interface UseUpdateMotiveGeneralSettingsProps {
  onSuccess?: () => void;
}

export type UpdateMotiveGeneralSettings = {
  id: string;
  endDate: Date;
  startDate: Date;
  name: string;
  description: string;
  clickUrl: string;
  isDefaultMotive: boolean;
};

export function useUpdateMotiveGeneralSettings(
  props: UseUpdateMotiveGeneralSettingsProps,
) {
  const facade = new CampaignHttpFacade();
  const toast = useToast();

  return useMutation(async (motive: UpdateMotiveGeneralSettings) => {
    const result = await facade.updateMotiveGeneralSettings(motive);

    if (result.isRight()) {
      props.onSuccess?.();
      toast.success(t('campaigns.useUpdateMotive.success'));
    } else {
      const error = result.extract() as UpdateMotiveFailure;

      switch (error.status) {
        case 503:
          toast.error(t('common.errors.serviceUnavailable'));
          break;
        case 500:
          toast.error(t('common.errors.internalServer'));
          break;
        case 400:
          toast.error(t('common.errors.badRequest'));
          break;
        default:
          toast.error(t('campaigns.useUpdateMotive.error'));
      }
    }
  });
}
