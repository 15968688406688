
import { ref } from 'vue';
import { sessionRouteNames } from '../routes/sessionRouteNames';
import { useSendResetMail } from '../../application/useSendResetMail';
import { useRouter } from 'vue-router';
import { isEmailValid } from '@/features/users/application/utils';

export default {
  setup() {
    const router = useRouter();

    const email = ref('');
    const { call, isLoading } = useSendResetMail(() =>
      router.push({
        name: sessionRouteNames.password.confirmResetAsked,
      }),
    );
    const hasSubmitted = ref(false);
    const previousRouteName = sessionRouteNames.sessionLogin;
    const onSubmit = () => {
      hasSubmitted.value = true;
      if (isEmailValid(email.value)) {
        call(email.value);
      }
    };
    return {
      email,
      hasSubmitted,
      isLoading,
      previousRouteName,
      onSubmit,
      isEmailValid
    };
  },
};
