import { InterfererImage } from '@/features/campaigns/domain/valueObjects/blocks/interferer/interfererImage';
import {
  CreateSlideInterfererForValuesParams,
  CreateSlideInterfererParams,
} from '@/features/campaigns/domain/valueObjects/blocks/slideInterferer';
import { Position } from '@/features/campaigns/domain/valueObjects/position';

export const generateDefaultInterfererForValuesParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlideInterfererForValuesParams => ({
  label: params.label,
  animation: 'flip',
  firstImage: InterfererImage.createForValues({
    content: '',
    position: Position.createForValues({
      x: 0,
      y: 0,
    }),
    scaling: 100,
    text: '',
    textSize: 1,
  }),
  zIndex: params?.zIndex,
  transition: {
    type: 'scale',
    delay: 800,
    x: 0,
    y: 0,
  },
  removed: false,
});

export const generateDefaultInterfererParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlideInterfererParams => ({
  label: params.label,
  animation: 'flip',
  firstImage: {
    content: '',
    position: {
      x: 0,
      y: 0,
    },
    scaling: 100,
    text: '',
    textSize: 1,
  },
  zIndex: params?.zIndex,
  transition: {
    type: 'scale',
    delay: 800,
    x: 0,
    y: 0,
  },
  removed: false,
});
