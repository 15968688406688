
import { defineComponent } from 'vue';
import { CampaignEvents } from '../events';

export default defineComponent({
  emits: [CampaignEvents.EMPTY_BIN],
  props: {
    isVisible: { type: Boolean, required: true },
  },
  setup(_, context) {
    const onEmptyBin = () => {
      context.emit(CampaignEvents.EMPTY_BIN);
    };
    return { onEmptyBin };
  },
});
