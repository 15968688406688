
import { campaignsTranslateByScope } from '../../translationScope';
import Section from '../createMotiveModal/section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { BulkCreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';
import { CampaignEvents } from '../../events';

export default {
  components: {
    Section,
  },
  props: {
    isSectionOpen: { type: Boolean, required: true },
  },
  emits: [CampaignEvents.UPDATE_IS_SECTION_OPEN],
  setup() {
    const t = campaignsTranslateByScope('bulkMotiveCreation');
    const { isAutoUpdateOn } = injectStrict(BulkCreateMotiveFormKey);

    return {
      t,
      CampaignEvents,
      isAutoUpdateOn,
    };
  },
};
