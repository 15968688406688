import { validateEmailAddress, validateMinLength } from '@/core';
import { UserInvite } from '../domain';

export const isPasswordValid = (password: string): boolean => {
  return validateMinLength(password, 8).isRight();
};

export const isEmailValid = (email: string): boolean => {
  return validateEmailAddress(email).isRight();
};

export const hasAnyValidUser = (users: UserInvite[]): boolean => {
  return users.some(user => isEmailValid(user.email) && user.role.isValid());
};

export const getValidUsers = (users: UserInvite[]): UserInvite[] => {
  return users.filter(user => isEmailValid(user.email) && user.role.isValid());
};
