
import { defineComponent, ref } from 'vue';
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import UpdateGeneralSettingsForm from '@/features/campaigns/presentation/motiveEditor/siderbar/edit/generalSettings/form.vue';
import { SubmitEventData } from '@/features/campaigns/presentation/motiveEditor/siderbar/edit/generalSettings/types';
import { useUpdateMotiveGeneralSettings } from '@/features/campaigns/application/motive/actions/useUpdateMotiveGeneralSettings';
import { useFetchMotiveById } from '@/features/campaigns/application/motive/actions/useFetchMotiveById';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { useCreateMotiveVersion } from '@/features/campaigns/application/motive/actions/useCreateMotiveVersion';
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { useFetchRawFormatsForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsForMotiveId';
import { useFetchRawFormatsDefaultsInstancesForMotiveId } from '@/features/campaigns/application/format/useFetchRawFormatsDefaultInstanceForMotiveId';

const CLOSE_MODAL = 'close-modal';

export default defineComponent({
  name: 'GeneralSettingsModal',
  inheritAttrs: false,
  components: {
    UpdateGeneralSettingsForm,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  emits: [CLOSE_MODAL],
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveId: { type: String, required: true },
  },
  setup(props, context) {
    const hasSubmitted = ref(false);

    const closeModal = () => context.emit(CLOSE_MODAL);

    const submit = () => {
      hasSubmitted.value = true;
    };

    const onUpdateSuccess = () => {
      useFetchMotiveById({
        motiveId: props.motiveId,
      });
      useFetchRawFormatsForMotiveId({
        motiveId: props.motiveId,
      });
      useFetchRawFormatsDefaultsInstancesForMotiveId({
        motiveId: props.motiveId,
      });
      closeModal();
    };

    const {
      call: updateMotiveGeneralSettings,
      isLoading: isLoadingUpdateMotive,
    } = useUpdateMotiveGeneralSettings({
      onSuccess: onUpdateSuccess,
    });
    const {
      call: createMotiveVersion,
      isLoading: isLoadingCreateMotiveVersion,
    } = useCreateMotiveVersion();

    const { currentUser } = useGetCurrentUser();

    const handleSubmitted = async (event: SubmitEventData) => {
      await createMotiveVersion({
        author: currentUser.value.email,
        motiveId: props.motiveId,
        type: versionType.Autosaved,
      });

      await updateMotiveGeneralSettings({
        clickUrl: event.clickUrl.value,
        endDate: event.endDate.value.getValue(),
        startDate: event.startDate.value.getValue(),
        name: event.name.value,
        description: event.description.value,
        isDefaultMotive: event.isDefaultMotive.value,
        id: props.motiveId,
      });

      hasSubmitted.value = false;
    };

    return {
      closeModal,
      submit,
      handleSubmitted,
      hasSubmitted,
      isLoading: isLoadingUpdateMotive || isLoadingCreateMotiveVersion,
    };
  },
});
