export type CreateSlideElementParams = {
  label: string;
  zIndex?: number;
  removed?: boolean;
  isDefault?: boolean;
};

export enum SlideElementSimpleType {
  Other = 'Other',
  Text = 'Text',
  Image = 'Image',
  Price = 'Price',
  Interferer = 'Interferer',
}

export enum SlideElementType {
  Text = 'Text',
  CTA = 'CTA',
  Price = 'Price',
  Image = 'Image',
  Logo = 'Logo',
  Interferer = 'Interferer',
}

export abstract class SlideElement {
  public label: string;
  public zIndex?: number;
  public removed?: boolean;
  public isDefault?: boolean;

  constructor(
    label: string,
    zIndex?: number,
    removed?: boolean,
    isDefault?: boolean,
  ) {
    this.label = label;
    this.zIndex = zIndex;
    this.removed = removed;
    this.isDefault = isDefault;
  }

  public abstract toJson(): any;

  public remove() {
    this.removed = true;
  }
}
