<template>
  <div :class="styleClasses">
    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.RICH_TEXT_EDITOR]="{
          id,
          placeholder,
          styleClasses,
          hasReset,
          initialFont,
          fontSize,
        }"
      >
        <rich-text-editor
          :id="`slide-${slideIndex}-text-${textIndex}`"
          label="Content"
          :class="styleClasses || ''"
          :placeholder="placeholder"
          v-model="textByLabel[id]"
          :hasReset="hasReset"
          :initialModelValue="resetValues.texts?.[textIndex]?.[id]"
          :initialFont="initialFont"
          :fontSize="fontSize"
        />
      </template>

      <template
        v-slot:[fieldTypes.SELECT]="{
          label,
          options,
          hasReset,
          styleClasses,
        }"
      >
        <app-select
          :class="styleClasses || ''"
          :label="label"
          :options="options"
          v-model="textByLabel.transition.type"
          :hasReset="hasReset"
          :initialModelValue="resetValues.texts?.[textIndex]?.transition?.type"
        />
      </template>

      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
          hasSecondaryValue,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="textByLabel[id]"
          :hasReset="hasReset"
          :initialModelValue="resetValues.texts?.[textIndex]?.[id]"
          :has-secondary-value="hasSecondaryValue"
          :second-value-provider="emToPixels"
        />
      </template>

      <template
        v-slot:[fieldTypes.OFFSET_PICKER]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="textByLabel['position']['x']"
          v-model:vertical="textByLabel['position']['y']"
          :label="label"
          :hasReset="hasReset"
          :initialHorizontal="
            resetValues.texts?.[textIndex]?.['position']?.['x']
          "
          :initialVertical="resetValues.texts?.[textIndex]?.['position']?.['y']"
        />
      </template>

      <template
        v-slot:[fieldTypes.TRANSITION_ORIGIN]="{
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="transitionOriginXModal"
          v-model:vertical="transitionOriginYModal"
          :label="label"
          :hasReset="hasReset"
        />
      </template>
    </FormGenerator>
  </div>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { campaignsTranslateByScope } from '../../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import ConfirmResetModal from '../../confirmResetModal.vue';
import { EditFormatsFormKey } from '@/features/campaigns/application/motive/forms/types';
import { emToPixels } from '@/utils/emToPixels';
import { SlideText } from '@/features/campaigns/domain/valueObjects/blocks/slideText';

export default defineComponent({
  components: {
    FormGenerator,
    ConfirmResetModal,
  },
  props: {
    fields: { type: Array, required: true },
    slideIndex: { type: Number, required: true },
    textIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    textLabel: { type: String, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const { slides, slideOrder, getSlideResetValues } = injectStrict(
      EditFormatsFormKey,
    );
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);
    const slideId = computed(() => slideOrder.value[props.slideIndex]);
    const resetValues = computed(() => getSlideResetValues(slideId.value));

    const textByLabel = ref<SlideText>(
      slides.value[slideId.value].texts.find(
        text => text.label === props.textLabel,
      ) || ({} as SlideText),
    );
    
    const transitionOriginXModal = ref<number>(
      textByLabel.value?.transition?.x || 0,
    );
    const transitionOriginYModal = ref<number>(
      textByLabel.value?.transition?.y || 0,
    );

    watch(transitionOriginXModal, () => {
      if (textByLabel.value && textByLabel.value.transition) {
        textByLabel.value.transition.x = transitionOriginXModal.value;
      }
    });
    watch(transitionOriginYModal, () => {
      if (textByLabel.value && textByLabel.value.transition) {
        textByLabel.value.transition.y = transitionOriginYModal.value;
      }
    });


    return {
      textByLabel,
      slideId,
      fieldTypes,
      slides,
      t,
      resetValues,
      resetModalOpen,
      onResetCallback,
      emToPixels,
      transitionOriginXModal,
      transitionOriginYModal,
    };
  },
});
</script>
