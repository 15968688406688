<template>
  <Section
    :isSectionOpen="isSectionOpen"
    @update:isSectionOpen="$emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, $event)"
    :title="t('autoUpdate')"
    :isCompleted="false"
  >
    <div class="px-3 md:px-5">
      <app-txt variant="small" class="text-gray-700">{{
        t('autoUpdateDescription')
      }}</app-txt>

      <div class="flex flex-row items-center mt-2">
        <input type="checkbox" id="isAutoUpdateOn" v-model="isAutoUpdateOn" />
        <label class="text-small text-gray-700 ml-1" for="isAutoUpdateOn">{{
          t('autoUpdateCheckboxLabel')
        }}</label>
      </div>
    </div>
  </Section>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '../../translationScope';
import Section from '../createMotiveModal/section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { BulkCreateMotiveFormKey } from '@/features/campaigns/application/motive/forms/types';
import { CampaignEvents } from '../../events';

export default {
  components: {
    Section,
  },
  props: {
    isSectionOpen: { type: Boolean, required: true },
  },
  emits: [CampaignEvents.UPDATE_IS_SECTION_OPEN],
  setup() {
    const t = campaignsTranslateByScope('bulkMotiveCreation');
    const { isAutoUpdateOn } = injectStrict(BulkCreateMotiveFormKey);

    return {
      t,
      CampaignEvents,
      isAutoUpdateOn,
    };
  },
};
</script>