export type PositionCreationParams = {
  x: number | null;
  y: number | null;
};

export type PositionCreationForValuesParams = {
  x: number;
  y: number;
};

export class Position {
  public x: number | null;
  public y: number | null;

  private constructor(params: PositionCreationForValuesParams) {
    this.x = params.x;
    this.y = params.y;
  }

  static create(params: PositionCreationParams): Position {
    return new Position({
      x: params.x as number,
      y: params.y as number,
    });
  }
  static createForValues(params: PositionCreationForValuesParams): Position {
    return new Position(params);
  }

  public toJson() {
    return {
      x: this.x,
      y: this.y,
    };
  }
}
