
import { ModalEvents } from '@/components/modal/modalEvents';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ConfirmDeletionModal',
  props: {
    isModalOpen: { type: Boolean, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    cancelLabel: { type: String, required: true },
    confirmLabel: { type: String, required: true },
    deleteFunction: { type: Function, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props, context) {
    const hasSubmitted = ref(false);

    const onCancel = () => {
      hasSubmitted.value = false;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onConfirm = () => {
      hasSubmitted.value = true;

      props.deleteFunction();
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    return {
      onCancel,
      onConfirm,
      hasSubmitted,
    };
  },
});
