import { computed, watch } from 'vue';
import { useGetMotiveFormData } from './useGetMotiveFormData';
import { CreateMotiveForm } from './types';
import { useManageSections } from './useManageSections';
import { useHandleFieldsPrefill } from './useHandleFieldsPrefill';
import {
  defaultClickUrl,
  generateDefaultSlideForValuesParams,
} from './defaults/defaultSlide';
import { useCreateMotiveFunction } from './useCreateMotiveFunction';
import { deepCopyJson } from '@/core/helpers';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { Slide } from '@/features/campaigns/domain/valueObjects/slide';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useGetCampaignById } from '../../campaign/actions/useGetCampaignById';

export const useCreateMotiveForm = (params: {
  campaignId?: string;
}): CreateMotiveForm => {
  const t = campaignsTranslateByScope('editMotive');

  const manageSectionProps = useManageSections();

  const { campaign } = useGetCampaignById(params.campaignId ?? '');

  const fieldsInit = {
    startDate: campaign.value?.startDate,
    endDate: campaign.value?.endDate,
  };

  const {
    name,
    description,
    isDefaultMotive,
    clickUrl,
    startDate,
    endDate,
    layout,
    slides,
    slideOrder,
  } = useGetMotiveFormData(fieldsInit as any);

  watch([campaign], () => {
    if (campaign.value) {
      startDate.value = campaign.value.startDate;
      endDate.value = campaign.value.endDate;
    }
  });

  const { onContractChanged, onDeviceChanged } = useHandleFieldsPrefill({
    slideOrder,
    slides,
    layout,
  });

  const isGeneralSectionValid = computed(
    () =>
      !!name.value &&
      startDate.value.isValid() &&
      endDate.value.isValid() &&
      endDate.value.getValue() >= startDate.value.getValue(),
  );
  const isVariationsSectionValid = computed(() => {
    return slideOrder.value.every(id => {
      if (!slides.value[id].contractId) return false;
      return layout.value === Layout.CONTRACT || slides.value[id].deviceId;
    });
  });
  const isSlideSectionValid = computed(() => slideOrder.value.length > 0);

  const isSubmitDisabled = computed(
    () =>
      !isGeneralSectionValid.value ||
      !isVariationsSectionValid.value ||
      !isSlideSectionValid.value,
  );

  const clearForm = () => {
    manageSectionProps.step.value = 1;

    name.value = '';
    description.value = '';
    isDefaultMotive.value = false;
    layout.value = Layout.DEVICE;

    if (campaign.value) {
      startDate.value = campaign.value.startDate;
      endDate.value = campaign.value.endDate;
    }

    clickUrl.value = defaultClickUrl;

    const slidesArr = [
      Slide.createForValues(generateDefaultSlideForValuesParams()),
      Slide.createForValues(generateDefaultSlideForValuesParams()),
    ];

    slides.value = slidesArr.reduce((map, slide) => {
      map[slide.id] = Slide.create(deepCopyJson(slide.toJson()));
      return map;
    }, {} as Record<string, Slide>);
  };

  const resetGeneralSettings = async () => {
    name.value = '';
    description.value = '';
    isDefaultMotive.value = false;
    startDate.value = campaign.value.startDate;
    endDate.value = campaign.value.endDate;
    layout.value = Layout.DEVICE;
  };

  const isValid = computed(() => {
    return isGeneralSectionValid.value && isVariationsSectionValid.value;
  });

  const errorMessageOnInvalidSlideSettings = computed(() => {
    if (!isVariationsSectionValid.value) {
      return t('invalidSettingsError.variationSection');
    }
    return t('invalidSettingsError.generic');
  });

  const { isLoading, createMotive } = useCreateMotiveFunction({
    name,
    description,
    isDefaultMotive,
    startDate,
    endDate,
    layout,
    clickUrl,
    slides,
    slideOrder,
  });

  return {
    ...manageSectionProps,
    isLoading,
    name,
    description,
    isDefaultMotive,
    startDate,
    endDate,
    layout,
    slides,
    slideOrder,
    onContractChanged,
    onDeviceChanged,
    clickUrl,
    isGeneralSectionValid,
    isVariationsSectionValid,
    isSlideSectionValid,
    clearForm,
    createMotive,
    isSubmitDisabled,
    resetGeneralSettings,
    isValid,
    errorMessageOnInvalidSlideSettings,
  };
};
