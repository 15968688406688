import { DateWrapper } from '@/core';

export enum versionType {
  Autosaved = 'autosaved',
  Restored = 'restored',
}

export class Version {
  constructor(
    public id: string,
    public author: string,
    public createdAt: DateWrapper,
    public type: versionType,
    public changedFormat?: string,
    public dateFromRestored?: DateWrapper,
  ) {}
  public static create(params: {
    id: string;
    author: string;
    createdAt: string;
    type: versionType;
    changedFormat?: string;
    dateFromRestored?: string;
  }) {
    return new Version(
      params.id,
      params.author,
      DateWrapper.create(params.createdAt),
      params.type,
      params.changedFormat,
      params.dateFromRestored
        ? DateWrapper.create(params.dateFromRestored)
        : undefined,
    );
  }
}

export type CreateVersion = {
  author: string;
  type: versionType;
  motiveId: string;
  changedFormat?: string;
};
