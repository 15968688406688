
import { computed, defineComponent } from 'vue';
import { AxisEvents } from '@/core/domain/events';
import { Unit } from '@/features/campaigns/application/motive/forms/unit';


export default defineComponent({
  name: 'app-offset-picker',
  emits: [
    AxisEvents.UPDATE_VERTICAL,
    AxisEvents.UPDATE_HORIZONTAL,
  ],
  props: {
    horizontal: { type: Number, required: true },
    vertical: { type: Number, required: true },
    label: String,
    hasReset: { type: Boolean, default: false },
    initialHorizontal: { type: Number, required: false },
    initialVertical: { type: Number, required: false },
  },
  setup(props, context) {
    const onUpdateVertical = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_VERTICAL,
        value,
      );
    };

    const onUpdateHorizontal = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_HORIZONTAL,
        value,
      );
    };

    const onReset = () => {
      context.emit(
        AxisEvents.UPDATE_HORIZONTAL,
        props.initialHorizontal,
      );
      context.emit(
        AxisEvents.UPDATE_VERTICAL,
        props.initialVertical,
      );
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialHorizontal != undefined &&
        props.initialVertical !== undefined &&
        (props.initialHorizontal !== props.horizontal ||
          props.initialVertical !== props.vertical)
      );
    });

    return {
      isResetVisible,
      onReset,
      onUpdateVertical,
      onUpdateHorizontal,
      Unit,
    };
  },
});
