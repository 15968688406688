
import { Ref, ref, defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { UserRoles } from '../domain/value_objects';
import { useGetIsMobileDisplay } from '../../../theme/useGetIsMobileDisplay';
import { useCreateUsers } from '../application/useCreateUsers';
import { getValidUsers, hasAnyValidUser, isEmailValid } from '../application/utils';
import { UserInvite } from '../domain';
import { inviteUsersModalStyle as style } from './styles';

export default defineComponent({
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(_, context) {
    const users = ref<UserInvite[]>([
      { email: '', role: UserRoles.create('') },
    ]) as Ref<UserInvite[]>;

    const hasSubmitted = ref(false);
    const onEmailChanged = (e: string, index: number) => {
      users.value[index].email = e;
    };
    const onRoleChanged = (value: string, index: number) => {
      users.value[index].role = UserRoles.create(value);
    };

    const { isLoading, call } = useCreateUsers();

    const addUserInvite = () => {
      users.value.push({ email: '', role: UserRoles.create('') });
      hasSubmitted.value = false;
    };

    const isMobileDisplay = useGetIsMobileDisplay();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
      users.value = [{ email: '', role: UserRoles.create('') }];
    };

    const onInviteNewUsers = () => {
      hasSubmitted.value = true;
      if (hasAnyValidUser(users.value)) {
        const validUsers = getValidUsers(users.value);

        call(validUsers);
        onCancel();
      }
    };

    return {
      style,
      onEmailChanged,
      onRoleChanged,
      onInviteNewUsers,
      isLoading,
      hasSubmitted,
      addUserInvite,
      users,
      isMobileDisplay,
      onCancel,
      isEmailValid
    };
  },
});
