import { useStore } from '@/store';
import { computed } from 'vue';

export const useGetFormatStaticDefaults = () => {
  const store = useStore();

  return computed(() => {
    return store.getters.formatStaticDefaults();
  });
};
