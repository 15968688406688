
import { Folder } from '../domain/folder';
import { Media } from '../domain/media';
import { MediaEvents } from './events';
import BreadcrumbFolder from './breadcrumbFolder.vue';
import { useOnDropMediaToFolder } from '../application/dragAndDrop/useOnDropMediaToFolder';
import { useGetRootFolder } from '../application/useGetRootFolder';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    BreadcrumbFolder,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<Array<Media>>,
      validator: (prop: Folder[]) => prop.every(el => el instanceof Folder),
      required: true
    },
    refetchFolders: { type: Function, default: () => null },
    refetchAssets: { type: Function, default: () => null },
  },
  emits: [MediaEvents.FOLDER_SELECTED],
  setup(props, context) {
    const { rootFolder } = useGetRootFolder();
    const onFolderSelected = (media: Media) => {
      context.emit(MediaEvents.FOLDER_SELECTED, media);
    };

    const { onDropMediaToFolder } = useOnDropMediaToFolder({
      refetchFolders: props.refetchFolders as () => void,
      refetchAssets: props.refetchAssets as () => void,
    });

    return {
      rootFolder,
      onFolderSelected,
      onDropMediaToFolder,
    };
  },
});
