
import MediaListDots from './mediaListDots.vue';
import { MediaEvents } from './events';
import { getNumberOfFilesInsideFolder } from '../application/utils';
import { useGetMedia } from '../application/useGetMedia';
import { Folder } from '../domain/folder';
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: {
    folder: { type: Folder, required: true },
    allowDeletion: { type: Boolean, default: true },
    isDropAllowed: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
  },
  emits: [
    MediaEvents.FOLDER_SELECTED,
    MediaEvents.DELETE_MEDIA,
    'dragstart',
    'drop',
    MediaEvents.RENAME_MEDIA,
  ],
  components: { MediaListDots },
  setup(
    props,
    context,
  ) {
    const { media: mediaList } = useGetMedia();
    const isDragEnter = ref(false);
    const isDragged = ref(false);

    const onDragOver = (e: any) => {
      if (
        !props.isDropAllowed ||
        isDragged.value ||
        (!e.dataTransfer.types.includes('asset') &&
          !e.dataTransfer.types.includes('folder'))
      ) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = true;
    };

    const onDragLeave = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
    };

    const onDragEnd = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
      isDragged.value = false;
    };

    const onDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
      context.emit('drop', e);
    };

    const onDragStart = (e: any) => {
      isDragged.value = true;
      context.emit('dragstart', e);
    };

    return {
      MediaEvents,
      getNumberOfFilesInsideFolder,
      mediaList,
      onDragOver,
      onDragLeave,
      isDragEnter,
      onDrop,
      onDragStart,
      isDragged,
      onDragEnd,
    };
  },
});
