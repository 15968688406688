
import { defineComponent } from 'vue';
import emptyState from './assets/emptyState.png';
import noSearchResult from './assets/noSearchResult.png';

export default defineComponent({
  props: {
    isNoSearchResult: { type: Boolean, required: true },
  },
  setup() {
    return { emptyState, noSearchResult };
  },
});
