<template>
  <app-unauthenticated-layout :title="$t('password.newPassword.pageTitle')">
    <form v-if="token" @submit.stop.prevent="onSubmit">
      <app-input
        :errorMessage="
          !isPasswordValid(password) && hasSubmitted
            ? $t('password.newPassword.newPassword.errorMessage')
            : null
        "
        v-model="password"
        type="password"
        :label="$t('password.newPassword.newPassword.label')"
        class="mb-3"
      />
      <app-input
        :errorMessage="
          confirmPassword !== password && hasSubmitted
            ? $t('password.newPassword.confirmPassword.errorMessage')
            : null
        "
        v-model="confirmPassword"
        type="password"
        :label="$t('password.newPassword.confirmPassword.label')"
      />
      <app-button type="submit" class="mt-4 w-full" :isLoading="isLoading">
        {{ $t('password.newPassword.button') }}
      </app-button>
    </form>

    <!-- If no token in the querystring -->
    <div v-else>
      <app-txt variant="h3" class="font-bold text-gray-900">{{
        $t('password.newPassword.noTokenError.title')
      }}</app-txt>
      <app-txt variant="small" class="mt-2 text-gray-700">{{
        $t('password.newPassword.noTokenError.description')
      }}</app-txt>
    </div>
  </app-unauthenticated-layout>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useResetPassword } from '../../application/useResetPassword';
import { isPasswordValid } from '@/features/users/application/utils';
export default {
  setup() {
    const route = useRoute();
    const token = route.query.token;
    const password = ref('');
    const confirmPassword = ref('');
    const hasSubmitted = ref(false);
    const { call, isLoading } = useResetPassword();

    const onSubmit = () => {
      hasSubmitted.value = true;
      if (
        confirmPassword.value === password.value &&
        isPasswordValid(password.value)
      ) {
        call({
          password: password.value,
          confirmPassword: confirmPassword.value,
          token: token as string,
        });
      }
    };
    return {
      onSubmit,
      hasSubmitted,
      isLoading,
      password,
      confirmPassword,
      token,
      isPasswordValid
    };
  },
};
</script>
