import { Motive } from '../domain/motive/motive';

export class MotiveDTO {
  static toDomain(json: { [key: string]: any }): Motive {
    const motive = Motive.create({
      id: json.id,
      name: json.name,
      description: json.description,
      status: json.status,
      isDefaultMotive: json.isDefaultMotive,
      startDate: json.startDate,
      endDate: json.endDate,
      layout: json.layout,
      clickUrl: json.clickUrl,
      campaignId: json.campaignId,
      isActive: json.isActive,
      templateVersion: json.templateVersion,
      motiveGroupId: json.motiveGroupId,
      campaignName: json.campaignName,
    });

    motive.uniqueClickUrls = json.uniqueClickUrls;
    return motive;
  }

  static toJson(motive: Motive): { [key: string]: any } {
    return {
      id: motive.id,
      name: motive.name,
      description: motive.description,
      status: motive.status,
      isDefaultMotive: motive.isDefaultMotive,
      startDate: motive.startDate.getValue().toISOString(),
      endDate: motive.endDate.getValue().toISOString(),
      layout: motive.layout,
      clickUrl: motive.clickUrl,
      campaignId: motive.campaignId,
      isActive: motive.isActive,
      templateVersion: motive.templateVersion,
      motiveGroupId: motive.motiveGroupId,
      campaignName: motive.campaignName,
      uniqueClickUrls: motive.uniqueClickUrls,
    };
  }
}
