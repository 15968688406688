<template>
  <app-layout>
    <div class="flex flex-col md:mx-2">
      <div class="flex flex-row mb-2 md:mb-0.5 mx-2 md:mx-0">
        <app-txt
          variant="small"
          class="text-gray-500 cursor-pointer"
          @click="returnToCampaignOverview"
        >
          {{ breadcrumbFirstTitle }}
        </app-txt>
        <app-txt variant="small" class="text-gray-400 ml-2 mr-2">/</app-txt>

        <app-txt
          variant="small"
          class="text-gray-500 cursor-pointer"
          @click="returnToCampaignDetails"
        >
          {{ breadcrumbs.campaignName }}
        </app-txt>
      </div>
      <!-- Laptop only -->
      <div class="laptop-only">
        <div class="flex flex-row justify-between items-center mt-1">
          <div class="flex flex-row items-center">
            <app-txt variant="h1" class="text-white">
              {{ breadcrumbs.motiveName }}
            </app-txt>
            <app-button
              variant="base"
              class="ml-3 border border-primary text-primary focus:outline-none px-2 py-10px text-small"
              rightIcon="editPenPrimary"
              @click="onEditMotive()"
            >
              {{ t('campaigns.motiveDetails.edit') }}
            </app-button>
          </div>
          <div class="flex-row flex items-center">
            <app-button
              variant="base"
              class="mr-3 border border-primary text-primary focus:outline-none px-2 py-10px text-small"
              leftIcon="eye"
              @click="onPreviewAllFormats"
            >
              {{ t('campaigns.motiveDetails.previewAllFormats') }}
            </app-button>

            <app-input
              :placeholder="t('search')"
              leftIcon="search"
              v-model="searchedValue"
            />
          </div>
        </div>
      </div>

      <!-- Mobile only -->

      <div class="mobile-only">
        <div class="mx-2">
          <div class="flex flex-row items-center">
            <app-txt variant="h3" class="text-white">
              {{ breadcrumbs.motiveName }}
            </app-txt>
            <app-button
              variant="base"
              class="ml-1.5 text-primary focus:outline-none text-small"
              leftIcon="eye"
              @click="onPreviewAllFormats"
            >
            </app-button>
          </div>

          <app-input
            :placeholder="t('search')"
            leftIcon="search"
            v-model="searchedValue"
            class="w-full mt-2"
          />
        </div>
      </div>
      <app-table
        :headers="headers"
        :rows="rows"
        :searchedValue="searchedValue"
        class="mt-3 mb-4"
        :pageSize="MOTIVE_DETAILS_TABLE_PAGE_SIZE"
      >
        <template v-slot:[TemplateNames.CHECKBOX]="{ rowId }">
          <CheckboxCell
            @select-checkbox="
              isSelected => onCheckboxSelected(rowId, isSelected)
            "
            :checked="!!selectedRows[rowId]"
          />
        </template>

        <template v-slot:[TemplateNames.SELECT_ALL_HEADER]="{ formattedRows }">
          <SelectAllHeaderCell
            @select-checkbox="onSelectAll"
            :rowsToSelect="formattedRows"
          />
        </template>

        <template v-slot:[TemplateNames.TEXT]="{ value }">
          <app-txt class="truncate" :title="value" variant="small">
            {{ value }}
          </app-txt>
        </template>

        <template v-slot:[TemplateNames.STATUS]="{ value, rowId }">
          <Toggle
            @click="() => onToggleFormatStatus(value, rowId)"
            :modelValue="value"
            :classes="{
              container:
                'inline-block rounded-full outline-none border-primary-750',
            }"
          />
        </template>

        <template v-slot:[TemplateNames.SIZE]="{ value }">
          <app-txt>
            {{ t('size', { size: value }) }}
          </app-txt>
        </template>

        <template v-slot:[TemplateNames.FORMAT_CLICK_URLS]="{ value }"
          ><app-txt>
            <img
              src="../../../../components/icon/assets/link.svg"
              class="link-icon cursor-pointer ml-2"
              alt="Link Icon"
              v-tippy="{
                content: generateTooltipContent(value),
                allowHTML: true,
                interactive: true,
                theme: 'custom',
                placement: 'top-start',
              }"
            /> </app-txt
        ></template>

        <template v-slot:[TemplateNames.DATE]="{ value }">
          <DateCell :value="value" />
        </template>

        <template
          v-slot:[TemplateNames.TEXT_WITH_DESCRIPTION]="{ value, description }"
        >
          <TextWithDescriptionCell :value="value" :description="description" />
        </template>

        <template v-slot:[TemplateNames.ICON]="{ value }">
          <IconCell :value="value" icon="device" />
        </template>

        <template v-slot:[TemplateNames.EDIT]="{ value }">
          <button
            class="focus:outline-none text-center w-full"
            @click="() => onEditFormat(value)"
          >
            <IconCell :value="''" icon="editPen" class="w-iconSm mx-auto" />
          </button>
        </template>

        <template v-slot:[TemplateNames.PREVIEW]="{ rowId }">
          <PreviewCell @click="() => onPreviewRow(rowId)" />
        </template>

        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
        >
          <SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
          />
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script lang="ts">
import { t } from '@/services/i18n';
import Toggle from '@vueform/toggle';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TemplateNames } from '../../../../components/table';
import {
  CheckboxCell,
  DateCell,
  IconCell,
  SelectAllHeaderCell,
  SortableHeaderCell,
  TextWithDescriptionCell,
} from '../../../../components/table/cellTemplates';
import { useActivateFormat } from '../../application/format/useActivateFormat';
import { useDeactivateFormat } from '../../application/format/useDeactivateFormat';
import { useFetchFormatsForMotiveId } from '../../application/format/useFetchFormatsForMotiveId';
import { motiveDetailsTableHeaders } from '../../application/motive/utils/motiveDetailsTableHeaders';
import { useGetBreadcrumbs } from '../../application/motive/utils/useGetBreadcrumbs';
import { useGetMotiveDetailsRows } from '../../application/motive/utils/useGetMotiveDetailsRows';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import PreviewCell from './previewCell.vue';
import { generateTooltipContent } from '@/utils/generateTooltipContent';
import { useFetchMotives } from '../../application/motive/actions/useFetchMotives';

const MOTIVE_DETAILS_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    TextWithDescriptionCell,
    DateCell,
    SelectAllHeaderCell,
    CheckboxCell,
    IconCell,
    SortableHeaderCell,
    PreviewCell,
    Toggle,
  },
  methods: {
    generateTooltipContent,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const motiveId = route.query.id as string;

    const breadcrumbs = useGetBreadcrumbs(motiveId);

    useFetchMotives({});
    useFetchFormatsForMotiveId({ motiveId });

    const rows = useGetMotiveDetailsRows(motiveId);

    const selectedRows = ref<Record<string, boolean>>({});

    const onCheckboxSelected = (rowId: string, isSelected: boolean) => {
      selectedRows.value[rowId] = isSelected;
    };
    const onSelectAll = (params: {
      selectAll: boolean;
      rowsToSelect: { id: string }[];
    }) => {
      if (!params.selectAll) {
        selectedRows.value = {};
      } else {
        selectedRows.value = Object.fromEntries(
          params.rowsToSelect.map(row => [row.id, true]),
        );
      }
    };
    const formattedRows = computed(() =>
      rows.value.map((row: { id: string }) => {
        return {
          ...row,
          isSelected: !!selectedRows.value[row.id],
        };
      }),
    );

    const searchedValue = ref('');

    const returnToCampaignDetails = () => {
      router.push({
        name: campaignsRouteNames.campaignDetails,
        query: { id: breadcrumbs.value.campaignId },
      });
    };

    const onPreviewAllFormats = () => {
      const routeUrl = router.resolve({
        name: campaignsRouteNames.motivePreview,
        query: { motiveId },
      });
      window.open(routeUrl.href, '_blank');
    };
    const onPreviewRow = (id: string) => {
      const row = rows.value.find(row => row.id === id);
      const routeUrl = router.resolve({
        name: campaignsRouteNames.motivePreview,
        query: {
          previewFormat: row?.name || '',
          motiveId,
        },
      });
      window.open(routeUrl.href, '_blank');
    };

    const { call: activateFormat } = useActivateFormat();
    const { call: deactivateFormat } = useDeactivateFormat();

    const onToggleFormatStatus = async (value: boolean, rowId: string) => {
      if (value) {
        await deactivateFormat({
          formatId: rowId,
          motiveId,
        });
      } else {
        await activateFormat({
          formatId: rowId,
          motiveId,
        });
      }
    };
    const onEditMotive = () => {
      router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motiveId },
      });
    };

    const onEditFormat = (formatName: string) => {
      router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motiveId, format: formatName },
      });
    };

    const routeHistoryJson = localStorage.getItem('routeHistory');
    const routeHistory = routeHistoryJson ? JSON.parse(routeHistoryJson) : [];

    const titleTranslationKey = computed(() => {
      if (routeHistory[1] === '/overview/list') {
        return 'campaigns.motiveDetails.overview';
      } else {
        return 'campaigns.title';
      }
    });

    const returnToCampaignOverview = () => {
      router.push({
        name:
          titleTranslationKey.value === 'campaigns.motiveDetails.overview'
            ? campaignsRouteNames.overview
            : campaignsRouteNames.campaignList,
      });
    };

    const breadcrumbFirstTitle = computed(() => t(titleTranslationKey.value));

    return {
      t,
      MOTIVE_DETAILS_TABLE_PAGE_SIZE,
      searchedValue,
      returnToCampaignOverview,
      returnToCampaignDetails,
      onPreviewAllFormats,
      headers: motiveDetailsTableHeaders,
      rows: formattedRows,
      onCheckboxSelected,
      onToggleFormatStatus,
      onSelectAll,
      selectedRows,
      TemplateNames,
      onPreviewRow,
      breadcrumbs,
      motiveId,
      onEditMotive,
      onEditFormat,
      breadcrumbFirstTitle,
    };
  },
};
</script>
