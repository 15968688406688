import { PositionCreationParams } from '../position';

export interface PreviewAttributes {
  style: string;
}

export const CssUnit = {
  PX: 'px',
  EM: 'em',
};

export class CSSInlineStyle {
  private style = '';

  build(): string {
    return this.style;
  }

  addAbsolutePositionForSlide(): CSSInlineStyle {
    this.style += `top: 0; right: 0; bottom: 0; left: 0; position: absolute;`;
    return this;
  }

  addPosition(position?: PositionCreationParams): CSSInlineStyle {
    if (position) {
      this.style += `position: absolute; top: ${position.y}${CssUnit.PX}; left: ${position.x}${CssUnit.PX};`;
    }
    return this;
  }

  addFontSize(size?: number): CSSInlineStyle {
    if (size) {
      this.style += `font-size: ${size}${CssUnit.EM};`;
    }
    return this;
  }

  addLineHeight(lineSpacing?: number): CSSInlineStyle {
    if (lineSpacing) {
      this.style += `line-height: ${lineSpacing}${CssUnit.EM};`;
    }
    return this;
  }

  addWidth(width?: number): CSSInlineStyle {
    if (width) {
      this.style += `width: ${width}${CssUnit.PX};`;
    }
    return this;
  }

  addZIndex(zIndex?: number): CSSInlineStyle {
    if (zIndex) {
      this.style += `z-index: ${zIndex};`;
    }
    return this;
  }
}
