import { PreviewSlide } from './preview/previewSlide';
import { Asset } from '@/features/media/domain/asset';
import { CSSInlineStyle } from './preview/iPreviewAttributes';
import { Layout } from '.';
import { Contract } from '../contract';
import { Device } from '../device';
import { formatContractData, formatDeviceData } from '../utils';
import {
  CreateSlideImageParams,
  CreateSlideImageForValuesParams,
  SlideImage,
} from './blocks/slideImage';
import {
  CreateSlideInterfererParams,
  CreateSlideInterfererForValuesParams,
  SlideInterferer,
} from './blocks/slideInterferer';
import {
  CreateSlidePriceParams,
  CreateSlidePriceForValuesParams,
  SlidePrice,
} from './blocks/slidePrice';
import {
  CreateSlideTextParams,
  CreateSlideTextForValuesParams,
  SlideText,
} from './blocks/slideText';

export enum SlideTypes {
  DEVICE = 'device',
  CONTRACT = 'contract',
}

export const DEFAULT_SALES_SLIDE_INDEX = 1;
export const DEFAULT_SLIDE_DURATION_IN_MS = 5000;

export type CreateSlideParams = {
  id: string;
  contractId: string;
  clickUrl: string;
  duration: number;
  texts: CreateSlideTextParams[];
  images: CreateSlideImageParams[];
  prices: CreateSlidePriceParams[];
  interferers: CreateSlideInterfererParams[];
  deviceId?: string | undefined;
  removed?: boolean;
  isDefault?: boolean;
};

export type CreateSlideForValuesParams = {
  id: string;
  contractId: string;
  clickUrl: string;
  duration: number;
  texts: CreateSlideTextForValuesParams[];
  images: CreateSlideImageForValuesParams[];
  prices: CreateSlidePriceForValuesParams[];
  interferers: CreateSlideInterfererForValuesParams[];
  deviceId?: string | undefined;
  removed?: boolean;
  isDefault?: boolean;
};

export class Slide {
  private constructor(
    public id: string,
    public contractId: string,
    public clickUrl: string,
    public duration: number,
    public texts: SlideText[],
    public images: SlideImage[],
    public prices: SlidePrice[],
    public interferers: SlideInterferer[],
    public deviceId?: string | undefined,
    public removed?: boolean,
    public isDefault?: boolean,
  ) {
    this.adjustZIndices();
  }

  public toJson() {
    return {
      id: this.id,
      contractId: this.contractId,
      clickUrl: this.clickUrl,
      duration: this.duration,
      texts: this.texts.map(text => text.toJson()),
      images: this.images.map(image => image.toJson()),
      prices: this.prices.map(price => price.toJson()),
      interferers: this.interferers.map(interferer => interferer.toJson()),
      deviceId: this.deviceId,
      removed: this.removed,
      isDefault: this.isDefault,
    };
  }

  public getElements() {
    return [...this.images, ...this.texts, ...this.prices, ...this.interferers];
  }

  public adjustZIndices() {
    let zIndex = 1;

    const zIndexSetter = (
      item: SlideText | SlideImage | SlidePrice | SlideInterferer,
    ) => {
      item.zIndex = zIndex;
      zIndex++;
    };

    const elements = this.getElements();

    const elementsWithoutZIndex = elements.filter(element => !element.zIndex);
    const elementsWithZIndex = elements.filter(
      element => element.zIndex !== undefined,
    );
    elementsWithZIndex.sort((a, b) => a.zIndex! - b.zIndex!);

    elementsWithoutZIndex.forEach(zIndexSetter);
    elementsWithZIndex.forEach(zIndexSetter);
  }

  public getHighestZIndex() {
    const elements = this.getElements();

    return elements.reduce(
      (highest, element) =>
        Math.max(highest, element.zIndex !== undefined ? element.zIndex : 1),
      1,
    );
  }

  public swapZIndices(indexA: number, indexB: number) {
    const elements = this.getElements();

    const elementA = elements.find(element => element.zIndex === indexA);
    const elementB = elements.find(element => element.zIndex === indexB);

    if (elementA && elementB) {
      elementA.zIndex = indexB;
      elementB.zIndex = indexA;
    }
  }

  static create(params: CreateSlideParams) {
    return new Slide(
      params.id,
      params.contractId,
      params.clickUrl,
      params.duration,
      params.texts.map(text => SlideText.create(text)),
      params.images.map(image => SlideImage.create(image)),
      params.prices.map(price => SlidePrice.create(price)),
      params.interferers.map(interferer => SlideInterferer.create(interferer)),
      params.deviceId,
      params.removed,
      params.isDefault,
    );
  }

  static createForValues(params: CreateSlideForValuesParams) {
    return new Slide(
      params.id,
      params.contractId,
      params.clickUrl,
      params.duration,
      params.texts.map(text => SlideText.createForValues(text)),
      params.images.map(image => SlideImage.createForValues(image)),
      params.prices.map(price => SlidePrice.createForValues(price)),
      params.interferers.map(interferer =>
        SlideInterferer.createForValues(interferer),
      ),
      params.deviceId,
      params.removed,
      params.isDefault,
    );
  }

  public toPreviewSlideDTO({
    imagesById,
    formatName,
    devicesById,
    contractsById,
  }: toPreviewSlideDTOParams) {
    const previewImages = this.images
      .filter(image => !image.removed)
      .map(image =>
        image.toPreviewContent({
          formatName,
          imagesById,
        }),
      );

    const previewTexts = this.texts
      .filter(text => !text.removed)
      .map(text =>
        text.toPreviewContent({
          contractData: formatContractData(contractsById[this.contractId]),
          deviceData: formatDeviceData(devicesById[this.deviceId ?? '']),
        }),
      );

    const previewPrices = this.prices
      .filter(price => !price.removed)
      .map(price =>
        price.toPreviewContent({
          contractData: formatContractData(contractsById[this.contractId]),
          deviceData: formatDeviceData(devicesById[this.deviceId ?? '']),
        }),
      );

    const previewSlideContentInterferer = this.interferers
      .filter(interferer => !interferer.removed)
      .map(interferer =>
        interferer.toPreviewContent({
          formatName,
          imagesById,
        }),
      );

    return PreviewSlide.create({
      attributes: {
        style: new CSSInlineStyle().addAbsolutePositionForSlide().build(),
      },
      duration: this.duration,
      clickUrl: this.clickUrl,
      content: [
        ...previewImages,
        ...previewTexts,
        ...previewPrices,
        ...previewSlideContentInterferer,
      ],
    });
  }
}

export type toPreviewSlideDTOParams = {
  devicesById: Record<string, Device>;
  contractsById: Record<string, Contract>;
  imagesById: Record<string, Asset>;
  formatName: string;
  layout: Layout;
};
