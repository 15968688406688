import { Ref, watch } from 'vue';

import { defaultDeviceImageLabel } from './defaults/elements/defaultImages';
import { Device } from '@/features/campaigns/domain/device';
import { ImageContentType } from '@/features/campaigns/domain/valueObjects/blocks/slideImage';
import {
  Slide,
  CreateSlideParams,
} from '@/features/campaigns/domain/valueObjects/slide';
import { Layout } from '@/features/campaigns/domain/valueObjects';

export const useHandleFieldsPrefill = ({
  slideOrder,
  slides,
  layout,
  devicesById,
  defaultSlides,
}: {
  slideOrder: Ref<string[]>;
  slides: Ref<Record<string, Slide>>;
  layout: Ref<Layout>;
  devicesById?: Ref<Record<string, Device>>;
  defaultSlides?: Ref<Record<string, CreateSlideParams>>;
}) => {
  const onContractChanged = (slideId: string, contractId: string) => {
    slides.value[slideId].contractId = contractId;
  };

  const onDeviceChanged = (slideId: string, deviceId: string) => {
    slides.value[slideId].deviceId = deviceId;
    const defaultImage = defaultSlides?.value?.[slideId]?.images?.find(
      image => image.label === defaultDeviceImageLabel,
    );

    if (devicesById?.value?.[deviceId]?.imageId) {
      slides.value[slideId].images.forEach(image => {
        const isContentDefault =
          JSON.stringify(defaultImage?.content ?? []) ===
          JSON.stringify(image.content || []);

        if (image.label === defaultDeviceImageLabel && isContentDefault) {
          image.content = [
            {
              id: devicesById?.value?.[deviceId]?.imageId,
              type: ImageContentType.Square,
            },
            {
              id: devicesById?.value?.[deviceId]?.imageId,
              type: ImageContentType.Vertical,
            },
            {
              id: devicesById?.value?.[deviceId]?.imageId,
              type: ImageContentType.Horizontal,
            },
          ];
        } else {
          return image;
        }
      });
    }
  };

  watch([layout], () => {
    if (layout.value === Layout.CONTRACT) {
      slideOrder.value.forEach(id => {
        onContractChanged(id, slides.value[id].contractId);
      });
    } else {
      slideOrder.value.forEach(id => {
        if (slides.value[id].deviceId !== undefined) {
          onDeviceChanged(id, slides.value[id].deviceId!);
        }
      });
    }
  });

  return {
    onContractChanged,
    onDeviceChanged,
  };
};
