
import { ModalEvents } from '@/components/modal/modalEvents';
import { t } from '@/services/i18n';
import { computed, defineComponent, ref } from 'vue';
import { useGetCampaignDropdown } from '../../application/campaign/list/useGetCampaignDropdown';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { useToast } from 'vue-toastification';

export default defineComponent({
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(_, context) {
    const hasSubmitted = ref(false);
    const name = ref('');
    const trackingParameter = ref('');
    const isStatusSelectValid = ref(true);

    const { campaignDropdownOptions } = useGetCampaignDropdown({onlyActive: true});
    const campaignIdSelected = ref([]);

    const isSelectValid = ref(false);
    const setIsSelectValid = (value: boolean) => {
      isSelectValid.value = value && campaignIdSelected.value.length > 0;
    };
    const statusSelected = ref('');
    const statusDropdownOptions = computed(() => [
      { value: 'all', label: t('overview.exportModal.allStatus') },
      { value: 'active', label: t('overview.exportModal.statusActive') },
      { value: 'inactive', label: t('overview.exportModal.statusInactive') },
    ]);

    const setIsStatusSelectValid = (value: boolean) => {
      isStatusSelectValid.value = value;
    };

    const closeModal = () => {
      campaignIdSelected.value = [];
      statusSelected.value = '';
      isSelectValid.value = false;
      isStatusSelectValid.value = true;
      hasSubmitted.value = false;
      name.value = '';
      trackingParameter.value = '';
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onExportMotives = async () => {
      hasSubmitted.value = true;
      const toast = useToast();
      if (campaignIdSelected.value) {
        try {
          const campaignHttpFacade = new CampaignHttpFacade();
          const campaignIds = campaignIdSelected.value.join(',');

          await campaignHttpFacade.exportMotivesOverview(
            campaignIds,
            statusSelected.value,
          );
          closeModal();
          toast.success(t('overview.exportModal.success'));
        } catch (e) {
          console.error('Failed to export motives overview', e);
          toast.error(t('overview.exportModal.error'));
        }
      }
    };

    return {
      name,
      trackingParameter,
      hasSubmitted,
      closeModal,
      campaignDropdownOptions,
      campaignIdSelected,
      setIsSelectValid,
      isSelectValid,
      statusDropdownOptions,
      isStatusSelectValid,
      setIsStatusSelectValid,
      statusSelected,
      onExportMotives,
    };
  },
});
