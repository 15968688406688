
import {
  computed,
  defineComponent,
  ref,
  watch,
  nextTick,
  Ref,
  provide,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { campaignsTranslateByScope } from '../translationScope';
import { TemplateNames } from '../../../../components/table';
import { useFetchRawFormatsForMotiveId } from '../../application/format/useFetchRawFormatsForMotiveId';
import VersionHistorySidebar from './siderbar/versionHistory/versionHistory.sidebar.vue';
import EditSidebar from './siderbar/edit/edit.sidebar.vue';
import Canvas from './canvas.vue';
import Topbar from './topbar.vue';
import ProviderWrapper from './providerWrapper.vue';
import UnsavedChangesModal from './unsavedChangesModal.vue';
import { useGetMotive } from '../../application/motive/actions/useGetMotive';
import { useGetRawMotiveFormats } from '../../application/format/useGetRawMotiveFormats';
import { useGetRawMotiveFormatsDefaultsInstances } from '../../application/format/useGetRawMotiveFormatsDefaultsInstances';
import { useFetchMotiveById } from '../../application/motive/actions/useFetchMotiveById';
import { useFetchContracts } from '../../application/contract/useFetchContracts';
import { useFetchDevices } from '../../application/device/useFetchDevices';
import { useFetchFolders } from '@/features/media/application/useFetchFolders';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { useGetFormatFilter } from '../../application/motive/editor/useGetFormatFilter';
import { PreviewSettings } from '../../domain/format/previewSettings';
import {
  AdjustablePreviewKey,
  FormState,
} from '../../application/motive/editor/types';
import { useAdjustablePreview } from '../../application/motive/editor/useAdjustablePreview';
import { useGetDevicesById } from '../../application/device/useGetDevicesById';
import { useGetContractsById } from '../../application/contract/useGetContractsById';
import { useToast } from 'vue-toastification';
import { useFetchMotiveVersions } from '../../application/motive/actions/useFetchMotiveVersions';
import ResetChangesModal from './resetChangesModal.vue';
import { useFetchRawFormatsDefaultsInstancesForMotiveId } from '../../application/format/useFetchRawFormatsDefaultInstanceForMotiveId';
import { Format } from '../../domain/format/format';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import { FormatDefault } from '../../domain/format/formatDefault';
import { useFetchFormatStaticDefaults } from '@/features/campaigns/application/format/useFetchFormatStaticDefaults';

export default defineComponent({
  components: {
    ProviderWrapper,
    EditSidebar,
    VersionHistorySidebar,
    Topbar,
    Canvas,
    UnsavedChangesModal,
    ResetChangesModal,
  },
  beforeRouteEnter(to, from, next) {
    const motiveId = to.query.id as string;
    const { isLoading: fetchFormatsLoading } = useFetchRawFormatsForMotiveId({
      motiveId,
    });
    const {
      isLoading: fetchFormatsDefaultsInstancesLoading,
    } = useFetchRawFormatsDefaultsInstancesForMotiveId({
      motiveId,
    });
    const { isLoading: fetchMotiveLoading } = useFetchMotiveById({ motiveId });

    watch(
      [
        fetchFormatsLoading,
        fetchMotiveLoading,
        fetchFormatsDefaultsInstancesLoading,
      ],
      () => {
        if (
          !fetchFormatsLoading.value &&
          !fetchMotiveLoading.value &&
          !fetchFormatsDefaultsInstancesLoading.value
        ) {
          next();
        }
      },
    );
  },
  setup() {
    useFetchContracts({});
    useFetchDevices({});
    useFetchFolders({});
    useFetchAssets({});

    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const motiveId = route.query.id as string;
    useFetchMotiveVersions({ motiveId });
    const formats = useGetRawMotiveFormats(motiveId);
    const formatsDefaultsInstances = useGetRawMotiveFormatsDefaultsInstances(
      motiveId,
    );
    const initialMotive = useGetMotive();
    const renderSidebar = ref(true);
    const selectedFormat = useGetFormatFilter();
    const devicesById = useGetDevicesById();
    const contractsById = useGetContractsById();
    const initialValidationDone = ref(false);
    const livePreviewEnabled = ref(true);
    const iframeInteractivityEnabled = ref(false);
    const shouldReplay = ref(false);
    const shouldSave = ref(false);
    const changingSelectedFormat = ref(false);
    const isVersionHistoryEnabled = ref(false);
    useFetchFormatStaticDefaults();

    const rawFormat = selectedFormat.value
      ? formats.value.filter(format => format?.name === selectedFormat.value)[0]
      : undefined;
    const rawFormatDefaultInstance = selectedFormat.value
      ? formatsDefaultsInstances.value.filter(
          (format: FormatDefault) => format?.name === selectedFormat.value,
        )[0]
      : FormatDefault.createIntersect(
          formatsDefaultsInstances.value,
          initialMotive.value.name,
        );

    const initialSidebarFormat = ref(
      initialMotive.value.toFormat({ rawFormat, rawFormatDefaultInstance }),
    ) as Ref<Format>;
    const formatFromSettings = ref(initialSidebarFormat.value) as Ref<Format>;
    const previewedFormat = ref(formatFromSettings.value) as Ref<Format>;

    const hasChanges = ref(false);
    const unsavedChangesModalOpen = ref(false);
    const unsavedChangesModalExitFunc = ref<Function | null>(null);

    const resetChangesModalOpen = ref(false);

    const formState = ref<FormState | undefined>();

    const defaultPreviewSettings: PreviewSettings = {
      selectedSlideId: undefined,
      keepSpecifics: !selectedFormat.value,
    };

    const previewSettings = ref<PreviewSettings>(defaultPreviewSettings);
    const activePreviewSettings = ref<PreviewSettings>(defaultPreviewSettings);

    const adjustablePreview = useAdjustablePreview();
    provide(AdjustablePreviewKey, adjustablePreview);

    const t = campaignsTranslateByScope('editMotive');

    const loadModalTextsConfig = (
      configDesired: { continue: boolean } = { continue: false },
    ) => {
      if (configDesired.continue) {
        return {
          title: t('unsavedContinueModal.exitTitle'),
          description: t('unsavedContinueModal.exitDescription'),
          exitLabel: t('unsavedContinueModal.exitButtonLabel'),
          saveAndExitLabel: t('unsavedContinueModal.saveAndExitButtonLabel'),
          cancelLabel: t('unsavedContinueModal.cancelButtonLabel'),
        };
      }
      return {
        title: t('unsavedExitModal.exitTitle'),
        description: t('unsavedExitModal.exitDescription'),
        exitLabel: t('unsavedExitModal.exitButtonLabel'),
        saveAndExitLabel: t('unsavedExitModal.saveAndExitButtonLabel'),
        cancelLabel: t('unsavedExitModal.cancelButtonLabel'),
      };
    };

    const unsavedChangesModalTexts = ref(loadModalTextsConfig());

    const validateNoDeletedContractsOrDevices = () => {
      const errorShown = false;

      // TODO: Fix this
      // initialMotive.value.slides.forEach((slide, index) => {
      //   const hasDeletedDevice =
      //     slide.deviceId && !devicesById.value[slide.deviceId];
      //   const hasDeletedContract =
      //     slide.contractId && !contractsById.value[slide.contractId];
      //   const slideNumber = index + 1;

      //   if (hasDeletedDevice && !errorShown) {
      //     toast.error(t('deletedDevice', { slideNumber }));
      //     errorShown = true;
      //   }

      //   if (hasDeletedContract && !errorShown) {
      //     toast.error(t('deletedContract', { slideNumber }));
      //     errorShown = true;
      //   }
      // });
    };

    const updateSidebar = () => {
      renderSidebar.value = false;
      changingSelectedFormat.value = true;

      nextTick(() => {
        renderSidebar.value = true;
      });
    };

    const updateVersionHistoryEnabled = (isEnabled: boolean) => {
      isVersionHistoryEnabled.value = isEnabled;
    };
    const checkForChangesAndExecute = (callback: Function) => {
      if (hasChanges.value) {
        unsavedChangesModalOpen.value = true;
        unsavedChangesModalExitFunc.value = callback;
      } else {
        callback();
      }
    };

    const resetForm = () => {
      hasChanges.value = false;
      formatFromSettings.value = initialSidebarFormat.value;
      previewedFormat.value = initialSidebarFormat.value;
      updateSidebar();
    };

    const updateSelectedFormat = (value: string) => {
      unsavedChangesModalTexts.value = loadModalTextsConfig({ continue: true });
      checkForChangesAndExecute(() => {
        selectedFormat.value = value;
        resetForm();
      });
    };

    const returnToDetailsPage = () => {
      unsavedChangesModalTexts.value = loadModalTextsConfig();
      checkForChangesAndExecute(() => {
        router.push({
          name: campaignsRouteNames.motiveDetails,
          query: { id: motiveId },
        });
      });
    };

    const returnToEditAllFormats = () => {
      unsavedChangesModalTexts.value = loadModalTextsConfig();
      checkForChangesAndExecute(() => {
        selectedFormat.value = '';
        resetForm();
      });
    };

    const campaignId = computed(() => initialMotive.value.campaignId);

    const updateFormatFromSettings = (updatedFormat: Format) => {
      formatFromSettings.value = updatedFormat;

      if (livePreviewEnabled.value || changingSelectedFormat.value) {
        changingSelectedFormat.value = false;
        previewedFormat.value = formatFromSettings.value;
      }
    };

    const setActivePreviewSettings = () => {
      if (livePreviewEnabled.value || iframeInteractivityEnabled.value) {
        activePreviewSettings.value = previewSettings.value;
      } else {
        activePreviewSettings.value = defaultPreviewSettings;
      }
    };

    const updatePreviewSettings = (settings: PreviewSettings) => {
      previewSettings.value = settings;
      setActivePreviewSettings();
    };

    const updateLivePreview = (isLive: boolean) => {
      livePreviewEnabled.value = isLive;
      setActivePreviewSettings();
      previewedFormat.value = formatFromSettings.value;
    };

    const updateInteractivity = (isInteractive: boolean) => {
      iframeInteractivityEnabled.value = isInteractive;
      setActivePreviewSettings();
    };

    const updateFormState = (newFormState: FormState) => {
      formState.value = newFormState;
    };

    const updateShouldReplay = (value: boolean) => {
      if (value) {
        previewedFormat.value = formatFromSettings.value;
        setActivePreviewSettings();
      }

      shouldReplay.value = value;
    };

    const setHasChanges = (value: boolean) => {
      hasChanges.value = value;
    };

    const closeUnsavedChangesModal = () => {
      unsavedChangesModalOpen.value = false;
    };

    const closeResetChangesModal = () => {
      resetChangesModalOpen.value = false;
    };

    const onSaveAndExit = () => {
      shouldSave.value = true;
    };

    const openResetChangesModal = () => {
      if (hasChanges.value) {
        resetChangesModalOpen.value = true;
      }
    };

    const confirmResetChanges = () => {
      resetForm();
      toast.success(t('resetChangesModal.success'));
    };

    const onSuccessfullySaved = () => {
      shouldSave.value = false;
      unsavedChangesModalExitFunc.value?.();
      hasChanges.value = false;
      unsavedChangesModalExitFunc.value = null;
      validateNoDeletedContractsOrDevices();
    };

    watch([initialSidebarFormat, formats, formatsDefaultsInstances], () => {
      updateSidebar();
      formatFromSettings.value = initialSidebarFormat.value;
      previewedFormat.value = initialSidebarFormat.value;
    });

    watch([initialMotive, contractsById, devicesById], () => {
      if (
        initialMotive.value &&
        contractsById.value &&
        devicesById.value &&
        !initialValidationDone.value
      ) {
        initialValidationDone.value = true;
        validateNoDeletedContractsOrDevices();
      }
    });

    return {
      shouldSave,
      onSaveAndExit,
      onSuccessfullySaved,
      updateFormState,
      formState,
      closeUnsavedChangesModal,
      unsavedChangesModalOpen,
      unsavedChangesModalExitFunc,
      closeResetChangesModal,
      resetChangesModalOpen,
      confirmResetChanges,
      setHasChanges,
      previewedFormat,
      renderSidebar,
      selectedFormat,
      updateSelectedFormat,
      t,
      returnToDetailsPage,
      returnToEditAllFormats,
      openResetChangesModal,
      TemplateNames,
      motiveId,
      campaignId,
      initialMotive,
      formats,
      formatsDefaultsInstances,
      updateFormatFromSettings,
      livePreviewEnabled,
      updateLivePreview,
      iframeInteractivityEnabled,
      updateInteractivity,
      versionHistoryEnabled: isVersionHistoryEnabled,
      updateVersionHistoryEnabled,
      shouldReplay,
      updateShouldReplay,
      activePreviewSettings,
      updatePreviewSettings,
      unsavedChangesModalConfig: unsavedChangesModalTexts,
    };
  },
});
