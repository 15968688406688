import {
  CreateSlideTextForValuesParams,
  CreateSlideTextParams,
} from '@/features/campaigns/domain/valueObjects/blocks/slideText';
import { Position } from '@/features/campaigns/domain/valueObjects/position';

export const generateDefaultTextParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlideTextParams => ({
  label: params.label,
  content: '',
  blockWidth: 100,
  lineSpacing: 1,
  transition: {
    type: 'scale',
    delay: 400,
    x: 0,
    y: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
  size: 1,
  zIndex: params?.zIndex,
  removed: false,
});
export const generateDefaultTextForValuesParams = (params: {
  label: string;
  zIndex?: number;
}): CreateSlideTextForValuesParams => ({
  label: params.label,
  content: '',
  blockWidth: 100,
  lineSpacing: 1,
  transition: {
    type: 'scale',
    delay: 400,
    x: 0,
    y: 0,
  },
  position: Position.create({
    x: 0,
    y: 0,
  }),
  size: 1,
  zIndex: params?.zIndex,
  removed: false,
});
