<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between pb-1">
      <app-txt v-if="label" variant="small" class="text-gray-700 font-medium">{{
        label
      }}</app-txt>
      <button v-if="isResetVisible" @click="onReset" class="focus:outline-none">
        <reset-icon color="#6B7280" />
      </button>
    </div>
    <div
      class="grid grid-cols-3 gap-y-1px gap-x-1px border border-gray-300 bg-gray-200 rounded-1 overflow-hidden"
    >
      <button
        v-for="alignment in alignments"
        :key="alignment"
        @click="onClick(alignment.vertical, alignment.horizontal)"
        :class="[
          'flex flex-row items-center justify-center h-alignmentPicker focus:outline-none',
          alignment.isSelected ? 'bg-primary' : 'bg-white hover:bg-primary-150',
        ]"
      >
        <component :is="alignment.icon" :isSelected="alignment.isSelected" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { AxisEvents } from '@/core/domain/events';
import TopLeftArrow from './icons/topLeftArrow.vue';
import TopArrow from './icons/topArrow.vue';
import TopRightArrow from './icons/topRightArrow.vue';
import CenterLeftArrow from './icons/centerLeftArrow.vue';
import Center from './icons/center.vue';
import CenterRightArrow from './icons/centerRightArrow.vue';
import BottomLeftArrow from './icons/bottomLeftArrow.vue';
import BottomArrow from './icons/bottomArrow.vue';
import BottomRightArrow from './icons/bottomRightArrow.vue';

const alignmentComponents = [
  {
    vertical: VerticalAlign.TOP,
    horizontal: HorizontalAlign.LEFT,
    icon: 'top-left-arrow',
  },
  {
    vertical: VerticalAlign.TOP,
    horizontal: HorizontalAlign.CENTER,
    icon: 'top-arrow',
  },
  {
    vertical: VerticalAlign.TOP,
    horizontal: HorizontalAlign.RIGHT,
    icon: 'top-right-arrow',
  },
  {
    vertical: VerticalAlign.CENTER,
    horizontal: HorizontalAlign.LEFT,
    icon: 'center-left-arrow',
  },
  {
    vertical: VerticalAlign.CENTER,
    horizontal: HorizontalAlign.CENTER,
    icon: 'center',
  },
  {
    vertical: VerticalAlign.CENTER,
    horizontal: HorizontalAlign.RIGHT,
    icon: 'center-right-arrow',
  },
  {
    vertical: VerticalAlign.BOTTOM,
    horizontal: HorizontalAlign.LEFT,
    icon: 'bottom-left-arrow',
  },
  {
    vertical: VerticalAlign.BOTTOM,
    horizontal: HorizontalAlign.CENTER,
    icon: 'bottom-arrow',
  },
  {
    vertical: VerticalAlign.BOTTOM,
    horizontal: HorizontalAlign.RIGHT,
    icon: 'bottom-right-arrow',
  },
];

export default defineComponent({
  name: 'alignment-picker',
  emits: [
    AxisEvents.UPDATE_VERTICAL,
    AxisEvents.UPDATE_HORIZONTAL,
  ],
  components: {
    TopLeftArrow,
    TopArrow,
    TopRightArrow,
    CenterLeftArrow,
    Center,
    CenterRightArrow,
    BottomLeftArrow,
    BottomArrow,
    BottomRightArrow,
  },
  props: {
    horizontal: { type: String, required: true },
    vertical: { type: String, required: true },
    label: String,
    hasReset: { type: Boolean, default: false },
    initialHorizontal: { type: String, required: false },
    initialVertical: { type: String, required: false },
  },
  setup(props, context) {
    const alignments = computed(() =>
      alignmentComponents.map(component => ({
        ...component,
        isSelected:
          component.vertical === props.vertical &&
          component.horizontal === props.horizontal,
      })),
    );

    const onClick = (vertical: VerticalAlign, horizontal: HorizontalAlign) => {
      context.emit(AxisEvents.UPDATE_VERTICAL, vertical);
      context.emit(AxisEvents.UPDATE_HORIZONTAL, horizontal);
    };

    const onReset = () => {
      context.emit(AxisEvents.UPDATE_VERTICAL, props.initialVertical);
      context.emit(AxisEvents.UPDATE_HORIZONTAL, props.initialHorizontal);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialHorizontal &&
        props.initialVertical &&
        (props.initialHorizontal !== props.horizontal ||
          props.initialVertical !== props.vertical)
      );
    });

    return {
      isResetVisible,
      onReset,
      onClick,
      alignments,
    };
  },
});
</script>
