import { Position, PositionCreationParams } from '../position';
import { CreateSlideElementParams, SlideElement } from './slideElement';
import { evaluate, formatContractData, formatDeviceData } from '../../utils';
import { CSSInlineStyle } from '../preview/iPreviewAttributes';
import { PreviewContentType } from '../preview/iPreviewContent';
import { PreviewTransition } from '../preview/iPreviewTransition';
import { PreviewPrice } from '../preview/previewPrice';
import { PreviewSlideContent } from '../preview/previewSlideContent';

export type CreateSlidePriceParams = CreateSlideElementParams & {
  overline?: string;
  value?: string;
  interval?: string;
  underline?: string;
  scaling?: number;
  position?: PositionCreationParams;
  transition?: PreviewTransition | undefined;
};
export type CreateSlidePriceForValuesParams = CreateSlideElementParams & {
  overline?: string;
  value?: string;
  interval?: string;
  underline?: string;
  scaling?: number;
  position?: Position;
  transition?: PreviewTransition | undefined;
  removed?: boolean;
  isDefault?: boolean;
};

type ContractData = ReturnType<typeof formatContractData>;
type DeviceData = ReturnType<typeof formatDeviceData>;

const HTML_TAG_REGEX = RegExp(/<[^>]+>/gi);
const HTML_TAG_CONTENT_REGEX = RegExp(/<[^>]*>([^<]*)<\/[^>]*>/);

const PRICE_CURRENCY_FALLBACK = '€';
const PRICE_VALUES_FALLBACK = '';
const DEFAULT_LABEL = 'Price';

export class SlidePrice extends SlideElement {
  public overline?: string;
  public value?: string;
  public interval?: string;
  public underline?: string;
  public scaling?: number;
  public position?: Position;
  public transition?: PreviewTransition | undefined;

  private constructor(params: CreateSlidePriceForValuesParams) {
    super(params.label, params.zIndex, params.removed);
    this.overline = params.overline;
    this.value = params.value;
    this.interval = params.interval;
    this.underline = params.underline;
    this.scaling = params.scaling;
    this.position = params.position;
    this.transition = params.transition;
    this.isDefault = params.isDefault;
  }

  private removeEmptyTagsContent(content: string | undefined) {
    if (!content) {
      return;
    }

    const matches = content.match(HTML_TAG_CONTENT_REGEX);

    if (!matches) {
      return content;
    }

    const contentMatch = matches[1];

    if (contentMatch === '') {
      return;
    }

    return content;
  }

  private formatPreviewContent(
    content: string | undefined,
    evaluationParams: {
      contractData: ContractData;
      deviceData: DeviceData;
    },
  ) {
    if (!content) {
      return;
    }

    const evaluated = evaluate(content, {
      contract: evaluationParams.contractData,
      device: evaluationParams.deviceData,
    });

    return this.removeEmptyTagsContent(evaluated);
  }

  private priceContentOrUndefined(
    content: string | undefined,
    evaluationParams: {
      contractData: ContractData;
      deviceData: DeviceData;
    },
  ) {
    return this.formatPreviewContent(content, evaluationParams) === undefined
      ? undefined
      : {
          type: PreviewContentType.Text,
          content: this.formatPreviewContent(content, evaluationParams),
        };
  }

  public toPreviewContent(params: {
    contractData: ContractData;
    deviceData: DeviceData;
  }): PreviewSlideContent {
    const previewPriceContent = PreviewPrice.create({
      price: this.priceContentOrUndefined(this.getPriceInteger(), params),
      top: this.priceContentOrUndefined(this.overline, params),
      bottom: this.priceContentOrUndefined(this.underline, params),
      currency: this.priceContentOrUndefined(this.getPriceCurrency(), params),
      cycle: this.priceContentOrUndefined(this.interval, params),
      decimals: this.priceContentOrUndefined(this.getPriceDecimal(), params),
    });

    const style = new CSSInlineStyle()
      .addPosition(this.position?.toJson())
      .addFontSize(this.scaling)
      .addZIndex(this.zIndex)
      .build();

    return PreviewSlideContent.createAsPrice({
      attributes: {
        style,
      },
      content: previewPriceContent,
      transition: this.transition,
    });
  }

  static create(params: CreateSlidePriceParams): SlidePrice {
    return new SlidePrice({
      label: params.label ?? DEFAULT_LABEL,
      overline: params.overline,
      value: params.value,
      interval: params.interval,
      underline: params.underline,
      scaling: params.scaling,
      position: params.position ? Position.create(params.position) : undefined,
      zIndex: params.zIndex,
      transition: params.transition,
      removed: params.removed,
      isDefault: params.isDefault,
    });
  }

  static createForValues(params: CreateSlidePriceForValuesParams): SlidePrice {
    return new SlidePrice({
      label: params.label,
      overline: params.overline,
      value: params.value,
      interval: params.interval,
      underline: params.underline,
      scaling: params.scaling,
      position: params.position,
      zIndex: params.zIndex,
      transition: params.transition,
      removed: params.removed,
      isDefault: params.isDefault,
    });
  }

  public toJson() {
    return {
      label: this.label,
      overline: this.overline,
      value: this.value,
      underline: this.underline,
      interval: this.interval,
      scaling: this.scaling,
      position: this.position?.toJson(),
      zIndex: this.zIndex,
      transition: this.transition,
      removed: this.removed,
      isDefault: this.isDefault,
    };
  }

  get valueWithoutHTMLTags(): string {
    return this.value?.replace(HTML_TAG_REGEX, '') ?? '';
  }

  insertValueWithHTMLTags(value: string): string {
    const match = this.value?.match(HTML_TAG_REGEX);

    if (match) {
      const firstClosedTagIndex = match.findIndex(tag => tag.startsWith('</'));

      if (firstClosedTagIndex) {
        match.splice(firstClosedTagIndex, 0, value);
        return match.join('');
      }
    }
    return value;
  }

  getPriceCurrency(): string {
    return this.insertValueWithHTMLTags(
      this.valueWithoutHTMLTags.split(' ')[1] || PRICE_CURRENCY_FALLBACK,
    );
  }

  getPriceDecimal(): string {
    const splitPrice = this.valueWithoutHTMLTags.split(',')[1];

    return this.insertValueWithHTMLTags(
      splitPrice ? splitPrice.split(' ')[0] : PRICE_VALUES_FALLBACK,
    );
  }

  getPriceInteger(): string {
    const splitPrice = this.valueWithoutHTMLTags.split(',')[0];
    return this.insertValueWithHTMLTags(splitPrice ?? PRICE_VALUES_FALLBACK);
  }
}
