
import { computed, defineComponent, ref } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-collapsable-input',
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    label: String,
    disabled: Boolean,
    modelValue: { type: String, required: true },
    errorMessage: String,
    leftIcon: { type: String, required: false },
    rightIcon: { type: String, required: false },
    step: { type: Number, required: false },
    hasReset: { type: Boolean, default: false },
    initialModelValue: { type: String, required: false },
  },
  setup(props, context) {
    const inputType = ref(props.type);
    const onToggleShowPassword = () => {
      inputType.value = inputType.value === 'password' ? 'text' : 'password';
    };

    const onInput = (event: Event) => {
      const value = (event?.target as HTMLInputElement)?.value;

      if (props.type === 'number' && isNaN(parseFloat(value))) return;

      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };

    const onReset = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue !== props.modelValue
      );
    });

    const isExpanded = ref(false);
    const toggleExpanded = () => {
      isExpanded.value = !isExpanded.value;
    };

    return {
      toggleExpanded,
      isExpanded,
      isResetVisible,
      onReset,
      inputType,
      onToggleShowPassword,
      onInput,
    };
  },
});
